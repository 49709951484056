import { getDelayVal, mswBaseUri } from 'mocks/utils/utils';
import { rest } from 'msw';
import {
  createStoriedSummaryData,
  getBreakdownRecoveryData,
  getBreakdownSleepData,
  getBreakdownStrainData,
  getComplianceData,
  getMemberOverviewData,
  getMemberTrendsData,
  getMemberOverviewV2Data,
  getMemberDayOverview,
} from 'mocks/actions/analyticsActions';
import {
  Pillars, RecoveryMetric, SleepMetric, StrainMetric,
} from 'types/analytics';
import { jsonCheckForPII } from '../transformer/deidentifyTransformer';

export const analyticsHandlers = [
  rest.get(mswBaseUri('v1/averages/sleep'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(createStoriedSummaryData(Pillars.SLEEP, SleepMetric.PERFORMANCE)),
  )),
  rest.get(mswBaseUri('v1/averages/recovery'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(createStoriedSummaryData(Pillars.RECOVERY, RecoveryMetric.SCORE)),
  )),
  rest.get(mswBaseUri('v1/averages/strain'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(createStoriedSummaryData(Pillars.STRAIN, StrainMetric.DAY_STRAIN)),
  )),
  rest.get(mswBaseUri('v2/breakdown/sleep'), (req, res) => {
    const cohortId = req.url.searchParams.get('cohortId');
    return res(jsonCheckForPII(getBreakdownSleepData(cohortId)));
  }),
  rest.get(mswBaseUri('v2/breakdown/recovery'), (req, res) => {
    const cohortId = req.url.searchParams.get('cohortId');
    return res(jsonCheckForPII(getBreakdownRecoveryData(cohortId)));
  }),
  rest.get(mswBaseUri('v2/breakdown/strain'), (req, res) => {
    const cohortId = req.url.searchParams.get('cohortId');
    return res(jsonCheckForPII(getBreakdownStrainData(cohortId)));
  }),
  rest.get(mswBaseUri('v1/member-overview'), (req, res) => {
    const memberId = req.url.searchParams.get('memberId');
    return res(jsonCheckForPII(getMemberOverviewData(memberId)));
  }),
  rest.get(mswBaseUri('v2/member-overview'), (req, res) => {
    const memberId = req.url.searchParams.get('memberId');
    return res(jsonCheckForPII(getMemberOverviewV2Data(memberId)));
  }),
  rest.delete(mswBaseUri('v1/cohorts/:groupId/member'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(204),
  )),
  rest.get(mswBaseUri('v2/leaderboard/sleep'), (req, res) => {
    const cohortId = req.url.searchParams.get('cohortId');
    return res(jsonCheckForPII(getBreakdownSleepData(cohortId)));
  }),
  rest.get(mswBaseUri('v2/leaderboard/sleep/aggregate'), (req, res, ctx) => {
    const cohortId = req.url.searchParams.get('cohortId');
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
      ctx.json(getBreakdownSleepData(cohortId)),
    );
  }),
  rest.get(mswBaseUri('v2/leaderboard/recovery'), (req, res) => {
    const cohortId = req.url.searchParams.get('cohortId');
    return res(jsonCheckForPII(getBreakdownRecoveryData(cohortId)));
  }),
  rest.get(mswBaseUri('v2/leaderboard/recovery/aggregate'), (req, res, ctx) => {
    const cohortId = req.url.searchParams.get('cohortId');
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
      ctx.json(getBreakdownRecoveryData(cohortId)),
    );
  }),
  rest.get(mswBaseUri('v2/leaderboard/strain'), (req, res) => {
    const cohortId = req.url.searchParams.get('cohortId');
    return res(jsonCheckForPII(getBreakdownStrainData(cohortId)));
  }),
  rest.get(mswBaseUri('v2/leaderboard/strain/aggregate'), (req, res, ctx) => {
    const cohortId = req.url.searchParams.get('cohortId');
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
      ctx.json(getBreakdownStrainData(cohortId)),
    );
  }),
  rest.get(mswBaseUri('v1/compliance/cohort'), (req, res) => {
    const cohortId = req.url.searchParams.get('cohortId');
    return res(jsonCheckForPII(getComplianceData(cohortId)));
  }),
  rest.get(mswBaseUri('v1/member/trends'), (req, res, ctx) => {
    const memberId = req.url.searchParams.get('memberId');
    const startDate = req.url.searchParams.get('startDate');
    const endDate = req.url.searchParams.get('endDate');
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
      ctx.json(getMemberTrendsData(memberId, startDate, endDate)),
    );
  }),
  rest.get(mswBaseUri('v2/day-overview'), (req, res, ctx) => {
    const memberId = req.url.searchParams.get('memberId');
    const date = req.url.searchParams.get('date');
    return res(
      ctx.delay(getDelayVal(50)),
      ctx.status(200),
      ctx.json(getMemberDayOverview(memberId, date)),
    );
  }),
  rest.get(mswBaseUri('v2/member/trends'), (req, res, ctx) => {
    const memberId = req.url.searchParams.get('memberId');
    const startDate = req.url.searchParams.get('startDate');
    const endDate = req.url.searchParams.get('endDate');
    return res(
      ctx.delay(getDelayVal(50)),
      ctx.status(200),
      ctx.json(getMemberTrendsData(memberId, startDate, endDate)),
    );
  }),
];

export default analyticsHandlers;
