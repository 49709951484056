import { ActivityOverview } from 'api/analyticsApi';
import { ActivityDetailV2 } from 'types/analytics';

export const mockSleepActivities: ActivityOverview[] = [
  {
    id: 'de817dc6-0a88-4980-a7b7-24210eb88bcf',
    activity_duration: 28752918,
    score_value: 89,
    type: 'sleep',
    cycle_start_date: '2023-02-07',
    start_time: '2023-02-07T01:26:17.320-05:00',
    end_time: '2023-02-07T09:25:30.238-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'fb827421-e19f-4e66-8482-ccb4bf5e40eb',
    activity_duration: 30858230,
    score_value: 100,
    type: 'sleep',
    cycle_start_date: '2023-02-08',
    start_time: '2023-02-07T23:42:30.468-05:00',
    end_time: '2023-02-08T08:16:48.698-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '17bbe43d-952a-4cc7-be31-5b4496a750e9',
    activity_duration: 28291489,
    score_value: 92,
    type: 'sleep',
    cycle_start_date: '2023-02-09',
    start_time: '2023-02-09T00:17:38.132-05:00',
    end_time: '2023-02-09T08:09:09.621-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '77cd45ff-c886-4a81-bfab-3ffc7e196bf8',
    activity_duration: 33341304,
    score_value: 100,
    type: 'sleep',
    cycle_start_date: '2023-02-10',
    start_time: '2023-02-09T23:29:18.072-05:00',
    end_time: '2023-02-10T08:44:59.376-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '45a78a38-ecee-424b-8925-2809cf3b1fe9',
    activity_duration: 3600000,
    score_value: 4,
    type: 'nap',
    cycle_start_date: '2023-02-23',
    start_time: '2023-02-10T10:51:00.000-05:00',
    end_time: '2023-02-10T11:51:00.000-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '5669805e-b20b-49dd-9646-4dd3d2bd6df6',
    activity_duration: 24946115,
    score_value: 84,
    type: 'sleep',
    cycle_start_date: '2023-02-11',
    start_time: '2023-02-11T00:52:09.354-05:00',
    end_time: '2023-02-11T07:47:55.469-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '4e9fc71e-8bac-43e6-ba30-268482fda8f0',
    activity_duration: 30021898,
    score_value: 95,
    type: 'sleep',
    cycle_start_date: '2023-02-12',
    start_time: '2023-02-11T23:28:41.604-05:00',
    end_time: '2023-02-12T07:49:03.502-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'f5049b08-d882-439c-8dba-8aacc35a193b',
    activity_duration: 33397969,
    score_value: 100,
    type: 'sleep',
    cycle_start_date: '2023-02-13',
    start_time: '2023-02-12T23:13:26.830-05:00',
    end_time: '2023-02-13T08:30:04.799-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'a4144cf8-f18c-4a69-8ca6-246b05dd5e88',
    activity_duration: 24859576,
    score_value: 83,
    type: 'sleep',
    cycle_start_date: '2023-02-14',
    start_time: '2023-02-14T01:05:24.761-05:00',
    end_time: '2023-02-14T07:59:44.337-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'cdb8691e-3061-4a49-8966-6d4f7386efd1',
    activity_duration: 32271302,
    score_value: 96,
    type: 'sleep',
    cycle_start_date: '2023-02-16',
    start_time: '2023-02-15T22:22:05.802-05:00',
    end_time: '2023-02-16T07:19:57.104-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '983604a6-9baa-4ef8-a885-97e19b62e098',
    activity_duration: 34293647,
    score_value: 100,
    type: 'sleep',
    cycle_start_date: '2023-02-17',
    start_time: '2023-02-16T23:06:09.554-05:00',
    end_time: '2023-02-17T08:37:43.201-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'b9ba77ec-45cd-44d8-9c16-6a9e706bbfb1',
    activity_duration: 25811283,
    score_value: 89,
    type: 'sleep',
    cycle_start_date: '2023-02-18',
    start_time: '2023-02-18T01:42:34.492-05:00',
    end_time: '2023-02-18T08:52:45.775-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '1aae2d19-f26a-42f3-b31d-2aca9dfc022a',
    activity_duration: 29906529,
    score_value: 96,
    type: 'sleep',
    cycle_start_date: '2023-02-19',
    start_time: '2023-02-18T22:51:02.600-05:00',
    end_time: '2023-02-19T07:09:29.129-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'a9c927c4-12f8-46a1-bc83-35af84f4e1e9',
    activity_duration: 34261290,
    score_value: 100,
    type: 'sleep',
    cycle_start_date: '2023-02-20',
    start_time: '2023-02-19T22:02:51.153-05:00',
    end_time: '2023-02-20T07:33:52.443-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'aa6c73a3-e8ec-40eb-b87c-513bd43b6a92',
    activity_duration: 1.805351E7,
    score_value: 55,
    type: 'sleep',
    cycle_start_date: '2023-02-21',
    start_time: '2023-02-20T23:56:38.901-05:00',
    end_time: '2023-02-21T07:51:32.250-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '28c7e6cb-65b6-4447-868c-bf3ff886f2cb',
    activity_duration: 29329744,
    score_value: 93,
    type: 'sleep',
    cycle_start_date: '2023-02-22',
    start_time: '2023-02-21T23:40:28.238-05:00',
    end_time: '2023-02-22T07:49:17.982-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '7283fb5d-c5df-42e9-b8ca-6814c40d5d60',
    activity_duration: 27520554,
    score_value: 90,
    type: 'sleep',
    cycle_start_date: '2023-02-23',
    start_time: '2023-02-22T23:46:16.557-05:00',
    end_time: '2023-02-23T07:24:57.111-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'c28cb95d-4373-4a95-8438-3543e9d62072',
    activity_duration: 33049939,
    score_value: 97,
    type: 'sleep',
    cycle_start_date: '2023-02-24',
    start_time: '2023-02-23T21:38:00.618-05:00',
    end_time: '2023-02-24T06:48:50.557-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'fd10db84-b192-40d8-a5ab-e954d8b6de71',
    activity_duration: 23475299,
    score_value: 74,
    type: 'sleep',
    cycle_start_date: '2023-02-25',
    start_time: '2023-02-25T02:32:31.767-05:00',
    end_time: '2023-02-25T09:03:47.066-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '316ed451-1f81-417b-8328-5071d030a705',
    activity_duration: 35587888,
    score_value: 100,
    type: 'sleep',
    cycle_start_date: '2023-02-26',
    start_time: '2023-02-25T23:10:14.433-05:00',
    end_time: '2023-02-26T09:03:22.321-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'f2750473-b038-4139-9a63-ef3a052faf64',
    activity_duration: 38125755,
    score_value: 100,
    type: 'sleep',
    cycle_start_date: '2023-02-27',
    start_time: '2023-02-26T22:27:56.074-05:00',
    end_time: '2023-02-27T09:03:21.829-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'af6cb0d8-2808-43ba-ba0c-afed22685c8f',
    activity_duration: 27166782,
    score_value: 87,
    type: 'sleep',
    cycle_start_date: '2023-02-28',
    start_time: '2023-02-28T00:43:19.626-05:00',
    end_time: '2023-02-28T08:16:06.408-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '7bd98f7d-4398-4b05-a595-20d59f4d136d',
    activity_duration: 27974275,
    score_value: 90,
    type: 'sleep',
    cycle_start_date: '2023-03-01',
    start_time: '2023-02-28T23:33:41.113-05:00',
    end_time: '2023-03-01T07:19:55.388-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '4bd6f5f8-7819-4fcf-b468-e242d9dda42b',
    activity_duration: 32098344,
    score_value: 100,
    type: 'sleep',
    cycle_start_date: '2023-03-02',
    start_time: '2023-03-01T22:49:45.113-05:00',
    end_time: '2023-03-02T07:44:43.457-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'c915c153-05a4-496c-8e50-3bd26725a8d4',
    activity_duration: 31897406,
    score_value: 100,
    type: 'sleep',
    cycle_start_date: '2023-03-03',
    start_time: '2023-03-03T00:08:27.739-05:00',
    end_time: '2023-03-03T09:00:05.145-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'd766f996-edf2-47cd-8ef7-9040be216fab',
    activity_duration: 5046868,
    score_value: 12,
    type: 'sleep',
    cycle_start_date: '2023-03-03',
    start_time: '2023-03-03T15:43:21.212-05:00',
    end_time: '2023-03-03T17:07:28.080-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'c0a1df26-61b8-466a-be70-82b17f99078c',
    activity_duration: 23533003,
    score_value: 91,
    type: 'sleep',
    cycle_start_date: '2023-03-04',
    start_time: '2023-03-04T00:25:25.151-05:00',
    end_time: '2023-03-04T06:57:38.154-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'aa7a727f-6aa6-4da5-977c-e838b66f92bb',
    activity_duration: 27801238,
    score_value: 86,
    type: 'sleep',
    cycle_start_date: '2023-03-05',
    start_time: '2023-03-04T23:26:39.502-05:00',
    end_time: '2023-03-05T07:10:00.740-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'de3d3637-903a-48b6-b026-da486a19e56a',
    activity_duration: 43413898,
    score_value: 100,
    type: 'sleep',
    cycle_start_date: '2023-03-06',
    start_time: '2023-03-05T21:03:27.874-05:00',
    end_time: '2023-03-06T09:07:01.772-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'df52a86a-9ccc-4743-9d8e-a9d006a77c05',
    activity_duration: 28060723,
    score_value: 90,
    type: 'sleep',
    cycle_start_date: '2023-03-07',
    start_time: '2023-03-06T23:51:22.840-05:00',
    end_time: '2023-03-07T07:39:03.563-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '4694059e-f832-4d81-a4fc-6ece0bf8f63c',
    activity_duration: 34144952,
    score_value: 100,
    type: 'sleep',
    cycle_start_date: '2023-03-08',
    start_time: '2023-03-07T22:30:43.157-05:00',
    end_time: '2023-03-08T07:59:48.109-05:00',
    zone_id: 'America/New_York',
  },
];

export const mockSleepActivityDetail: ActivityDetailV2 = {
  stage_durations: [
    {
      stage_name: 'rem_sleep_stage',
      stage_duration: 4187348,
    },
    {
      stage_name: 'sws_sleep_stage',
      stage_duration: 4649413,
    },
    {
      stage_name: 'light_sleep_stage',
      stage_duration: 10077502,
    },
    {
      stage_name: 'awake_stage',
      stage_duration: 1530542,
    },
  ],
  hr_metrics: [
    {
      hr: 53,
      user_id: 20333,
      timestamp: 1667462982016,
    },
    {
      hr: 52,
      user_id: 20333,
      timestamp: 1667463042579,
    },
    {
      hr: 52,
      user_id: 20333,
      timestamp: 1667463103141,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667463163704,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667463224266,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667463284829,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667463345391,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667463405954,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667463466516,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667463527079,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667463587641,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667463648204,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667463708766,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667463769329,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667463829891,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667463890454,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667463951016,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667464011579,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667464072141,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667464132704,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667464193266,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667464253829,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667464314391,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667464374954,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667464435516,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667464496079,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667464556641,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667464617204,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667464677766,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667464738329,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667464798891,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667464859454,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667464920016,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667464980592,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667465041154,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667465101717,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667465162279,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667465222842,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667465283404,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667465343967,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667465404529,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667465465092,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667465525654,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667465586217,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667465646779,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667465707342,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667465767904,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667465828467,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667465889029,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667465949592,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667466010154,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667466070717,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667466131292,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667466191854,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667466252417,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667466312979,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667466373542,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667466434104,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667466494667,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667466555229,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667466615792,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667466676354,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667466736917,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667466797479,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667466858042,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667466918604,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667466979167,
    },
    {
      hr: 52,
      user_id: 20333,
      timestamp: 1667467039730,
    },
    {
      hr: 52,
      user_id: 20333,
      timestamp: 1667467100292,
    },
    {
      hr: 53,
      user_id: 20333,
      timestamp: 1667467160855,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667467221417,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667467281992,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667467342555,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667467403117,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667467463680,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667467524242,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667467584805,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667467645367,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667467705930,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667467766492,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667467827055,
    },
    {
      hr: 54,
      user_id: 20333,
      timestamp: 1667467887617,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667467948180,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667468008743,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667468069305,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667468129868,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667468190430,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667468250993,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667468311555,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667468372118,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667468432693,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667468493255,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667468553818,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667468614380,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667468674943,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667468735505,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667468796068,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667468856630,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667468917193,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667468977756,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667469038318,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667469098881,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667469159443,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667469220006,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667469280568,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667469341131,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667469401693,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667469462256,
    },
    {
      hr: 64,
      user_id: 20333,
      timestamp: 1667469522818,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667469583393,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667469643956,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667469704519,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667469765081,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667469825644,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667469886206,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667469946769,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667470007331,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667470067894,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667470128456,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667470189019,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667470249581,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667470310144,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667470370706,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667470431269,
    },
    {
      hr: 46,
      user_id: 20333,
      timestamp: 1667470491831,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667470552394,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667470612957,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667470673519,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667470734094,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667470794657,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667470855219,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667470915781,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667470976344,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667471036907,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667471097469,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667471158032,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667471218594,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667471279157,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667471339719,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667471400282,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667471460844,
    },
    {
      hr: 62,
      user_id: 20333,
      timestamp: 1667471521406,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667471581969,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667471642532,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667471703094,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667471763657,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667471824219,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667471884795,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667471945357,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667472005920,
    },
    {
      hr: 53,
      user_id: 20333,
      timestamp: 1667472066482,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667472127045,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667472187607,
    },
    {
      hr: 46,
      user_id: 20333,
      timestamp: 1667472248170,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667472308732,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667472369295,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667472429857,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667472490420,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667472550982,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667472611545,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667472672107,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667472732670,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667472793232,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667472853795,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667472914357,
    },
    {
      hr: 52,
      user_id: 20333,
      timestamp: 1667472974920,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667473035490,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667473096057,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667473156620,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667473217182,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667473277745,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667473338307,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667473398870,
    },
    {
      hr: 46,
      user_id: 20333,
      timestamp: 1667473459432,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667473519995,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667473580557,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667473641120,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667473701682,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667473762245,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667473822807,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667473883370,
    },
    {
      hr: 46,
      user_id: 20333,
      timestamp: 1667473943932,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667474004495,
    },
    {
      hr: 46,
      user_id: 20333,
      timestamp: 1667474065057,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667474125620,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667474186191,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667474246758,
    },
    {
      hr: 46,
      user_id: 20333,
      timestamp: 1667474307320,
    },
    {
      hr: 46,
      user_id: 20333,
      timestamp: 1667474367883,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667474428445,
    },
    {
      hr: 44,
      user_id: 20333,
      timestamp: 1667474489008,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667474549570,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667474610133,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667474670695,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667474731258,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667474791820,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667474852383,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667474912945,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667474973508,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667475034070,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667475094633,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667475155195,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667475215758,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667475276320,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667475336891,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667475397458,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667475458020,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667475518583,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667475579145,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667475639708,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667475700270,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667475760833,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667475821395,
    },
    {
      hr: 46,
      user_id: 20333,
      timestamp: 1667475881958,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667475942520,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667476003083,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667476063645,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667476124208,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667476184770,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667476245333,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667476305895,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667476366458,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667476427020,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667476487591,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667476548158,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667476608720,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667476669283,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667476729845,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667476790408,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667476850970,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667476911533,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667476972095,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667477032658,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667477093220,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667477153783,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667477214345,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667477274908,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667477335470,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667477396033,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667477456595,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667477517158,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667477577720,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667477638291,
    },
    {
      hr: 59,
      user_id: 20333,
      timestamp: 1667477698858,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667477759420,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667477819983,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667477880545,
    },
    {
      hr: 57,
      user_id: 20333,
      timestamp: 1667477941108,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667478001670,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667478062233,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667478122795,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667478183358,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667478243920,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667478304483,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667478365045,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667478425608,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667478486170,
    },
    {
      hr: 46,
      user_id: 20333,
      timestamp: 1667478546733,
    },
    {
      hr: 52,
      user_id: 20333,
      timestamp: 1667478607295,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667478667858,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667478728420,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667478788991,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667478849558,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667478910120,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667478970683,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667479031245,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667479091808,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667479152370,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667479212933,
    },
    {
      hr: 46,
      user_id: 20333,
      timestamp: 1667479273495,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667479334058,
    },
    {
      hr: 78,
      user_id: 20333,
      timestamp: 1667479394620,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667479455183,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667479515745,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667479576308,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667479636870,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667479697433,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667479757995,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667479818558,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667479879120,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667479939691,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667480000258,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667480060820,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667480121383,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667480181945,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667480242508,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667480303070,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667480363633,
    },
    {
      hr: 56,
      user_id: 20333,
      timestamp: 1667480424195,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667480484758,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667480545320,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667480605883,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667480666445,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667480727008,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667480787570,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667480848133,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667480908695,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667480969258,
    },
    {
      hr: 66,
      user_id: 20333,
      timestamp: 1667481029820,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667481090383,
    },
    {
      hr: 56,
      user_id: 20333,
      timestamp: 1667481150947,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667481211509,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667481272072,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667481332634,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667481393197,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667481453759,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667481514322,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667481574884,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667481635447,
    },
    {
      hr: 47,
      user_id: 20333,
      timestamp: 1667481696009,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667481756572,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667481817134,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667481877697,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667481938259,
    },
    {
      hr: 54,
      user_id: 20333,
      timestamp: 1667481998822,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667482059384,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667482119947,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667482180509,
    },
    {
      hr: 49,
      user_id: 20333,
      timestamp: 1667482241072,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667482301646,
    },
    {
      hr: 48,
      user_id: 20333,
      timestamp: 1667482362209,
    },
    {
      hr: 46,
      user_id: 20333,
      timestamp: 1667482422771,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667482483334,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667482543896,
    },
    {
      hr: 50,
      user_id: 20333,
      timestamp: 1667482604459,
    },
    {
      hr: 52,
      user_id: 20333,
      timestamp: 1667482665021,
    },
    {
      hr: 53,
      user_id: 20333,
      timestamp: 1667482725584,
    },
    {
      hr: 52,
      user_id: 20333,
      timestamp: 1667482786146,
    },
    {
      hr: 62,
      user_id: 20333,
      timestamp: 1667482846709,
    },
    {
      hr: 52,
      user_id: 20333,
      timestamp: 1667482907271,
    },
    {
      hr: 52,
      user_id: 20333,
      timestamp: 1667482967834,
    },
    {
      hr: 53,
      user_id: 20333,
      timestamp: 1667483028396,
    },
    {
      hr: 67,
      user_id: 20333,
      timestamp: 1667483088959,
    },
    {
      hr: 54,
      user_id: 20333,
      timestamp: 1667483149521,
    },
    {
      hr: 57,
      user_id: 20333,
      timestamp: 1667483210084,
    },
    {
      hr: 51,
      user_id: 20333,
      timestamp: 1667483270646,
    },
    {
      hr: 56,
      user_id: 20333,
      timestamp: 1667483331209,
    },
    {
      hr: 58,
      user_id: 20333,
      timestamp: 1667483391771,
    },
  ],
  metrics: [
    {
      metric_name: 'sleepDuration',
      metric_value: 4094166.0,
    },
    {
      metric_name: 'sleepLatency',
      metric_value: 172074.0,
    },
    {
      metric_name: 'sleepConsistency',
      metric_value: 42.0,
    },
    {
      metric_name: 'sleepDisturbances',
      metric_value: 2.0,
    },
    {
      metric_name: 'respiratoryRate',
      metric_value: 15.17578125,
    },
    {
      metric_name: 'sleepEfficiency',
      metric_value: 93.48212480545044,
    },
  ],
};

export const mockStrainActivities: ActivityOverview[] = [
  {
    id: '4c9e90ac-c52f-49b9-a4cf-d608ccb56ef1',
    activity_duration: 3546243,
    score_value: 13.53983870101991,
    type: 'running',
    cycle_start_date: '2023-02-06',
    start_time: '2023-02-06T22:39:23.565-05:00',
    end_time: '2023-02-06T23:38:29.808-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '35387d45-5b8f-414e-8863-b9e9a2817dd2',
    activity_duration: 1383274,
    score_value: 11.645060685804573,
    type: 'running',
    cycle_start_date: '2023-02-16',
    start_time: '2023-02-16T07:53:02.186-08:00',
    end_time: '2023-02-16T08:16:05.460-08:00',
    // zone_id: 'America/New_York',
    zone_id: 'America/Los_Angeles',
  },
  {
    id: 'c658fc08-ffe0-41a7-8084-95722ab4ca78',
    activity_duration: 1260000,
    score_value: 10.184679316099855,
    type: 'yoga',
    cycle_start_date: '2023-02-20',
    start_time: '2023-02-20T10:49:00.000-05:00',
    end_time: '2023-02-20T11:10:00.000-05:00',
    // zone_id: 'America/New_York',
    zone_id: null,
  },
  {
    id: '297b46da-381b-4601-9d39-dac19513256e',
    activity_duration: 2681082,
    score_value: 11.982417488746128,
    type: 'activity',
    cycle_start_date: '2023-02-21',
    start_time: '2023-02-20T20:55:11.221-05:00',
    end_time: '2023-02-20T21:39:52.303-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '27f06ab1-da99-4d00-860b-cc4a105023f4',
    activity_duration: 1729360,
    score_value: 11.259410853250113,
    type: 'running',
    cycle_start_date: '2023-02-10',
    start_time: '2023-02-10T15:04:38.483-05:00',
    end_time: '2023-02-10T15:33:27.843-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '428f2990-2334-4459-aa71-b63fb40c1dde',
    activity_duration: 120000,
    score_value: 0.0010244026243924926,
    type: 'yoga',
    cycle_start_date: '2023-02-23',
    start_time: '2023-02-23T16:09:00.000-05:00',
    end_time: '2023-02-23T16:11:00.000-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '9b42b0d5-e5c5-4240-be5c-bbceb8691808',
    activity_duration: 480000,
    score_value: 0.0031358772249666204,
    type: 'snowboarding',
    cycle_start_date: '2023-02-23',
    start_time: '2023-02-23T16:30:00.000-05:00',
    end_time: '2023-02-23T16:38:00.000-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'de20349b-3a7b-4e86-8f2e-612315e82e33',
    activity_duration: 1200000,
    score_value: 0.00734024188328209,
    type: 'running',
    cycle_start_date: '2023-02-23',
    start_time: '2023-02-23T16:50:00.000-05:00',
    end_time: '2023-02-23T17:10:00.000-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '46cb5cb7-80a2-49a6-8f54-0a1e322bcb59',
    activity_duration: 4326836,
    score_value: 10.839980793953538,
    type: 'activity',
    cycle_start_date: '2023-02-26',
    start_time: '2023-02-26T10:45:13.362-05:00',
    end_time: '2023-02-26T11:57:20.198-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: 'e0e50f3f-b024-4ceb-9f58-dff3873d48d6',
    activity_duration: 2940634,
    score_value: 13.542309377541498,
    type: 'activity',
    cycle_start_date: '2023-02-27',
    start_time: '2023-02-27T20:47:16.733-05:00',
    end_time: '2023-02-27T21:36:17.367-05:00',
    zone_id: 'America/New_York',
  },
  {
    id: '74561637-6d88-4473-a0b0-bafc33b9508c',
    activity_duration: 1815892,
    score_value: 10.2950624392555,
    type: 'activity',
    cycle_start_date: '2023-03-03',
    start_time: '2023-03-03T17:17:28.896-05:00',
    end_time: '2023-03-03T17:47:44.788-05:00',
    zone_id: 'America/New_York',
  },
];

export const mockStrainActivityDetail: ActivityDetailV2 = {
  stage_durations: [
    {
      stage_name: 'strain_stage_one',
      stage_duration: 1249.7,
    },
    {
      stage_name: 'strain_stage_two',
      stage_duration: 50472.5,
    },
    {
      stage_name: 'strain_stage_three',
      stage_duration: 475844,
    },
    {
      stage_name: 'strain_stage_four',
      stage_duration: 369128,
    },
    {
      stage_name: 'strain_stage_five',
      stage_duration: 504684,
    },
    {
      stage_name: 'strain_stage_six',
      stage_duration: 295121,
    },
  ],
  metrics: [
    {
      metric_name: 'activity_strain',
      metric_value: 12.3,
    },
    {
      metric_name: 'calories',
      metric_value: 646,
    },
    {
      metric_name: 'max_heart_rate',
      metric_value: 173,
    },
    {
      metric_name: 'averageHeartRate',
      metric_value: 132,
    },
    {
      metric_name: 'activity_duration',
      metric_value: 4200000,
    },
  ],
  hr_metrics: [
    {
      hr: 100,
      user_id: 30991,
      timestamp: 1665855373486,
    },
    {
      hr: 108,
      user_id: 30991,
      timestamp: 1665855380215,
    },
    {
      hr: 118,
      user_id: 30991,
      timestamp: 1665855386944,
    },
    {
      hr: 119,
      user_id: 30991,
      timestamp: 1665855393673,
    },
    {
      hr: 117,
      user_id: 30991,
      timestamp: 1665855400402,
    },
    {
      hr: 126,
      user_id: 30991,
      timestamp: 1665855407131,
    },
    {
      hr: 133,
      user_id: 30991,
      timestamp: 1665855413860,
    },
    {
      hr: 136,
      user_id: 30991,
      timestamp: 1665855420590,
    },
    {
      hr: 142,
      user_id: 30991,
      timestamp: 1665855427319,
    },
    {
      hr: 146,
      user_id: 30991,
      timestamp: 1665855434048,
    },
    {
      hr: 147,
      user_id: 30991,
      timestamp: 1665855440777,
    },
    {
      hr: 147,
      user_id: 30991,
      timestamp: 1665855447506,
    },
    {
      hr: 147,
      user_id: 30991,
      timestamp: 1665855454235,
    },
    {
      hr: 147,
      user_id: 30991,
      timestamp: 1665855460965,
    },
    {
      hr: 143,
      user_id: 30991,
      timestamp: 1665855467694,
    },
    {
      hr: 138,
      user_id: 30991,
      timestamp: 1665855474423,
    },
    {
      hr: 138,
      user_id: 30991,
      timestamp: 1665855481152,
    },
    {
      hr: 146,
      user_id: 30991,
      timestamp: 1665855487881,
    },
    {
      hr: 149,
      user_id: 30991,
      timestamp: 1665855494610,
    },
    {
      hr: 148,
      user_id: 30991,
      timestamp: 1665855501339,
    },
    {
      hr: 153,
      user_id: 30991,
      timestamp: 1665855508068,
    },
    {
      hr: 156,
      user_id: 30991,
      timestamp: 1665855514797,
    },
    {
      hr: 161,
      user_id: 30991,
      timestamp: 1665855521527,
    },
    {
      hr: 165,
      user_id: 30991,
      timestamp: 1665855528256,
    },
    {
      hr: 167,
      user_id: 30991,
      timestamp: 1665855534985,
    },
    {
      hr: 172,
      user_id: 30991,
      timestamp: 1665855541714,
    },
    {
      hr: 172,
      user_id: 30991,
      timestamp: 1665855548443,
    },
    {
      hr: 172,
      user_id: 30991,
      timestamp: 1665855555172,
    },
    {
      hr: 174,
      user_id: 30991,
      timestamp: 1665855561901,
    },
    {
      hr: 170,
      user_id: 30991,
      timestamp: 1665855568631,
    },
    {
      hr: 170,
      user_id: 30991,
      timestamp: 1665855575360,
    },
    {
      hr: 173,
      user_id: 30991,
      timestamp: 1665855582089,
    },
    {
      hr: 173,
      user_id: 30991,
      timestamp: 1665855588818,
    },
    {
      hr: 175,
      user_id: 30991,
      timestamp: 1665855595547,
    },
    {
      hr: 174,
      user_id: 30991,
      timestamp: 1665855602276,
    },
    {
      hr: 172,
      user_id: 30991,
      timestamp: 1665855609005,
    },
    {
      hr: 177,
      user_id: 30991,
      timestamp: 1665855615735,
    },
    {
      hr: 178,
      user_id: 30991,
      timestamp: 1665855622464,
    },
    {
      hr: 176,
      user_id: 30991,
      timestamp: 1665855629193,
    },
    {
      hr: 180,
      user_id: 30991,
      timestamp: 1665855635922,
    },
    {
      hr: 173,
      user_id: 30991,
      timestamp: 1665855642651,
    },
    {
      hr: 178,
      user_id: 30991,
      timestamp: 1665855649380,
    },
    {
      hr: 176,
      user_id: 30991,
      timestamp: 1665855656109,
    },
    {
      hr: 183,
      user_id: 30991,
      timestamp: 1665855662838,
    },
    {
      hr: 182,
      user_id: 30991,
      timestamp: 1665855669568,
    },
    {
      hr: 180,
      user_id: 30991,
      timestamp: 1665855676297,
    },
    {
      hr: 181,
      user_id: 30991,
      timestamp: 1665855683026,
    },
    {
      hr: 179,
      user_id: 30991,
      timestamp: 1665855689755,
    },
    {
      hr: 181,
      user_id: 30991,
      timestamp: 1665855696484,
    },
    {
      hr: 188,
      user_id: 30991,
      timestamp: 1665855703214,
    },
    {
      hr: 186,
      user_id: 30991,
      timestamp: 1665855709942,
    },
    {
      hr: 183,
      user_id: 30991,
      timestamp: 1665855716672,
    },
    {
      hr: 180,
      user_id: 30991,
      timestamp: 1665855723401,
    },
    {
      hr: 173,
      user_id: 30991,
      timestamp: 1665855730130,
    },
    {
      hr: 167,
      user_id: 30991,
      timestamp: 1665855736859,
    },
    {
      hr: 170,
      user_id: 30991,
      timestamp: 1665855743588,
    },
    {
      hr: 176,
      user_id: 30991,
      timestamp: 1665855750317,
    },
    {
      hr: 171,
      user_id: 30991,
      timestamp: 1665855757046,
    },
    {
      hr: 174,
      user_id: 30991,
      timestamp: 1665855763776,
    },
    {
      hr: 177,
      user_id: 30991,
      timestamp: 1665855770505,
    },
    {
      hr: 174,
      user_id: 30991,
      timestamp: 1665855777234,
    },
    {
      hr: 175,
      user_id: 30991,
      timestamp: 1665855783963,
    },
    {
      hr: 173,
      user_id: 30991,
      timestamp: 1665855790692,
    },
    {
      hr: 174,
      user_id: 30991,
      timestamp: 1665855797421,
    },
    {
      hr: 181,
      user_id: 30991,
      timestamp: 1665855804150,
    },
    {
      hr: 181,
      user_id: 30991,
      timestamp: 1665855810880,
    },
    {
      hr: 181,
      user_id: 30991,
      timestamp: 1665855817609,
    },
    {
      hr: 184,
      user_id: 30991,
      timestamp: 1665855824338,
    },
    {
      hr: 186,
      user_id: 30991,
      timestamp: 1665855831067,
    },
    {
      hr: 186,
      user_id: 30991,
      timestamp: 1665855837796,
    },
    {
      hr: 183,
      user_id: 30991,
      timestamp: 1665855844525,
    },
    {
      hr: 185,
      user_id: 30991,
      timestamp: 1665855851254,
    },
    {
      hr: 183,
      user_id: 30991,
      timestamp: 1665855857983,
    },
    {
      hr: 180,
      user_id: 30991,
      timestamp: 1665855864713,
    },
    {
      hr: 183,
      user_id: 30991,
      timestamp: 1665855871442,
    },
    {
      hr: 183,
      user_id: 30991,
      timestamp: 1665855878171,
    },
    {
      hr: 181,
      user_id: 30991,
      timestamp: 1665855884900,
    },
    {
      hr: 176,
      user_id: 30991,
      timestamp: 1665855891629,
    },
    {
      hr: 176,
      user_id: 30991,
      timestamp: 1665855898358,
    },
    {
      hr: 170,
      user_id: 30991,
      timestamp: 1665855905087,
    },
    {
      hr: 166,
      user_id: 30991,
      timestamp: 1665855911817,
    },
    {
      hr: 166,
      user_id: 30991,
      timestamp: 1665855918546,
    },
    {
      hr: 165,
      user_id: 30991,
      timestamp: 1665855925275,
    },
    {
      hr: 166,
      user_id: 30991,
      timestamp: 1665855932004,
    },
    {
      hr: 164,
      user_id: 30991,
      timestamp: 1665855938733,
    },
    {
      hr: 162,
      user_id: 30991,
      timestamp: 1665855945463,
    },
    {
      hr: 159,
      user_id: 30991,
      timestamp: 1665855952191,
    },
    {
      hr: 163,
      user_id: 30991,
      timestamp: 1665855958921,
    },
    {
      hr: 166,
      user_id: 30991,
      timestamp: 1665855965650,
    },
    {
      hr: 166,
      user_id: 30991,
      timestamp: 1665855972379,
    },
    {
      hr: 161,
      user_id: 30991,
      timestamp: 1665855979108,
    },
    {
      hr: 159,
      user_id: 30991,
      timestamp: 1665855985837,
    },
    {
      hr: 158,
      user_id: 30991,
      timestamp: 1665855992566,
    },
    {
      hr: 159,
      user_id: 30991,
      timestamp: 1665855999295,
    },
    {
      hr: 161,
      user_id: 30991,
      timestamp: 1665856006025,
    },
    {
      hr: 156,
      user_id: 30991,
      timestamp: 1665856012754,
    },
    {
      hr: 157,
      user_id: 30991,
      timestamp: 1665856019483,
    },
    {
      hr: 155,
      user_id: 30991,
      timestamp: 1665856026212,
    },
    {
      hr: 160,
      user_id: 30991,
      timestamp: 1665856032941,
    },
    {
      hr: 161,
      user_id: 30991,
      timestamp: 1665856039670,
    },
    {
      hr: 162,
      user_id: 30991,
      timestamp: 1665856046399,
    },
    {
      hr: 160,
      user_id: 30991,
      timestamp: 1665856053128,
    },
    {
      hr: 158,
      user_id: 30991,
      timestamp: 1665856059858,
    },
    {
      hr: 152,
      user_id: 30991,
      timestamp: 1665856066587,
    },
    {
      hr: 150,
      user_id: 30991,
      timestamp: 1665856073316,
    },
    {
      hr: 144,
      user_id: 30991,
      timestamp: 1665856080045,
    },
    {
      hr: 133,
      user_id: 30991,
      timestamp: 1665856086774,
    },
    {
      hr: 130,
      user_id: 30991,
      timestamp: 1665856093503,
    },
    {
      hr: 129,
      user_id: 30991,
      timestamp: 1665856100232,
    },
    {
      hr: 124,
      user_id: 30991,
      timestamp: 1665856106962,
    },
    {
      hr: 126,
      user_id: 30991,
      timestamp: 1665856113691,
    },
    {
      hr: 122,
      user_id: 30991,
      timestamp: 1665856120420,
    },
    {
      hr: 123,
      user_id: 30991,
      timestamp: 1665856127149,
    },
    {
      hr: 119,
      user_id: 30991,
      timestamp: 1665856133878,
    },
    {
      hr: 111,
      user_id: 30991,
      timestamp: 1665856140607,
    },
    {
      hr: 110,
      user_id: 30991,
      timestamp: 1665856147336,
    },
    {
      hr: 106,
      user_id: 30991,
      timestamp: 1665856154066,
    },
    {
      hr: 110,
      user_id: 30991,
      timestamp: 1665856160795,
    },
    {
      hr: 104,
      user_id: 30991,
      timestamp: 1665856167524,
    },
    {
      hr: 106,
      user_id: 30991,
      timestamp: 1665856174253,
    },
    {
      hr: 104,
      user_id: 30991,
      timestamp: 1665856180982,
    },
    {
      hr: 107,
      user_id: 30991,
      timestamp: 1665856187711,
    },
    {
      hr: 107,
      user_id: 30991,
      timestamp: 1665856194440,
    },
    {
      hr: 104,
      user_id: 30991,
      timestamp: 1665856201169,
    },
    {
      hr: 104,
      user_id: 30991,
      timestamp: 1665856207899,
    },
    {
      hr: 103,
      user_id: 30991,
      timestamp: 1665856214628,
    },
    {
      hr: 107,
      user_id: 30991,
      timestamp: 1665856221357,
    },
    {
      hr: 104,
      user_id: 30991,
      timestamp: 1665856228086,
    },
    {
      hr: 114,
      user_id: 30991,
      timestamp: 1665856234815,
    },
    {
      hr: 127,
      user_id: 30991,
      timestamp: 1665856241544,
    },
    {
      hr: 141,
      user_id: 30991,
      timestamp: 1665856248274,
    },
    {
      hr: 147,
      user_id: 30991,
      timestamp: 1665856255003,
    },
    {
      hr: 151,
      user_id: 30991,
      timestamp: 1665856261732,
    },
    {
      hr: 138,
      user_id: 30991,
      timestamp: 1665856268461,
    },
    {
      hr: 126,
      user_id: 30991,
      timestamp: 1665856275190,
    },
    {
      hr: 119,
      user_id: 30991,
      timestamp: 1665856281919,
    },
    {
      hr: 119,
      user_id: 30991,
      timestamp: 1665856288648,
    },
    {
      hr: 118,
      user_id: 30991,
      timestamp: 1665856295377,
    },
    {
      hr: 121,
      user_id: 30991,
      timestamp: 1665856302107,
    },
    {
      hr: 120,
      user_id: 30991,
      timestamp: 1665856308836,
    },
    {
      hr: 120,
      user_id: 30991,
      timestamp: 1665856315565,
    },
    {
      hr: 114,
      user_id: 30991,
      timestamp: 1665856322294,
    },
    {
      hr: 119,
      user_id: 30991,
      timestamp: 1665856329023,
    },
    {
      hr: 133,
      user_id: 30991,
      timestamp: 1665856335752,
    },
    {
      hr: 147,
      user_id: 30991,
      timestamp: 1665856342481,
    },
    {
      hr: 150,
      user_id: 30991,
      timestamp: 1665856349211,
    },
    {
      hr: 147,
      user_id: 30991,
      timestamp: 1665856355940,
    },
    {
      hr: 136,
      user_id: 30991,
      timestamp: 1665856362669,
    },
    {
      hr: 123,
      user_id: 30991,
      timestamp: 1665856369398,
    },
    {
      hr: 110,
      user_id: 30991,
      timestamp: 1665856376127,
    },
    {
      hr: 106,
      user_id: 30991,
      timestamp: 1665856382856,
    },
    {
      hr: 101,
      user_id: 30991,
      timestamp: 1665856389585,
    },
    {
      hr: 101,
      user_id: 30991,
      timestamp: 1665856396314,
    },
    {
      hr: 106,
      user_id: 30991,
      timestamp: 1665856403044,
    },
    {
      hr: 104,
      user_id: 30991,
      timestamp: 1665856409773,
    },
    {
      hr: 101,
      user_id: 30991,
      timestamp: 1665856416502,
    },
    {
      hr: 115,
      user_id: 30991,
      timestamp: 1665856423231,
    },
    {
      hr: 129,
      user_id: 30991,
      timestamp: 1665856429960,
    },
    {
      hr: 143,
      user_id: 30991,
      timestamp: 1665856436689,
    },
    {
      hr: 145,
      user_id: 30991,
      timestamp: 1665856443418,
    },
    {
      hr: 138,
      user_id: 30991,
      timestamp: 1665856450148,
    },
    {
      hr: 133,
      user_id: 30991,
      timestamp: 1665856456877,
    },
    {
      hr: 119,
      user_id: 30991,
      timestamp: 1665856463606,
    },
    {
      hr: 107,
      user_id: 30991,
      timestamp: 1665856470335,
    },
    {
      hr: 98,
      user_id: 30991,
      timestamp: 1665856477064,
    },
    {
      hr: 96,
      user_id: 30991,
      timestamp: 1665856483793,
    },
    {
      hr: 100,
      user_id: 30991,
      timestamp: 1665856490523,
    },
    {
      hr: 110,
      user_id: 30991,
      timestamp: 1665856497252,
    },
    {
      hr: 119,
      user_id: 30991,
      timestamp: 1665856503981,
    },
    {
      hr: 121,
      user_id: 30991,
      timestamp: 1665856510710,
    },
    {
      hr: 133,
      user_id: 30991,
      timestamp: 1665856517439,
    },
    {
      hr: 139,
      user_id: 30991,
      timestamp: 1665856524168,
    },
    {
      hr: 144,
      user_id: 30991,
      timestamp: 1665856530897,
    },
    {
      hr: 145,
      user_id: 30991,
      timestamp: 1665856537626,
    },
    {
      hr: 143,
      user_id: 30991,
      timestamp: 1665856544355,
    },
    {
      hr: 134,
      user_id: 30991,
      timestamp: 1665856551085,
    },
    {
      hr: 121,
      user_id: 30991,
      timestamp: 1665856557814,
    },
    {
      hr: 118,
      user_id: 30991,
      timestamp: 1665856564543,
    },
    {
      hr: 115,
      user_id: 30991,
      timestamp: 1665856571272,
    },
    {
      hr: 112,
      user_id: 30991,
      timestamp: 1665856578001,
    },
    {
      hr: 113,
      user_id: 30991,
      timestamp: 1665856584730,
    },
    {
      hr: 121,
      user_id: 30991,
      timestamp: 1665856591459,
    },
    {
      hr: 124,
      user_id: 30991,
      timestamp: 1665856598189,
    },
    {
      hr: 138,
      user_id: 30991,
      timestamp: 1665856604918,
    },
    {
      hr: 152,
      user_id: 30991,
      timestamp: 1665856611647,
    },
    {
      hr: 165,
      user_id: 30991,
      timestamp: 1665856618376,
    },
    {
      hr: 171,
      user_id: 30991,
      timestamp: 1665856625105,
    },
    {
      hr: 162,
      user_id: 30991,
      timestamp: 1665856631834,
    },
    {
      hr: 150,
      user_id: 30991,
      timestamp: 1665856638563,
    },
    {
      hr: 145,
      user_id: 30991,
      timestamp: 1665856645293,
    },
    {
      hr: 144,
      user_id: 30991,
      timestamp: 1665856652022,
    },
    {
      hr: 133,
      user_id: 30991,
      timestamp: 1665856658751,
    },
    {
      hr: 130,
      user_id: 30991,
      timestamp: 1665856665480,
    },
    {
      hr: 131,
      user_id: 30991,
      timestamp: 1665856672209,
    },
    {
      hr: 137,
      user_id: 30991,
      timestamp: 1665856678938,
    },
    {
      hr: 134,
      user_id: 30991,
      timestamp: 1665856685667,
    },
    {
      hr: 128,
      user_id: 30991,
      timestamp: 1665856692397,
    },
    {
      hr: 128,
      user_id: 30991,
      timestamp: 1665856699126,
    },
    {
      hr: 125,
      user_id: 30991,
      timestamp: 1665856705855,
    },
    {
      hr: 124,
      user_id: 30991,
      timestamp: 1665856712584,
    },
    {
      hr: 124,
      user_id: 30991,
      timestamp: 1665856719313,
    },
    {
      hr: 125,
      user_id: 30991,
      timestamp: 1665856726042,
    },
    {
      hr: 122,
      user_id: 30991,
      timestamp: 1665856732771,
    },
    {
      hr: 115,
      user_id: 30991,
      timestamp: 1665856739500,
    },
    {
      hr: 112,
      user_id: 30991,
      timestamp: 1665856746230,
    },
    {
      hr: 109,
      user_id: 30991,
      timestamp: 1665856752959,
    },
    {
      hr: 108,
      user_id: 30991,
      timestamp: 1665856759688,
    },
    {
      hr: 109,
      user_id: 30991,
      timestamp: 1665856766417,
    },
    {
      hr: 117,
      user_id: 30991,
      timestamp: 1665856773146,
    },
    {
      hr: 117,
      user_id: 30991,
      timestamp: 1665856779875,
    },
    {
      hr: 113,
      user_id: 30991,
      timestamp: 1665856786604,
    },
    {
      hr: 111,
      user_id: 30991,
      timestamp: 1665856793334,
    },
    {
      hr: 109,
      user_id: 30991,
      timestamp: 1665856800063,
    },
    {
      hr: 109,
      user_id: 30991,
      timestamp: 1665856806792,
    },
    {
      hr: 112,
      user_id: 30991,
      timestamp: 1665856813521,
    },
    {
      hr: 113,
      user_id: 30991,
      timestamp: 1665856820250,
    },
    {
      hr: 115,
      user_id: 30991,
      timestamp: 1665856826979,
    },
    {
      hr: 109,
      user_id: 30991,
      timestamp: 1665856833708,
    },
    {
      hr: 115,
      user_id: 30991,
      timestamp: 1665856840438,
    },
    {
      hr: 116,
      user_id: 30991,
      timestamp: 1665856847167,
    },
    {
      hr: 115,
      user_id: 30991,
      timestamp: 1665856853896,
    },
    {
      hr: 124,
      user_id: 30991,
      timestamp: 1665856860625,
    },
    {
      hr: 125,
      user_id: 30991,
      timestamp: 1665856867354,
    },
    {
      hr: 125,
      user_id: 30991,
      timestamp: 1665856874083,
    },
    {
      hr: 135,
      user_id: 30991,
      timestamp: 1665856880813,
    },
    {
      hr: 138,
      user_id: 30991,
      timestamp: 1665856887542,
    },
    {
      hr: 136,
      user_id: 30991,
      timestamp: 1665856894271,
    },
    {
      hr: 145,
      user_id: 30991,
      timestamp: 1665856901000,
    },
    {
      hr: 152,
      user_id: 30991,
      timestamp: 1665856907729,
    },
    {
      hr: 152,
      user_id: 30991,
      timestamp: 1665856914458,
    },
    {
      hr: 160,
      user_id: 30991,
      timestamp: 1665856921187,
    },
    {
      hr: 161,
      user_id: 30991,
      timestamp: 1665856927917,
    },
    {
      hr: 162,
      user_id: 30991,
      timestamp: 1665856934646,
    },
    {
      hr: 167,
      user_id: 30991,
      timestamp: 1665856941375,
    },
    {
      hr: 167,
      user_id: 30991,
      timestamp: 1665856948104,
    },
    {
      hr: 167,
      user_id: 30991,
      timestamp: 1665856954833,
    },
    {
      hr: 172,
      user_id: 30991,
      timestamp: 1665856961562,
    },
    {
      hr: 177,
      user_id: 30991,
      timestamp: 1665856968291,
    },
    {
      hr: 181,
      user_id: 30991,
      timestamp: 1665856975021,
    },
    {
      hr: 185,
      user_id: 30991,
      timestamp: 1665856981750,
    },
    {
      hr: 178,
      user_id: 30991,
      timestamp: 1665856988479,
    },
    {
      hr: 179,
      user_id: 30991,
      timestamp: 1665856995208,
    },
    {
      hr: 177,
      user_id: 30991,
      timestamp: 1665857001937,
    },
    {
      hr: 179,
      user_id: 30991,
      timestamp: 1665857008666,
    },
    {
      hr: 179,
      user_id: 30991,
      timestamp: 1665857015395,
    },
    {
      hr: 170,
      user_id: 30991,
      timestamp: 1665857022125,
    },
    {
      hr: 173,
      user_id: 30991,
      timestamp: 1665857028854,
    },
    {
      hr: 177,
      user_id: 30991,
      timestamp: 1665857035583,
    },
    {
      hr: 181,
      user_id: 30991,
      timestamp: 1665857042312,
    },
    {
      hr: 182,
      user_id: 30991,
      timestamp: 1665857049041,
    },
    {
      hr: 172,
      user_id: 30991,
      timestamp: 1665857055770,
    },
    {
      hr: 175,
      user_id: 30991,
      timestamp: 1665857062499,
    },
    {
      hr: 177,
      user_id: 30991,
      timestamp: 1665857069228,
    },
    {
      hr: 184,
      user_id: 30991,
      timestamp: 1665857075958,
    },
    {
      hr: 183,
      user_id: 30991,
      timestamp: 1665857082687,
    },
    {
      hr: 176,
      user_id: 30991,
      timestamp: 1665857089416,
    },
    {
      hr: 174,
      user_id: 30991,
      timestamp: 1665857096145,
    },
    {
      hr: 181,
      user_id: 30991,
      timestamp: 1665857102874,
    },
    {
      hr: 183,
      user_id: 30991,
      timestamp: 1665857109603,
    },
    {
      hr: 184,
      user_id: 30991,
      timestamp: 1665857116332,
    },
    {
      hr: 182,
      user_id: 30991,
      timestamp: 1665857123062,
    },
    {
      hr: 178,
      user_id: 30991,
      timestamp: 1665857129791,
    },
    {
      hr: 176,
      user_id: 30991,
      timestamp: 1665857136520,
    },
    {
      hr: 178,
      user_id: 30991,
      timestamp: 1665857143249,
    },
    {
      hr: 176,
      user_id: 30991,
      timestamp: 1665857149978,
    },
    {
      hr: 172,
      user_id: 30991,
      timestamp: 1665857156708,
    },
    {
      hr: 169,
      user_id: 30991,
      timestamp: 1665857163436,
    },
    {
      hr: 165,
      user_id: 30991,
      timestamp: 1665857170166,
    },
    {
      hr: 156,
      user_id: 30991,
      timestamp: 1665857176895,
    },
    {
      hr: 143,
      user_id: 30991,
      timestamp: 1665857183624,
    },
    {
      hr: 135,
      user_id: 30991,
      timestamp: 1665857190353,
    },
    {
      hr: 130,
      user_id: 30991,
      timestamp: 1665857197082,
    },
    {
      hr: 125,
      user_id: 30991,
      timestamp: 1665857203811,
    },
    {
      hr: 117,
      user_id: 30991,
      timestamp: 1665857210540,
    },
    {
      hr: 116,
      user_id: 30991,
      timestamp: 1665857217270,
    },
    {
      hr: 112,
      user_id: 30991,
      timestamp: 1665857223999,
    },
    {
      hr: 108,
      user_id: 30991,
      timestamp: 1665857230728,
    },
    {
      hr: 105,
      user_id: 30991,
      timestamp: 1665857237457,
    },
    {
      hr: 104,
      user_id: 30991,
      timestamp: 1665857244186,
    },
    {
      hr: 106,
      user_id: 30991,
      timestamp: 1665857250915,
    },
    {
      hr: 107,
      user_id: 30991,
      timestamp: 1665857257644,
    },
    {
      hr: 113,
      user_id: 30991,
      timestamp: 1665857264373,
    },
    {
      hr: 109,
      user_id: 30991,
      timestamp: 1665857271103,
    },
    {
      hr: 111,
      user_id: 30991,
      timestamp: 1665857277832,
    },
    {
      hr: 109,
      user_id: 30991,
      timestamp: 1665857284561,
    },
    {
      hr: 107,
      user_id: 30991,
      timestamp: 1665857291290,
    },
    {
      hr: 107,
      user_id: 30991,
      timestamp: 1665857298019,
    },
    {
      hr: 107,
      user_id: 30991,
      timestamp: 1665857304748,
    },
    {
      hr: 110,
      user_id: 30991,
      timestamp: 1665857311477,
    },
    {
      hr: 119,
      user_id: 30991,
      timestamp: 1665857318207,
    },
    {
      hr: 119,
      user_id: 30991,
      timestamp: 1665857324936,
    },
    {
      hr: 115,
      user_id: 30991,
      timestamp: 1665857331665,
    },
    {
      hr: 118,
      user_id: 30991,
      timestamp: 1665857338394,
    },
    {
      hr: 124,
      user_id: 30991,
      timestamp: 1665857345123,
    },
    {
      hr: 137,
      user_id: 30991,
      timestamp: 1665857351852,
    },
    {
      hr: 127,
      user_id: 30991,
      timestamp: 1665857358581,
    },
    {
      hr: 117,
      user_id: 30991,
      timestamp: 1665857365311,
    },
    {
      hr: 121,
      user_id: 30991,
      timestamp: 1665857372040,
    },
    {
      hr: 126,
      user_id: 30991,
      timestamp: 1665857378769,
    },
    {
      hr: 124,
      user_id: 30991,
      timestamp: 1665857385498,
    },
    {
      hr: 137,
      user_id: 30991,
      timestamp: 1665857392227,
    },
    {
      hr: 137,
      user_id: 30991,
      timestamp: 1665857398956,
    },
    {
      hr: 137,
      user_id: 30991,
      timestamp: 1665857405685,
    },
    {
      hr: 137,
      user_id: 30991,
      timestamp: 1665857412414,
    },
    {
      hr: 136,
      user_id: 30991,
      timestamp: 1665857419144,
    },
    {
      hr: 133,
      user_id: 30991,
      timestamp: 1665857425873,
    },
    {
      hr: 146,
      user_id: 30991,
      timestamp: 1665857432602,
    },
    {
      hr: 154,
      user_id: 30991,
      timestamp: 1665857439331,
    },
    {
      hr: 153,
      user_id: 30991,
      timestamp: 1665857446060,
    },
    {
      hr: 146,
      user_id: 30991,
      timestamp: 1665857452789,
    },
    {
      hr: 142,
      user_id: 30991,
      timestamp: 1665857459519,
    },
    {
      hr: 149,
      user_id: 30991,
      timestamp: 1665857466248,
    },
    {
      hr: 154,
      user_id: 30991,
      timestamp: 1665857472962,
    },
    {
      hr: 140,
      user_id: 30991,
      timestamp: 1665857479691,
    },
    {
      hr: 140,
      user_id: 30991,
      timestamp: 1665857486420,
    },
    {
      hr: 138,
      user_id: 30991,
      timestamp: 1665857493149,
    },
    {
      hr: 133,
      user_id: 30991,
      timestamp: 1665857499878,
    },
    {
      hr: 128,
      user_id: 30991,
      timestamp: 1665857506607,
    },
    {
      hr: 121,
      user_id: 30991,
      timestamp: 1665857513336,
    },
    {
      hr: 115,
      user_id: 30991,
      timestamp: 1665857520066,
    },
    {
      hr: 114,
      user_id: 30991,
      timestamp: 1665857526795,
    },
    {
      hr: 113,
      user_id: 30991,
      timestamp: 1665857533524,
    },
  ],
};
