import {
  RecoveryBucket,
  SleepDebtBucket, SleepPerformanceBucket, StrainBucket, TrainingZoneBucket,
} from 'api/widgetApi';
import { WidgetType } from 'insights/components/widget/widget';
import dayjs from 'dayjs';
import { BREAKDOWN_LABEL_BY_WIDGET } from 'insights/components/widget/widgetFormatter';
import { PieChartLabel } from 'dataVisualizations/iconPieChart/iconPieChartConsts';
import { classes, Icon } from '@whoop/web-components';
import styles from './hoverDataComponent.module.scss';

type AllBuckets = RecoveryBucket |
StrainBucket | SleepPerformanceBucket | SleepDebtBucket | TrainingZoneBucket;

export type RangeHoverDataComponentProps = {
  bucket: AllBuckets;
  widgetType: WidgetType;
};

function RangeHoverDataComponent(
  { bucket, widgetType }: RangeHoverDataComponentProps,
) {
  return (
    <div className={styles.hoverData}>
      <div className={styles.rangeText}>
        {dayjs(bucket.date).format('dddd, MMM D, YYYY')}
      </div>
      <div className={classes(
        styles.rangeDataSection,
        BREAKDOWN_LABEL_BY_WIDGET[widgetType].length > 3 && styles.smallGapRangeDataSection,
      )}
      >
        {/* Reverse to match designs ordering of labels vs hover state */}
        {BREAKDOWN_LABEL_BY_WIDGET[widgetType].slice(0).reverse().map((label: PieChartLabel) => (
          <div
            className={classes(
              styles.label,
              BREAKDOWN_LABEL_BY_WIDGET[widgetType].length > 3 && styles.sizedLabel,
            )}
            key={label.key}
          >
            <div className={styles.labelSquare} style={{ backgroundColor: label.color }} />
            <div className={styles.labelCount}>{bucket[label.key as keyof AllBuckets]}</div>
            <Icon className={styles.icon} name="profile" />
            {/* Only want the name of the label, not the description */}
            <div className={styles.labelName}>{label.label.split('(')[0]}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RangeHoverDataComponent;
