import MessagePage from 'messagePage/messagePage';

type ErrorPageProps = {
  is404?: boolean;
  includeFooter?: boolean;
};

function ErrorPage({ is404 = true, includeFooter = true }: ErrorPageProps) {
  return (
    <MessagePage
      icon="error"
      title={is404 ? '404 Not Found' : 'Sorry, there was an error.'}
      subtitle={is404 ? 'Sorry, we could not find the page you were looking for' : 'Please try again'}
      includeFooter={includeFooter}
      showRedirectButton={is404}
    />
  );
}

export default ErrorPage;
