import { usePrivacy } from 'context/privacy-context';
import { SeriesType } from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import OverviewHRGraph from 'dataVisualizations/overviewHRGraph/overviewHRGraph';
import Card from 'progress/components/Card/card';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import { formatDate, getDateRangeString } from 'progress/profile/profileUtils';
import { useParams } from 'react-router-dom';
import { Pillars } from 'types/analytics';
import { PrivacyLevel } from 'types/dashboardUser';
import { UrlParams } from 'types/profile';
import { useDateRange } from 'insights/hooks/useDateRange';
import CardPlaceholder from 'progress/components/cardPlaceholder/cardPlaceholder';
import useDayOverview from 'api/hooks/useDayOverview';
import useInsightsRoutes from 'insights/insightsRoutes/useInsightsRoutes';
import styles from './hrOverviewCard.module.scss';

function HROverviewCard() {
  const { accountId, userId } = useParams<UrlParams>();
  const { isInPrivacyLevel } = usePrivacy();
  const { dateRange } = useDateRange();
  const { formattedDate: detailDate } = getDateRangeString(dateRange);
  const onInsightsTab = useInsightsRoutes();

  const { data: overviewData, isFetching } = useDayOverview(dateRange);

  const getAnnotationLink = (activityId: string, type: SeriesType) => {
    const annotationURL = (pillar: Pillars) => {
      const profileURLSection = onInsightsTab ? `insights/profile/${userId}` : `progress/${userId}/profile`;

      return `/accounts/${accountId}/${profileURLSection}/${pillar}?activityId=${activityId}&from=${formatDate(dateRange.from)}`;
    };

    if (type === SeriesType.ACTIVITY) {
      return annotationURL(Pillars.STRAIN);
    }
    if (type === SeriesType.SLEEP) {
      return annotationURL(Pillars.SLEEP);
    }
    return '';
  };

  // TODO: Bug with rendering the table's Axis https://github.com/plouc/nivo/issues/1006
  // If you remove the axes from the charts layers you don't need to set the state to null
  const renderGraph = () => {
    if (overviewData) {
      const numActivities = Object.keys(overviewData.activities).length;
      const numSleeps = Object.keys(overviewData.sleeps).length;
      const numDayHr = overviewData.day_hr.length;
      const isOverviewDataEmpty = numActivities === 0 && numSleeps === 0 && numDayHr === 0;
      if (!isOverviewDataEmpty) {
        return (
          <div className={styles.overviewHRGraph}>
            <OverviewHRGraph
              hrData={overviewData}
              getAnnotationLink={getAnnotationLink}
            />
          </div>
        );
      }
    }
    return <div> No Data for this day</div>;
  };

  if (isInPrivacyLevel(PrivacyLevel.all_metrics)) {
    return (
      <Card
        loading={isFetching}
        showBackground={false}
        id="HR Overview"
      >
        <CardHeader
          title="HR Overview"
          details={detailDate}
        />
        {renderGraph()}
      </Card>
    );
  }

  return (
    <CardPlaceholder
      icon="hide_privacy"
      cardTitle="HR Overview"
      cardDetails={detailDate}
      text="Your access to HR Overview is restricted"
      subtext={['If you think this is an error, please contact your admin.']}
    />
  );
}

export default HROverviewCard;
