import { Colors } from '@whoop/web-components';

export type PieChartLabel = {
  key: string;
  color: string;
  label: string;
};

export enum RecoveryLabel {
  GREEN = 'Green (67%+)',
  YELLOW = 'Yellow (34% - 66%)',
  RED = 'Red (< 34%)',
  MISSING = 'Missing or hidden data',
}

export enum StrainLabel {
  ALL_OUT = 'All out (18.0+)',
  STRENUOUS = 'Strenuous (14.1 - 18.0)',
  MODERATE = 'Moderate (10.1 - 14.0)',
  LIGHT = 'Light (< 10.0)',
  MISSING = 'Missing or hidden data',
}

export enum SleepPerformanceLabel {
  OPTIMAL = 'Optimal (85%+)',
  SUFFICIENT = 'Sufficient (71% - 85%)',
  POOR = 'Poor (< 71%)',
  MISSING = 'Missing or hidden data',
}

export enum SleepDebtLabel {
  HIGH = 'High (45+ mins)',
  MODERATE = 'Moderate (30-45 mins)',
  LOW = 'Low (< 30 mins)',
  MISSING = 'Missing or hidden data',
}

export enum TrainingZoneLabel {
  OVERREACHING = 'Overreaching',
  MAINTAINING = 'Maintaining',
  TAPERING = 'Tapering',
  PARTIAL = 'Partial data',
  MISSING = 'Missing or hidden data',
}

// These arrays are ordered in the order that the pie chart is displayed in
// This ordering is also tied to pie chart labels which uses the reverse order
// That will need to be updated if this ordering changes
export const PieChartLabels = {
  RECOVERY: [
    {
      key: 'red',
      color: Colors.brandingRed,
      label: RecoveryLabel.RED,
    },
    {
      key: 'yellow',
      color: Colors.yellow,
      label: RecoveryLabel.YELLOW,
    },
    {
      key: 'green',
      color: Colors.green,
      label: RecoveryLabel.GREEN,
    },
  ],
  STRAIN: [
    {
      key: 'light',
      color: Colors.strainBlueAlpha500,
      label: StrainLabel.LIGHT,
    },
    {
      key: 'moderate',
      color: Colors.strainBlueAlpha700,
      label: StrainLabel.MODERATE,
    },
    {
      key: 'strenuous',
      color: Colors.strainBlueAlpha850,
      label: StrainLabel.STRENUOUS,
    },
    {
      key: 'all_out',
      color: Colors.strainBlue,
      label: StrainLabel.ALL_OUT,
    },
  ],
  SLEEP_PERFORMANCE: [
    {
      key: 'poor',
      color: Colors.sleepBlueAlpha300,
      label: SleepPerformanceLabel.POOR,
    },
    {
      key: 'sufficient',
      color: Colors.sleepBlueAlpha600,
      label: SleepPerformanceLabel.SUFFICIENT,
    },
    {
      key: 'optimal',
      color: Colors.sleepBlue,
      label: SleepPerformanceLabel.OPTIMAL,
    },
  ],
  SLEEP_DEBT: [
    {
      key: 'low',
      color: Colors.sleepBlueAlpha300,
      label: SleepDebtLabel.LOW,
    },
    {
      key: 'moderate',
      color: Colors.sleepBlueAlpha600,
      label: SleepDebtLabel.MODERATE,
    },
    {
      key: 'high',
      color: Colors.sleepBlue,
      label: SleepDebtLabel.HIGH,
    },
  ],
  TRAINING_ZONES: [
    {
      key: 'tapering',
      color: Colors.hrZoneBlue,
      label: TrainingZoneLabel.TAPERING,
    },
    {
      key: 'maintaining',
      color: Colors.hrZoneTeal,
      label: TrainingZoneLabel.MAINTAINING,
    },
    {
      key: 'overreaching',
      color: Colors.hrZoneRed,
      label: TrainingZoneLabel.OVERREACHING,
    },
  ],
};
