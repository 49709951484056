import Table from 'components/table';
import { capitalizeWords } from 'groups/utils';
import { useMemo } from 'react';
import {
  CellProps,
  Column, Row, TableCommonProps,
} from 'react-table';
import { nameWithAvatarCell, normalCell } from 'tableUtils/tableCells';
import { SELECTION_COLUMN } from 'tableUtils/tableColumns';
import { CohortCollaborator } from 'types/cohort';
import { UseStatePropType } from 'types/useStatePropType';
import { Collaborator } from 'types/collaborator';
import { PRIVACY_LEVEL_NAME_MAP } from 'accountDetails/types';

type AvailableCollaboratorsTableProps = {
  availableCollaborators: CohortCollaborator[];
  loading: boolean;
  setSelectedRows: UseStatePropType<Row<CohortCollaborator>[]>;
};

function AvailableCollaboratorsTable({
  availableCollaborators,
  loading,
  setSelectedRows,
}: AvailableCollaboratorsTableProps) {
  const columns: Column<CohortCollaborator>[] = useMemo(() => [
    SELECTION_COLUMN,
    {
      Header: 'Collaborator Name',
      id: 'name',
      accessor: (collaborator: CohortCollaborator) => capitalizeWords(`${collaborator.first_name} ${collaborator.last_name}`),
      width: '30%',
      align: 'left',
      Cell: (cellProps:
      CellProps<CohortCollaborator | Collaborator>) => nameWithAvatarCell(cellProps),
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: (collaborator: CohortCollaborator) => (collaborator.email
        ? collaborator.email : null),
      width: '40%',
      align: 'left',
    },
    {
      Header: 'Privacy Level',
      id: 'privacy_level',
      accessor:
        (collaborator: CohortCollaborator) => PRIVACY_LEVEL_NAME_MAP[collaborator.privacy_level],
      width: '30%',
      align: 'left',
      Cell: normalCell,
    },
  ], []);

  const getRowStyles = (): TableCommonProps => ({
    style: {
      height: '80px',
    },
  });

  return (
    <Table
      columns={columns}
      data={availableCollaborators}
      loading={loading}
      tableName="availableGroupMembers"
      unit="available member"
      noDataFoundMessage="Selected members will appear here"
      tableLength={50}
      getAdditionalRowProps={getRowStyles}
      displayHeader={false}
      search={false}
      setSelectedRows={setSelectedRows}
    />
  );
}

export default AvailableCollaboratorsTable;
