import { Icon, Button, classes } from '@whoop/web-components';
import React, { useEffect, useRef, useState } from 'react';
import {
  ClassNames, DateRange, DayPicker,
} from 'react-day-picker';
import dayPickerStyles from 'react-day-picker/dist/style.module.css';
import dayjs from 'dayjs';
import { trackEvent } from 'helpers/analyticsTracking';
import { useParams } from 'react-router-dom';
import { useDateRange } from 'insights/hooks/useDateRange';
import styles from './customDatePicker.module.scss';
import captionStyles from './customCaption/customCaption.module.scss';
import CustomCaption from './customCaption/customCaption';
import {
  DAY_OF_WEEK_HEADERS,
  TODAY_DATE,
  formatDate,
  getDateRangeAsString,
  getNumOfDays,
  getPageUserIsOn,
} from './datePickerUtils/datePickerUtils';
import QuickSelect from './quickSelect/quickSelect';
import CustomDay from './customDay/customDay';

// The calendar input has 3 states,
// a user can select a FROM date
// a user can select a TO date
// a user can not be selecting anything = NONE
enum Updating {
  TO = 'to',
  FROM = 'from',
  NONE = 'none',
}

type CustomDatePickerProps = {
  disableBefore?: Date;
  theme?: 'light' | 'dark';
  disabled?: boolean;
};
function CustomDatePicker({ theme = 'dark', disabled = false, disableBefore }: CustomDatePickerProps) {
  const { tab, groupId, pillar } = useParams();
  const datePickerModal = useRef<HTMLDivElement>(null);
  const [currentlyUpdating, setCurrentlyUpdating] = useState<Updating>(Updating.NONE);
  // the date range is the source of truth
  // should only be set with the true range to display in the url
  const {
    dateRange: appliedDateRange,
    setDateRange: setAppliedDateRange,
    MAX_NUM_OF_DAYS_IN_RANGE,
  } = useDateRange();
  const [editedDateRange, setEditedDateRange] = useState<DateRange>(appliedDateRange);

  const classNames: ClassNames = {
    ...dayPickerStyles,
    root: styles.rootCalendar,
    head_row: styles.rowHead,
    month: styles.month,
    head_cell: styles.head_cell,
    table: styles.table,
    day: styles.day,
    day_range_start: styles.day_range_start,
    day_range_end: styles.day_range_end,
    day_range_middle: styles.day_range_middle,
    day_selected: styles.day_selected,
    day_today: styles.today_date,
    day_disabled: styles.disabled_day,
    button: styles.button,
    caption_start: captionStyles.caption_start,
    caption_end: styles.caption_end,
  };

  // a user can click on either the FROM or TO input
  // a user can deselect an input which then closes the modal
  const handleRadioGroupClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const newUpdating = (e.target as HTMLInputElement).id as Updating;
    setCurrentlyUpdating(
      (prevState) => {
        if (prevState === newUpdating) {
          setEditedDateRange(appliedDateRange);
          return Updating.NONE;
        }
        return newUpdating;
      },
    );
  };

  const cancelDateRange = () => {
    setEditedDateRange(appliedDateRange);
    setCurrentlyUpdating(Updating.NONE);
  };

  const applyDateRange = () => {
    const page = getPageUserIsOn(tab, groupId, pillar);
    trackEvent(`${page} - Custom Date Picker`, {
      date_range_start: dayjs(editedDateRange.from).startOf('day').toISOString(),
      date_range_end: dayjs(editedDateRange.to ?? editedDateRange.from).endOf('day').toISOString(),
      number_of_days: `${getNumOfDays(editedDateRange)}`,
    });
    setAppliedDateRange(editedDateRange);
    setCurrentlyUpdating(Updating.NONE);
  };

  const onSelectDate = (selectedDay: Date) => {
    let newDateRange = {} as DateRange;
    if (currentlyUpdating === Updating.FROM) {
      newDateRange.from = selectedDay;
    } else {
      newDateRange.from = editedDateRange.from;
      newDateRange.to = selectedDay;
    }

    // if start and end are equal clear end date
    if (dayjs(newDateRange.from).isSame(newDateRange.to, 'day')) {
      newDateRange.to = null;
    }

    // if end date is before start date flip them
    if (newDateRange.to && dayjs(newDateRange.to).isBefore(newDateRange.from, 'day')) {
      newDateRange = { from: newDateRange.to, to: newDateRange.from };
      if (getNumOfDays(newDateRange) <= MAX_NUM_OF_DAYS_IN_RANGE) {
        setCurrentlyUpdating(Updating.FROM);
        setEditedDateRange(newDateRange);
      }
    } else if (getNumOfDays(newDateRange) <= MAX_NUM_OF_DAYS_IN_RANGE) {
      // only persist changes if the range a user is attempting to select
      // is less than MAX_NUM_OF_DAYS_IN_RANGE
      setEditedDateRange(newDateRange);
      if (currentlyUpdating === Updating.FROM) {
        // push user to pick TO date
        setCurrentlyUpdating(Updating.TO);
      }
    }
  };

  useEffect(() => {
    setEditedDateRange(appliedDateRange);
  }, [appliedDateRange]);

  useEffect(() => {
    // this logic makes the modal go away when you click outside
    const didClickOutside = (e: MouseEvent) => {
      if (datePickerModal.current
        && e.target instanceof Element
        // modalBackground div lives behind the modal
        && (e.target?.className.includes && e.target?.className.includes('modalBackground'))
      ) {
        e.stopPropagation();
        cancelDateRange();
      }
    };
    document.addEventListener('click', didClickOutside);

    return () => {
      document.removeEventListener('click', didClickOutside);
    };
  }, [cancelDateRange]);

  return (
    <div data-theme={theme} ref={datePickerModal} data-testid="custom-date-picker" className={styles.datePickerContainer}>
      <div
        className={
          classes(
            styles.buttonGroup,
            !disabled && styles.buttonGroupEnabled,
            currentlyUpdating === Updating.NONE && styles.noSelection,
          )
        }
        role="radiogroup"
      >
        <label
          className={styles.dateButton}
          htmlFor="from"
        >
          <input
            name="fromDateRangeSelector"
            type="radio"
            id={Updating.FROM}
            value={formatDate(editedDateRange.from, true)}
            onClick={(e) => handleRadioGroupClick(e)}
            readOnly
            checked={currentlyUpdating === Updating.FROM}
            disabled={disabled}
          />
          <span className={
            classes(
              currentlyUpdating === Updating.FROM && styles.selected,
            )
          }
          >
            {formatDate(editedDateRange.from, true)}
          </span>
        </label>
        <label className={styles.dateButton} htmlFor="to">
          <input
            name="toDateRangeSelector"
            type="radio"
            id={Updating.TO}
            value={formatDate(editedDateRange?.to ?? editedDateRange.from, true)}
            onClick={(e) => handleRadioGroupClick(e)}
            readOnly
            checked={currentlyUpdating === Updating.TO}
            disabled={disabled}
          />
          <span className={
            classes(
              currentlyUpdating === Updating.TO && styles.selected,
            )
          }
          >
            {formatDate(editedDateRange?.to ?? editedDateRange.from, true)}
          </span>
        </label>
        <Icon data-testid="calendar-icon" name="annual_pa" className={styles.calendarIcon} />
      </div>
      <div>
        {
          currentlyUpdating !== Updating.NONE
          && !disabled
          && (
            <div data-testid="calendar-modal" className={styles.modalContainer}>
              <div>
                <DayPicker
                  mode="range"
                  components={{
                    Caption: CustomCaption,
                    Day: CustomDay,
                    // in order to access the goToMonth() functions
                    // the quick select needs to be a component so we pass it in the footer
                    // it is the only component available for us to use
                    Footer: ({ displayMonth }) => QuickSelect(
                      { editedDateRange, setEditedDateRange, displayMonth },
                    ),
                  }}
                  formatters={{
                    formatWeekdayName: (nameOfDay) => DAY_OF_WEEK_HEADERS[nameOfDay.getDay()],
                  }}
                  selected={editedDateRange}
                  onSelect={(_, selectedDay) => onSelectDate(selectedDay)}
                  defaultMonth={dayjs(appliedDateRange?.to ?? appliedDateRange.from).subtract(1, 'month').toDate()}
                  toMonth={TODAY_DATE}
                  disabled={[
                    { after: TODAY_DATE },
                    { before: disableBefore ?? null },
                  ]}
                  classNames={classNames}
                  numberOfMonths={2}
                />
                <div className={styles.footer}>
                  <span className={styles.dateRangeString}>
                    {getDateRangeAsString(editedDateRange)}
                    <span className={styles.numOfDays}>
                      {`${getNumOfDays(editedDateRange)} ${getNumOfDays(editedDateRange) === 1 ? 'day' : 'days'}`}
                    </span>
                  </span>
                  <div>
                    <Button onClick={() => cancelDateRange()} className={styles.actionButton} label="cancel" variant="link" />
                    <Button onClick={() => applyDateRange()} className={styles.actionButton} label="apply" variant="link" />
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      {/* sets the background for the modal to live ontop of */}
      {/* if the background is clicked the modal closes */}
      {currentlyUpdating !== Updating.NONE && <div className={styles.modalBackground} />}
    </div>
  );
}

export default CustomDatePicker;
