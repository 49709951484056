import { useEffect, useState } from 'react';
import { TITLE_3, BODY_LONG_1 } from 'components/text';
import Footer from 'components/footer/footer';
import AdminAccountsTable from 'admin/accountsTable/accountsTable';
import Loading from 'loading';
import { adminClient } from 'api';
import { AdminAccountDetail } from 'types/admin';
import NavigationBar from 'components/navigationBar/navigationBar';
import NotificationBanner from 'components/notificationBanner/notificationBanner';
import styles from './accountsPage.module.scss';

function AdminAccountsPage() {
  const [adminAccounts, setAdminAccounts] = useState<AdminAccountDetail[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const accountsResponse = await adminClient.getAdminAccounts();
      setAdminAccounts(accountsResponse);
      setLoading(false);
    };
    init();

    return () => {
      setLoading(true);
      setAdminAccounts([]);
    };
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={styles.accountFooterContainer}>
      {window._env.SHOW_BANNER === 'true' && <NotificationBanner />}
      <NavigationBar />
      <div className={styles.accountsContainer}>
        <div className={styles.pageHeader}>
          <TITLE_3>Select a customer account</TITLE_3>
          <BODY_LONG_1>
            View all active WHOOP enterprise accounts on behalf of customers.
            Please be aware that compliance (number of members using their WHOOP
            within a time frame) is not yet public to end customers.
          </BODY_LONG_1>
        </div>
        <div className={styles.tableContainer}>
          <AdminAccountsTable accounts={adminAccounts} />
        </div>
      </div>
      <Footer logo={false} />
    </div>
  );
}

export default AdminAccountsPage;
