import { PRIVACY_LEVEL_NAME_MAP } from 'accountDetails/types';
import { JobTitle } from 'api/jobTitleApi';
import { DescriptionDropdownOption } from 'types/collaborator';

/** DescriptionDropdownOption is the object shape used for dropdown components */
export const mapTitlesToDropdownOptions = (jobTitle: JobTitle): DescriptionDropdownOption => ({
  name: jobTitle.job_title_name,
  description: `Assigned Privacy Level: ${PRIVACY_LEVEL_NAME_MAP[jobTitle.privacy_level]}`,
  value: jobTitle.job_title_id,
});

/** Inactive job titles are those that do not have a privacy level assigned */
export const filterInactiveTitles = (jobTitle: JobTitle) => !!jobTitle.privacy_level;

/** Utility for filtering inactive titles and mapping them to dropdown options */
export const getActiveJobTitleDropdownOptions = (
  jobTitles: JobTitle[],
) => jobTitles
  .filter(filterInactiveTitles)
  .map(mapTitlesToDropdownOptions);
