import DropdownList from 'components/dropdown/dropdownList';
import CheckIcon from '@material-ui/icons/Check';
import Dropdown from 'components/dropdown';
import { HEADING_4 } from 'components/text';
import { Icon } from '@whoop/web-components';
import normalize from 'components/dropdown/util';
import styles from './filterDropdown.module.scss';

type FilterDropdownProps = {
  heading: string;
  options: any[];
  ariaLabel?: string;
};

function FilterDropdown({ heading, options, ariaLabel }: FilterDropdownProps) {
  return (
    <Dropdown
      button={(
        <div className={styles.linkBtn}>
          <HEADING_4>
            {normalize(heading)}
          </HEADING_4>
          <Icon name="tiny_triangle_down" className={styles.logo} />
        </div>
          )}
      buttonProps={{ variant: 'link', 'aria-label': ariaLabel }}
      dropdownStyles={{ borderTop: '3px solid black' }}
      closeOnClick
    >
      <DropdownList items={options} activeIcon={<CheckIcon />} />
    </Dropdown>
  );
}

export default FilterDropdown;
