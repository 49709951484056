import { CaptionProps, useNavigation } from 'react-day-picker';
import { Icon } from '@whoop/web-components';
import dayjs from 'dayjs';
import styles from './customCaption.module.scss';

// using CSS to target the first month to hide the navigation buttons
function CustomCaption({ displayMonth }: CaptionProps) {
  const {
    goToMonth, nextMonth, previousMonth, currentMonth,
  } = useNavigation();
  return (
    <div className={styles.headerContainer}>
      <p className={styles.monthName}>
        {displayMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
      </p>
      {
      !dayjs(currentMonth).isSame(dayjs(displayMonth), 'month')
       && (
       <div className={styles.navigationButtons}>
         <button
           type="button"
           data-testid="prevMonthButton"
           className={styles.prevMonthButton}
           disabled={!previousMonth}
           onClick={() => previousMonth && goToMonth(previousMonth)}
         >
           <Icon data-testid="caret_left" className={styles.caretIcon} name="caret_left" />
         </button>
         <button
           type="button"
           data-testid="nextMonthButton"
           className={styles.nextMonthButton}
           disabled={!nextMonth}
           onClick={() => nextMonth && goToMonth(nextMonth)}
         >
           <Icon data-testid="caret_right" className={styles.caretIcon} name="caret_right" />
         </button>
       </div>
       )

}
    </div>
  );
}
export default CustomCaption;
