import { HEADING_2, HEADING_5 } from 'components/text';
import { useAccounts } from 'context/account-context';
import Loading from 'loading';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTable } from 'react-table';
import { headerProps, rowStyles } from 'tableUtils/tableProps';
import {
  AddCollaboratorsReducerAction,
  Collaborator,
  DescriptionDropdownOption,
} from 'types/collaborator';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import { privacyLevelOptions } from 'accountDetails/types';
import styles from './reviewCollaboratorDetails.module.scss';
import {
  COLLABORATOR_EMAIL_COLUMN,
  getJobTitleOptionsColumn,
  getJobTitleTextColumn,
  getPrivacyLevelColumn,
  getRoleColumn,
  PRIVACY_LEVEL_TEXT_COLUMN, ROLE_TEXT_COLUMN,
} from './reviewCollaboratorDetailsTable';

/* eslint-disable react/jsx-props-no-spreading */

type ReviewCollaboratorDetailsProps = {
  emails: string[];
  jobTitleOptions: DescriptionDropdownOption[];
  dataSent: boolean;
  dispatch: React.Dispatch<AddCollaboratorsReducerAction>;
  loading: boolean;
};

function ReviewCollaboratorDetails({
  emails, jobTitleOptions, dataSent, dispatch, loading,
}: ReviewCollaboratorDetailsProps) {
  const [data, setData] = useState<Collaborator[]>([]);
  const { checkAccess } = useAccounts();

  const updateRow = (collaborator: Collaborator) => {
    const newData = [...data].map(
      (collab: Collaborator) => (collab.user_id === collaborator.user_id ? collaborator : collab),
    );
    setData(newData);
    dispatch({ type: 'updateInvitedCollaborator', invites: newData });
  };

  const columns = useMemo(
    () => {
      let tempColumns = [
        COLLABORATOR_EMAIL_COLUMN,
        dataSent ? ROLE_TEXT_COLUMN : getRoleColumn(dataSent, checkAccess),
      ];
      if (jobTitleOptions.length) {
        tempColumns = tempColumns.concat(dataSent ? getJobTitleTextColumn(jobTitleOptions)
          : getJobTitleOptionsColumn(jobTitleOptions, checkAccess));
      } else {
        tempColumns = tempColumns.concat(dataSent
          ? PRIVACY_LEVEL_TEXT_COLUMN
          : getPrivacyLevelColumn(privacyLevelOptions, false, checkAccess));
      }
      return tempColumns;
    },
    [data, dataSent, privacyLevelOptions, jobTitleOptions],
  );

  useEffect(() => {
    const newData = emails.map((email, i) => ({
      email,
      user_id: i,
      role: DashboardRole.GROUP_MANAGER,
      privacy_level: PrivacyLevel.no_metrics,
    } as Collaborator));
    setData(newData);
    dispatch({ type: 'updateInvitedCollaborator', invites: newData });
  }, []);

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      initialState: {},
      getRowId: useCallback((row, relativeIndex) => row.id || relativeIndex, []),
      updateRow,
    },
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={styles.formFields}>
      {dataSent
        ? (
          <p>
            The following collaborators have been added to your account
            and have received invitations with instructions for signing up.
          </p>
        )
        : (
          <div className={styles.title}>
            <HEADING_2>
              2
              <span className={styles.overTwo}>/2</span>
              {' '}
              SET &amp; REVIEW COLLABORATOR DETAILS
            </HEADING_2>
          </div>
        )}

      <table className={styles.collaboratorDetailsTable} {...getTableBodyProps()}>
        <thead className={styles.stickyHeader}>
          {headerGroups.map((headerGroup) => (
            <tr
              key="seatTableHeader"
              className={styles.headerGroup}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th key={column.id} {...column.getHeaderProps(headerProps)}>
                  <HEADING_5>{column.render('Header')}</HEADING_5>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr key={row.id} {...row.getRowProps(rowStyles(row))}>
                {row.cells.map((cell) => (
                  <td
                    key={cell.row.id}
                    {...cell.getCellProps([])}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ReviewCollaboratorDetails;
