import { Pillars } from 'types/analytics';
import { Colors } from '@whoop/web-components';

const RECOVERY_RED_MAX = 33;
const RECOVERY_YELLOW_MAX = 66;
export const STRAIN_MAX = 21;
export const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;

export const getColorFromVal = (value: number, hidden: boolean = false): string => {
  if (value <= RECOVERY_RED_MAX) {
    return hidden ? Colors.redAlpha200 : Colors.red;
  } if (value <= RECOVERY_YELLOW_MAX) {
    return hidden ? Colors.yellowAlpha200 : Colors.yellow;
  }
  return hidden ? Colors.greenAlpha200 : Colors.green;
};

export const getColorForPillar = (pillar: Pillars, value?: number) => {
  if (pillar === Pillars.STRAIN) { return Colors.strainBlue; }
  if (pillar === Pillars.RECOVERY) { return getColorFromVal(value); }
  if (pillar === Pillars.SLEEP) { return Colors.sleepBlue; }
  return Colors.gray500;
};
