import { Row, TableState } from 'react-table';
import {
  RecoveryLabel,
  SleepDebtLabel,
  SleepPerformanceLabel,
  StrainLabel,
  TrainingZoneLabel,
} from 'dataVisualizations/iconPieChart/iconPieChartConsts';
import { DEFAULT_SORT_BY_WIDGET, FORMAT_METRIC_VALUE } from 'insights/components/widget/widgetFormatter';
import { WidgetType } from '../widget/widget';
import { LeaderboardTableRow } from './useLeaderboardTable';

type BucketLabel = RecoveryLabel | StrainLabel | SleepPerformanceLabel
| SleepDebtLabel | TrainingZoneLabel;

export type BucketRow = Row<LeaderboardTableRow> | BucketHeader;

export type BucketHeader = {
  label: string;
  id: string;
};

export type Bucket<T extends {}, V extends BucketLabel> = {
  [key in V]: Row<T>[];
};

export type BucketBound<T extends BucketLabel> = {
  [key in T]: Bound
};

// All bounds are inclusive
type Bound = {
  upperBound?: number;
  lowerBound?: number;
};

const RECOVERY_BOUND: BucketBound<RecoveryLabel> = {
  [RecoveryLabel.GREEN]: {
    lowerBound: 67,
  },
  [RecoveryLabel.YELLOW]: {
    upperBound: 66,
    lowerBound: 34,
  },
  [RecoveryLabel.RED]: {
    upperBound: 33,
  },
  [RecoveryLabel.MISSING]: {},
};

const STRAIN_BOUND: BucketBound<StrainLabel> = {
  [StrainLabel.ALL_OUT]: {
    lowerBound: 18.1,
  },
  [StrainLabel.STRENUOUS]: {
    upperBound: 18.0,
    lowerBound: 14.1,
  },
  [StrainLabel.MODERATE]: {
    upperBound: 14.0,
    lowerBound: 10.1,
  },
  [StrainLabel.LIGHT]: {
    upperBound: 10,
  },
  [StrainLabel.MISSING]: {},
};

const SLEEP_PERFORMANCE_BOUND: BucketBound<SleepPerformanceLabel> = {
  [SleepPerformanceLabel.OPTIMAL]: {
    lowerBound: 86,
  },
  [SleepPerformanceLabel.SUFFICIENT]: {
    upperBound: 85,
    lowerBound: 71,
  },
  [SleepPerformanceLabel.POOR]: {
    upperBound: 70,
  },
  [SleepPerformanceLabel.MISSING]: {},
};

const SLEEP_DEBT_BOUND: BucketBound<SleepDebtLabel> = {
  [SleepDebtLabel.HIGH]: {
    lowerBound: 46,
  },
  [SleepDebtLabel.MODERATE]: {
    upperBound: 45,
    lowerBound: 30,
  },
  [SleepDebtLabel.LOW]: {
    upperBound: 29,
  },
  [SleepDebtLabel.MISSING]: {},
};

const TRAINING_ZONE_BOUND: BucketBound<TrainingZoneLabel> = {
  [TrainingZoneLabel.OVERREACHING]: {},
  [TrainingZoneLabel.MAINTAINING]: {},
  [TrainingZoneLabel.TAPERING]: {},
  [TrainingZoneLabel.PARTIAL]: {},
  [TrainingZoneLabel.MISSING]: {},
};

export const BOUND_MAP = {
  [WidgetType.RECOVERY]: RECOVERY_BOUND,
  [WidgetType.SLEEP_PERFORMANCE]: SLEEP_PERFORMANCE_BOUND,
  [WidgetType.DAY_STRAIN]: STRAIN_BOUND,
  [WidgetType.SLEEP_DEBT]: SLEEP_DEBT_BOUND,
  [WidgetType.TRAINING_ZONE]: TRAINING_ZONE_BOUND,
};

const RECOVERY_DESC_ORDER: RecoveryLabel[] = [
  RecoveryLabel.GREEN,
  RecoveryLabel.YELLOW,
  RecoveryLabel.RED,
  RecoveryLabel.MISSING,
];
const SLEEP_PERFORMANCE_DESC_ORDER: SleepPerformanceLabel[] = [
  SleepPerformanceLabel.OPTIMAL,
  SleepPerformanceLabel.SUFFICIENT,
  SleepPerformanceLabel.POOR,
  SleepPerformanceLabel.MISSING,
];
const STRAIN_DESC_ORDER: StrainLabel[] = [
  StrainLabel.ALL_OUT,
  StrainLabel.STRENUOUS,
  StrainLabel.MODERATE,
  StrainLabel.LIGHT,
  StrainLabel.MISSING,
];
const SLEEP_DEBT_DESC_ORDER: SleepDebtLabel[] = [
  SleepDebtLabel.HIGH,
  SleepDebtLabel.MODERATE,
  SleepDebtLabel.LOW,
  SleepDebtLabel.MISSING,
];
const TRAINING_ZONE_DESC_ORDER: TrainingZoneLabel[] = [
  TrainingZoneLabel.OVERREACHING,
  TrainingZoneLabel.MAINTAINING,
  TrainingZoneLabel.TAPERING,
  TrainingZoneLabel.PARTIAL,
  TrainingZoneLabel.MISSING,
];

export const DESC_ORDER_MAP = {
  [WidgetType.RECOVERY]: RECOVERY_DESC_ORDER,
  [WidgetType.SLEEP_PERFORMANCE]: SLEEP_PERFORMANCE_DESC_ORDER,
  [WidgetType.DAY_STRAIN]: STRAIN_DESC_ORDER,
  [WidgetType.SLEEP_DEBT]: SLEEP_DEBT_DESC_ORDER,
  [WidgetType.TRAINING_ZONE]: TRAINING_ZONE_DESC_ORDER,
};

export const reverseBucketOrder = (widgetType: WidgetType) => {
  const copyOrder = Object.assign([], DESC_ORDER_MAP[widgetType]);
  const missingLabel = copyOrder.pop();
  return [...copyOrder.reverse(), missingLabel];
};

export const sortRowsIntoBuckets = <T extends BucketLabel>(
  rows: Row<LeaderboardTableRow>[],
  widgetType: WidgetType) => rows
    .reduce<Bucket<LeaderboardTableRow, T>>((acc, curr) => {
    let value = curr.values[DEFAULT_SORT_BY_WIDGET[widgetType]];

    // All digits are whole numbers instead of strain.
    if (widgetType === WidgetType.DAY_STRAIN && value) {
      value = FORMAT_METRIC_VALUE[widgetType](value);
    }

    Object.entries(BOUND_MAP[widgetType]).forEach(([key, bound]: [T, Bound]) => {
      const { lowerBound, upperBound } = bound;
      const arrayAppendedWithRow = [...(acc[key] || []), curr];

      if (value !== null) {
        if (lowerBound && !upperBound && value >= lowerBound) {
          // check if value if [lowerBound, Infinity)
          acc[key] = arrayAppendedWithRow;
        } else if (lowerBound && upperBound && value >= lowerBound && value <= upperBound) {
          // check if value if [lowerBound, upperBound]
          acc[key] = arrayAppendedWithRow;
        } else if (!lowerBound && upperBound && value <= upperBound) {
          // check if value if (Infinity, lowerBound]
          acc[key] = arrayAppendedWithRow;
        }
      } else if (!lowerBound && !upperBound) {
        // the case is for missing data
        acc[key] = arrayAppendedWithRow;
      }
    });

    return acc;
  }, {} as Bucket<LeaderboardTableRow, T>);

export const sortTrainingZoneRowsIntoBuckets = (rows: Row<LeaderboardTableRow>[]) => {
  const tzBuckets: Bucket<LeaderboardTableRow, TrainingZoneLabel> = {
    [TrainingZoneLabel.OVERREACHING]: [],
    [TrainingZoneLabel.MAINTAINING]: [],
    [TrainingZoneLabel.TAPERING]: [],
    [TrainingZoneLabel.PARTIAL]: [],
    [TrainingZoneLabel.MISSING]: [],
  };
  rows.forEach((row) => {
    switch (row.original.training_zone) {
      case 'OVERREACHING':
        tzBuckets[TrainingZoneLabel.OVERREACHING].push(row);
        break;
      case 'MAINTAINING':
        tzBuckets[TrainingZoneLabel.MAINTAINING].push(row);
        break;
      case 'TAPERING':
        tzBuckets[TrainingZoneLabel.TAPERING].push(row);
        break;
      default:
        if (row.original.recovery || row.original.strain) {
          tzBuckets[TrainingZoneLabel.PARTIAL].push(row);
        } else {
          tzBuckets[TrainingZoneLabel.MISSING].push(row);
        }
    }
  });
  return tzBuckets as Bucket<LeaderboardTableRow, BucketLabel>;
};

export const bucketRows = <T extends BucketLabel>(
  rows: Row<LeaderboardTableRow>[],
  tableState: TableState<LeaderboardTableRow>,
  widgetType: WidgetType,
) => {
  if (!rows) {
    return rows;
  }

  let bucketValues: Bucket<LeaderboardTableRow, T>;
  if (widgetType === WidgetType.TRAINING_ZONE) {
    bucketValues = sortTrainingZoneRowsIntoBuckets(rows);
  } else {
    bucketValues = sortRowsIntoBuckets<T>(rows, widgetType);
  }

  const r: BucketRow[] = [];
  const { sortBy } = tableState;
  // table can only be sorted by one column, but in case this req changes in the future
  const isPrimaryColumnDesc = sortBy
    .find((sortedColumns) => sortedColumns.id === DEFAULT_SORT_BY_WIDGET[widgetType]).desc;
  // Missing Data is always last in the list
  const bucketLabelOrder = isPrimaryColumnDesc
    ? DESC_ORDER_MAP[widgetType]
    : reverseBucketOrder(widgetType);

  bucketLabelOrder.forEach((label) => {
    if (bucketValues[label as T]?.length > 0) {
      r.push({ label, id: `${label} - sectionHeader` });
      r.push(...bucketValues[label as T]);
    }
  });

  return r;
};

export const moveNullRowsToBottom = <T extends {}>(
  rows: Row<T>[],
  valueKey: string,
) => {
  const nonNullElements = rows.filter((row) => row.values[valueKey] !== null
  && row.values[valueKey] !== undefined);
  const nullElements = rows.filter((row) => row.values[valueKey] === null);
  // groups that don't meet avg sharing req return undefined
  const undefinedElements = rows.filter((row) => row.values[valueKey] === undefined);
  return [...nonNullElements, ...nullElements, ...undefinedElements];
};
