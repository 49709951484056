import useGroupLanding from 'api/hooks/useGroupLanding';
import GroupsTable from 'insights/components/groupsTable/groupsTable';
import LoadingSkeleton from 'insights/components/loadingSkeleton/loadingSkeleton';
import {
  Icon, Alert, Alerts, StatusTagThemes,
} from '@whoop/web-components';
import { useAccounts } from 'context/account-context';
import { usePrivacy } from 'context/privacy-context';
import { PrivacyLevel } from 'types/dashboardUser';
import { useDateRange } from 'insights/hooks/useDateRange';
import styles from './groupsPage.module.scss';

function GroupsPage() {
  const { account } = useAccounts();
  const { dateRange } = useDateRange();
  const { data: groupLanding, isLoading } = useGroupLanding(dateRange);
  const { isInPrivacyLevel } = usePrivacy();

  if (isLoading) {
    return (
      <>
        <div className={styles.headerLoading}>
          <LoadingSkeleton isLoading={isLoading} />
        </div>
        { Array.from({ length: 5 }, (item, index) => (
          <div className={styles.rowLoading} key={index}>
            <LoadingSkeleton isLoading={isLoading} />
          </div>
        ))}
      </>
    );
  }

  if (groupLanding?.groups.length === 0) {
    return (
      <div className={styles.noDataWrapper}>
        <div className={styles.accountName}>{account?.account_name}</div>
        <div className={styles.noData}>
          <Icon name="new_message" className={styles.icon} />
          <div className={styles.noDataSubtitle}>
            There are no groups to show.
            Contact your admin to add you to a group.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.pageBackground}>
      {!isInPrivacyLevel(PrivacyLevel.aggregate_metrics) && (
        <Alert
          theme={StatusTagThemes.UNITE}
          type={Alerts.UNITE_ERROR}
          iconName="hide_privacy"
          className={styles.errorMessage}
        >
          You do not have access to analytics.
          Contact your admin to change your data access permission.
        </Alert>
      )}
      <GroupsTable data={groupLanding?.groups} />
    </div>
  );
}

export default GroupsPage;
