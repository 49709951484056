import { SeatStatus, ShippingType } from 'types/seat';

const users = [
  // active
  {
    first_name: 'Craig',
    last_name: 'Anderson',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Peter',
    last_name: 'Henderson',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Jane',
    last_name: 'Edwards',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Harold',
    last_name: 'Williams',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Kimberly',
    last_name: 'Nelson',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Charles',
    last_name: 'Hernandez',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Ruby',
    last_name: 'Butler',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Jason',
    last_name: 'Robinson',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Ann',
    last_name: 'Powell',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Alexis',
    last_name: 'Perez',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Faith',
    last_name: 'Leonard',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'John',
    last_name: 'Chandler',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Sarah',
    last_name: 'Guerrero',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Rebecca',
    last_name: 'Hopkins',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Roy',
    last_name: 'Murray',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Maggie',
    last_name: 'Barnett',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Maria',
    last_name: 'Taylor',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Jack',
    last_name: 'Morgan',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Henry',
    last_name: 'Hughes',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Caleb',
    last_name: 'Gonzales',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Jack',
    last_name: 'Sanchez',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Justin',
    last_name: 'Lewis',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Adam',
    last_name: 'Phillips',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Frank',
    last_name: 'Alexander',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Roger',
    last_name: 'White',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Alex',
    last_name: 'Reed',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Sean',
    last_name: 'Price',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Ann',
    last_name: 'Cox',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Mark',
    last_name: 'Barnes',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Steve',
    last_name: 'Martinez',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Martin',
    last_name: 'Robinson',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Henry',
    last_name: 'Sanchez',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Julie',
    last_name: 'Washington',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Theresa',
    last_name: 'Moore',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Janet',
    last_name: 'Green',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Lillian',
    last_name: 'Clark',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Jane',
    last_name: 'Morris',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Diana',
    last_name: 'Roberts',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Lauren',
    last_name: 'Wilson',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Melissa',
    last_name: 'Gonzalez',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Nicole',
    last_name: 'Lopez',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Katie',
    last_name: 'Martin',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Megan',
    last_name: 'Carter',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Jenna',
    last_name: 'Collins',
    seat_status: SeatStatus.Active,
  },
  {
    first_name: 'Jean',
    last_name: 'Martinez',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Philip',
    last_name: 'Walker',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Jeffrey',
    last_name: 'Stewart',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Todd',
    last_name: 'Martin',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Cassie',
    last_name: 'Cooper',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Andrea',
    last_name: 'Howard',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Gary',
    last_name: 'Moore',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Lawrence',
    last_name: 'Gray',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Kimberly',
    last_name: 'Bell',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Cynthia',
    last_name: 'Hill',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Maria',
    last_name: 'Nelson',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Diane',
    last_name: 'Wilson',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Julia',
    last_name: 'Torres',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Douglas',
    last_name: 'Evans',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Orlando',
    last_name: 'Lewis',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Antonia',
    last_name: 'Sharp',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Rosemary',
    last_name: 'Riley',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Kellie',
    last_name: 'Barton',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Ricardo',
    last_name: 'Allen',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Preston',
    last_name: 'Reid',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Harvey',
    last_name: 'Townsend',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Jeffery',
    last_name: 'Cohen',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Yvonne',
    last_name: 'Cobb',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Mabel',
    last_name: 'Smith',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Matthew',
    last_name: 'Copeland',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Joyce',
    last_name: 'Watson',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Bobby',
    last_name: 'Reese',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Candace',
    last_name: 'Parker',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Taylor',
    last_name: 'Bell',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Tim',
    last_name: 'Kelley',
    seat_status: SeatStatus.Active,
  }, {
    first_name: 'Tanya',
    last_name: 'Welch',
    seat_status: SeatStatus.Active,
  },
  // invited
  {
    first_name: 'Marsha',
    last_name: 'Vega',
    seat_status: SeatStatus.Invited,
    shipping_type: ShippingType.BULK,
  }, {
    first_name: 'Jake',
    last_name: 'Rodriguez',
    seat_status: SeatStatus.Invited,
    shipping_type: ShippingType.BULK,
  }, {
    first_name: 'Lori',
    last_name: 'Young',
    seat_status: SeatStatus.Invited,
    shipping_type: ShippingType.BULK,
  }, {
    first_name: 'Jason',
    last_name: 'Stewart',
    seat_status: SeatStatus.Invited,
    shipping_type: ShippingType.BULK,
  }, {
    first_name: 'Jason',
    last_name: 'Johnson',
    seat_status: SeatStatus.Invited,
    shipping_type: ShippingType.BULK,
  }, {
    first_name: 'William',
    last_name: 'Price',
    seat_status: SeatStatus.Invited,
    shipping_type: ShippingType.DIRECT,
  }, {
    first_name: 'Lori',
    last_name: 'Hall',
    seat_status: SeatStatus.Invited,
    shipping_type: ShippingType.DIRECT,
  }, {
    first_name: 'Patricia',
    last_name: 'Turner',
    seat_status: SeatStatus.Invited,
    shipping_type: ShippingType.DIRECT,
  }, {
    first_name: 'Christine',
    last_name: 'Clark',
    seat_status: SeatStatus.Invited,
    shipping_type: ShippingType.DIRECT,
  }, {
    first_name: 'Joshua',
    last_name: 'Bryan',
    seat_status: SeatStatus.Invited,
    shipping_type: ShippingType.DIRECT,
  },
  // pending
  {
    first_name: 'Jeffrey',
    last_name: 'Bell',
    seat_status: SeatStatus.Pending,
    shipping_type: ShippingType.DIRECT,
  }, {
    first_name: 'Allen',
    last_name: 'Lynch',
    seat_status: SeatStatus.Pending,
    shipping_type: ShippingType.DIRECT,
  }, {
    first_name: 'Ellen',
    last_name: 'Cooper',
    seat_status: SeatStatus.Pending,
    shipping_type: ShippingType.DIRECT,
  }, {
    first_name: 'Spencer',
    last_name: 'Jenson',
    seat_status: SeatStatus.Pending,
    shipping_type: ShippingType.DIRECT,
  }, {
    first_name: 'Lance',
    last_name: 'Schultz',
    seat_status: SeatStatus.Pending,
    shipping_type: ShippingType.DIRECT,
  },
];

const generateStrapSerial = () => {
  const gen4SerialLength = 9;
  let serial = '4A';
  while (serial.length < gen4SerialLength) {
    serial += Math.floor(Math.random() * 10);
  }
  return serial;
};

const genericSeatData = {
  contract_number: 1,
  contract_start_date: '2021-03-22T00:00:00.000+0000',
  contract_end_date: '2023-03-21T00:00:00.000+0000',
  last_activity_at: '',
  activated_on: '2021-03-22T00:00:00.000+0000',
};

export const deactivatedSeat = {
  avatar_url: '',
  email: 'deactivated.user@fosterjefferson.com',
  id: 101,
  user_id: 101,
  strap_serial: generateStrapSerial(),
  status: SeatStatus.Deactivated,
  seat_status: SeatStatus.Deactivated,
  shipping_type: ShippingType.BULK,
  hub_member: false,
  ...genericSeatData,
};

export const baseSeats = users.map((user, i) => {
  const seat = {
    avatar_url: '',
    email: `${user.first_name.toLowerCase()}.${user.last_name.toLowerCase()}@fosterjefferson.com`,
    id: i + 1,
    user_id: i + 1,
    strap_serial: generateStrapSerial(),
    status: user.seat_status,
    seat_status: user.seat_status,
    shipping_type: ShippingType.BULK,
    hub_member: i % 2 === 0,
    first_name: '',
    last_name: '',
    opted_in: true,
    ...genericSeatData,
  };
  if (seat.status !== SeatStatus.Invited) {
    seat.first_name = user.first_name;
    seat.last_name = user.last_name;
  } else {
    seat.shipping_type = user.shipping_type;
  }
  return seat;
});

export default baseSeats;
