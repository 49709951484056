import { DateRange, useNavigation } from 'react-day-picker';
import dayjs from 'dayjs';
import { classes } from '@whoop/web-components';
import { trackEvent } from 'helpers/analyticsTracking';
import { useParams } from 'react-router-dom';
import styles from '../customDatePicker.module.scss';
import { getPageUserIsOn, getNumOfDays } from '../datePickerUtils/datePickerUtils';

type QuickSelectProps = {
  editedDateRange: DateRange;
  setEditedDateRange: (range: DateRange) => void;
  displayMonth: Date;
};

function QuickSelect({ editedDateRange, setEditedDateRange, displayMonth }: QuickSelectProps) {
  const { currentMonth, goToMonth } = useNavigation();
  const { tab, groupId, pillar } = useParams();

  const PRESET_BUTTONS = [
    {
      num_of_days: 1,
      text: 'Today',
    },
    {
      num_of_days: 7,
      text: 'Last 7 days',
    },
    {
      num_of_days: 14,
      text: 'Last 14 days',
    },
    {
      num_of_days: 30,
      text: 'Last 30 days',
    },
  ];

  const handleQuickSelect = (numDays: number) => {
    const range: DateRange = { from: dayjs().startOf('day').toDate() };
    if (numDays !== 1) {
      range.to = range.from;
      range.from = dayjs().subtract(numDays - 1, 'days').startOf('day').toDate();
    }
    const page = getPageUserIsOn(tab, groupId, pillar);
    trackEvent(`${page} - Quick Select`, {
      date_range_start: dayjs(editedDateRange.from).startOf('day').toISOString(),
      date_range_end: dayjs(editedDateRange.to ?? editedDateRange.from).endOf('day').toISOString(),
      number_of_days: `${getNumOfDays(editedDateRange)}`,
    });
    setEditedDateRange(range);
    goToMonth(dayjs(range?.to ?? range.from).subtract(1, 'month').toDate());
  };

  const checkIfPresetRangeIsSelected = (presetNumberOfDays: number) => {
    if (dayjs(editedDateRange?.to ?? editedDateRange.from).isSame(dayjs(), 'day')) {
      return getNumOfDays(editedDateRange) === presetNumberOfDays;
    }
    return false;
  };

  if (dayjs(currentMonth).isSame(dayjs(displayMonth), 'month')) {
    return null;
  }

  return (
    <tfoot className={styles.presetContainer}>
      <tr>
        <td className={styles.quickSelectLabel}>
          Quick select
        </td>
      </tr>
      <tr>
        <td className={styles.quickSelectButtons}>

          {
          PRESET_BUTTONS.map((presetButton) => (
            <button
              key={presetButton.text}
              className={
              classes(
                styles.quickSelectButton,
                checkIfPresetRangeIsSelected(
                  presetButton.num_of_days,
                ) && styles.selectedButton,
              )
                }
              type="button"
              onClick={() => handleQuickSelect(presetButton.num_of_days)}
            >
              {presetButton.text}
            </button>
          ))
        }
        </td>
      </tr>
    </tfoot>
  );
}

export default QuickSelect;
