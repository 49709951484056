import React, {
  useCallback, useEffect, useReducer, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TITLE_3 } from 'components/text';
import { cohortClient } from 'api';
import { useAuth } from 'context/auth-context';
import { Cohort, CohortCollaborator, GroupMember } from 'types/cohort';
import ActionContent from 'components/actionContent/actionContent';
import ProgressActionFooter from 'components/progressActionFooter/progressActionFooter';
import { Icon } from '@whoop/web-components';
import { getAllCollaborators } from 'api/dashboardApi';
import AddCollaboratorsSearch from 'groups/addCollaboratorsPage/components/addCollaboratorsSearch';
import AddGroupMembersSearch from 'groups/addGroupMembersSearch/addGroupMembersSearch';
import { addCollaboratorsToGroup, addMembersToGroup } from 'groups/utils';
import { getAllSeats } from 'api/seatApi';
import { DashboardRole } from 'types/dashboardUser';
import { trackEvent } from 'helpers/analyticsTracking';
import { InviteStatus } from 'types/collaborator';
import AddGroup from './components/addGroup/addGroup';
import styles from './addGroupWizard.module.scss';
import {
  addGroupReducer,
  AddGroupReducerState,
  AddGroupSteps,
  collaboratorToCohortCollaborators,
  seatDetailsToGroupMembers,
} from './utils';
import LoadingHandler from './components/loadingHandler';

const initialFormState: AddGroupReducerState = {
  groupName: '',
  groupDescription: '',
  isGroupConcealed: false,
  error: null,
  step: AddGroupSteps.set_name_description,
  cohortCollaborators: [],
  members: [],
};

function AddGroupWizard() {
  const [state, dispatch] = useReducer(addGroupReducer, initialFormState);
  const {
    groupName, groupDescription, isGroupConcealed, error, step, cohortCollaborators, members,
  } = state;
  const { accountId } = useParams<{ accountId: string }>();
  const navigate = useNavigate();

  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [availableCohortCollaborators,
    setAvailableCohortCollaborators] = useState<CohortCollaborator[]>([]);
  const [availableMembers, setAvailableMembers] = useState<GroupMember[]>([]);

  const getCurrentProgressDot = (): number => {
    switch (state.step) {
      case AddGroupSteps.set_name_description:
        return 0;
      case AddGroupSteps.add_collaborators:
        return 1;
      case AddGroupSteps.add_members:
        return 2;
      default:
        return 0;
    }
  };

  const addGroupsHandler = async () => {
    try {
      trackEvent('Create Group - Save Group Button Click', {
        accountId,
      });
      const newCohort: Cohort = await cohortClient.createGroup({
        salesforce_account_id: accountId,
        name: groupName,
        owner_id: user.id,
        about: groupDescription,
        is_primary: false,
        concealed: isGroupConcealed,
      });
      const collaboratorIds = cohortCollaborators.map((collaborator) => collaborator.user_id);
      await addCollaboratorsToGroup(newCohort.id, collaboratorIds);

      const memberSeatIds = members.map((mem) => mem.seat_id);
      await addMembersToGroup(newCohort.id, memberSeatIds);

      navigate(`/accounts/${accountId}/people/groups/${newCohort.id}`);
    } catch (e) {
      dispatch({ type: 'error', payload: e });
    }
  };

  const getActionButtonProps = () => {
    let label = (
      <>
        <p>Next</p>
        <Icon name="arrow_right" />
      </>
    );
    if (state.step === AddGroupSteps.add_members) {
      label = (
        <>
          Save Group
        </>
      );
    }
    const disabled: boolean = !groupName || step === AddGroupSteps.loading;

    return {
      theme: 'enterprise',
      variant: 'primary',
      label,
      disabled,
      'aria-label': 'next-button',
    };
  };

  const getSecondaryButtonProps = () => ({
    label: (
      <>
        <Icon name="arrow_left" />
        {' '}
        <p>Previous</p>
      </>
    ),
    variant: 'link',
  });

  const handleGroupNameChange = (name: string) => {
    dispatch({
      type: 'handleInputText',
      field: 'groupName',
      value: name,
    });
  };

  const handleGroupDescriptionChange = (description: string) => {
    dispatch({
      type: 'handleInputText',
      field: 'groupDescription',
      value: description,
    });
  };

  const handleCollaboratorsChange = (collabs: CohortCollaborator[]) => {
    dispatch({
      type: 'handleCollaboratorsChange',
      value: collabs,
    });
  };

  const handleMembersChange = (membs: GroupMember[]) => {
    dispatch({
      type: 'handleMembersChange',
      value: membs,
    });
  };

  const handleGroupConcealedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === 'false';
    dispatch({
      type: 'handleGroupConcealedChange',
      value: newValue,
    });
  };

  const fetchCollaborators = useCallback(async () => {
    const collaborators = await getAllCollaborators();
    const activeGroupManagers = collaborators.filter(
      (collaborator) => collaborator.status === InviteStatus.accepted
          && collaborator.role === DashboardRole.GROUP_MANAGER,
    );

    const groupManagers = collaboratorToCohortCollaborators(activeGroupManagers);

    setAvailableCohortCollaborators(groupManagers);
  }, []);

  const fetchMembers = useCallback(async () => {
    const seatDetailRequest = await getAllSeats();
    const seatDetails = seatDetailRequest.data;

    const membs = seatDetailsToGroupMembers(seatDetails);
    setAvailableMembers(membs);
  }, []);

  useEffect(() => {
    setLoading(true);
    const init = async () => {
      await fetchCollaborators();
      await fetchMembers();
      setLoading(false);
    };

    init();
  }, []);

  const trackNextEvent = () => {
    if (step === AddGroupSteps.set_name_description || step === AddGroupSteps.add_collaborators) {
      trackEvent('Create Group - Next Button Click', {
        accountId,
        step,
      });
    }
  };

  return (
    <>
      <ActionContent>
        <div>
          <div className={styles.textContent} data-testid="page-text-content">
            {step === AddGroupSteps.error && (
              <>
                <TITLE_3>Error creating group.</TITLE_3>
                <p>
                  There was an error creating the group
                  {error.message}
                </p>
              </>
            )}
            {step === AddGroupSteps.loading && (
              <LoadingHandler handler={addGroupsHandler} />
            )}
            <div className={styles.container}>
              <TITLE_3>Create a member group</TITLE_3>
              {step === AddGroupSteps.set_name_description && (
                <>
                  <p className={styles.pageHeading}>
                    <span className={styles.pageNumber}>1</span>
                    <span className={styles.totalPages}>/3</span>
                    Name the group
                  </p>
                  <span className={styles.pageDesc}>
                    Groups make it easier to manage smaller subsets of members.
                  </span>
                  <div className={styles.input}>
                    <AddGroup
                      groupName={groupName}
                      setGroupName={handleGroupNameChange}
                      groupDescription={groupDescription}
                      setGroupDescription={handleGroupDescriptionChange}
                      isGroupConcealed={isGroupConcealed}
                      setIsGroupConcealed={handleGroupConcealedChange}
                    />
                  </div>
                </>
              )}
              {step === AddGroupSteps.add_collaborators && (
                <>
                  <p className={styles.pageHeading}>
                    <span className={styles.pageNumber}>2</span>
                    <span className={styles.totalPages}>/3</span>
                    Add Collaborators
                  </p>
                  <span className={styles.pageDesc}>
                    Collaborators are individuals who can view and take action on this group.
                    Collaborators can be added to more than one group.
                  </span>
                  <div className={styles.input}>
                    <AddCollaboratorsSearch
                      availableCollaborators={availableCohortCollaborators}
                      loading={loading}
                      selectedCollaborators={cohortCollaborators}
                      setSelectedCollaborators={handleCollaboratorsChange}
                    />
                  </div>
                </>
              )}
              {step === AddGroupSteps.add_members && (
                <>
                  <p className={styles.pageHeading}>
                    <span className={styles.pageNumber}>3</span>
                    <span className={styles.totalPages}>/3</span>
                    Add Members
                  </p>
                  <span className={styles.pageDesc}>
                    Members can be added to multiple groups.
                    Members are not notified if they are added or removed from specific groups.
                  </span>
                  <div className={styles.input}>
                    <AddGroupMembersSearch
                      availableMembers={availableMembers}
                      selectedMembers={members}
                      setSelectedMembers={handleMembersChange}
                      loading={loading}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </ActionContent>

      <ProgressActionFooter
        state={state}
        getPrimaryButtonProps={getActionButtonProps}
        getSecondaryButtonProps={getSecondaryButtonProps}
        dispatch={dispatch}
        hideSecondaryButton={getCurrentProgressDot() === 0}
        progressDots={{ range: 3, current: getCurrentProgressDot() }}
        trackNextEvent={trackNextEvent}
      />
    </>
  );
}

export default AddGroupWizard;
