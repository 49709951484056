import {
  RecoveryBreakdown,
  StrainBreakdown, SleepDebtBreakdown, SleepPerformanceBreakdown, TrainingZoneBreakdown,
} from 'api/widgetApi';
import IconPieChart from 'dataVisualizations/iconPieChart/iconPieChart';
import { PieChartLabel } from 'dataVisualizations/iconPieChart/iconPieChartConsts';
import IconPieChartLegend from 'dataVisualizations/iconPieChart/iconPieChartLegend';
import React from 'react';
import styles from './pieChartAndLabels.module.scss';

type PieChartAndLabelsProps = {
  labels: PieChartLabel[];
  icon: string;
  data: RecoveryBreakdown |
  StrainBreakdown | SleepDebtBreakdown | SleepPerformanceBreakdown | TrainingZoneBreakdown;
  noDataState: boolean;
};

function PieChartAndLabels({
  labels, icon, data, noDataState,
} : PieChartAndLabelsProps) {
  return (
    <div className={styles.pieChartContainer} key={icon}>
      <div className={styles.pieChart}>
        <IconPieChart
          data={data}
          labels={labels}
          iconName={icon}
        />
      </div>
      <IconPieChartLegend
        // Reverse to match designs ordering of labels vs pie chart slices
        labels={labels.slice(0).reverse()}
        data={data}
        noDataState={noDataState}
      />
    </div>
  );
}

export const MemoizedPieChartAndLabel = React.memo(PieChartAndLabels);
