import { optOutIconCell } from 'tableUtils/tableCells';
import { ReactNode } from 'react';
import { Row } from 'react-table';

type Cell = {
  value: number;
};

export const compareDecimals = (
  rowA: Row<any>,
  rowB: Row<any>,
  id: string,
) => {
  const rowAVal = rowA.values[id];
  const rowBVal = rowB.values[id];
  // Need to handle undefined values
  if (!rowAVal && !rowBVal) return 0;
  if (!rowAVal && rowBVal) return -1;
  if (rowAVal && !rowBVal) return 1;
  if (rowAVal > rowBVal) return 1;
  if (rowBVal > rowAVal) return -1;

  return 0;
};

export function roundedNumber({ value }: Cell) {
  return value ? Math.round(value) : null;
}

export function roundedPercent(cell: Cell) {
  const rounded = roundedNumber(cell);
  return rounded ? `${rounded}%` : null;
}

export function scaledHrv({ value }: Cell) {
  const scaled = value ? value * 1000 : null;
  return roundedNumber({ value: scaled });
}

export function scaledSleepEfficiency({ value }: Cell) {
  const scaled = value ? value * 100 : null;
  return roundedPercent({ value: scaled });
}

export function checkOptInInfoCell(
  cellComponent: ReactNode,
  opt_in: boolean,
) {
  if (opt_in === false) {
    return optOutIconCell();
  }
  return cellComponent;
}
