import { emailRegex } from '@whoop/web-components';
import { InviteFlowReducerState, InviteFlowReducerAction } from './types';

// TODO: Consolidate this with src/login/index.jsx
export const isEmail = (email: string) => emailRegex.test(email);

export const parseEmails = (text: string) => {
  const whitespaceToCommas = text.trim().replace(/\s/g, ',');
  const possibleEmails = whitespaceToCommas.split(/,+/);
  const distinctPossibleEmails = possibleEmails.filter(
    (value, index, self) => self.indexOf(value) === index,
  );
  return distinctPossibleEmails.reduce(
    (acc, possibleEmail) => {
      if (!possibleEmail.length) {
        return acc;
      }
      if (isEmail(possibleEmail)) {
        acc.validParsedEmails = acc.validParsedEmails.concat(possibleEmail);
      } else {
        acc.invalidParsedEmails = acc.invalidParsedEmails.concat(possibleEmail);
      }
      return acc;
    },
    { validParsedEmails: [], invalidParsedEmails: [] },
  );
};

export const checkEmail = (emails: string[], email: string) => {
  let error = '';

  if (emails.includes(email)) {
    error = `${email} has already been added.`;
  }

  if (!isEmail(email)) {
    error = `${email} is not a valid email address.`;
  }

  return error;
};

export const inviteFlowReducer = (
  state: InviteFlowReducerState,
  action: InviteFlowReducerAction,
): InviteFlowReducerState => {
  switch (action.type) {
    case 'setShippingMethod':
      return {
        ...state,
        shippingMethod: action.shippingMethod,
      };
    default:
      return state;
  }
};
