import { Icon } from '@whoop/web-components';
import { getIcon, getTrendColor } from 'insights/components/trendIndicatorBox/trendIndicator.utils';
import DefaultAvatar from 'assets/hub-default-user-profile.svg';
import { BaseLeaderboard } from 'api/widgetApi';
import { WidgetType } from 'insights/components/widget/widget';
import { DAILY_GRAPH_LABEL_COLOR, FORMAT_METRIC_VALUE } from 'insights/components/widget/widgetFormatter';
import styles from './hoverDataComponent.module.scss';

export interface DailyHoverData extends BaseLeaderboard {
  metricValue: number;
}

export type HoverDataComponentProps = {
  dailyHoverData: DailyHoverData;
  widgetType: WidgetType;
};

function DailyHoverDataComponent(
  { dailyHoverData, widgetType }: HoverDataComponentProps,
) {
  const { color: dayOverDayTrendColor } = getTrendColor(dailyHoverData.day_over_day_sentiment);
  const { color: baselineTrendColor } = getTrendColor(dailyHoverData.base_line_sentiment);

  return (
    <div className={styles.hoverData}>
      <div className={styles.nameAndAvatar}>
        <img
          alt="User Profile"
          className={styles.avatar}
          src={dailyHoverData.avatar_url ?? DefaultAvatar}
        />
        <div className={styles.name}>{dailyHoverData.name}</div>
      </div>
      <div className={styles.dailyDataSection}>
        <div>
          <div className={styles.header}>{widgetType}</div>
          <div
            className={styles.metricValue}
            style={{ color: DAILY_GRAPH_LABEL_COLOR[widgetType](dailyHoverData.metricValue) }}
          >
            {FORMAT_METRIC_VALUE[widgetType](dailyHoverData.metricValue)}
          </div>
        </div>
        <div>
          <div className={styles.header}>Day-over-day</div>
          <div className={styles.trendIndicator}>
            {Number.isFinite(dailyHoverData.day_over_day) && (
              <Icon
                name={getIcon(dailyHoverData.day_over_day)}
                className={styles.trendIcon}
                style={{ color: dayOverDayTrendColor }}
              />
            )}
            <div className={styles.data}>
              {Number.isFinite(dailyHoverData.day_over_day) ? FORMAT_METRIC_VALUE[widgetType](Math.abs(dailyHoverData.day_over_day)) : '--'}
            </div>
          </div>
        </div>
        <div>
          <div className={styles.header}>Baseline</div>
          <div className={styles.trendIndicator}>
            {Number.isFinite(dailyHoverData.base_line) && (
              <Icon
                name={getIcon(dailyHoverData.base_line)}
                className={styles.trendIcon}
                style={{ color: baselineTrendColor }}
              />
            )}
            <div className={styles.data}>
              {Number.isFinite(dailyHoverData.base_line) ? FORMAT_METRIC_VALUE[widgetType](Math.abs(dailyHoverData.base_line)) : '--'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailyHoverDataComponent;
