import StrapManagementHeader from 'components/strapManagementHeader';
import { useAccounts } from 'context/account-context';
import { useState } from 'react';
import {
  Navigate, Route, Routes, useNavigate, useParams,
} from 'react-router-dom';
import { Row } from 'react-table';
import { Collaborator, RemoveCollaboratorType } from 'types/collaborator';
import { DashboardRole } from 'types/dashboardUser';
import ResendInvite from 'resendInvite/resendInvite';
import Tabs, { TabThemes } from 'components/tabs/tabs';
import styles from './accountDetails.module.scss';
import RemoveCollaborator from './removeCollaborator/removeCollaborator';
import Summary from './summary/summary';
import { AccountDetailsTab } from './types';
import CollaboratorsPage from './collaboratorsPage/collaboratorsPage';
import SettingsV2 from './settingsV2/settingsV2';

type AccountDetailsParams = {
  accountId: string;
  tabTitle: AccountDetailsTab;
};

function AccountDetails() {
  const { account: { id: accountId }, checkAccess } = useAccounts();
  const { tabTitle } = useParams<AccountDetailsParams>();
  const params = useParams();
  const navigate = useNavigate();

  const [removalModal, setRemovalModal] = useState(false);
  const [resendInviteModal, setResendInviteModal] = useState(false);
  const [removalType, setRemovalType] = useState<RemoveCollaboratorType>(
    RemoveCollaboratorType.ACCOUNT,
  );

  const [selectedRows, setSelectedRows] = useState<Row<Collaborator>[]>([]);

  const handleRemovalModal = (type: RemoveCollaboratorType, isRemovalModal: boolean) => {
    setRemovalType(type);
    setRemovalModal(isRemovalModal);
  };

  if (removalModal) {
    return (
      <RemoveCollaborator
        type={removalType}
        collaborators={selectedRows.map((collab) => collab.original)}
        setModal={setRemovalModal}
      />
    );
  }

  if (resendInviteModal) {
    return (
      <ResendInvite
        selectedRows={selectedRows.map((collab) => collab.original)}
        setSelectedRows={setSelectedRows}
        refetchData={() => { }}
        setPageState={() => setResendInviteModal(false)}
        triggerToast={() => { }}
        isCollaboratorInvite
      />
    );
  }

  const tabs = [AccountDetailsTab.SUMMARY, AccountDetailsTab.PERMISSIONS];
  if (checkAccess(DashboardRole.WHOOP_CSM)) {
    tabs.splice(1, 0, AccountDetailsTab.SETTINGS);
  }

  if (tabTitle === AccountDetailsTab.SETTINGS && !checkAccess(DashboardRole.WHOOP_CSM)) {
    return <Navigate to={`/accounts/${accountId}/plan/summary`} />;
  }

  return (
    <>
      <StrapManagementHeader />
      <div className={styles.tabs}>
        <Tabs
          tabList={tabs.map((tab) => ({ tab }))}
          // params has format: {accountId: 'abc', *: 'summary/settings/permissions'}
          selectedTab={params['*']}
          onClick={(tab) => {
            navigate(tab);
          }}
          theme={TabThemes.HEADER}
        />
      </div>
      <div className={styles.content}>
        <Routes>
          <Route path={AccountDetailsTab.SUMMARY} element={<Summary />} />
          <Route
            path={AccountDetailsTab.SETTINGS}
            element={<SettingsV2 />}
          />
          <Route
            path={AccountDetailsTab.PERMISSIONS}
            element={(
              <CollaboratorsPage
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                setRemovalModal={handleRemovalModal}
                setResendInviteModal={setResendInviteModal}
              />
            )}
          />
          <Route path="*" element={<Navigate to={AccountDetailsTab.SUMMARY} />} />
        </Routes>
      </div>
    </>
  );
}

export default AccountDetails;
