import CustomSwitch from 'accountDetails/settingsV2/customSwitch';
import { classes, Button } from '@whoop/web-components';
import { AccountConfigurationSettingType } from 'accountDetails/types';
import { ReactChild } from 'react';
import styles from './settingsV2.module.scss';

type SettingCardProps = {
  onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEnabled?: boolean;
  title: string;
  description?: string;
  name: string;
  type?: AccountConfigurationSettingType;
  children?: ReactChild[] | ReactChild;
};

function SettingCard({
  onChangeHandler, isEnabled, title, description, name, type, children,
}: SettingCardProps) {
  return (
    <div className={classes('whoop-typography', styles.cardWrapper)}>
      <div className={styles.topSection}>
        <div className={styles.titleCol}>
          <p className="p5">
            {title}
          </p>
          <p className={styles.description}>
            {description}
          </p>
        </div>
        <div className={styles.toggleContainer}>
          {type === AccountConfigurationSettingType.Toggle && (
          <CustomSwitch
            onChange={onChangeHandler}
            value={isEnabled}
            checked={isEnabled}
            name={name}
            customheight={30}
            customwidth={60}
          />
          )}
          {type === AccountConfigurationSettingType.Button
          && (
          <Button
            className={styles.settingsButton}
            label={title}
            name={name}
            size="small"
            theme="enterprise"
            variant="normal"
            onClick={onChangeHandler}
          />
          )}
        </div>
      </div>
      {children}
    </div>
  );
}

export default SettingCard;
