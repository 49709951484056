import { useEffect, StrictMode } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { FeatureFlagContextProvider } from 'components/featureFlag/feature-flag-context';
import { ToastProvider } from 'context/toast-context';
import ReactDOM from 'react-dom';
import {
  BrowserRouter, createRoutesFromChildren,
  matchRoutes, useLocation,
  useNavigationType,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './App';
import env from './constants/env';
import { AuthProvider } from './context/auth-context';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

if (env.ENV !== 'local') {
  Sentry.init({
    dsn: 'https://fbb74780c92345f19214bf3b1c065b53@o217231.ingest.sentry.io/5643516',
    environment: window._env.ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });

  datadogRum.init({
    applicationId: '798dbea2-5767-4310-9f43-cb850dbb1b3e',
    clientToken: 'pub30ff28373728653e690e4ac7f8176716',
    site: 'datadoghq.com',
    service: 'enterprise-web',
    env: window._env.ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

if (window.location.hostname.includes('demo.qa.whoop')
  || window.location.hostname.includes('demo.whoopunite')
  // || window._env.ENV === 'local' // uncomment to enable MSW in local dev
) {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  worker.start();
}

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <FeatureFlagContextProvider>
            <ToastProvider>
              <App />
            </ToastProvider>
          </FeatureFlagContextProvider>
        </AuthProvider>
        {/* enables react query dev tools */}
        {/* <ReactQueryDevtools initialIsOpen /> */}
      </QueryClientProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// TODO: hook up to Google Analytics
reportWebVitals();
