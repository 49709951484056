import { classes, Button, Row } from '@whoop/web-components';
import { MoreInfoText } from 'components/IconTooltip/iconTooltipTypes';
import IconTooltip from 'components/IconTooltip/iconTooltip';
import { ActivityDetails } from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import { ActivityOverview } from 'api/analyticsApi';
import styles from '../card.module.scss';

type CommonProps = {
  title: string;
  details?: string;
  moreInfoText?: MoreInfoText;
  sticky?: boolean;
};

type ActivityProps =
  | {
    activities: ActivityDetails[];
    activitySelected: ActivityDetails;
    setActivitySelected: (activity: ActivityDetails) => void;
  }
  | {
    activities: ActivityOverview[];
    activitySelected: ActivityOverview;
    setActivitySelected: (activity: ActivityOverview) => void;
  }
  | {
    activities?: null;
    activitySelected?: never;
    setActivitySelected?: never;
  };

type CardHeaderProps = CommonProps & ActivityProps;

function CardHeader({
  title, details, moreInfoText, activities, activitySelected, setActivitySelected,
  sticky = false,
}: CardHeaderProps) {
  return (
    <div className={classes(styles.cardHeaderContainer, sticky && styles.stickyHeader)}>
      <div>
        <div className={styles.cardHeader}>
          {title}
        </div>
        <div className={styles.cardDetails}>
          {details}
        </div>
      </div>
      {activities?.length > 0
        && (
          <div className={styles.chipContainer}>
            <Row className={styles.activities}>
              {activities.map((activity: ActivityDetails | ActivityOverview) => (
                <Button
                  key={activity.id}
                  className={classes(
                    styles.activityChip,
                    activitySelected?.id === activity.id && styles.selectedChip,
                  )}
                  // @ts-ignore Will get fixed once we can use a base type here
                  label={activity.translated_type ?? activity.type}
                  theme="enterprise"
                  variant="normal"
                  // @ts-ignore Will get fixed once we can use a base type here
                  onClick={() => setActivitySelected(activity)}
                />
              ))}
            </Row>
          </div>
        )}
      {moreInfoText && (
        <div className={styles.tooltipContainer}>
          <IconTooltip moreInfoText={moreInfoText} icon="education" />
        </div>
      )}
    </div>
  );
}

export default CardHeader;
