import { useState } from 'react';
import { Button } from '@whoop/web-components';
import { useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { countries } from 'register/constants';
import { ProfileRegistration } from 'types/base';
import styles from '../register.module.scss';

const useStyles = makeStyles({
  options: {
    '&:hover': {
      backgroundColor: '#2e2e2e',
    },
    "&[aria-selected='true']": {
      backgroundColor: '#2e2e2e',
    },
    color: 'white',
  },
  paper: {
    backgroundColor: '#171717',
  },
});

type SetupProfileProps = {
  handleRegistration: (profile: ProfileRegistration) => void;
};

function SetupProfile({ handleRegistration }: SetupProfileProps) {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    mode: 'onChange',
  });

  const watchFirstName = watch('firstName', '');
  const watchLastName = watch('lastName', '');

  const [countryKey, setCountryKey] = useState('US');

  const firstNameValidationError = errors?.firstName
    || (dirtyFields.watchFirstName && watchFirstName.length === 0);

  const lastNameValidationError = errors?.lastName
    || (dirtyFields.watchLastName && watchLastName.length === 0);

  const firstNameInputClass = `${styles.inputField} ${
    firstNameValidationError && styles.inputFieldInvalid
  }`;

  const lastNameInputClass = `${styles.inputField} ${
    lastNameValidationError && styles.inputFieldInvalid
  }`;

  const onSubmit = (values: ProfileRegistration) => {
    const profile: ProfileRegistration = {
      firstName: values.firstName,
      lastName: values.lastName,
      countryKey,
    };
    handleRegistration(profile);
  };

  return (
    <>
      <div className={styles.infoText}>
        <p>STEP 2/3</p>
        <h1>Setup your profile</h1>
      </div>
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <label id="firstNameLabel" className={styles.inputFieldLabel} htmlFor="first-name-input">
          First Name
          <input
            aria-labelledby="firstNameLabel"
            data-testid="first-name-input"
            className={firstNameInputClass}
            {...register('firstName', { required: true })}
          />
          <div className={styles.invalidMessage}>
            {firstNameValidationError && 'First name cannot be blank'}
          </div>
        </label>

        <label id="lastNameLabel" className={styles.inputFieldLabel} htmlFor="last-name-input">
          Last Name
          <input
            aria-labelledby="lastNameLabel"
            data-testid="last-name-input"
            className={lastNameInputClass}
            {...register('lastName', { required: true })}
          />
          <div className={styles.invalidMessage}>
            {lastNameValidationError && 'Last name cannot be blank'}
          </div>
        </label>

        <Autocomplete
          options={countries}
          getOptionLabel={(option) => option.label}
          onChange={(event, value: { label: string; key: string }) => setCountryKey(
            value.key,
          )}
          ListboxProps={{ style: { maxHeight: '10rem' } }}
          classes={{
            option: classes.options,
            paper: classes.paper,
          }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <label id="countryLabel" className={styles.inputFieldLabel} htmlFor="country-input">
                Country
                <input
                  data-testid="country-input"
                  type="text"
                  {...params.inputProps}
                  className={styles.inputField}
                />
              </label>

            </div>
          )}
        />

        <Button
          label="next"
          aria-label="Next"
          variant="primary"
          theme="enterprise"
          type="submit"
          data-testid="next-btn"
          disabled={!isValid}
        />
      </form>
    </>
  );
}

export default SetupProfile;
