import dayjs from 'dayjs';
import { DateRange } from 'react-day-picker';

export const TODAY_DATE = new Date();
export const DAY_OF_WEEK_HEADERS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getNumOfDays = (range: DateRange) => (range.to ? dayjs(range.to).diff(range.from, 'day') + 1 : 1);

export const formatDate = (date: Date, withDay?: boolean) => {
  if (withDay) {
    return dayjs(date).format('ddd, MMM D');
  }
  return dayjs(date).format('MMM D');
};

export const getDateRangeAsString = (range: DateRange) => {
  if (range.to) {
    return `${formatDate(range.from)} - ${formatDate(range.to)}`;
  }
  return `${formatDate(range.from)}`;
};

export const getPageUserIsOn = (tab: string, groupId: string, pillar: string) => {
  let page = '';
  if (tab) {
    if (tab === 'groups' && !groupId) {
      page = 'Insights Tab - Groups Page';
    } else if (tab === 'groups' && groupId) {
      page = 'Insights Tab - Group Details Page';
    } else if (tab === 'individuals') {
      page = 'Insights Tab - Individuals Page';
    }
  } else if (!tab && !groupId && pillar) {
    page = 'Insights Tab - Member Profile Page';
  }

  return page;
};
