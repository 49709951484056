import { MenuItem, MenuList } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import Dropdown from 'components/dropdown';
import { CellProps } from 'react-table';
import { Collaborator, DescriptionDropdownOption } from 'types/collaborator';
import { classes } from '@whoop/web-components';
import styles from './descriptionDropdown.module.scss';

type DescriptionDropdownProps = {
  options: DescriptionDropdownOption[];
  cellProps: CellProps<Collaborator>;
  field: 'role' | 'privacy_level' | 'job_title_id';
};

function DescriptionDropdown({
  options, cellProps, field,
}: DescriptionDropdownProps) {
  const { row } = cellProps;

  const activeOption = options.find((option) => option.value === row.original[field]);

  return (
    <div>
      <Dropdown
        button={(
          <p className={styles.buttonText}>
            <span className={classes(!activeOption && styles.unassigned)}>
              {activeOption?.name || 'Unassigned'}
            </span>
            <ArrowDropDown />
          </p>
        )}
        buttonProps={{
          variant: 'link',
          className: styles.dropdownBtn,
          'aria-label': `${field}_btn`,
        }}
        dropdownStyles={{ borderRadius: '8px', width: '396px' }}
        closeOnClick
      >
        <MenuList disablePadding>
          {options.map((item) => {
            // @ts-ignore
            const original = row.original[field];
            const active = item.value === original;

            return (
              <MenuItem
                key={`menu-item-${item.name}`}
                // @ts-ignore
                className={active
                  ? styles.activeItem
                  : styles.inactiveItem}
                onClick={() => cellProps.updateRow({
                  ...row.original,
                  [field]: item.value,
                })}
              >

                {active ? <CheckIcon /> : ''}
                <div>
                  <p className={styles.itemLabel}>
                    {item.name}
                  </p>
                  <div className={styles.itemDescription}>{item.description}</div>
                </div>
              </MenuItem>
            );
          })}
        </MenuList>
      </Dropdown>
    </div>
  );
}

export default DescriptionDropdown;
