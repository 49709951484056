import { PieCustomLayerProps } from '@nivo/pie';

export default function GetInnerLayer({
  dataWithArc,
  centerX,
  centerY,
}: PieCustomLayerProps<PieData>) {
  const { displayVal } = dataWithArc[0].data;
  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
    >
      {displayVal}
    </text>
  );
}

export type PieData = {
  id: string;
  label: string;
  value: number;
  displayVal: string | number;
};
