import { useEffect, useState } from 'react';
import { RadioToggle } from '@whoop/web-components';
import DatePicker from 'components/datePicker/datePicker';
import dayjs from 'dayjs';
import { Period } from 'types/analytics';
import { daysBetweenRange } from 'progress/profile/profileUtils';
import { trackEvent } from 'helpers/analyticsTracking';
import { useLocation } from 'react-router-dom';
import { useDateRange } from 'insights/hooks/useDateRange';
import styles from './datePickerSelection.module.scss';

type DatePickerSelectionProps = {
  disableMultiDay?: boolean;
  disabled?: boolean;
};

function DatePickerSelection({
  disableMultiDay = false, disabled = false,
}: DatePickerSelectionProps) {
  const { dateRange, setDateRange } = useDateRange();
  const location = useLocation();
  const [periodSelected, setPeriodSelected] = useState<Period>(Period.DAILY);

  const radioToggleOptions = [
    {
      label: Period.DAILY,
      value: Period.DAILY,
    },
    {
      label: Period.SEVEN_DAYS,
      value: Period.SEVEN_DAYS,
    },
    {
      label: Period.THIRTY_DAYS,
      value: Period.THIRTY_DAYS,
    },
  ];

  const selectPeriodFromDateRange = () => {
    const daysBetweenDates = daysBetweenRange(dateRange);
    if (daysBetweenDates === 7) {
      setPeriodSelected(Period.SEVEN_DAYS);
    } else if (daysBetweenDates === 30) {
      setPeriodSelected(Period.THIRTY_DAYS);
    } else {
      // daysBetweenDates is not 7 days or 30 days so set daily as default
      setPeriodSelected(Period.DAILY);
    }
  };

  useEffect(() => {
    selectPeriodFromDateRange();
  }, [dateRange]);

  useEffect(() => {
    if (disableMultiDay && dateRange.to && dateRange.to !== dateRange.from) {
      setDateRange({ from: dateRange.from }, true);
    }
  }, [dateRange, disableMultiDay]);

  const togglePeriod = (period: Period) => {
    const page = location.pathname.includes('individuals') ? 'individuals' : 'groups';
    trackEvent('Insights Pages - Date Range Toggle', { period, tab: page });
    setPeriodSelected(period);
    let newRange;
    if (period === Period.DAILY) {
      newRange = { from: dayjs().startOf('day').toDate() };
    } else {
      newRange = {
        from: dayjs().subtract(period === Period.SEVEN_DAYS ? 6 : 29, 'days').startOf('day').toDate(),
        to: dayjs().startOf('day').toDate(),
      };
    }
    setDateRange(newRange);
  };

  return (
    <fieldset disabled={disabled} className={styles.datePickerContainer}>
      {!disableMultiDay && (
        <div className={styles.toggleContainer}>
          <RadioToggle
            options={radioToggleOptions}
            value={periodSelected}
            onChange={togglePeriod}
            size={null}
            useExternalState
            name="period"
            theme="dark"
          />
        </div>
      )}
      {/* Future TODO : when updating this date picker, add in tracking events */}
      <DatePicker
        selectSingleDay={periodSelected === Period.DAILY}
        required
        autoSelectRange={periodSelected}
        currentRange={dateRange}
        applyRange={setDateRange}
        styleDatePickerBox={styles.datePickerBox}
        datePickerButtonStyles={styles.datePickerButton}
        disabled={disabled}
      />
    </fieldset>
  );
}

export default DatePickerSelection;
