import { Icon } from '@whoop/web-components';
import {
  addDashboardAccess, getAllCollaborators,
  getAllCSMs,
  removeCollaboratorFromAccount,
} from 'api/dashboardApi';
import ActionContent from 'components/actionContent/actionContent';
import ActionFooter from 'components/actionFooter/actionFooter';
import FuzzySearch from 'components/fuzzySearch/fuzzySearch';
import Table from 'components/table';
import { HEADING_2, TITLE_3 } from 'components/text';
import { AccountParams, useAccounts } from 'context/account-context';
import { useToast } from 'context/toast-context';
import { convertToCollaborator } from 'helpers/csmUtils';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  CellProps, Column, Row, TableCommonProps,
} from 'react-table';
import { nameWithAvatarCell } from 'tableUtils/tableCells';
import { CohortCollaborator } from 'types/cohort';
import { Collaborator } from 'types/collaborator';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import { WhoopRole } from 'types/whoopRole';
import styles from './manageCSMs.module.scss';

function ManageCSMs() {
  const navigate = useNavigate();
  const { accountId } = useParams<AccountParams>();
  const [availableCSMs, setAvailableCSMs] = useState<Collaborator[]>([]);
  const [currentCSMs, setCurrentCSMs] = useState<Collaborator[]>([]);
  const [filteredCSMs, setFilteredCSMs] = useState<Collaborator[]>([]);
  const [originalCSMs, setOriginalCSMs] = useState<Collaborator[]>([]);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const { openToast } = useToast();
  const { checkAccess } = useAccounts();

  const columns: Column<Collaborator>[] = useMemo(() => [
    {
      Header: 'CSM Name',
      id: 'name',
      align: 'left',
      width: '40%',
      accessor: (csm: Collaborator) => csm.name,
      Cell: (cellProps:
      CellProps<Collaborator | CohortCollaborator>) => nameWithAvatarCell(cellProps),
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: (csm: Collaborator) => csm.email,
      align: 'left',
      width: '35%',
    },
  ], []);

  useEffect(() => {
    const init = async () => {
      const data: Collaborator[] = await getAllCollaborators();
      const csms = data.filter(
        (u) => u.role === DashboardRole.WHOOP_CSM,
      );
      setCurrentCSMs(csms);
      setOriginalCSMs(csms);
      const allCSMs = await getAllCSMs();
      const currentCSMIds = csms.map((csm) => csm.user_id);
      setAvailableCSMs(allCSMs.filter((csm) => !currentCSMIds.includes(csm.user_id))
        .map((c) => convertToCollaborator(c)));
    };

    init();
  }, []);

  useEffect(() => {
    setShowMenu(true);
  }, [filteredCSMs]);

  const getRowStyles = (): TableCommonProps => ({
    style: {
      height: '80px',
    },
  });

  const handleSave = async () => {
    const csmsToAdd = currentCSMs.filter((current) => !originalCSMs.includes(current));
    const csmsToRemove = originalCSMs.filter((original) => !currentCSMs.includes(original));

    const addCSMsPromises = csmsToAdd.map((csm) => addDashboardAccess(
      {
        salesforce_account_id: accountId,
        user_id: csm.user_id,
        dashboard_role: WhoopRole.ENTERPRISE_ADMIN,
        is_primary_owner: false,
        privacy_level: PrivacyLevel.all_metrics,
        role: DashboardRole.WHOOP_CSM,
      },
    ));

    const removeCSMsPromises = csmsToRemove.map((csm) => removeCollaboratorFromAccount(
      csm.user_id.toString(),
    ));

    const addResults = await Promise.allSettled(addCSMsPromises);
    const removeResults = await Promise.allSettled(removeCSMsPromises);

    const numAdded = addResults.filter(
      (res) => res.status === 'fulfilled',
    ).length;
    const failedToAdd = addCSMsPromises.length - numAdded;

    const numRemoved = removeResults.filter(
      (res) => res.status === 'fulfilled',
    ).length;
    const failedToRemove = removeCSMsPromises.length - numRemoved;

    let message = '';
    if (numAdded > 0) {
      message += `Added ${numAdded} CSMs to the account.`;
    }

    if (numRemoved > 0) {
      message += ` Removed ${numRemoved} CSMs to the account.`;
    }

    if (failedToAdd > 0) {
      message += ` ${failedToAdd} CSMs failed to be added to the account.`;
    }

    if (failedToRemove > 0) {
      message += ` ${failedToRemove} CSMs failed to be removed from the account.`;
    }

    navigate(`/accounts/${accountId}/plan/permissions`);
    openToast(message);
  };

  const getActionButtonProps = () => {
    const label = 'Save';
    const isDisabled = originalCSMs.values() === currentCSMs.values();
    return {
      className: `${styles.button}`,
      theme: 'enterprise',
      variant: 'primary',
      label,
      disabled: isDisabled,
      onClick: handleSave,
    };
  };

  const getSecondaryButtonProps = (cancel: () => void) => ({
    variant: 'link',
    label: 'Cancel',
    onClick: cancel,
  });

  const handleRemoveRow = (csm: Collaborator) => {
    setCurrentCSMs((prevState) => prevState.filter((sm) => sm.user_id !== csm.user_id));
    setAvailableCSMs((prevState) => [...prevState, csm]);
  };

  const removeRowCell = (csm: Row<Collaborator>) => (<td><Icon data-testid="deleteBtn" onClick={() => handleRemoveRow(csm.original)} className={styles.searchIcon} name="trash" /></td>);

  const handleClickCSM = (csm: Collaborator) => {
    setAvailableCSMs((prevState) => prevState.filter((sm) => sm.user_id !== csm.user_id));
    setCurrentCSMs((prevState) => [...prevState, csm]);
    setShowMenu(false);
  };

  if (!checkAccess(DashboardRole.WHOOP_CSM)) {
    return <Navigate to={`/accounts/${accountId}/people`} />;
  }

  return (
    <>
      <ActionContent>
        <TITLE_3>Manage the WHOOP CSMs for this account</TITLE_3>
        <HEADING_2>Manage The Whoop CSMs</HEADING_2>
        <div className={styles.searchContainer}>
          <FuzzySearch
            searchables={availableCSMs}
            searchKeys={['email', 'name']}
            setFilteredSearch={setFilteredCSMs}
            placeholder="Search for a CSM name or email"
          />
          <ul className={!showMenu ? styles.hide : ''}>
            {filteredCSMs.map((csm) => (
              <li
                key={csm.user_id}
                tabIndex={0}
                role="menuitem"
                onClick={() => handleClickCSM(csm)}
                onKeyDown={() => { }}
              >
                <div>
                  <div className={styles.nameAndEmail}>
                    <span className={styles.name}>
                      {csm.name}
                    </span>
                    <span className={styles.email}>
                      {csm.email}
                    </span>
                    <span className={styles.privacyLevel}>
                      {PrivacyLevel.all_metrics}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <Table
          columns={columns}
          data={currentCSMs}
          loading={false}
          tableName="customerSuccessManagers"
          unit="CSM"
          noDataFoundMessage="Selected CSMs Will appear here"
          tableLength={50}
          getAdditionalRowProps={getRowStyles}
          displayHeader={false}
          search={false}
          pagination={false}
          createExtraRowComponent={removeRowCell}
        />
      </ActionContent>
      <ActionFooter
        getPrimaryButtonProps={() => getActionButtonProps()}
        getSecondaryButtonProps={() => getSecondaryButtonProps(() => navigate(-1))}
      />
    </>
  );
}

export default ManageCSMs;
