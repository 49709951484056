import { Icon, classes } from '@whoop/web-components';
import {
  RecoveryLeaderboard, SleepLeaderboard, StrainLeaderboard, TrainingZoneLeaderboard,
} from 'api/widgetApi';
import { AccountParams } from 'context/account-context';
import { usePrivacy } from 'context/privacy-context';
import {
  createSearchParams, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { Column } from 'react-table';
import { PrivacyLevel } from 'types/dashboardUser';
import { useMemo } from 'react';
import {
  RecoveryLabel, SleepDebtLabel, SleepPerformanceLabel, StrainLabel, TrainingZoneLabel,
} from 'dataVisualizations/iconPieChart/iconPieChartConsts';
import { trackEvent } from 'helpers/analyticsTracking';
import { getTrackedSortDirection } from 'insights/components/groupsTable/groupsTableUtils';
import { WidgetType } from 'insights/components/widget/widget';
import { DEFAULT_SORT_BY_WIDGET } from 'insights/components/widget/widgetFormatter';
import styles from './leaderboardTable.module.scss';
import useLeaderboardTable from './useLeaderboardTable';
import { BucketRow, bucketRows, moveNullRowsToBottom } from './bucketData';

type LeaderboardTableProps = {
  columns: Column[];
  data: RecoveryLeaderboard[] |
  StrainLeaderboard[] | SleepLeaderboard[] | TrainingZoneLeaderboard[];
  defaultSortById: string;
  leaderboardType: WidgetType;
};

function LeaderboardTable({
  columns, data, defaultSortById, leaderboardType,
}: LeaderboardTableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
  } = useLeaderboardTable(data, columns, defaultSortById);
  const { accountId } = useParams<AccountParams>();
  const { isInPrivacyLevel } = usePrivacy();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = new URLSearchParams(searchParams);

  const navigateToMemberProfile = (user_id: number, opted_in: boolean) => {
    if (isInPrivacyLevel(PrivacyLevel.primary_metrics) && opted_in) {
      if (params.get('groupId')) {
        params.delete('groupId');
      }
      const queryParams = createSearchParams(params).toString();
      navigate(`/accounts/${accountId}/insights/profile/${user_id}/overview${queryParams ? `?${queryParams}` : ''}`);
    }
  };

  const rowBuckets = useMemo(() => {
    // if card is expanded and another column is used to sort
    // if that isn't the primary bucket column, remove buckets
    if (state.sortBy.some((sortType) => sortType.id === DEFAULT_SORT_BY_WIDGET[leaderboardType])) {
      if (leaderboardType === WidgetType.RECOVERY) {
        return bucketRows<RecoveryLabel>(rows, state, leaderboardType);
      }
      if (leaderboardType === WidgetType.DAY_STRAIN) {
        return bucketRows<StrainLabel>(rows, state, leaderboardType);
      }
      if (leaderboardType === WidgetType.SLEEP_PERFORMANCE) {
        return bucketRows<SleepPerformanceLabel>(rows, state, leaderboardType);
      }
      if (leaderboardType === WidgetType.SLEEP_DEBT) {
        return bucketRows<SleepDebtLabel>(rows, state, leaderboardType);
      }
      if (leaderboardType === WidgetType.TRAINING_ZONE) {
        return bucketRows<TrainingZoneLabel>(rows, state, leaderboardType);
      }
    } else {
      // sorting by a column that isn't the primary bucket column
      // the table sorts the rows already so I just want to move the nulls to the bottom
      const columnName = state.sortBy[0].id;
      const isDesc = state.sortBy[0].desc;
      return isDesc ? rows : moveNullRowsToBottom(rows, columnName);
    }
    return rows;
  }, [rows, state, leaderboardType]);

  return (
    <table className={styles.leaderboardTable} {...getTableProps()}>
      <thead className={styles.tableHead}>
        {headerGroups.map((headerGroup) => (
          <tr className={styles.tableHeaderRow} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className={styles.tableHeader}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                onClick={(e) => {
                  const sortDirection = getTrackedSortDirection(column.isSortedDesc);
                  trackEvent('Insights Page - Leaderboard Table Sort', { column: column.Header, sortDirection, widgetType: leaderboardType });
                  column.getSortByToggleProps().onClick(e);
                }}
              >
                <div className={classes(
                  styles.tableHeaderText,
                  !column.canSort && styles.leftAlign,
                )}
                >
                  {column.render('Header')}
                  {column.isSorted && <Icon name={column.isSortedDesc ? 'tiny_caret_down' : 'tiny_caret_up'} />}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className={styles.tableBody} {...getTableBodyProps()}>
        {rowBuckets.map((row: BucketRow, index: number) => {
          if ('label' in row) {
            const { id, label } = row;
            return (
              <tr
                key={id}
                className={classes(
                  styles.bucketHeader,
                )}
              >
                <td className={styles.bucketHeaderCell}>
                  <div data-testid={id}>
                    {label}
                  </div>
                  <span className={styles.bucketHeaderLine} />
                </td>
              </tr>
            );
          }
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={classes(
                styles.tableRow,
                row.original.opted_in && isInPrivacyLevel(PrivacyLevel.primary_metrics)
                && styles.clickRow,
                index === rowBuckets.length - 1 && styles.lastRowPadding,
              )}
              onClick={() => navigateToMemberProfile(row.original.user_id, row.original.opted_in)}
            >
              {row.cells.map((cell) => (<td className={styles.widgetCell} {...cell.getCellProps()}>{cell.render('Cell')}</td>))}
            </tr>
          );
        })}
        <tr key="gradientRow" className={classes(styles.gradient)} />
      </tbody>
    </table>
  );
}

export default LeaderboardTable;
