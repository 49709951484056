import { useQuery } from '@tanstack/react-query';
import { getGroupLanding } from 'api/groupLandingApi';
import { formatDate } from 'progress/profile/profileUtils';
import { useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { useParams } from 'react-router-dom';

export default function useGroupLanding(range: DateRange) {
  const { accountId } = useParams();

  const queryParams = useMemo(() => {
    const param = {
      salesforceAccountId: accountId,
      startDate: formatDate(range.from),
      endDate: formatDate(range.to ?? range.from),
    };
    return param;
  }, [accountId, range]);

  return useQuery({
    queryKey: ['groupLanding', queryParams],
    queryFn: () => getGroupLanding(queryParams),
  });
}
