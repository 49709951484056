import {
  sleepDebtBaseColumns, sleepPerformanceBaseColumns, strainBaseColumns,
  recoveryBaseColumns, strainExpandedColumns, sleepPerformanceExpandedColumns,
  sleepDebtExpandedColumns, recoveryExpandedColumns, trainingZoneBaseColumns,
  trainingZoneExpandedColumns,
} from 'insights/components/leaderboardTable/useLeaderboardTable';
import { PieChartLabels } from 'dataVisualizations/iconPieChart/iconPieChartConsts';
import { WidgetType } from 'insights/components/widget/widget';
import { Colors } from '@whoop/web-components';
import { getColorFromVal } from 'progress/progressPageUtils';
import { formatNumberToPercent, getSleepDuration, roundToOneDecimalString } from 'progress/utils/numberFormatter';
import { TrainingZoneKeys } from 'api/widgetApi';

export const WIDGET_TYPE_TO_NAME = {
  [WidgetType.DAY_STRAIN]: 'Day Strain',
  [WidgetType.SLEEP_PERFORMANCE]: 'Sleep Performance',
  [WidgetType.SLEEP_DEBT]: 'Sleep Debt',
  [WidgetType.RECOVERY]: 'Recovery',
  [WidgetType.TRAINING_ZONE]: 'Training Zones',
};

export const BREAKDOWN_LABEL_BY_WIDGET = {
  [WidgetType.DAY_STRAIN]: PieChartLabels.STRAIN,
  [WidgetType.SLEEP_PERFORMANCE]: PieChartLabels.SLEEP_PERFORMANCE,
  [WidgetType.SLEEP_DEBT]: PieChartLabels.SLEEP_DEBT,
  [WidgetType.RECOVERY]: PieChartLabels.RECOVERY,
  [WidgetType.TRAINING_ZONE]: PieChartLabels.TRAINING_ZONES,
};

export const COLLAPSED_LEADERBOARD_COLUMNS_BY_WIDGET = {
  [WidgetType.DAY_STRAIN]: strainBaseColumns,
  [WidgetType.SLEEP_PERFORMANCE]: sleepPerformanceBaseColumns,
  [WidgetType.SLEEP_DEBT]: sleepDebtBaseColumns,
  [WidgetType.RECOVERY]: recoveryBaseColumns,
  [WidgetType.TRAINING_ZONE]: trainingZoneBaseColumns,
};

export const PRIMARY_METRICS_COLUMNS_BY_WIDGET = {
  [WidgetType.DAY_STRAIN]: strainBaseColumns,
  [WidgetType.SLEEP_PERFORMANCE]: sleepPerformanceExpandedColumns,
  [WidgetType.SLEEP_DEBT]: sleepDebtExpandedColumns,
  [WidgetType.RECOVERY]: recoveryBaseColumns,
  [WidgetType.TRAINING_ZONE]: trainingZoneExpandedColumns,
};

export const EXPANDED_LEADERBOARD_COLUMNS_BY_WIDGET = {
  [WidgetType.DAY_STRAIN]: strainExpandedColumns,
  [WidgetType.SLEEP_PERFORMANCE]: sleepPerformanceExpandedColumns,
  [WidgetType.SLEEP_DEBT]: sleepDebtExpandedColumns,
  [WidgetType.RECOVERY]: recoveryExpandedColumns,
  [WidgetType.TRAINING_ZONE]: trainingZoneExpandedColumns,
};

export const ICON_BY_WIDGET = {
  [WidgetType.DAY_STRAIN]: 'strain',
  [WidgetType.SLEEP_PERFORMANCE]: 'sleep',
  [WidgetType.SLEEP_DEBT]: 'sleep',
  [WidgetType.RECOVERY]: 'recovery_score',
  [WidgetType.TRAINING_ZONE]: 'training_adaptation',
};

export const DEFAULT_SORT_BY_WIDGET = {
  [WidgetType.DAY_STRAIN]: 'strain',
  [WidgetType.SLEEP_PERFORMANCE]: 'sleep_performance',
  [WidgetType.SLEEP_DEBT]: 'sleep_debt_minutes',
  [WidgetType.RECOVERY]: 'recovery',
  [WidgetType.TRAINING_ZONE]: '',
};

export const WIDGET_TO_AVG_KEY = {
  [WidgetType.DAY_STRAIN]: 'strain_average',
  [WidgetType.SLEEP_PERFORMANCE]: 'sleep_performance_average',
  [WidgetType.SLEEP_DEBT]: 'sleep_debt_average_minutes',
  [WidgetType.RECOVERY]: 'recovery_average',
  // Training zone doesn't show an avg val
  [WidgetType.TRAINING_ZONE]: '',
};

export const BAR_KEYS_BY_WIDGET = {
  [WidgetType.RECOVERY]: ['red', 'yellow', 'green'],
  [WidgetType.SLEEP_PERFORMANCE]: ['poor', 'sufficient', 'optimal'],
  [WidgetType.SLEEP_DEBT]: ['low', 'moderate', 'high'],
  [WidgetType.DAY_STRAIN]: ['light', 'moderate', 'strenuous', 'all_out'],
  [WidgetType.TRAINING_ZONE]: ['tapering', 'maintaining', 'overreaching'],
};

export const BAR_COLORS_BY_WIDGET = {
  [WidgetType.RECOVERY]: [Colors.brandingRed, Colors.yellow, Colors.green],
  [WidgetType.SLEEP_PERFORMANCE]: [Colors.sleepBluePoor, Colors.sleepBlueSufficient,
    Colors.sleepBlue],
  [WidgetType.SLEEP_DEBT]: [Colors.sleepBluePoor, Colors.sleepBlueSufficient, Colors.sleepBlue],
  [WidgetType.DAY_STRAIN]: [Colors.strainBlueLight, Colors.strainBlueModerate,
    Colors.strainBlueStrenuous, Colors.strainBlue],
  [WidgetType.TRAINING_ZONE]: [Colors.hrZoneBlue, Colors.hrZoneTeal, Colors.hrZoneRed],
};

// Round to nearest 5 minutes
const getSleepDebtYValues = (maxSleepDebt: number) => Array.from(
  { length: 5 },
  (v, i) => Math.round((i * maxSleepDebt) / 4),
);

export const DAILY_GRAPHS_Y_AXIS_VALUES = {
  [WidgetType.DAY_STRAIN]: () => [0, 5, 10, 15, 21],
  [WidgetType.SLEEP_PERFORMANCE]: () => [0, 25, 50, 75, 100],
  [WidgetType.SLEEP_DEBT]: getSleepDebtYValues,
  [WidgetType.RECOVERY]: () => [0, 33, 66, 100],
  [WidgetType.TRAINING_ZONE]: () => [0, 5, 10, 15, 21],
};

const getRecoveryColor = (value: number) => getColorFromVal(value, false);

export const DAILY_GRAPH_LABEL_COLOR = {
  [WidgetType.DAY_STRAIN]: () => Colors.strainBlue,
  [WidgetType.SLEEP_PERFORMANCE]: () => Colors.sleepBlue,
  [WidgetType.SLEEP_DEBT]: () => Colors.sleepBlue,
  [WidgetType.RECOVERY]: getRecoveryColor,
  // TODO : this can be remove / set to null once training graph is implemented
  [WidgetType.TRAINING_ZONE]: () => Colors.strainBlue,
};

const getStrainBarColor = (value: number) => {
  if (value > 18) { return Colors.strainBlue; }
  if (value > 14) { return Colors.strainBlueStrenuous; }
  if (value > 10) { return Colors.strainBlueModerate; }
  return Colors.strainBlueLight;
};

const getSleepPerformanceBarColor = (value: number) => {
  if (value > 85) { return Colors.sleepBlue; }
  if (value >= 70) { return Colors.sleepBlueSufficient; }
  return Colors.sleepBluePoor;
};

const getSleepDebtBarColor = (value: number) => {
  if (value > 45) { return Colors.sleepBlue; }
  if (value >= 30) { return Colors.sleepBlueSufficient; }
  return Colors.sleepBluePoor;
};

export const DAILY_GRAPH_BAR_COLOR = {
  [WidgetType.DAY_STRAIN]: getStrainBarColor,
  [WidgetType.SLEEP_PERFORMANCE]: getSleepPerformanceBarColor,
  [WidgetType.SLEEP_DEBT]: getSleepDebtBarColor,
  [WidgetType.RECOVERY]: getRecoveryColor,
  // Not used because has custom graph
  [WidgetType.TRAINING_ZONE]: () => '',
};

export const DAILY_TRAINING_ZONE_NODE_COLOR = {
  [TrainingZoneKeys.OVERREACHING]: Colors.hrZoneRed,
  [TrainingZoneKeys.MAINTAINING]: Colors.hrZoneTeal,
  [TrainingZoneKeys.TAPERING]: Colors.hrZoneBlue,
};

export const FORMAT_METRIC_VALUE = {
  [WidgetType.DAY_STRAIN]: roundToOneDecimalString,
  [WidgetType.SLEEP_PERFORMANCE]: (val: number) => (val === 0 ? '0%' : formatNumberToPercent(val)),
  // Need the duration val to be in milliseconds (but sleep debt val returned is in minutes)
  [WidgetType.SLEEP_DEBT]: (value: number) => getSleepDuration(value * 60 * 1000),
  [WidgetType.RECOVERY]: (val: number) => (val === 0 ? '0%' : formatNumberToPercent(val)),
  // Not used because has custom graph
  [WidgetType.TRAINING_ZONE]: () => '',
};

export const WIDGET_STATIC_VOW = {
  [WidgetType.DAY_STRAIN]: 'Day Strain measures total daily exertion. It quantifies how strenuous a member\'s day was and how hard their body worked.',
  [WidgetType.SLEEP_PERFORMANCE]: 'Sleep Performance is the amount of sleep a member had divided by the amount of sleep they needed. This assesses whether they are getting enough sleep nightly.',
  [WidgetType.SLEEP_DEBT]: 'Sleep Debt is the accumulated lack of sleep that occurs from consistently not getting enough sleep. It is how much sleep a member needs to restore their body’s well-being.',
  [WidgetType.RECOVERY]: 'Recovery is a daily measure of how prepared a members body is to perform and take on Strain. It indicates general well-being and the ability to adapt to stressors.',
  [WidgetType.TRAINING_ZONE]: 'Training zones monitor how a member\'s body is adapting to training. This helps gauge whether today\'s exertion was consistent with their recovery capacity.',
};
