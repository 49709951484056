import { PrivacyLevel } from './dashboardUser';
import { SeatStatus } from './seat';

export type Cohort = {
  avatar_url?: string;
  concealed: boolean;
  id: number;
  community_id: number;
  salesforce_account_id: string;
  name: string;
  member_count: number;
  about: string;
  collaborators: CohortCollaborator[];
  legacy_team_id?: number;
  is_primary?: boolean;
  created_at: string;
};

export const isCohort = (obj: any): obj is Cohort => (obj as Cohort).community_id !== undefined;

// Will get renamed in a tech debt item. We will want to have the BE send back a
// different object type when we call getAvailableCollaborators in cohortApi ENT-2074
export type CohortCollaborator = {
  user_id: number;
  first_name: string;
  last_name: string;
  avatar_url?: string;
  email?: string;
  privacy_level?: PrivacyLevel;
};

export type CreateCohortParams = {
  salesforce_account_id: string;
  name: string;
  owner_id: number;
  about?: string;
  is_primary: boolean;
  concealed: boolean;
};

export type GroupMember = {
  user_id: number;
  cohort_id?: number;
  seat_id: number;
  first_name?: string;
  last_name?: string;
  strap_serial?: string;
  last_activity_at?: Date;
  email?: string;
  seat_status?: SeatStatus;
  activated_on?: Date;
  member_identifier?: string;
  avatar_url?: string;
  opted_in: boolean;
};

export type GroupMembersResult = {
  offset: number;
  total_count: number;
  records: GroupMember[];
};

export type GroupUser = {
  cohort_id: number;
  user_id: number;
};

export type GroupUserAccess = {
  id: number;
  created_at: Date;
  updated_at: Date;
  user_id: number;
  cohort_id: number;
  name: string;
};
