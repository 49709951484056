import {
  RecoveryWidget, SleepDebtWidget, SleepPerformanceWidget,
  StrainWidget, TrainingZoneKeys, TrainingZoneWidget,
} from 'api/widgetApi';
import { SENTIMENT } from 'api/groupLandingApi';

export const mockRecoveryWidgetResponse: RecoveryWidget = {
  group_id: 42408,
  start_date: '2023-06-05',
  end_date: '2023-06-05',
  vow: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
  recovery_average: 89.0,
  breakdown: { green: 1, yellow: 1, red: 1 },
  leaderboard: [
    {
      user_id: 1,
      name: '10GOLCI',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 89.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 2,
      name: 'asdas',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 50.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 3,
      name: '10GivOLCI',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 30.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 4,
      name: 'aiudbs91',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: null,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
  ],
  buckets: [
    {
      date: '2023-06-05',
      green: 1,
      yellow: 1,
      red: 1,
    },
  ],
};

export const mockRecoveryWidgetManyUsers: RecoveryWidget = {
  group_id: 42408,
  start_date: '2023-06-05',
  end_date: '2023-06-05',
  vow: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
  recovery_average: 89.0,
  breakdown: { green: 2, yellow: 7, red: 7 },
  leaderboard: [
    {
      user_id: 1,
      name: 'test 1',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 89.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 2,
      name: 'test 2',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 50.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 3,
      name: 'test 3',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 30.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 4,
      name: 'test 4',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: null,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 5,
      name: 'test 5',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 89.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 6,
      name: 'test 6',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 50.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 7,
      name: 'test 7',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 30.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 8,
      name: 'test 7',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 50.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 9,
      name: 'test 9',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 30.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 10,
      name: 'test 10',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 50.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 11,
      name: 'test 11',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 30.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 12,
      name: 'rest 12',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 50.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 13,
      name: 'test 13',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 30.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 14,
      name: 'test 14',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 50.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 15,
      name: 'test 15',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 30.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 16,
      name: 'test 16',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 50.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 17,
      name: 'test 17',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 30.0,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
  ],
  buckets: [
    {
      date: '2023-06-05',
      green: 2,
      yellow: 7,
      red: 7,
    },
  ],
};

export const mockSleepPerformanceWidgetManyUsers: SleepPerformanceWidget = {
  group_id: 42408,
  start_date: '2023-06-14',
  end_date: '2023-06-14',
  vow: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
  sleep_performance_average: 88.0,
  breakdown: {
    optimal: 1,
    sufficient: 1,
    poor: 1,
  },
  leaderboard: [
    {
      user_id: 1,
      name: 'test 1',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 88.0,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 2,
      name: 'test 2',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 75.0,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 3,
      name: 'test 3',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 4,
      name: 'test 4',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: null,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 5,
      name: 'test 5',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 6,
      name: 'test 6',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 7,
      name: 'test 7',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 8,
      name: 'test 8',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 9,
      name: 'test 9',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 10,
      name: 'test 10',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 11,
      name: 'test 11',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 12,
      name: 'test 12',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 13,
      name: 'test 13',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 14,
      name: 'test 14',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 15,
      name: 'test 15',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 16,
      name: 'test 16',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 17,
      name: 'test 17',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
  ],
  buckets: [
    {
      date: '2023-06-14',
      optimal: 1,
      sufficient: 14,
      poor: 1,
    },
  ],
};

export const mockSleepPerformanceWidgetResponse: SleepPerformanceWidget = {
  group_id: 42408,
  start_date: '2023-06-14',
  end_date: '2023-06-14',
  vow: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
  sleep_performance_average: 88.0,
  breakdown: {
    optimal: 1,
    sufficient: 1,
    poor: 1,
  },
  leaderboard: [
    {
      user_id: 1,
      name: '534gfc',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 88.0,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 2,
      name: '123vc12',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 75.0,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 3,
      name: '12d2g',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 50,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 4,
      name: 'sad12d',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: null,
      sleep_debt_minutes: 36.03968333333333,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
  ],
  buckets: [
    {
      date: '2023-06-14',
      optimal: 1,
      sufficient: 1,
      poor: 1,
    },
  ],
};

export const mockSleepDebtWidgetResponse: SleepDebtWidget = {
  group_id: 218088,
  start_date: '2023-06-21',
  end_date: '2023-06-21',
  vow: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
  sleep_debt_average_minutes: 66.4153388888889,
  breakdown: {
    high: 1,
    moderate: 1,
    low: 1,
  },
  leaderboard: [
    {
      user_id: 1,
      name: '10GO231LCI',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 93.0,
      sleep_debt_minutes: 93,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 2,
      name: 'aosdno1',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 35,
      sleep_debt_minutes: 35,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 3,
      name: 'asdaiub1',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 4,
      name: 'asd',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 93.0,
      sleep_debt_minutes: null,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
  ],
  buckets: [
    {
      date: '2023-06-21',
      high: 1,
      moderate: 1,
      low: 1,
    },
  ],
};
export const mockSleepDebtWidgetManyUsers: SleepDebtWidget = {
  group_id: 218088,
  start_date: '2023-06-21',
  end_date: '2023-06-21',
  vow: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
  sleep_debt_average_minutes: 66.4153388888889,
  breakdown: {
    high: 1,
    moderate: 1,
    low: 14,
  },
  leaderboard: [
    {
      user_id: 1,
      name: 'test 1',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 93.0,
      sleep_debt_minutes: 93,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 2,
      name: 'test 2',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 35,
      sleep_debt_minutes: 35,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 3,
      name: 'test 3',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 4,
      name: 'test 4',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 93.0,
      sleep_debt_minutes: null,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 5,
      name: 'test 5',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 6,
      name: 'test 6',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 7,
      name: 'test 7',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 8,
      name: 'test 8',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 9,
      name: 'test 9',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 10,
      name: 'test 10',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 11,
      name: 'test 11',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 12,
      name: 'test 12',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 13,
      name: 'test 13',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 14,
      name: 'test 14',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 15,
      name: 'test 15',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 16,
      name: 'test 16',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 17,
      name: 'test 17',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 15,
      sleep_debt_minutes: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
  ],
  buckets: [
    {
      date: '2023-06-21',
      high: 1,
      moderate: 1,
      low: 14,
    },
  ],
};

export const mockDayStrainWidgetResponse: StrainWidget = {
  group_id: 42408,
  start_date: '2023-06-14',
  end_date: '2023-06-14',
  vow: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
  strain_average: 8.366140563286349,
  breakdown: {
    all_out: 1,
    strenuous: 1,
    moderate: 1,
    light: 1,
  },
  leaderboard: [
    {
      user_id: 1,
      name: '1d12d12',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 20,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 2,
      name: 'sd12d12',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 3,
      name: '10GO12d12LCI',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 12,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 4,
      name: 'asdaasd',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 5,
      name: '12312',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: null,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
  ],
  buckets: [
    {
      date: '2023-06-14',
      all_out: 1,
      strenuous: 1,
      moderate: 1,
      light: 1,
    },
  ],
};
export const mockDayStrainWidgetManyUsers: StrainWidget = {
  group_id: 42408,
  start_date: '2023-06-14',
  end_date: '2023-06-14',
  vow: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
  strain_average: 8.366140563286349,
  breakdown: {
    all_out: 1,
    strenuous: 1,
    moderate: 13,
    light: 1,
  },
  leaderboard: [
    {
      user_id: 1,
      name: 'test 1',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 20,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 2,
      name: 'test 2',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 15,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 3,
      name: 'test 3',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 12,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 4,
      name: 'test 4',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 5,
      name: 'test 5',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: null,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 6,
      name: 'test 6',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 7,
      name: 'test 7',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 8,
      name: 'test 8',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 9,
      name: 'test 9',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 10,
      name: 'test 10',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 11,
      name: 'test 11',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 12,
      name: 'test 12',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 13,
      name: 'test 13',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 14,
      name: 'test 14',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 15,
      name: 'test 15',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 16,
      name: 'test 16',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
    {
      user_id: 17,
      name: 'test 17',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 7,
      base_line: 0,
      base_line_sentiment: SENTIMENT.NEUTRAL,
      day_over_day: 0,
      day_over_day_sentiment: SENTIMENT.NEUTRAL,
      opted_in: true,
    },
  ],
  buckets: [
    {
      date: '2023-06-14',
      all_out: 1,
      strenuous: 1,
      moderate: 13,
      light: 1,
    },
  ],
};

export const mockTrainingZoneWidgetResponse: TrainingZoneWidget = {
  group_id: 408289,
  start_date: '2023-08-28',
  end_date: '2023-08-28',
  vow: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
  breakdown: {
    overreaching: 2,
    maintaining: 7,
    tapering: 4,
  },
  leaderboard: [
    {
      user_id: 1,
      name: 'Test 1',
      avatar_url: null,
      opted_in: true,
      training_zone: TrainingZoneKeys.OVERREACHING,
      strain: 13.980544608361532,
      recovery: 62.86666666666667,
    },
    {
      user_id: 2,
      name: 'Test 2',
      avatar_url: null,
      opted_in: true,
      training_zone: TrainingZoneKeys.MAINTAINING,
      strain: 10.222410438687504,
      recovery: 48.63333333333333,
    },
    {
      user_id: 3,
      name: 'Test 3',
      avatar_url: null,
      opted_in: true,
      training_zone: TrainingZoneKeys.TAPERING,
      strain: 5.123481657718278,
      recovery: 61.310344827586206,
    },
  ],
  buckets: [
    {
      date: '2023-08-29',
      overreaching: 1,
      maintaining: 1,
      tapering: 1,
    },
  ],
};
