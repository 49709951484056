import { classes, Button, Icon } from '@whoop/web-components';
import styles from './header.module.scss';

type HeaderProps = {
  title: string;
  onClick: () => void;
  isExpanded: boolean;
};

function Header({ title, onClick, isExpanded }: HeaderProps) {
  return (
    <div className={classes(styles.headerContainer, isExpanded && styles.expandedStyle)}>
      <p className={styles.title}>
        {title}
      </p>
      <Button onClick={onClick} className={styles.actionButton}>
        {isExpanded
          ? <Icon data-testid="tiny_collapse" name="tiny_collapse" className={styles.icon} />
          : <Icon data-testid="tiny_expand" name="tiny_expand" className={styles.icon} />}
      </Button>
    </div>
  );
}

export default Header;
