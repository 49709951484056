import { PieCustomLayerProps, ResponsivePie } from '@nivo/pie';
import { PieData } from 'dataVisualizations/pillarPieChart/pillarPieChartUtils';
import { useMemo } from 'react';
import { PieChartLabel } from './iconPieChartConsts';
import { CenteredIcon, convertDataToPieData } from './iconPieChartUtils';

type IconPieChartProps = {
  data: { [key: string]: number };
  labels: PieChartLabel[];
  iconName: string;
};

const iconSize = 24;
// The higher this is, the smaller the pie slice widths
const innerRadius = 0.82;
// The adds padding between pie slices
const padAngle = 3;

function IconPieChart({
  data, labels, iconName,
}: IconPieChartProps) {
  // Create pie data from the keys of the breakdown objects
  const pieData = useMemo(() => convertDataToPieData(data, labels), [data, labels]);

  const getInnerLayer = ({ centerX, centerY }: PieCustomLayerProps<PieData>) => (
    CenteredIcon(
      iconName,
      centerX,
      centerY,
      iconSize,
    )
  );

  return (
    <ResponsivePie
      data={pieData}
      innerRadius={innerRadius}
      padAngle={padAngle}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      isInteractive={false}
      colors={{ datum: 'data.color' }}
      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', getInnerLayer]}
    />
  );
}

export default IconPieChart;
