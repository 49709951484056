import { CustomLayerProps } from '@nivo/line';
import { Icon, Colors } from '@whoop/web-components';
import { useNavigate } from 'react-router-dom';
import { OverviewGraphAnnotation } from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import { convertActivityNameToIconName } from '@whoop/web-commons';
import { FORMAT_METRIC_VALUE } from 'insights/components/widget/widgetFormatter';
import { WidgetType } from 'insights/components/widget/widget';
import { BarCustomLayerProps, BarDatum } from '@nivo/bar';
import {
  curveBasis, area, Area, line, Line,
} from 'd3-shape';
import { getTrainingAdaptation, OptimalStrainMapping } from 'api/groupLandingApi';
import { ScatterPlotDatum, ScatterPlotLayerProps } from '@nivo/scatterplot/dist/types/types';
import styles from './layers.module.scss';

export function ActivityButtonLayer(
  { series, xScale, yScale }: CustomLayerProps,
): React.ReactNode {
  const navigate = useNavigate();
  const activityList = [];
  const yVal = yScale(225) as number - 70;
  const rectWidth = 84;
  const squareWidth = 40;
  const iconWidth = 8;

  // For all the values in series, if they don't have annotationData,
  // then they are part of the line graph, and we want to isolate just the activity data
  // so we can render the activity rectangles at the top of the graph.
  // For each object of the activity data, we pull out:
  // the annotation data (which includes info like the activity name),
  // the activity color, and important points like:
  // leftBound: the point on the graph in which the activity began
  // rightBound: the point on the graph in which the activity ended
  // midPoint: the point on the graph in the middle of the activity
  const activities = series.map((lineSegment: any) => {
    if (!lineSegment.annotationData) {
      return null;
    }
    const { data, annotationData, color } = lineSegment;
    const numElements = data.length;

    // activity title has '-' instead of spaces in the backend
    annotationData.title = annotationData.title.replaceAll(/[-_]/g, ' ');

    // if activity has no heart rate data, we don't want to display it
    if (numElements === 0) {
      return null;
    }

    // Handle case if the activity only spans 1 minute --> numElements = 1
    if (numElements === 1) {
      return {
        annotationData,
        color,
        leftBound: xScale(data[0].data.x) as number,
        rightBound: xScale(data[0].data.x) as number,
        midpoint: xScale(data[0].data.x) as number,
        key: lineSegment.id,
      };
    }

    return {
      annotationData,
      color,
      leftBound: xScale(data[0].data.x) as number,
      rightBound: xScale(data[numElements - 1].data.x) as number,
      midpoint: xScale(data[Math.round(numElements / 2)].data.x) as number - 36,
      key: lineSegment.id,
    };
  }).filter((activity) => activity != null);

  // When fullDetails is false, this method returns the small version for the activity rectangle
  // When fullDetails is true, this method returns the large version,
  // which includes the duration, and activity name
  const activityRect = (
    annotationData: OverviewGraphAnnotation,
    key: string,
    color: string,
    midpoint: number,
    iconMidpoint: number,
    fullDetails: boolean,
  ) => (
    <g
      onClick={() => { navigate(annotationData.link); }}
      className={styles.clickableAnnotation}
      key={key}
    >
      {fullDetails && (
        <text
          fill={color}
          textAnchor="middle"
          x={midpoint + 42}
          y={yVal - 8}
        >
          {annotationData.title}
        </text>
      )}
      <rect
        x={midpoint}
        y={yVal}
        width={fullDetails ? rectWidth : squareWidth}
        height={squareWidth}
        rx="4"
        fill={color}
      />

      <Icon
        className={styles.activityIcon}
        width="24"
        height="24"
        name={convertActivityNameToIconName(annotationData.activityName)}
        x={iconMidpoint}
        y={yVal + 8}
      />
      {fullDetails && (<text className={styles.durationText} fill={Colors.white} textAnchor="middle" x={midpoint + 58} y={yVal + 26}>{annotationData.duration}</text>)}
    </g>
  );

  for (let i = 0; i < activities.length; i += 1) {
    const {
      annotationData, key, color, leftBound, midpoint,
    } = activities[i];

    // Default to the large version of activity rectangle for first and last activities
    if (i === 0 || i === activities.length - 1) {
      activityList.push(activityRect(annotationData, key, color, midpoint, midpoint + 8, true));
    } else {
      const currXVal = midpoint;
      const nextXVal = activities[i + 1].midpoint;

      // difference between midpoints of current and next activity rectangle
      const difference = nextXVal - currXVal;

      // If the difference is less than the largest possible distance before activities
      // start to overlap we should stick two small rectangles next to each other,
      // centered around the midpoint of both activities
      if (difference < 56) {
        const {
          annotationData: nextAnnotationData,
          key: nextKey, color: nextColor, rightBound: nextRightBound,
        } = activities[i + 1];
        const combinedMidpoint = (nextRightBound - leftBound) / 2 + leftBound;
        const gap = 1;

        activityList.push(activityRect(
          annotationData,
          key,
          color,
          combinedMidpoint - squareWidth - gap, // 1 px gap between squares
          combinedMidpoint - squareWidth + iconWidth - gap,
          false,
        ));
        activityList.push(activityRect(
          nextAnnotationData,
          nextKey,
          nextColor,
          combinedMidpoint,
          combinedMidpoint + iconWidth,
          false,
        ));

        i += 1; // Skip the next one. It will stick with current
      } else if (difference < rectWidth + 3) {
        // Show the small version of the activity rectangle
        const offset = 15;
        activityList.push(
          activityRect(
            annotationData,
            key,
            color,
            currXVal + offset,
            currXVal + iconWidth + offset,
            false,
          ),
        );
      } else {
        // Show the large version of the activity rectangle
        activityList.push(activityRect(
          annotationData,
          key,
          color,
          currXVal,
          currXVal + iconWidth,
          true,
        ));
      }
    }
  }

  return (
    <g>
      {activityList.map((activity) => (
        <g key={activity.key}>
          {activity}
        </g>
      ))}
    </g>
  );
}

const avgMarkerXOffset = -32;
const avgMarkerYOffset = 7;

export const CustomAvgMarker = (
  avgVal: number,
  widgetType: WidgetType,
) => function (layerProps: BarCustomLayerProps<BarDatum>) {
  const { yScale } = layerProps;

  // If avg value is 0 or null, we don't want to plot the avg marker
  if (!avgVal) {
    return null;
  }

  return (
    <g>
      <rect
        x={avgMarkerXOffset}
        y={yScale(avgVal) - avgMarkerYOffset}
        width={28}
        height={16}
        rx="2"
        className={styles.avgMarker}
      />
      <text
        x={-18}
        y={yScale(avgVal)}
        textAnchor="middle"
        dominantBaseline="central"
        className={styles.avgMarkerText}
      >
        {FORMAT_METRIC_VALUE[widgetType](avgVal)}
      </text>
    </g>
  );
};

export function TrainingAdaptationBackgroundLayer(
  { xScale, yScale }: ScatterPlotLayerProps<ScatterPlotDatum>,
) {
  // Filter out every 5th data point to reduce the number of points plotted.
  // This creates a smoother curve.
  const trainingData: OptimalStrainMapping[] = getTrainingAdaptation.filter(
    (d: OptimalStrainMapping, i: number) => i % 5 === 0,
  );

  const xAccessor = (optimalStrainMapping: OptimalStrainMapping) => xScale(
    optimalStrainMapping.recovery,
  );
  const lowerStrainAccessor = (optimalStrainMapping: OptimalStrainMapping) => yScale(
    optimalStrainMapping.lower_optimal_strain,
  );
  const upperStrainAccessor = (optimalStrainMapping: OptimalStrainMapping) => yScale(
    optimalStrainMapping.upper_optimal_strain,
  );

  const xAxisPointsLeft: [number, number][] = [
    [xScale(0), yScale(0)],
    [xScale(32.8), yScale(0)],
  ];
  const xAxisPointsMid: [number, number][] = [
    [xScale(33.3), yScale(0)],
    [xScale(66.7), yScale(0)],
  ];
  const xAxisPointsRight: [number, number][] = [
    [xScale(67.2), yScale(0)],
    [xScale(100), yScale(0)],
  ];
  const xAxisLineGenerator: Line<[number, number]> = line();
  const lowerMappingLineGenerator = line(xAccessor, lowerStrainAccessor).curve(curveBasis);
  const upperMappingLineGenerator = line(xAccessor, upperStrainAccessor).curve(curveBasis);

  // Creating generators for each section. These return a function and when called with data,
  // return a string of points that the <path/> component needs
  const lowerAreaGenerator: Area<OptimalStrainMapping> = area(
    xAccessor,
    yScale(0),
    lowerStrainAccessor,
  ).curve(curveBasis);

  const midAreaGenerator: Area<OptimalStrainMapping> = area(
    xAccessor,
    lowerStrainAccessor,
    upperStrainAccessor,
  )
    .curve(curveBasis);

  const upperGenerator: Area<OptimalStrainMapping> = area(
    xAccessor,
    upperStrainAccessor,
    yScale(21),
  )
    .curve(curveBasis);

  return (
    <g>
      {/* Draw 3 background sections */}
      <path
        d={lowerAreaGenerator(trainingData)}
        className={styles.lowerTrainingAdaptationBackground}
        // Need to inline style this otherwise wasn't building with the correct opacity
        style={{ opacity: '5%' }}
      />
      <path
        d={midAreaGenerator(trainingData)}
        className={styles.midTrainingAdaptationBackground}
        style={{ opacity: '5%' }}
      />
      <path
        d={upperGenerator(trainingData)}
        className={styles.upperTrainingAdaptationBackground}
        style={{ opacity: '5%' }}
      />
      {/* Draw the mapping curve */}
      <path
        d={lowerMappingLineGenerator(trainingData)}
        className={styles.mappingLine}
      />
      <path
        d={upperMappingLineGenerator(trainingData)}
        className={styles.mappingLine}
      />
      {/* 3 Lines for X axis */}
      <path
        d={xAxisLineGenerator(xAxisPointsLeft)}
        className={styles.xAxisLine}
      />
      <path
        d={xAxisLineGenerator(xAxisPointsMid)}
        className={styles.xAxisLine}
      />
      <path
        d={xAxisLineGenerator(xAxisPointsRight)}
        className={styles.xAxisLine}
      />
    </g>
  );
}
