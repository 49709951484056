import { WidgetType } from 'insights/components/widget/widget';
import {
  ResponseComposition, rest, RestContext, RestRequest,
} from 'msw';
import { getDelayVal, mswBaseUri } from 'mocks/utils/utils';
import {
  mockRecoveryWidgetResponse, mockSleepPerformanceWidgetResponse,
  mockDayStrainWidgetResponse, mockSleepDebtWidgetResponse, mockTrainingZoneWidgetResponse,
} from 'mocks/store/insights/mockWidgetData';

const widgetResponseHandler = (req: RestRequest, res: ResponseComposition, ctx: RestContext) => {
  const { widgetType } = req.params;
  let response = {};
  switch (widgetType) {
    case WidgetType.DAY_STRAIN: {
      response = mockDayStrainWidgetResponse;
      break;
    }
    case WidgetType.SLEEP_PERFORMANCE: {
      response = mockSleepPerformanceWidgetResponse;
      break;
    }
    case WidgetType.RECOVERY: {
      response = mockRecoveryWidgetResponse;
      break;
    }
    case WidgetType.SLEEP_DEBT: {
      response = mockSleepDebtWidgetResponse;
      break;
    }
    case WidgetType.TRAINING_ZONE: {
      response = mockTrainingZoneWidgetResponse;
      break;
    }
    default:
      break;
  }

  return res(ctx.delay(getDelayVal(50)), ctx.status(200), ctx.json(response));
};

export const widgetHandlers = [rest.get(mswBaseUri('v1/card/:widgetType'), widgetResponseHandler)];

export default widgetHandlers;
