import { JobTitle, UpdateJobTitle } from 'api/jobTitleApi';
import { baseAccount } from 'mocks/store/data';
import { baseJobTitles } from 'mocks/store/jobTitles';
import { PrivacyLevel } from 'types/dashboardUser';

export const getAllJobTitles = () => baseJobTitles;

export const createJobTitle = (jobTitleId: string) => {
  const foundIndex = baseJobTitles.findIndex((jobTitle) => jobTitle.job_title_id === jobTitleId);
  baseJobTitles[foundIndex].privacy_level = PrivacyLevel.no_metrics;
  return {
    job_title_id: jobTitleId,
    salesforce_account_id: baseAccount.id,
    privacy_level: PrivacyLevel.no_metrics,
  };
};

const getJobTitleIndex = (id: string) => baseJobTitles.findIndex(
  (option: JobTitle) => option.job_title_id === id,
);

export const updateJobTitle = (updatedJobTitle: UpdateJobTitle) => {
  // If job title ids match, just need to update the privacy level
  if (updatedJobTitle.job_title_id === updatedJobTitle.previous_job_title_id) {
    const foundJobTitleIndex = getJobTitleIndex(updatedJobTitle.job_title_id);
    baseJobTitles[foundJobTitleIndex].privacy_level = updatedJobTitle.privacy_level;
  } else {
    // Otherwise, need to update the privacy level of the new id
    // and remove the privacy level from the old id
    const oldJobTitleIndex = getJobTitleIndex(updatedJobTitle.previous_job_title_id);
    const newJobTitleIndex = getJobTitleIndex(updatedJobTitle.job_title_id);
    baseJobTitles[oldJobTitleIndex].privacy_level = null;
    baseJobTitles[newJobTitleIndex].privacy_level = updatedJobTitle.privacy_level;
  }
};

export const deleteJobTitle = (id: string) => {
  const foundIndex = getJobTitleIndex(id);
  baseJobTitles[foundIndex].privacy_level = null;
};
