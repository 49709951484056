import DefaultAvatar from 'assets/hub-default-user-profile.svg';
import { NodeData } from 'dataVisualizations/points/points';
import { classes } from '@whoop/web-components';
import { WidgetType } from 'insights/components/widget/widget';
import styles from './hoverDataComponent.module.scss';
import { DAILY_GRAPH_LABEL_COLOR, DAILY_TRAINING_ZONE_NODE_COLOR, FORMAT_METRIC_VALUE } from '../widget/widgetFormatter';

export type DailyTrainingZoneHoverProps = {
  node: NodeData;
};

function DailyTrainingZoneHover({ node }: DailyTrainingZoneHoverProps) {
  return (
    <div className={styles.hoverData}>
      <div className={styles.nameAndAvatar}>
        <img
          alt="User Profile"
          className={styles.avatar}
          src={node.avatar_url ?? DefaultAvatar}
        />
        <div className={styles.name}>{node.name ?? '--'}</div>
      </div>
      <div className={styles.dailyDataSection}>
        <div>
          <div className={styles.header}>Zone</div>
          <div className={styles.trainingZoneLabel}>
            <div
              className={styles.labelSquare}
              style={{ backgroundColor: DAILY_TRAINING_ZONE_NODE_COLOR[node.trainingZone] }}
            />
            {node.trainingZone.toLowerCase()}
          </div>
        </div>
        <div>
          <div className={styles.header}>Day Strain</div>
          <div className={classes(styles.metricValue, styles.strainText)}>
            {FORMAT_METRIC_VALUE[WidgetType.DAY_STRAIN](node.y)}
          </div>
        </div>
        <div>
          <div className={styles.header}>Recovery</div>
          <div
            className={styles.metricValue}
            style={{ color: DAILY_GRAPH_LABEL_COLOR[WidgetType.RECOVERY](node.x) }}
          >
            {FORMAT_METRIC_VALUE[WidgetType.RECOVERY](node.x)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailyTrainingZoneHover;
