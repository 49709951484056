import { classes, Icon } from '@whoop/web-components';
import { WidgetType } from 'insights/components/widget/widget';
import KeyStatisticWidget from 'insights/components/widget/keyStatisticWidget/keyStatisticWidget';
import {
  useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePrivacy } from 'context/privacy-context';
import { PrivacyLevel } from 'types/dashboardUser';
import { trackEvent } from 'helpers/analyticsTracking';
import { wrapGrid } from 'animate-css-grid';
import useGroupDetails from 'api/hooks/useGroupDetails';
import { useFeatureFlags } from 'components/featureFlag/feature-flag-context';
import styles from './groupDetailsView.module.scss';

function GroupDetailsView() {
  const grid = useRef();
  const { getFeatureFlag } = useFeatureFlags();
  const [expandedCards, setExpandedCards] = useState([]);
  const [showHoverState, setShowHoverState] = useState(false);
  const [showTrainingZoneWidget, setShowTrainingZoneWidget] = useState(false);
  const { accountId, groupId } = useParams<{
    groupId: string;
    accountId: string;
  }>();
  const navigate = useNavigate();
  const { isInPrivacyLevel } = usePrivacy();
  const { data: group, isError } = useGroupDetails(Number(groupId));
  const widgetsToDisplay = useMemo(() => {
    const widgets = [WidgetType.RECOVERY,
      WidgetType.SLEEP_PERFORMANCE, WidgetType.DAY_STRAIN];

    if (isInPrivacyLevel(PrivacyLevel.primary_metrics)) { // Primary metrics or higher
      widgets.push(WidgetType.SLEEP_DEBT);
    }
    if (showTrainingZoneWidget) {
      widgets.splice(0, 0, WidgetType.TRAINING_ZONE);
    }
    return widgets;
  }, [isInPrivacyLevel(PrivacyLevel.primary_metrics), showTrainingZoneWidget]);

  useEffect(() => {
    const getShowHoverStates = async () => {
      const response = await getFeatureFlag('unite-tld-hover-states');
      setShowHoverState(response);
    };

    const getShowTrainingZoneWidget = async () => {
      const response = await getFeatureFlag('unite-training-zone-widget');
      setShowTrainingZoneWidget(response);
    };

    getShowHoverStates();
    getShowTrainingZoneWidget();
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);

    wrapGrid(grid.current, {
      duration: 250,
      stagger: 100,
    });
  }, []);

  useEffect(() => {
    if (!isInPrivacyLevel(PrivacyLevel.aggregate_metrics)) { // No metrics
      navigate(`/accounts/${accountId}/insights/groups`);
    }
  }, []);

  const isExpanded = (type: WidgetType) => expandedCards.includes(type);

  const toggleExpanded = (type: WidgetType) => {
    if (expandedCards.includes(type)) {
      trackEvent('Insights Page - Widget Expand/Collapse', { widgetType: type, direction: 'collapse' });
      setExpandedCards(expandedCards.filter((item) => item !== type));
    } else {
      trackEvent('Insights Page - Widget Expand/Collapse', { widgetType: type, direction: 'expand' });
      setExpandedCards((oldArray) => [...oldArray, type]);
    }
  };

  // WU-1572. Cards were not animating smoothly if the user hovered over the graph during animation.
  // The solution is to disable hovering until the animation is complete (700ms).
  useEffect(() => {
    setShowHoverState(false);
    setTimeout(() => {
      setShowHoverState(true);
    }, 700);
  }, [expandedCards]);

  return (
    <>
      {isError && (
      <div className={styles.hideGroupWrapper}>
        <Icon name="hide_privacy" className={styles.icon} />
        <div className={styles.hideGroupText}>
          You do not have access to this group.
          Contact your admin to add you to this group.
        </div>
      </div>
      )}
      <div
        ref={grid}
        className={styles.gridLayout}
      >
        {group && Object.values(widgetsToDisplay).map((widgetType) => (
          <div
            style={{
              order: isExpanded(widgetType)
                ? expandedCards.indexOf(widgetType)
                : widgetsToDisplay.length + 1,
            }}
            className={classes(styles.widgetGridItem, isExpanded(widgetType) && styles.expanded)}
            key={widgetType}
          >
            <KeyStatisticWidget
              widgetType={widgetType}
              isExpanded={isExpanded(widgetType)}
              toggleExpanded={toggleExpanded}
              showHoverState={showHoverState}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default GroupDetailsView;
