import { classes, Colors } from '@whoop/web-components';
import { getColorFromRecovery } from 'dataVisualizations/utils/utils';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import styles from './leaderboardTableCells.module.scss';
import { WidgetType } from '../widget/widget';

dayjs.extend(duration);

export const getRoundedStringFromNumber = (
  number: number,
) => Math.round(number).toLocaleString();

const getColorFromWidgetType = (type: WidgetType) => {
  switch (type) {
    case WidgetType.DAY_STRAIN:
      return Colors.strainBlue;
    case WidgetType.SLEEP_PERFORMANCE:
    case WidgetType.SLEEP_DEBT:
      return Colors.sleepBlue;
    case WidgetType.RECOVERY:
      return Colors.recoveryBlue;
    default:
      throw new Error('Invalid widget type');
  }
};

export const roundedNumberCell = (
  value: number,
  widgetType: WidgetType,
  showPercentage: boolean = false,
) => {
  const color = value ? getColorFromWidgetType(widgetType) : Colors.whiteAlpha500;

  return (
    <span className={classes(styles.cellText, styles.numberCell, 'whoop-typography')} style={{ color }}>
      {value ? `${getRoundedStringFromNumber(value)}` : '--'}
      {value && showPercentage && <span className={classes(styles.percentSign, 'n6')}>%</span>}
    </span>
  );
};

export const strainCell = (value: number) => (
  <span className={classes(styles.cellText, value ? styles.strainCell : styles.emptyCell)}>
    {value ? value.toFixed(1) : '--'}
  </span>
);

export const recoveryCell = (value: number) => {
  const recoveryColor = value ? getColorFromRecovery(value) : Colors.whiteAlpha500;
  return (
    <span className={classes(styles.cellText, 'whoop-typography')} style={{ color: recoveryColor }}>
      {value ? `${Math.round(value)}` : '--'}
      {value && <span className="n6">%</span>}
    </span>
  );
};

export const minutesToHoursCell = (value: number) => (
  <span className={classes(
    styles.cellText,
    value !== null ? styles.minutesToHoursCell : styles.emptyCell,
  )}
  >
    {value !== null ? dayjs.duration(value, 'minutes').format('H:mm') : '--'}
  </span>
);
