import { useQuery } from '@tanstack/react-query';
import { getActivityById } from 'api/analyticsApi';
import { useParams } from 'react-router-dom';
import { Pillars } from 'types/analytics';
import { UrlParams } from 'types/profile';

export default function useActivityById(pillar: Pillars, activityId: string) {
  const { userId } = useParams<UrlParams>();

  return useQuery({
    queryKey: [parseInt(userId, 10), activityId],
    queryFn: () => getActivityById(pillar, parseInt(userId, 10), activityId),
  });
}
