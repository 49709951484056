import { AccountConfigurationSettingType, AccountDetailsTab } from 'accountDetails/types';
import { adminClient } from 'api/';
import DemoDisabled from 'components/demoDisabled';
import FeatureFlag from 'components/featureFlag';
import { useAccounts } from 'context/account-context';
import { usePrivacy } from 'context/privacy-context';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountConfiguration, reidentificationTokenKey } from 'types/admin';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import PrivacyLevelTable from 'accountDetails/privacyLevelTable/privacyLevelTable';
import CustomSwitch from 'accountDetails/settingsV2/customSwitch';
import { classes, Icon } from '@whoop/web-components';
import JobTitleSettings from 'components/jobTitleSetting/jobTitleSettings';
import {
  createAccountJobTitle, deleteAccountJobTitle,
  JobTitle, updateAccountJobTitle,
} from 'api/jobTitleApi';
import { useJobTitles } from 'api/hooks/useJobTitles';
import styles from './settingsV2.module.scss';
import SettingCard from './settingCard';

type AccountDetailsParams = {
  accountId: string;
  tabTitle: AccountDetailsTab;
};

function SettingsV2() {
  const {
    checkAccess, setAccountConfig, accountConfig, isReidTokenValid, loading,
  } = useAccounts();
  const { updatePrivacyLevel } = usePrivacy();
  const navigate = useNavigate();
  const { accountId } = useParams<AccountDetailsParams>();
  const [isReidentified, setIsReidentified] = useState<boolean>(false);
  const initialJobTitles = useJobTitles();
  const [jobTitles, setJobTitles] = useState(initialJobTitles);

  useEffect(() => {
    setJobTitles(initialJobTitles);
  }, [initialJobTitles]);

  useEffect(() => {
    const init = async () => {
      if (accountConfig.deidentified) {
        const tokenState = await isReidTokenValid();
        setIsReidentified(tokenState);
      }
    };

    if (!checkAccess(DashboardRole.WHOOP_CSM)) {
      navigate(`/accounts/${accountId}/plan/summary`);
    }

    init();
  }, [accountId]);

  // Job Titles with privacy level set are 'used'
  const usedJobTitles = useMemo(() => jobTitles.filter(
    (title) => title.privacy_level,
  ), [jobTitles]);
  // Job Titles with no privacy level are 'unused' - available for new or updating existing
  const unusedJobTitles = useMemo(() => jobTitles.filter(
    (title) => !title.privacy_level,
  ), [jobTitles]);

  const updateSettings = async (body: AccountConfiguration): Promise<void> => {
    const updatedConfig: AccountConfiguration = await adminClient.updateAccountConfiguration(body);
    setAccountConfig(updatedConfig);
    updatePrivacyLevel();
  };

  const handleToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const toggleToChange = e.target.name;
    const newValue = e.target.value === 'false';
    const body: AccountConfiguration = {
      ...accountConfig,
      [toggleToChange]: newValue,
    };
    updateSettings(body);
  };

  const handlePrivacyLevelChange = (value: PrivacyLevel) => {
    updateSettings({
      ...accountConfig,
      privacy_ceiling_level: value,
    });
  };

  const handleReidentificationToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === 'false';
    if (newValue) {
      const reidentificationTokenObject = await adminClient.getReidentificationToken();
      const { token } = reidentificationTokenObject;
      localStorage.setItem(reidentificationTokenKey, token);
    } else {
      localStorage.removeItem(reidentificationTokenKey);
    }
    setIsReidentified(newValue);
  };

  const handleResetAccount = () => {
    navigate('reset-account');
  };

  const updateJobTitleName = async (oldJobTitle: JobTitle, updatedName: string) => {
    const newJobTitle = jobTitles.find((title) => title.job_title_name === updatedName);
    await updateAccountJobTitle(
      newJobTitle.job_title_id,
      oldJobTitle.job_title_id,
      oldJobTitle.privacy_level,
      accountId,
    );

    // Get index of old and new elements
    const oldJobTitleIndex = jobTitles.findIndex(
      (title) => oldJobTitle.job_title_id === title.job_title_id,
    );
    const newJobTitleIndex = jobTitles.findIndex(
      (title) => newJobTitle.job_title_id === title.job_title_id,
    );
    // Swap privacy level and position of elements - maintain dropdown order
    jobTitles[oldJobTitleIndex] = { ...newJobTitle, privacy_level: oldJobTitle.privacy_level };
    jobTitles[newJobTitleIndex] = { ...oldJobTitle, privacy_level: null };

    setJobTitles([...jobTitles]);
  };

  const updateJobTitlePrivacy = async (
    updatedJobTitle: JobTitle,
    updatedPrivacyLevel: PrivacyLevel,
  ) => {
    await updateAccountJobTitle(
      updatedJobTitle.job_title_id,
      updatedJobTitle.job_title_id,
      updatedPrivacyLevel,
      accountId,
    );

    const changedJobTitleIndex = jobTitles.findIndex(
      (title) => updatedJobTitle.job_title_id === title.job_title_id,
    );
    jobTitles[changedJobTitleIndex].privacy_level = updatedPrivacyLevel;

    setJobTitles([...jobTitles]);
  };

  const deleteJobTitle = async (deletedJobTitle: JobTitle) => {
    await deleteAccountJobTitle(deletedJobTitle.job_title_id, accountId);
    const deletedJobTitleIndex = jobTitles.findIndex(
      (title) => title.job_title_id === deletedJobTitle.job_title_id,
    );
    jobTitles[deletedJobTitleIndex] = { ...deletedJobTitle, privacy_level: null };
    setJobTitles([...jobTitles]);
  };

  const addJobTitle = async () => {
    const firstUnusedJobTitleIndex = jobTitles.findIndex(
      (title) => !title.privacy_level,
    );
    const newJobTitle = await createAccountJobTitle(
      jobTitles[firstUnusedJobTitleIndex].job_title_id,
      accountId,
    );
    jobTitles[firstUnusedJobTitleIndex] = {
      ...jobTitles[firstUnusedJobTitleIndex], privacy_level: newJobTitle.privacy_level,
    };
    setJobTitles([...jobTitles]);
  };

  return (!loading
    && (
      <div className={styles.content}>
        <p className={styles.sectionTitle}>
          Member Privacy
        </p>
        <SettingCard
          title="Set Up account-wide data access"
          description="This is the maximum amount of data collaborators on this account can access"
          name="account-wide-data-access"
        >
          <PrivacyLevelTable
            privacyLevel={accountConfig.privacy_ceiling_level}
            changePrivacyLevel={handlePrivacyLevelChange}
          />
        </SettingCard>
        <SettingCard
          isEnabled={accountConfig.opt_out_enabled}
          onChangeHandler={handleToggle}
          title="Allow members to share data as group averages"
          description="By toggling this on, members on this account will have the option in the Join Flow to share their individual data with their organization, or share their data as a team average only. Also, when the toggle is off, every new user added to this account will be opted-in to individual data sharing."
          name="opt_out_enabled"
          type={AccountConfigurationSettingType.Toggle}
        />
        <FeatureFlag flagName="enterprise-fe-deid-toggle">
          <SettingCard
            isEnabled={accountConfig.deidentified}
            onChangeHandler={handleToggle}
            title="Account Deidentification"
            description="Account wide deidentification for member PII."
            name="deidentified"
            type={AccountConfigurationSettingType.Toggle}
          />
          {accountConfig.deidentified
            && (
              <div className={styles.toggleContainer}>
                <p>Temporarily re-identify account members?</p>
                <div className={styles.buttonWrap}>
                  <CustomSwitch
                    onChange={handleReidentificationToggle}
                    value={isReidentified}
                    checked={isReidentified}
                    name="reidentification"
                    customheight={30}
                    customwidth={60}
                  />
                </div>
              </div>
            )}
        </FeatureFlag>
        <p className={classes(styles.sectionTitle, styles.sectionSpacing)}>
          Collaborator Setting
        </p>
        <SettingCard
          title="Job-based data access permission"
          name="job_titles"
        >
          {usedJobTitles.length === 0 && (
            <div className={classes(styles.addJobTitleContainer)}>
              Add a job title
              <Icon data-testid="addFirstJobTitle" name="add_circle" className={styles.icon} onClick={addJobTitle} />
            </div>
          )}
          <div className={styles.jobTitlesContainer}>
            {usedJobTitles.map((jobTitle: JobTitle, index: number) => (
              <JobTitleSettings
                key={jobTitle.job_title_id}
                jobTitle={jobTitle}
                updateJobTitleName={updateJobTitleName}
                updateJobTitlePrivacy={updateJobTitlePrivacy}
                // Can't delete only job title
                deleteEnabled={usedJobTitles.length > 1}
                // Only show add button on last row and if there are any job titles left to assign
                addEnabled={index === usedJobTitles.length - 1 && usedJobTitles.length > 0}
                onDelete={deleteJobTitle}
                addJobTitle={addJobTitle}
                jobTitleOptions={unusedJobTitles.map(
                  (option: JobTitle) => option.job_title_name,
                )}
              />
            ))}
          </div>
        </SettingCard>

        <p className={classes(styles.sectionTitle, styles.sectionSpacing)}>
          Preference
        </p>
        <SettingCard
          isEnabled={accountConfig.strap_onboarding_enabled}
          onChangeHandler={handleToggle}
          title="Strap Based Onboarding Enabled"
          description="Members who onboard through strap based onboarding get added to the team associated with the strap ID"
          name="strap_onboarding_enabled"
          type={AccountConfigurationSettingType.Toggle}
        />
        <SettingCard
          isEnabled={accountConfig.whoop_coach_enabled}
          onChangeHandler={handleToggle}
          title="WHOOP Coach Enabled"
          description="Turning this option on allows all members on this account to see the WHOOP Coach in their mobile app. Turning this off hides the coach, and all associated settings."
          name="whoop_coach_enabled"
          type={AccountConfigurationSettingType.Toggle}
        />
        <FeatureFlag flagName="enterprise-fe-concealed-mode-toggle">
          <SettingCard
            isEnabled={accountConfig.concealed_feature_enabled}
            onChangeHandler={handleToggle}
            title="Conceal the Member&apos;s App"
            description={`${accountConfig.concealed_feature_enabled ? 'ON' : 'OFF'} 
            – Member can use the WHOOP app
            without intervention.`}
            name="concealed_feature_enabled"
            type={AccountConfigurationSettingType.Toggle}
          />
          {accountConfig.concealed_feature_enabled
            && (
              <div className={styles.toggleContainer}>
                <p>Auto-conceal all members</p>
                <div className={styles.buttonWrap}>
                  <CustomSwitch
                    onChange={handleToggle}
                    value={accountConfig.concealed}
                    checked={accountConfig.concealed}
                    name="concealed"
                    customheight={30}
                    customwidth={60}
                  />
                </div>
              </div>
            )}
        </FeatureFlag>
        <SettingCard
          isEnabled={accountConfig.hub_enabled}
          onChangeHandler={handleToggle}
          title="Bluetooth Hub Enabled"
          description="A Bluetooth Hub allows members without mobile devices to upload data from their straps."
          name="hub_enabled"
          type={AccountConfigurationSettingType.Toggle}
        />
        {
          accountConfig.hub_enabled && (
            <SettingCard
              isEnabled={accountConfig.bluetooth_roaming}
              onChangeHandler={handleToggle}
              title="Bluetooth Roaming Enabled"
              description="Bluetooth roaming allows all straps to connect to any hub in the account"
              name="bluetooth_roaming"
              type={AccountConfigurationSettingType.Toggle}
            />
          )
        }
        <DemoDisabled>
          <>
            <p className={classes(styles.sectionTitle, styles.sectionSpacing)}>
              Reset Account
            </p>
            <SettingCard
              onChangeHandler={handleResetAccount}
              title="Reset Account"
              description="Delete all members and groups."
              name="reset_account_button"
              type={AccountConfigurationSettingType.Button}
            />
          </>
        </DemoDisabled>
      </div>
    )
  );
}

export default SettingsV2;
