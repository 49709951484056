import { Icon, Logo } from '@whoop/web-components';
import styles from './glassTile.module.scss';

type GlassTileProps = {
  title: string;
  img?: string;
  logo?: boolean;
  text: string;
  buttonText: string;
  buttonAction: () => void;
};

function GlassTile({
  title,
  text,
  img,
  logo,
  buttonText,
  buttonAction,
}: GlassTileProps) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {img && <img alt={title} src={img} />}
        {logo && <Logo variant="circle" />}
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
      <button type="button" className={styles.actionBtn} onClick={buttonAction}>
        <p className={styles.btnText}>{buttonText}</p>
        <Icon name="arrow_right" className={styles.btnIcon} />
      </button>
    </div>
  );
}

export default GlassTile;
