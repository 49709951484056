import * as Sentry from '@sentry/browser';
import { authClient } from 'api';
import { client } from 'api/apiClient';
import Loading from 'loading';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { reidentificationTokenKey } from 'types/admin';

/* eslint-disable react/jsx-props-no-spreading */

const tokenStorageKey = 'token';

const clearTokenStorage = () => {
  localStorage.clear(tokenStorageKey);
  localStorage.removeItem(reidentificationTokenKey);
  sessionStorage.clear(tokenStorageKey);
};

const storeToken = (token, persistLocal) => {
  clearTokenStorage();

  if (persistLocal) {
    localStorage.setItem(tokenStorageKey, token);
  } else {
    sessionStorage.setItem(tokenStorageKey, token);
  }
};

const getTokenFromStorage = () => localStorage.getItem(tokenStorageKey)
  ?? sessionStorage.getItem(tokenStorageKey);

export const AuthContext = React.createContext({
  user: null,
  login: null,
  logout: null,
});

export function AuthProvider(props) {
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    Sentry.setUser(user);

    return () => Sentry.setUser(null);
  }, [user]);

  const fetchProfile = async () => {
    try {
      const profile = await authClient.getProfile();
      setUser(profile);
      analytics.identify(profile.id, {
        email: profile.email,
      }); // identify the user for other track events
    } catch (error) {
      setUser(null);
    }
  };

  useEffect(() => {
    const init = async () => {
      if (getTokenFromStorage()) {
        await fetchProfile();
      }
      setInitialized(true);
    };

    const requestInterceptor = client.interceptors.request.use((conf) => {
      const token = getTokenFromStorage();
      const config = { ...conf };
      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }
      return config;
    }, Promise.reject);

    init();

    return () => client.interceptors.request.eject(requestInterceptor);
  }, []);

  const login = async (email, password, staySignedIn, inviteToken) => {
    let loginResponse;
    if (inviteToken) {
      loginResponse = await authClient.validateInviteAndlogin(
        email,
        password,
        inviteToken,
      );
    } else {
      loginResponse = await authClient.login(email, password);
    }
    const { token } = loginResponse;
    storeToken(token, staySignedIn);
    await fetchProfile();
    analytics.track('login');
  };

  const logout = () => {
    clearTokenStorage();
    setUser(null);
    analytics.reset();
    navigate('/login');
  };

  const contextValue = useMemo(() => ({
    user,
    login,
    logout,
  }), [user, login, logout]);

  return initialized ? (
    <AuthContext.Provider
      value={contextValue}
      {...props}
    />
  ) : (
    <Loading />
  );
}

export const useAuth = () => React.useContext(AuthContext);
