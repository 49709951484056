import { Pillars } from 'types/analytics';
import { getDelayVal, mswBaseUri } from 'mocks/utils/utils';
import { rest } from 'msw';
import { createActivityDetailData, createActivityData } from 'mocks/actions/activitiesActions';
import dayjs from 'dayjs';

export const activitiesHandlers = [
  rest.get(mswBaseUri('v2/activities/sleep'), (req, res, ctx) => {
    const start = req.url.searchParams.get('startDate');
    const end = req.url.searchParams.get('endDate');
    const range = {
      from: dayjs(start).toDate(),
      to: dayjs(end ?? start).toDate(),
    };
    return res(
      ctx.delay(getDelayVal(50)),
      ctx.status(200),
      ctx.json(createActivityData(range, Pillars.SLEEP)),
    );
  }),
  rest.get(mswBaseUri('v2/activities/strain'), (req, res, ctx) => {
    const start = req.url.searchParams.get('startDate');
    const end = req.url.searchParams.get('endDate');
    const range = {
      from: dayjs(start).toDate(),
      to: dayjs(end ?? start).toDate(),
    };
    return res(
      ctx.delay(getDelayVal(50)),
      ctx.status(200),
      ctx.json(createActivityData(range, Pillars.STRAIN)),
    );
  }),
  rest.get(mswBaseUri('v2/activities/:pillar/:activityId'), (req, res, ctx) => {
    const { pillar } = req.params;

    return res(
      ctx.delay(getDelayVal(50)),
      ctx.status(200),
      ctx.json(createActivityDetailData(pillar as Pillars)),
    );
  }),
];

export default activitiesHandlers;
