import { P1 } from 'components/text';
import { getIdentifier } from 'tableUtils/utils';
import { ProgressCohort, ProgressMemberProfile } from 'types/analytics';
import DefaultAvatar from 'assets/hub-default-user-profile.svg';
import { useState } from 'react';
import { Modal } from '@whoop/web-components';
import ProfileInformation from 'components/profileInformation/profileInformation';
import styles from './profileButton.module.scss';

type ProfileButtonProps = {
  user: ProgressMemberProfile;
  groupList: ProgressCohort[];
  getMemberOverview: () => void;
};

function ProfileButton({ user, groupList, getMemberOverview }: ProfileButtonProps) {
  const [showProfilePopup, setShowProfilePopup] = useState(false);

  return (
    <>
      <button className={styles.buttonContainer} type="button" onClick={() => setShowProfilePopup(true)}>
        {user.avatar_url ? <img alt="Avatar" src={user.avatar_url} className={styles.largerAvatarSize} /> : <img src={DefaultAvatar} alt="my_account" className={styles.largerAvatarSize} />}
        <P1>
          {getIdentifier(user)}
        </P1>
      </button>
      <Modal data-testid="profileButtonModal" show={showProfilePopup} onClose={() => setShowProfilePopup(false)}>
        <ProfileInformation
          user={user}
          groupList={groupList}
          getMemberOverview={getMemberOverview}
        />
      </Modal>
    </>
  );
}

export default ProfileButton;
