import { client } from 'api/apiClient';
import {
  HROverview,
} from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import {
  ActivityTypes,
  DeviceHealthRecord,
  MemberTrends,
  MetricOverview,
  MetricType,
  ActivityDetailV2,
  Pillars,
  ProgressMemberOverview,
} from 'types/analytics';

export const getMemberOverviewV2 = async (memberId: number) => {
  const response = await client.get<ProgressMemberOverview>('enterprise-service/v2/member-overview', {
    params: {
      memberId,
    },
  });
  return response.data;
};

export const getDeviceHealthData = async (cohortId: number | string) => {
  const response = await client.get<DeviceHealthRecord[]>('enterprise-service/v1/compliance/cohort', {
    params: {
      cohortId,
    },
  });
  return response.data;
};

export const getDayOverview = async (params: { memberId: number; date: string }) => {
  const response = await client.get<HROverview>('enterprise-service/v2/day-overview', {
    params,
  });

  return response.data;
};

// This is a date so the startDate and endDate should be formatted YYYY-MM-DD
export const getRecoveryAndStrainTrend = async (
  memberId: number,
  startDate: string,
  endDate: string,
) => {
  const response = await client.get<MemberTrends>('enterprise-service/v2/member/trends', {
    params: {
      memberId,
      startDate,
      endDate,
    },
  });

  return response.data;
};

export type UserRangeQueryParams = {
  memberId: number;
  startDate: string;
  endDate?: string;
};

export const getMetricOverview = async (
  params: UserRangeQueryParams,
  pillar: Pillars,
  metric: MetricType,
) => {
  const response = await client.get<MetricOverview>(`enterprise-service/v2/${pillar}-overview/${metric}`, {
    params,
  });

  return response.data;
};

export type ActivityOverview = {
  id: string;
  cycle_start_date: string;
  activity_duration: number;
  type: ActivityTypes | string;
  score_value: number;
  start_time: string;
  end_time: string;
  zone_id: string;
};

export const getActivities = async (
  pillar: Pillars,
  params: UserRangeQueryParams,
) => {
  const response = await client.get<ActivityOverview[]>(`enterprise-service/v2/activities/${pillar}`, {
    params,
  });

  return response.data;
};

export const getActivityById = async (
  pillar: Pillars,
  memberId: number,
  activityId: string,
) => {
  const response = await client.get<ActivityDetailV2>(`enterprise-service/v2/activities/${pillar}/${activityId}`, {
    params: {
      memberId,
    },
  });

  return response.data;
};
