import { Tooltip, makeStyles } from '@material-ui/core';
import { Button, IconButton, Modal } from '@whoop/web-components';
import { getCsvExport } from 'api/csvExportApi';
import { useFeatureFlags } from 'components/featureFlag/feature-flag-context';
import { usePrivacy } from 'context/privacy-context';
import { useDateRange } from 'insights/hooks/useDateRange';
import { formatDate } from 'progress/profile/profileUtils';
import { useEffect, useState } from 'react';
import { Cohort } from 'types/cohort';
import { PrivacyLevel } from 'types/dashboardUser';
import styles from './csvExport.module.scss';

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: 'rgba(26, 34, 39, 0.9)',
    width: '132px',
    height: '18px',
    padding: '6px',
    textAlign: 'center',
    fontFamily: 'ProximaNova',
    fontSize: '12.64px',
    lineHeight: '16px',
    fontWeight: 500,
  },
  customArrow: {
    color: 'rgba(26, 34, 39, 0.9)',
  },
  customPlacement: {
    margin: '8px 0',
  },
}));

export type CsvExportProps = {
  group: Cohort;
};

function CsvExport({ group }: CsvExportProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const muiStyles = useStyles();
  const { dateRange } = useDateRange();
  const { getFeatureFlag } = useFeatureFlags();
  const [flagValue, setFlagValue] = useState(false);
  const { isInPrivacyLevel } = usePrivacy();

  const handleExportClick = () => {
    getCsvExport(group, formatDate(dateRange.from), formatDate(dateRange.to ?? dateRange.from));
    setModalOpen(false);
  };

  useEffect(() => {
    const fetchFlag = async () => {
      setFlagValue(await getFeatureFlag('wu-csv-export'));
    };
    fetchFlag();
  }, []);

  if (!flagValue || !group?.id || !isInPrivacyLevel(PrivacyLevel.primary_metrics)) {
    return null;
  }

  return (
    <>
      <Tooltip
        title="Export CSV Data"
        placement="bottom-end"
        arrow
        aria-label="open export modal"
        classes={{
          tooltip: muiStyles.customTooltip,
          arrow: muiStyles.customArrow,
          tooltipPlacementBottom: muiStyles.customPlacement,
        }}
      >
        <IconButton name="download" className={styles.actionButton} onClick={() => setModalOpen(true)} />
      </Tooltip>
      <Modal
        data-testid="csvExportModal"
        show={modalOpen}
        onClose={() => setModalOpen(false)}
        className={styles.csvExportModal}
        disableClose
      >
        <div className={styles.container}>
          <div className={styles.header}>
            <span>Export CSV Data</span>
            <IconButton name="cross" className={styles.closeButton} onClick={() => setModalOpen(false)} />
          </div>
          By downloading this file, I agree to&nbsp;
          <a
            className={styles.link}
            data-testid="privacy-policy / terms-and-conditions"
            href="https://www.whoopunite.com/legal/terms-of-use"
            target="_blank"
            rel="noreferrer"
          >
            WHOOP Data Privacy Terms and Conditions
          </a>
          .
          <div className={styles.footer}>
            <Button
              theme="enterprise"
              variant="primary"
              onClick={handleExportClick}
              className={styles.exportButton}
            >
              Export
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CsvExport;
