import { ActivityOverview } from 'api/analyticsApi';
import hoursToMilliseconds from 'date-fns/hoursToMilliseconds';
import dayjs from 'dayjs';
import { formatNumberToPercent, roundToOneDecimalString } from 'progress/utils/numberFormatter';
import { Pillars } from 'types/analytics';
import { SectionData } from './activitySection';

export type ActivitySectionData = {
  name: string;
  compareVal: number | Date;
  data: SectionData[];
};

export enum PerformanceGroups {
  OPTIMAL = 'Optimal (85%+)',
  SUFFICIENT = 'Sufficient (71% - 85%)',
  POOR = 'Poor (<71%)',
}

// format functions start with objects as
// groups[PerformanceGroups.OPTIMAL].push(formattedActivity);
// more readable than doing
// groups[0].data.push(formattedActivity)
// but we need to remap those into the array input ActivitySection expects
export const formatGroups = (
  groups: { [key: string]: SectionData[] },
) => Object.keys(groups).map((group) => ({
  name: group,
  data: groups[group],
})).filter((group) => group.data.length);

export const formatSleepActivity = (activity: ActivityOverview) => {
  const date = dayjs(activity.cycle_start_date, 'YYYY-MM-DD');
  return {
    rowTitle: date.format('ddd, MMM D'),
    rowDetail: formatNumberToPercent(activity.score_value),
    fromDate: date.toDate(),
    activityId: activity.id,
  };
};

export const formatStrainActivity = (activity: ActivityOverview, showDate: boolean) => {
  const date = dayjs(activity.cycle_start_date, 'YYYY-MM-DD');

  // This gets -07 from the end of the start_time string
  const offsetString = `${activity.start_time.slice(activity.start_time.length - 6, activity.start_time.length - 3)}`;
  // This convert 07 into a number that can be used to format (7 in this case)
  const hoursOffset = Number(offsetString.slice(1, 3));

  const dateTimeToDisplay = new Intl.DateTimeFormat(undefined, {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    hour12: false,
    // offsetString[0] is the +- sign here
    timeZone: activity.zone_id ?? `Etc/GMT${offsetString[0]}${hoursOffset}`,
  }).format(new Date(activity.start_time));

  return {
    rowTitle: showDate ? dateTimeToDisplay : activity.type,
    rowDetail: roundToOneDecimalString(activity.score_value),
    fromDate: date.toDate(),
    activityId: activity.id,
  };
};

export const formatPerformanceActivities = (activityData: ActivityOverview[]) => {
  const groups: {
    [key in PerformanceGroups]: SectionData[];
  } = {
    [PerformanceGroups.OPTIMAL]: [],
    [PerformanceGroups.SUFFICIENT]: [],
    [PerformanceGroups.POOR]: [],
  };
  activityData.forEach((activity) => {
    const formattedActivity = formatSleepActivity(activity);
    if (activity.score_value > 85) {
      groups[PerformanceGroups.OPTIMAL].push(formattedActivity);
    } else if (activity.score_value >= 71) {
      groups[PerformanceGroups.SUFFICIENT].push(formattedActivity);
    } else {
      groups[PerformanceGroups.POOR].push(formattedActivity);
    }
  });
  return formatGroups(groups);
};

export enum TimeInBedGroups {
  EIGHT_PLUS = '8+ hours',
  SEVEN_TO_EIGHT = '7-8 hours',
  SIX_TO_SEVEN = '6-7 hours',
  FIVE_TO_SIX = '5-6 hours',
  LESS_THAN_FIVE = '<5 hours',
}

export const formatTimeInBedActivities = (activityData: ActivityOverview[]) => {
  const groups: {
    [key in TimeInBedGroups]: SectionData[];
  } = {
    [TimeInBedGroups.EIGHT_PLUS]: [],
    [TimeInBedGroups.SEVEN_TO_EIGHT]: [],
    [TimeInBedGroups.SIX_TO_SEVEN]: [],
    [TimeInBedGroups.FIVE_TO_SIX]: [],
    [TimeInBedGroups.LESS_THAN_FIVE]: [],
  };
  activityData.forEach((activity) => {
    const formattedActivity = formatSleepActivity(activity);
    if (activity.activity_duration > hoursToMilliseconds(8)) {
      groups[TimeInBedGroups.EIGHT_PLUS].push(formattedActivity);
    } else if (activity.activity_duration > hoursToMilliseconds(7)) {
      groups[TimeInBedGroups.SEVEN_TO_EIGHT].push(formattedActivity);
    } else if (activity.activity_duration > hoursToMilliseconds(6)) {
      groups[TimeInBedGroups.SIX_TO_SEVEN].push(formattedActivity);
    } else if (activity.activity_duration > hoursToMilliseconds(5)) {
      groups[TimeInBedGroups.FIVE_TO_SIX].push(formattedActivity);
    } else {
      groups[TimeInBedGroups.LESS_THAN_FIVE].push(formattedActivity);
    }
  });
  return formatGroups(groups);
};

export enum StrainGroups {
  ALL_OUT = 'All-Out (18.1+)',
  STRENUOUS = 'Strenuous (14.1 - 18.0)',
  MODERATE = 'Moderate (10.1 - 14.0)',
  LIGHT = 'Light (< 10.0)',
}

export const formatStrainActivities = (activityData: ActivityOverview[]) => {
  const groups: {
    [key in StrainGroups]: SectionData[];
  } = {
    [StrainGroups.ALL_OUT]: [],
    [StrainGroups.STRENUOUS]: [],
    [StrainGroups.MODERATE]: [],
    [StrainGroups.LIGHT]: [],
  };
  activityData.forEach((activity) => {
    const formattedActivity = formatStrainActivity(activity, false);
    if (activity.score_value > 18.0) {
      groups[StrainGroups.ALL_OUT].push(formattedActivity);
    } else if (activity.score_value > 14.0) {
      groups[StrainGroups.STRENUOUS].push(formattedActivity);
    } else if (activity.score_value > 10.0) {
      groups[StrainGroups.MODERATE].push(formattedActivity);
    } else {
      groups[StrainGroups.LIGHT].push(formattedActivity);
    }
  });
  return formatGroups(groups);
};

const baseFormatActivities = (
  activityData: ActivityOverview[],
  property: keyof ActivityOverview,
  day: boolean,
) => {
  const uniqueVals = new Set<string>();
  activityData.forEach((activity) => {
    uniqueVals.add(activity[property] as string);
  });
  const groups: { [key in string] : SectionData[] } = {};
  [...uniqueVals].forEach((val) => {
    const dateList: SectionData[] = [];
    activityData.forEach((activity) => {
      if (val === activity[property]) {
        const formattedActivity = formatStrainActivity(activity, !day);
        dateList.push(formattedActivity);
      }
    });
    groups[day ? dayjs(val, 'YYYY-MM-DD').format('ddd, MMM D') : val] = dateList;
  });

  return formatGroups(groups);
};

export const formatSportActivities = (activityData: ActivityOverview[]) => baseFormatActivities(activityData, 'type', false);

export const formatDayActivities = (activityData: ActivityOverview[]) => baseFormatActivities(activityData, 'cycle_start_date', true);

export enum StrainSections {
  STRAIN = 'strain',
  SPORT = 'sport',
  DAY = 'day',
}

export enum SleepSections {
  PERFORMANCE = 'performance',
  TIME_IN_BED = 'time in bed',
}

export type Sections = StrainSections | SleepSections;

export const DEFAULT_PILLAR_SECTIONS = {
  [Pillars.STRAIN]: StrainSections.STRAIN,
  [Pillars.SLEEP]: SleepSections.PERFORMANCE,
};

export const PILLAR_SECTIONS = {
  [Pillars.STRAIN]: StrainSections,
  [Pillars.SLEEP]: SleepSections,
};

export const PILLAR_ACTIVITY_TITLES = {
  [Pillars.SLEEP]: 'Sleep',
  [Pillars.STRAIN]: 'All Activities',
};

export const TAB_ACTIVITY_FORMATTER = {
  [SleepSections.PERFORMANCE]: formatPerformanceActivities,
  [SleepSections.TIME_IN_BED]: formatTimeInBedActivities,
  [StrainSections.STRAIN]: formatStrainActivities,
  [StrainSections.SPORT]: formatSportActivities,
  [StrainSections.DAY]: formatDayActivities,
};
