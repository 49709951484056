import Card from 'progress/components/Card/card';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import { Pillars } from 'types/analytics';
import { ActivityOverview } from 'api/analyticsApi';
import HeartRateLineGraph from 'dataVisualizations/heartRateLineGraph/heartRateLineGraph';
import useActivityById from 'api/hooks/useActivityById';
import { HRMetric } from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import styles from './activityHRCard.module.scss';

type ActivityHRCardProps = {
  pillar: Pillars;
  activitySelected: ActivityOverview;
};

function ActivityHRCard({ pillar, activitySelected }: ActivityHRCardProps) {
  // Due to issue with Nivo rendering, we need to set the data to null before we load a new activity
  // So, if the activitySelected is null, we return empty data
  const { data: activity, isLoading } = activitySelected
    ? useActivityById(pillar, activitySelected.id)
    : { data: { hr_metrics: [] as HRMetric[] }, isLoading: true };

  return (
    <Card
      id="Activity HR"
      showBackground={false}
      loading={isLoading}
      className={styles.activityHrCard}
    >
      <CardHeader
        title="Activity HR"
      />
      {!isLoading && activity.hr_metrics.length !== 0 && (
        <div data-testid="hr-line-graph" className={styles.activityHRGraph}>
          <HeartRateLineGraph metrics={activity.hr_metrics} />
        </div>
      )}
    </Card>
  );
}

export default ActivityHRCard;
