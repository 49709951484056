import { ErrorBoundary } from '@sentry/react';
import '@whoop/web-components/dist/index.css';
import AcceptPage from 'acceptPage/acceptPage';
import AccessRestricted from 'accessRestricted';
import AccountRoutes from 'accountRoutes';
import AdminAccountsPage from 'admin/accountsPage';
import AuthRequired from 'components/authRequired';
import Routes from 'components/routes';
import { useAuth } from 'context/auth-context';
import ForgotPassword from 'forgotPassword';
import GroupsRedirect from 'groups/groupsRedirect/groupsRedirect';
import { trackPath } from 'helpers/analyticsTracking';
import Loading from 'loading';
import Login from 'login/login';
import ErrorPage from 'messagePage/errorPage/errorPage';
import MaintenancePage from 'messagePage/maintenancePage/maintenancePage';
import NoAccessPage from 'messagePage/noAccessPage/noAccessPage';
import { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import Register from 'register/register';
import RequestAccess from 'requestAccess/requestAccess';
import ResetPassword from 'resetPassword/resetPassword';

function App() {
  const { user } = useAuth();
  const location = useLocation();
  const [oldPathname, setOldPathname] = useState('');

  useEffect(() => {
    // Prevents duplicate tracking events on the same path
    if (oldPathname !== location.pathname) {
      trackPath(location.pathname);
      setOldPathname(location.pathname);
    }
  }, [location]);

  // if maintenance flag is on, all routes should show the maintenance page
  if (window._env.SHOW_MAINTENANCE_PAGE === 'true') {
    return (
      <Routes>
        <Route
          path="*"
          element={<MaintenancePage dateTime="2023-04-12T12:00:00-04:00" />}
        />
      </Routes>
    );
  }

  return (
    <ErrorBoundary fallback={<ErrorPage is404={false} />}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route element={<AuthRequired />}>
            <Route index element={<Navigate to="accounts" replace />} />
          </Route>
          <Route path="accounts" element={<AuthRequired />}>
            {/* Admins select an account which navigates them to /accounts/:accountId */}
            {/* load-accounts tells the account-context to fetch the accounts */}
            {user?.admin && (
              <Route index element={<AdminAccountsPage />} />)}
            {!user?.admin && (
              <Route index element={<Navigate to="load-accounts" replace />} />
            )}
            <Route path=":accountId/*" element={<AccountRoutes />} />
          </Route>
          <Route path="groups/:groupId" element={<AuthRequired />}>
            <Route index element={<GroupsRedirect />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="register/:token" element={<Register />} />
          <Route path="accept/:token" element={<AcceptPage />} />
          <Route path="access-restricted" element={<AccessRestricted />} />
          <Route path="forgot" element={<ForgotPassword />} />
          <Route path="forgot/reset/*" element={<ResetPassword />} />
          <Route path="request-access" element={<RequestAccess />} />
          <Route path="forbidden" element={<NoAccessPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
