import { useState, useEffect } from 'react';
import {
  PointTooltipProps, ResponsiveLine, Serie,
} from '@nivo/line';
import { Colors } from '@whoop/web-components';
import { CustomHoverPoint } from 'dataVisualizations/points/points';
import { HeartRateToolTip } from 'dataVisualizations/tooltips/tooltips';
import { formatData, getApproxMinFromVals, getTimeStepFromMin } from 'dataVisualizations/utils/utils';
import { getXAxisTickMark } from 'dataVisualizations/axis/utils';
import { commonProps, getTimeAxisProps, rawHeartRateAxisProps } from './heartRateLineGraphConsts';
import { HRMetric } from './hrLineGraphTypes';

type HeartRateLineGraphProps = {
  metrics: HRMetric[];
};

function HeartRateLineGraph({ metrics }: HeartRateLineGraphProps) {
  // get approximate number of minutes based on difference between min and max vals
  const approxMin = getApproxMinFromVals(
    metrics[0].timestamp,
    metrics[metrics.length - 1].timestamp,
  );
  // Use approx number of minutes to get timestep
  // This function sets a timestep
  // (how often we should display an axis tick) based on how many minutes of data we have
  const timeStep = getTimeStepFromMin(approxMin);
  const [data, setData] = useState<Serie>(null);
  const [xTickTime, setXTickTime] = useState<number[]>([]);

  useEffect(() => {
    const graphData = formatData(metrics);
    setData(graphData);
    const timeTickMark = getXAxisTickMark(
      graphData,
      timeStep,
    );
    setXTickTime(timeTickMark);
  }, [metrics]);

  const graphCommonProps = {
    data: [data],
    colors: Colors.strainBlue,
    tooltip: (tooltip: PointTooltipProps) => HeartRateToolTip(
      tooltip,
      data.data.length,
    ),
  };

  const timeAxisProps = getTimeAxisProps(xTickTime);

  return (
    <ResponsiveLine
      {...commonProps}
      {...graphCommonProps}
      {...rawHeartRateAxisProps}
      {...timeAxisProps}
      layers={[
        'areas',
        'grid',
        'axes',
        'crosshair',
        'lines',
        CustomHoverPoint,
        'slices',
        'mesh',
        'legends',
      ]}
      margin={
        {
          top: 10, right: 10, bottom: 50, left: 36,
        }
      }
    />
  );
}

export default HeartRateLineGraph;
