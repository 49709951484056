import { Icon } from '@whoop/web-components';
import { inviteDashboardUser } from 'api/adminApi';
import { inviteSteps } from 'wizards/invite/constants';
import { isEmail } from 'wizards/invite/util';
import {
  AddCollaboratorsReducerAction,
  AddCollaboratorsReducerState,
  WhoopButtonProps,
  Collaborator,
} from 'types/collaborator';

export const replaceRoleInErrorMessage = (error: string, role: string) => error.replace(
  /\b(?:enterprise_cohort_manager|enterprise_workspace_manager|enterprise_admin|community_admin)\b/gi,
  role,
);

export const addCollaboratorsReducer = (
  state: AddCollaboratorsReducerState,
  action: AddCollaboratorsReducerAction,
): AddCollaboratorsReducerState => {
  let previousStep: string = '';
  let nextStep: string = '';
  let { emails } = state;
  let { invitedCollaborators } = state;

  switch (action.type) {
    case 'sentData':
      return {
        ...state,
        sentData: false,
      };
    case 'updateInvitedCollaborator':
      return {
        ...state,
        invitedCollaborators: action.invites,
      };
    case 'updatedEmails':
      return {
        ...state,
        emails: action.emails,
      };
    case 'setJobTitleOptions':
      return {
        ...state,
        jobTitleOptions: action.options,
      };
    case 'error':
      return {
        ...state,
        error: true,
      };
    case 'back':
      if (state.inviteStep === inviteSteps.loading) {
        previousStep = inviteSteps.details;
      } else if (state.inviteStep === inviteSteps.send) {
        previousStep = inviteSteps.loading;
      } else if (state.inviteStep === inviteSteps.input) {
        previousStep = inviteSteps.send;
      } else {
        previousStep = inviteSteps.input;
      }
      return {
        ...state,
        inviteStep: previousStep,
      };

    case 'next':
      if (state.inviteStep === inviteSteps.loading) {
        nextStep = inviteSteps.send;
      } else if (state.inviteStep === inviteSteps.send) {
        emails = [];
        invitedCollaborators = [];
        nextStep = inviteSteps.input;
      } else if (state.inviteStep === inviteSteps.input) {
        nextStep = inviteSteps.details;
      } else if (state.inviteStep === inviteSteps.details) {
        nextStep = inviteSteps.loading;
      } else {
        return state;
      }
      return {
        ...state,
        error: false,
        sentData: nextStep === inviteSteps.loading,
        inviteStep: nextStep,
        invitedCollaborators,
        emails,
      };
    default:
      return state;
  }
};

export type HandleSendInvitesPayload = {
  fulfilled: number;
  failed: number;
  errors: string[];
};

export const handleSendInvites = async (
  invitedCollaborators: Collaborator[],
  salesforceAccountId: string,
): Promise<HandleSendInvitesPayload> => {
  const promises: Promise<void>[] = invitedCollaborators.map(
    (invited: Collaborator) => inviteDashboardUser(
      invited,
      salesforceAccountId,
    ),
  );
  const results = await Promise.allSettled(promises);
  const numFulfilled = results.filter(
    (res) => res.status === 'fulfilled',
  ).length;
  const failed = promises.length - numFulfilled;
  return {
    fulfilled: numFulfilled,
    failed,
    errors: results
      .filter((res: PromiseRejectedResult) => res.status === 'rejected')
      .map((res: PromiseRejectedResult, index: number) => replaceRoleInErrorMessage(
        res.reason.response.data,
        invitedCollaborators[index].role,
      )),
  };
};

export const getSecondaryButtonProps = (
  state: AddCollaboratorsReducerState,
): WhoopButtonProps => ({
  label: state.inviteStep === inviteSteps.send ? <>Back to Account</> : (
    <>
      <Icon name="arrow_left" />
      {' '}
      <p>Previous</p>
    </>
  ),
  variant: 'link',
});

export const getPrimaryButtonProps = (
  state: AddCollaboratorsReducerState,
): WhoopButtonProps => {
  const { emails } = state;
  let label = (
    <>
      <p>Next</p>
      <Icon name="arrow_right" />
    </>
  );

  if (state.inviteStep === inviteSteps.details) {
    label = <>Invite Collaborators</>;
  }
  if (state.inviteStep === inviteSteps.send) {
    label = <>Invite More Members</>;
  }

  // The primary button should be disabled if:
  let disabled = false;
  if (state.inviteStep === inviteSteps.input) {
    // invite step is input and there are invalid emails
    disabled = emails.some((email: string) => !isEmail(email));
  } else if (state.inviteStep === inviteSteps.details) {
    // invite step is details, job titles are available,
    // and a collaborator is not assigned a job title
    const { invitedCollaborators, jobTitleOptions } = state;
    const collabsWithoutJobTitles = invitedCollaborators
      .filter((collab) => !collab.job_title_id).length;
    disabled = jobTitleOptions.length > 0 && collabsWithoutJobTitles > 0;
  }

  return {
    theme: state.inviteStep === inviteSteps.input ? 'default' : 'enterprise',
    variant: state.inviteStep === inviteSteps.input ? 'link' : 'primary',
    label,
    disabled,
  };
};

export const createToastMessage = (
  fulfilled: number,
  failed: number,
): string => {
  let msg = '';
  if (fulfilled) {
    msg += `${fulfilled} user${fulfilled === 1 ? ' has' : 's have'
    } been invited to your enterprise account.`;
  }
  if (failed) {
    msg += `Failed to invite ${failed} member${failed > 1 ? 's' : ''}`;
  }
  return msg;
};
