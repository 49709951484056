import { Pillars } from 'types/analytics';
import PillarPieCard from 'progress/components/pillarPieCard/pillarPieCard';
import HROverviewCard from 'progress/components/hrOverviewCard/hrOverviewCard';
import { Row, Col } from '@whoop/web-components';
import styles from './views.module.scss';
import layoutStyles from '../layout.module.scss';

function DailyView() {
  return (
    <>
      <Row center="xs" className={layoutStyles.topCardsContainer}>
        <Col xs={4}>
          <PillarPieCard pillar={Pillars.STRAIN} />
        </Col>
        <Col xs={4}>
          <PillarPieCard pillar={Pillars.RECOVERY} />
        </Col>
        <Col xs={4}>
          <PillarPieCard pillar={Pillars.SLEEP} />
        </Col>
      </Row>
      <Row center="xs" className={styles.overviewHRContainer}>
        <Col xs={12}>
          <HROverviewCard />
        </Col>
      </Row>
    </>
  );
}

export default DailyView;
