import { ResponsiveScatterPlot, ScatterPlotNodeProps } from '@nivo/scatterplot';
import { TrainingZoneKeys, TrainingZoneLeaderboard } from 'api/widgetApi';
import { TrainingAdaptationBackgroundLayer } from 'dataVisualizations/layers/layers';
import { Colors } from '@whoop/web-components';
import { AxisTickProps } from '@nivo/axes';
import { TrainingAdaptationXAxisTick, TrainingAdaptationYAxisTick } from 'dataVisualizations/axis/axis';
import { PropsWithChildren, useMemo } from 'react';
import { NodeData, TrainingAdaptationNode } from 'dataVisualizations/points/points';
import DefaultAvatar from 'assets/hub-default-user-profile.svg';
import { DAILY_GRAPHS_Y_AXIS_VALUES } from 'insights/components/widget/widgetFormatter';
import { WidgetType } from 'insights/components/widget/widget';
import { usePrivacy } from 'context/privacy-context';
import { PrivacyLevel } from 'types/dashboardUser';
import styles from './trainingGraph.module.scss';

export function formatDataForDailyTrainingGraph(data: TrainingZoneLeaderboard[]) {
  const overreachingData: NodeData[] = [];
  const maintainingData: NodeData[] = [];
  const taperingData: NodeData[] = [];
  data.forEach((row: any, index: number) => {
    const dataForRow = {
      x: row.recovery,
      y: row.strain,
      avatar_url: row.avatar_url ?? DefaultAvatar,
      name: row.name,
      trainingZone: row.training_zone,
      user_id: row.user_id ?? index,
    };
    if (row.training_zone === TrainingZoneKeys.TAPERING) {
      taperingData.push(dataForRow);
    } else if (row.training_zone === TrainingZoneKeys.MAINTAINING) {
      maintainingData.push(dataForRow);
    } else if (row.training_zone === TrainingZoneKeys.OVERREACHING) {
      overreachingData.push(dataForRow);
    }
  });

  return {
    overreachingData,
    maintainingData,
    taperingData,
    numPoints: overreachingData.length + maintainingData.length + taperingData.length,
  };
}

export type TrainingGraphProps = {
  data: TrainingZoneLeaderboard[];
  highlightedNode: NodeData;
  setHighlightedNode: (nodeData: NodeData) => void;
};

function TrainingGraph({ data, highlightedNode, setHighlightedNode }: TrainingGraphProps) {
  const formattedData = useMemo(() => formatDataForDailyTrainingGraph(data), [data]);
  const { isInPrivacyLevel } = usePrivacy();
  const allowHoverState = isInPrivacyLevel(PrivacyLevel.primary_metrics);

  const dataForGraph = [{
    id: TrainingZoneKeys.OVERREACHING,
    data: formattedData.overreachingData,
  },
  {
    id: TrainingZoneKeys.MAINTAINING,
    data: formattedData.maintainingData,
  },
  {
    id: TrainingZoneKeys.TAPERING,
    data: formattedData.taperingData,
  }];

  const simplifyGraph = formattedData.numPoints > 50;

  return (
    <div className={styles.wrapper}>
      <ResponsiveScatterPlot
        data={dataForGraph}
        nodeComponent={(
          node: PropsWithChildren<ScatterPlotNodeProps<NodeData>>,
        ) => TrainingAdaptationNode(
          node.node.x,
          node.node.y,
          node.node.serieId as TrainingZoneKeys,
          node.node.data,
          simplifyGraph,
          highlightedNode,
          allowHoverState ? setHighlightedNode : () => null,
        )}
        // Have a custom interaction state so don't need this
        isInteractive={false}
        margin={{
          top: 20, right: 15, bottom: 40, left: 40,
        }}
        axisBottom={{
          tickValues: [0, 17, 33, 50, 67, 83, 100],
          tickSize: 0,
          renderTick: (tick: AxisTickProps<string>) => TrainingAdaptationXAxisTick(
            tick.x,
            tick.y,
            tick.value,
          ),
        }}
        axisLeft={{
          tickSize: 0,
          tickValues: DAILY_GRAPHS_Y_AXIS_VALUES[WidgetType.TRAINING_ZONE](),
          renderTick: (tick: AxisTickProps<string>) => TrainingAdaptationYAxisTick(
            tick.x,
            tick.y,
            tick.value,
          ),
        }}
        xScale={{ type: 'linear', min: 0, max: 100 }}
        yScale={{ type: 'linear', min: 0, max: 21 }}
        theme={{
          grid: {
            line: {
              stroke: Colors.blackAlpha100,
            },
          },
        }}
        layers={[TrainingAdaptationBackgroundLayer, 'grid', 'axes', 'nodes', 'markers', 'mesh', 'annotations']}
      />
    </div>
  );
}

export default TrainingGraph;
