import { useQuery } from '@tanstack/react-query';
import { getGroupById } from '../cohortApi';

export default function useGroupDetails(groupId: number) {
  return useQuery({
    queryKey: ['group-details', groupId],
    queryFn: () => getGroupById(groupId),
    retry: 0,
    enabled: !!groupId,
  });
}
