import { Colors } from '@whoop/web-components';
import { SENTIMENT } from 'api/groupLandingApi';

export const getTrendColor = (trend: SENTIMENT) => {
  switch (trend) {
    case SENTIMENT.POSITIVE:
      return {
        background: Colors.greenTrendAlpha150,
        color: Colors.teal,
      };
    case SENTIMENT.NEGATIVE:
      return {
        background: Colors.yellowTrendAlpha150,
        color: Colors.trendYellow,
      };
    default:
      return {
        background: Colors.whiteAlpha100,
        color: Colors.whiteAlpha700,
      };
  }
};

const TREND_ICON_MAP = {
  [SENTIMENT.POSITIVE]: 'tiny_triangle_up',
  [SENTIMENT.NEGATIVE]: 'tiny_triangle_down',
  [SENTIMENT.NEUTRAL]: 'tiny_ellipse',
};

export const getIcon = (diff: number) => {
  if (diff > 0) {
    return TREND_ICON_MAP[SENTIMENT.POSITIVE];
  }
  if (diff < 0) {
    return TREND_ICON_MAP[SENTIMENT.NEGATIVE];
  }
  if (diff === 0) {
    return TREND_ICON_MAP[SENTIMENT.NEUTRAL];
  }

  return null;
};
