import { Cohort } from 'types/cohort';
import { client } from './apiClient';

export async function getCsvExport(group: Cohort, startDate: string, endDate: string) {
  const params = {
    cohortId: group.id,
    startDate,
    endDate,
  };
  await client.get('/enterprise-service/v1/card/export', { params, responseType: 'blob' }).then((response) => {
    const href = window.URL.createObjectURL(response.data);

    const anchorElement = document.createElement('a');

    anchorElement.href = href;
    anchorElement.download = `${group.name} ${params.startDate}-${params.endDate}.csv`;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  });
}
