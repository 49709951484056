import Card from 'progress/components/Card/card';
import { getDateRangeString, getMoreInfo } from 'progress/profile/profileUtils';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import PieChart from 'dataVisualizations/pieChart/pieChart';
import {
  Pillars, MetricType, PRIMARY_METRIC_MAP, METRIC_DISPLAY_NAMES,
} from 'types/analytics';
import useMetric from 'api/hooks/useMetric';
import { getColorForPillar, STRAIN_MAX } from 'progress/progressPageUtils';
import { useDateRange } from 'insights/hooks/useDateRange';
import { roundToOneDecimalString } from 'progress/utils/numberFormatter';
import styles from './pillarPieCard.module.scss';

type PillarPieCardProps = {
  pillar: Pillars;
};

function PillarPieCard({ pillar }: PillarPieCardProps) {
  const { dateRange, comparisonDateRange } = useDateRange();
  const { formattedDate: detailDate } = getDateRangeString(dateRange, comparisonDateRange);
  const pillarMetricType: MetricType = PRIMARY_METRIC_MAP[pillar];
  const { data: { average_value } = { average_value: 0 }, isFetching } = useMetric(
    pillarMetricType,
    dateRange,
  );
  const pillarColor = getColorForPillar(pillar, average_value);
  const formattedValue = pillar === Pillars.STRAIN
    ? (average_value / STRAIN_MAX) * 100
    : average_value;

  let displayVal;

  if (average_value === null) {
    displayVal = null;
  } else if (pillar === Pillars.STRAIN) {
    displayVal = roundToOneDecimalString(average_value);
  } else {
    displayVal = Math.round(average_value);
  }

  return (
    <Card
      loading={isFetching}
      id={pillarMetricType}
    >
      <CardHeader
        title={METRIC_DISPLAY_NAMES[pillarMetricType]}
        details={detailDate}
        moreInfoText={getMoreInfo(pillar)}
      />
      <div className={styles.pillarPieChart}>
        <PieChart
          pieValue={formattedValue}
          displayVal={`${displayVal ?? '--'}${[Pillars.SLEEP, Pillars.RECOVERY].includes(pillar) ? '%' : ''}`}
          color={pillarColor}
        />
      </div>
    </Card>
  );
}

export default PillarPieCard;
