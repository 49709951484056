import { Hooks, Row } from 'react-table';

/*
  This is a react-table plugin that separates the pinned row from the rest.
  We remove the pinned row from the "rows" prop
  and append it to the table instance via a prop called "pinnedRow".

  This isolates the pinned row from any effects caused by other
  react-table plugins down the chain.

  The order of plugins is significant!
  (i.e. placing this first will prevent all plugins from knowing about the summary row)

  To use, add an `isPinnedRow` property to your row data, and set the pinned row to true
*/
export const usePinnedRow = <T extends { isPinnedRow: boolean }>(
  hooks: Hooks<T>,
) => {
  hooks.useInstance.push((instance) => {
    Object.assign(instance, {
      pinnedRow: instance.rows.find((r) => r.original.isPinnedRow),
      rows: instance.rows.filter((r) => !r.original.isPinnedRow),
    });
  });
};

export type UsePinnedRowInstanceProps<T extends { }> = {
  pinnedRow?: Row<T>;
};
