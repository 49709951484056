import { accountHandlers } from './accountHandlers';
import { activitiesHandlers } from './activitiesHandlers';
import { analyticsHandlers } from './analyticsHandlers';
import { breakdownHandlers } from './breakdownHandlers';
import { groupsHandlers } from './groupHandlers';
import { hubsHandlers } from './hubHandlers';
import { memberOverviewHandlers } from './memberOverviewHandlers';
import { seatsHandlers } from './seatHandlers';
import strapsHandlers from './strapHandlers';
import { jobTitleHandlers } from './jobTitleHandlers';
import { insightsGroupsHandlers } from './insightsGroupsHandlers';
import { widgetHandlers } from './widgetHandler';

export default [
  ...accountHandlers,
  ...seatsHandlers,
  ...groupsHandlers,
  ...hubsHandlers,
  ...analyticsHandlers,
  ...strapsHandlers,
  ...breakdownHandlers,
  ...memberOverviewHandlers,
  ...activitiesHandlers,
  ...jobTitleHandlers,
  ...insightsGroupsHandlers,
  ...widgetHandlers,
];
