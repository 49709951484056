import { useState, useEffect, useMemo } from 'react';
import {
  Datum, Layer, PointTooltipProps, ResponsiveLine,
} from '@nivo/line';
import { Colors } from '@whoop/web-components';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { formatData, getDuration } from 'dataVisualizations/utils/utils';
import { HeartRateToolTip } from 'dataVisualizations/tooltips/tooltips';
import { ActivityButtonLayer } from 'dataVisualizations/layers/layers';
import { CustomHoverPoint } from 'dataVisualizations/points/points';
import { getXAxisTickFromMin } from 'dataVisualizations/axis/utils';
import { ActivityTypes } from 'types/analytics';
import { commonProps, getTimeAxisProps, rawHeartRateAxisProps } from '../heartRateLineGraph/heartRateLineGraphConsts';
import {
  SeriesDetails,
  SeriesType,
  OverviewGraphAnnotation,
  Series,
  HROverview,
  DayHR,
} from '../heartRateLineGraph/hrLineGraphTypes';

dayjs.extend(duration);

type HeartRateLineGraphProps = {
  hrData: HROverview;
  getAnnotationLink: (activityId: string, type: SeriesType) => string;
};

function OverviewHRGraph({ hrData, getAnnotationLink }: HeartRateLineGraphProps) {
  const timeStep = 4; // This will make ticks every 4 hours
  const [series, setSeries] = useState<Series[]>([]);
  const [xTickTime, setXTickTime] = useState<number[]>([]);
  const [maxXVal, setMaxXVal] = useState<number>();
  const navigate = useNavigate();

  const getSeriesDetails = (rawData: HROverview, id: string): SeriesDetails => {
    if (id) {
      if (rawData.activities[id]) {
        return {
          type: SeriesType.ACTIVITY,
          details: rawData.activities[id],
          color: Colors.strainBlue,
        };
      }
      if (rawData.sleeps[id]) {
        return {
          type: SeriesType.SLEEP,
          details: rawData.sleeps[id],
          color: Colors.sleepBlue,
        };
      }
    }
    return { type: SeriesType.OVERVIEW, color: Colors.gray500 };
  };

  const getAnnotationData = (seriesDetails: SeriesDetails): OverviewGraphAnnotation => {
    switch (seriesDetails.type) {
      case SeriesType.ACTIVITY:
        return {
          title: seriesDetails.details.type,
          link: getAnnotationLink(seriesDetails.details.id, SeriesType.ACTIVITY),
          // Future TODO : figure out if need to create an activity to icon map
          activityName: seriesDetails.details.type,
          duration: getDuration(seriesDetails.details.during),
        };
      case SeriesType.SLEEP:
        return {
          title: seriesDetails.details.nap ? 'Nap' : 'Sleep',
          link: getAnnotationLink(seriesDetails.details.id, SeriesType.SLEEP),
          activityName: seriesDetails.details.nap ? ActivityTypes.NAP : ActivityTypes.SLEEP,
          duration: getDuration(seriesDetails.details.during),
        };
      default:
        return null;
    }
  };

  // Takes the chunks of data that come back in DayHR type and creates a series of data for each
  // Each chunk (object) represents a sleep/nap, or activity, or a set of untypes overview data
  // Sleep/activity data gets annotationdata added to it
  const parseHrData = (rawData: HROverview): Series[] => {
    const graphs: Series[] = [];
    rawData.day_hr.forEach((dataChunk: DayHR, index: number) => {
      const seriesDetails: SeriesDetails = getSeriesDetails(rawData, dataChunk.activity_id);
      const parsedSeries: Series = {
        id: `${seriesDetails.type}_${index}`,
        data: formatData(dataChunk.metrics).data,
        color: seriesDetails.color,
        annotationData: getAnnotationData(seriesDetails),
        navigate: seriesDetails.type === SeriesType.OVERVIEW ? null : navigate,
      };
      graphs.push(parsedSeries);
    });
    return graphs;
  };

  useEffect(() => {
    const graphs: Series[] = parseHrData(hrData);
    setSeries(graphs);
    const allHrData = graphs.map((graph) => graph.data);
    setMaxXVal(allHrData.flat().length);
    const timeTickMark = getXAxisTickFromMin({ data: allHrData.flat() }, timeStep);
    setXTickTime(timeTickMark);
  }, [hrData]);

  const graphCommonProps = {
    data: series,
    tooltip: (tooltip: PointTooltipProps) => HeartRateToolTip(
      tooltip,
      maxXVal,
    ),
    colors: ((d: Datum) => d.color),
  };
  const layers: Layer[] = [ActivityButtonLayer, 'areas', 'grid', 'axes', 'crosshair', 'lines', CustomHoverPoint, 'slices', 'mesh', 'legends'];

  const timeAxisProps = useMemo(() => getTimeAxisProps(xTickTime), [xTickTime]);

  return (
    <ResponsiveLine
      {...commonProps}
      {...graphCommonProps}
      {...rawHeartRateAxisProps}
      {...timeAxisProps}
      enablePoints={false}
      layers={layers}
      margin={
        {
          top: 100, right: 50, bottom: 50, left: 50,
        }
      }
    />
  );
}

export default OverviewHRGraph;
