import { JobTitle } from 'api/jobTitleApi';
import { PrivacyLevel } from 'types/dashboardUser';
import { baseAccount } from './data';

export const baseJobTitles: JobTitle[] = [
  {
    privacy_level: PrivacyLevel.no_metrics,
    job_title_name: 'Administration',
    job_title_id: '852af61f-9c98-4968-8102-d5ede8da522b',
    salesforce_account_id: baseAccount.id,
  },
  {
    privacy_level: PrivacyLevel.all_metrics,
    job_title_name: 'Medical',
    job_title_id: '17b39d2e-ccc0-4d3e-9440-714a8ee87ca2',
    salesforce_account_id: baseAccount.id,
  },
  {
    privacy_level: null,
    job_title_name: 'Position Coach',
    job_title_id: '9665c68d-041c-49f1-bbec-b792cdd34817',
    salesforce_account_id: baseAccount.id,
  },
  {
    privacy_level: null,
    job_title_name: 'Sports Performance',
    job_title_id: '06d32f2f-427b-4ad4-82cd-4f76ba3407c0',
    salesforce_account_id: baseAccount.id,
  },
];
