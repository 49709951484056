import { classes, Icon } from '@whoop/web-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useState } from 'react';
import { trackEvent } from 'helpers/analyticsTracking';
import styles from './notificationBanner.module.scss';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

export const getMaintenanceMessage = (maintenanceDate: string) => {
  // get timezone from user's browser
  const browserTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // convert the maintenance date to the user's locale date
  const userMaintenanceDate = dayjs(maintenanceDate).tz(browserTZ);
  const userMaintenanceDateString = userMaintenanceDate.format('MMMM D, YYYY');

  // maintenance window is supposed to be 3 hours
  const startTime = userMaintenanceDate.format('h A');
  const endTime = userMaintenanceDate.add(3, 'hours').format('h A (z)');
  const isDayBefore = dayjs().isAfter(userMaintenanceDate.startOf('day').subtract(1, 'day'));

  return `Due to scheduled maintenance, service will be interrupted from ${startTime} to ${endTime} ${isDayBefore ? 'tomorrow. ' : `on ${userMaintenanceDateString}. `}`;
};

function NotificationBanner() {
  const [showBanner, setShowBanner] = useState(sessionStorage.getItem('show_banner') !== 'false');

  const handleStatusClick = () => {
    trackEvent('Maintenance Banner - Status Page Click');
  };

  const dismissBanner = () => {
    trackEvent('Maintenance Banner - Dismiss Click');
    setShowBanner(false);
    sessionStorage.setItem('show_banner', 'false');
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div
      role="dialog"
      className={classes(styles.bannerContainer, 'whoop-typography')}
    >
      <p className={classes('p5', 'font-semibold')}>
        {getMaintenanceMessage('2023-04-12T12:00:00-04:00')}
        <a className={classes('p5', 'font-bold', 'link')} href="https://status.whoop.com/" onClick={handleStatusClick}>
          Learn More
        </a>
      </p>
      <Icon data-testid="close-banner" name="cross" className={styles.closeIcon} onClick={dismissBanner} />
    </div>
  );
}

export default NotificationBanner;
