import { useNavigate } from 'react-router-dom';
import { classes } from '@whoop/web-components';
import { trackEvent } from 'helpers/analyticsTracking';
import styles from './breadcrumbs.module.scss';

export type Breadcrumb = {
  name: string;
  link: string;
};

type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
};

function Breadcrumbs({ breadcrumbs } : BreadcrumbsProps) {
  const navigate = useNavigate();

  const handleOnClick = (link: string) => {
    trackEvent('Insights Page - My Groups Breadcrumb Click');
    navigate(link);
  };

  return (
    <div className={styles.breadcrumbs}>
      {breadcrumbs.map((breadcrumb, index) => {
        const isLast = breadcrumbs.length === index + 1;
        return (
          <div className={styles.breadcrumb} key={breadcrumb.name}>
            <button type="button" disabled={isLast} className={classes(!isLast && styles.clickableBreadcrumb)} onClick={() => handleOnClick(breadcrumb.link)}>
              {breadcrumb.name}
            </button>
            {!isLast && (
              <span className={styles.slash}>
                /
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Breadcrumbs;
