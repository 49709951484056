import { useState } from 'react';
import Tabs, { TabThemes } from 'components/tabs/tabs';
import { Pillars } from 'types/analytics';
import useActivities from 'api/hooks/useActivities';
import { getDateRangeString } from 'progress/profile/profileUtils';
import { useDateRange } from 'insights/hooks/useDateRange';
import styles from './activitiesTable.module.scss';
import {
  DEFAULT_PILLAR_SECTIONS,
  PILLAR_ACTIVITY_TITLES, PILLAR_SECTIONS, Sections, TAB_ACTIVITY_FORMATTER,
} from './activitiesTableUtils';
import ActivitySection from './activitySection';
import Card from '../Card/card';
import CardHeader from '../Card/cardHeader/cardHeader';

type ActivitiesTableProps = {
  pillar: Exclude<Pillars, Pillars.RECOVERY>;
};

function ActivitiesTable({ pillar }: ActivitiesTableProps) {
  const [sectionSelected, setSectionSelected] = useState<Sections>(DEFAULT_PILLAR_SECTIONS[pillar]);
  const tabs = Object.values(PILLAR_SECTIONS[pillar]);
  const { dateRange } = useDateRange();
  const { data: activities, isLoading } = useActivities(pillar, dateRange);

  const activityFormatter = TAB_ACTIVITY_FORMATTER[sectionSelected];
  const sectionData = activityFormatter(activities ?? []);

  return (
    <Card
      id="all-activities-table"
      loading={isLoading}
    >
      <CardHeader
        title={PILLAR_ACTIVITY_TITLES[pillar]}
        details={getDateRangeString(dateRange).formattedDate}
      />
      <div className={styles.activitiesTable}>
        <div className={styles.navBar}>
          <Tabs
            tabList={tabs.map((s) => ({ tab: s }))}
            selectedTab={sectionSelected}
            onClick={(tab: Sections) => setSectionSelected(tab)}
            theme={TabThemes.ACTIVITIES_TABLE}
          />
        </div>
        <div className={styles.sectionsWrapper} data-testid="activities-sections">
          {sectionData.map((section) => (
            <ActivitySection
              key={section.name}
              sectionTitle={section.name}
              sectionDetail={section.data.length}
              sectionData={section.data}
            />
          ))}
        </div>
      </div>
    </Card>
  );
}

export default ActivitiesTable;
