import { METRIC_LABEL_FORMATTER } from 'progress/utils/numberFormatter';
import { RecoveryMetricType, SleepMetricType, StrainMetricType } from 'types/analytics';

export type StatTileDetails = {
  icon: string;
  stat: string;
  formatter: (val: number) => string | number;
};

export const SLEEP_METRIC_MAP: { [key in string]: StatTileDetails } = {
  sleepDuration: {
    icon: 'hours_of_sleep',
    stat: 'Time in Bed',
    formatter: METRIC_LABEL_FORMATTER[SleepMetricType.DURATION],
  },
  sleepLatency: {
    icon: 'latency',
    stat: 'Latency',
    formatter: METRIC_LABEL_FORMATTER[SleepMetricType.LATENCY],
  },
  sleepConsistency: {
    icon: 'bedtime',
    stat: 'Consistency',
    formatter: METRIC_LABEL_FORMATTER[SleepMetricType.CONSISTENCY],
  },
  sleepDisturbances: {
    icon: 'disturbances',
    stat: 'Wake Events',
    formatter: METRIC_LABEL_FORMATTER[SleepMetricType.DISTURBANCES],
  },
  respiratoryRate: {
    icon: 'respiratory_rate',
    stat: 'Respiratory Rate',
    formatter: METRIC_LABEL_FORMATTER[RecoveryMetricType.RESPIRATORY_RATE],
  },
  sleepEfficiency: {
    icon: 'efficiency',
    stat: 'Efficiency',
    formatter: METRIC_LABEL_FORMATTER[SleepMetricType.EFFICIENCY],
  },
};

export const STRAIN_METRIC_MAP: { [key in string]: StatTileDetails } = {
  activity_strain: {
    icon: 'strain',
    stat: 'Activity Strain',
    formatter: METRIC_LABEL_FORMATTER[StrainMetricType.STRAIN],
  },
  calories: {
    icon: 'calories',
    stat: 'Calories',
    formatter: METRIC_LABEL_FORMATTER[StrainMetricType.CALORIES],
  },
  max_heart_rate: {
    icon: 'max_heart_rate',
    stat: 'Max HR',
    formatter: METRIC_LABEL_FORMATTER[StrainMetricType.AVG_HR],
  },
  averageHeartRate: {
    icon: 'heart_rate',
    stat: 'Avg. HR',
    formatter: METRIC_LABEL_FORMATTER[StrainMetricType.AVG_HR],
  },
  activity_duration: {
    icon: 'duration',
    stat: 'Duration',
    formatter: METRIC_LABEL_FORMATTER[SleepMetricType.DURATION],
  },
};

export const RECOVERY_METRIC_MAP = {} as { [x: string]: StatTileDetails };
