import { classes } from '@whoop/web-components';
import styles from './loadingBar.module.scss';

interface LoadingBarProps {
  visible: boolean;
}

export default function LoadingBar({ visible }: LoadingBarProps) {
  return (
    <div className={styles.container}>
      <div role="progressbar" aria-label="loading" className={classes(styles.loader, visible && styles.visible)} />
    </div>
  );
}
