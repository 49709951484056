import { useQuery } from '@tanstack/react-query';
import { getActivities, UserRangeQueryParams } from 'api/analyticsApi';
import { formatDate } from 'progress/profile/profileUtils';
import { useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { useParams } from 'react-router-dom';
import { Pillars } from 'types/analytics';
import { UrlParams } from 'types/profile';

export default function useActivities(pillar: Pillars, range: DateRange) {
  const { userId } = useParams<UrlParams>();

  const queryParams = useMemo(() => {
    const param: UserRangeQueryParams = {
      memberId: parseInt(userId, 10),
      startDate: formatDate(range.from),
    };
    if (range.to) {
      param.endDate = formatDate(range.to);
    }
    return param;
  }, [userId, range]);

  return useQuery({
    queryKey: [queryParams],
    queryFn: () => getActivities(pillar, queryParams),
  });
}
