import { useEffect, useMemo, useState } from 'react';
import {
  createSearchParams, Outlet, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { usePrivacy } from 'context/privacy-context';
import { PrivacyLevel } from 'types/dashboardUser';
import Tabs, { TabThemes } from 'components/tabs/tabs';
import { useAccounts } from 'context/account-context';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import DatePickerSelection from 'insights/components/datePickerSelection/datePickerSelection';
import {
  classes, Col, Container, Icon, Row,
} from '@whoop/web-components';
import { trackEvent } from 'helpers/analyticsTracking';
import { getNumDays } from 'progress/utils/date';
import { useAuth } from 'context/auth-context';
import { useFeatureFlags } from 'components/featureFlag/feature-flag-context';
import CustomDatePicker from 'components/customDatePicker/customDatePicker';
import useGroupDetails from 'api/hooks/useGroupDetails';
import { useDateRange } from 'insights/hooks/useDateRange';
import styles from './groupsHeader.module.scss';
import CsvExport from '../csvExport/csvExport';

export enum GroupsPageTabs {
  GROUPS = 'groups',
  INDIVIDUALS = 'individuals',
}

export type InsightsParams = {
  tab: string;
  groupId?: string;
  accountId: string;
};

type Breadcrumb = {
  name: string;
  link: string;
};

function GroupsHeader() {
  const navigate = useNavigate();
  const { tab, groupId } = useParams<InsightsParams>();
  const { isInPrivacyLevel } = usePrivacy();
  const { dateRange } = useDateRange();
  const { account } = useAccounts();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const { getFeatureFlag } = useFeatureFlags();
  const params = new URLSearchParams(searchParams);
  const { data: group } = useGroupDetails(Number(groupId));

  const getQueryParams = () => {
    const queryParams = createSearchParams(params).toString();
    return queryParams ? `?${queryParams}` : '';
  };

  // Only show on group details page
  const canViewMXPLink = groupId && group?.legacy_team_id && user.admin;

  const numDays = getNumDays(dateRange);

  const baseBreadcrumb: Breadcrumb = {
    name: 'My Groups',
    link: `/accounts/${account.id}/insights/${GroupsPageTabs.GROUPS}${getQueryParams()}`,
  };
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([baseBreadcrumb]);

  const navToTeamsPage = () => {
    if (params.get('groupId')) {
      params.delete('groupId');
    }
    navigate(`/accounts/${account.id}/insights/${GroupsPageTabs.GROUPS}${getQueryParams()}`);
  };

  const tabList = useMemo(() => {
    const baseTabs = [{ tab: GroupsPageTabs.GROUPS }];
    if (isInPrivacyLevel(PrivacyLevel.primary_metrics)) {
      baseTabs.push({ tab: GroupsPageTabs.INDIVIDUALS });
    }
    return baseTabs;
  }, []);

  useEffect(() => {
    const fetchCustomDatePickerFlag = async () => {
      setShowCustomDatePicker(await getFeatureFlag('unite-custom-date-picker'));
    };
    fetchCustomDatePickerFlag();
  }, []);

  useEffect(() => {
    if (groupId && group && isInPrivacyLevel(PrivacyLevel.aggregate_metrics)) {
      setBreadcrumbs([
        baseBreadcrumb,
        {
          name: group.name,
          link: `/accounts/${account.id}/insights/${GroupsPageTabs.GROUPS}/${groupId}`,
        },
      ]);
    } else {
      setBreadcrumbs([baseBreadcrumb]);
    }
  }, [groupId, group]);

  const handleTabClick = (newTab: GroupsPageTabs) => {
    if (newTab === GroupsPageTabs.GROUPS) {
      trackEvent('Insights Page - Tab Click', { tab: GroupsPageTabs.GROUPS });
      navToTeamsPage();
    } else {
      trackEvent('Insights Page - Tab Click', { tab: GroupsPageTabs.INDIVIDUALS });
      navigate(`/accounts/${account.id}/insights/${GroupsPageTabs.INDIVIDUALS}${getQueryParams()}`);
    }
  };

  return (
    <Container fluid data-theme="dark" className={styles.teamsContainer}>
      <Row className={styles.header}>
        <Col xs={12}>
          <div className={styles.tabsWrapper}>
            <Tabs
              tabList={tabList}
              selectedTab={tab}
              onClick={(tabClicked : GroupsPageTabs) => handleTabClick(tabClicked)}
              theme={TabThemes.TEAMS}
            />
            {canViewMXPLink && (
            <div className={styles.mxpSectionWrapper}>
              <Icon name="attention" className={styles.attentionIcon} />
              <a href={`${window._env.MXP_URI}/teams/${group.legacy_team_id}/roster`} target="_blank" rel="noreferrer">Click here</a>
              {' '}
              to view this team in MXP.
            </div>
            )}
          </div>
        </Col>
      </Row>
      {tab !== GroupsPageTabs.INDIVIDUALS && (
        <>
          <Row className={
            classes(styles.breadcrumbsRow, groupId && styles.breadcrumbsRowWithGroup)
          }
          >
            <Col xs={12} md={12} lg={6}>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </Col>
            {isInPrivacyLevel(PrivacyLevel.aggregate_metrics) && (
            <Col xs={12} md={12} lg={6} className={styles.actionMenu}>
              {showCustomDatePicker
                ? <CustomDatePicker />
                : <DatePickerSelection />}
              <CsvExport group={group} />
            </Col>
            )}
          </Row>
          {/* Only show this on the groups landing page for PLs aggregate and higher */}
          {isInPrivacyLevel(PrivacyLevel.aggregate_metrics) && !groupId && (
            <Row className={styles.comparisonDateRow} end="xs">
              <Col xs={12}>
                <div className={styles.comparisonVs}>
                  <Icon name="tiny_triangle_up" className={styles.comparisonIcon} />
                  <Icon name="tiny_triangle_down" className={styles.comparisonIcon} />
                  vs. previous
                  {numDays === 1 ? ' day' : ` ${numDays} days`}
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
      <Outlet />
    </Container>
  );
}

export default GroupsHeader;
