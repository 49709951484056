import { useEffect, useState } from 'react';
import EmailsInput from 'components/emailsInput/emailsInput';
import { TITLE_3 } from 'components/text';
import OptionSelect from 'components/optionSelect/optionSelect';
import { Alert, Alerts } from '@whoop/web-components';
import { useAccounts } from 'context/account-context';
import { trackEvent } from 'helpers/analyticsTracking';
import StepSubheader from 'wizards/components/stepSubheader/stepSubheader';
import { useFeatureFlags } from 'components/featureFlag/feature-flag-context';
import LoadingPage from 'loading';
import { InviteFlowReducerState, InviteFlowReducerAction, ShippingMethodType } from '../../types';
import {
  bulkShippingOption,
  directShippingOption,
  old_bulkShippingOption,
  old_directShippingOption,
  SHIPPING_METHOD_STEP_NUMBER,
  TOTAL_STEPS,
} from '../../constants';
import styles from './selectShippingMethod.module.scss';

type SelectShippingMethodProps = {
  emails: string[];
  state: InviteFlowReducerState;
  dispatch: (action: InviteFlowReducerAction) => void;
};

function SelectShippingMethod({
  emails,
  state,
  dispatch,
}: SelectShippingMethodProps) {
  const { account } = useAccounts();
  const { getFeatureFlag } = useFeatureFlags();
  const [loading, setLoading] = useState<boolean>(true);
  const [newTextFF, setNewTextFF] = useState<boolean>(false);

  useEffect(() => {
    async function init() {
      setNewTextFF(await getFeatureFlag('updated-strap-distribution-text'));
      setLoading(false);
    }
    init();
  }, []);

  const options = newTextFF
    ? [bulkShippingOption, directShippingOption]
    : [old_bulkShippingOption, old_directShippingOption];

  const handleShippingOptionSelect = (value: ShippingMethodType) => {
    trackEvent('New Member Invite - Select Shipment Type', {
      accountId: account.id,
      shippingMethod: value,
    });
    dispatch({ type: 'setShippingMethod', shippingMethod: value });
  };

  return (
    <div>
      <TITLE_3>Add Team Members</TITLE_3>
      <StepSubheader
        stepNumber={SHIPPING_METHOD_STEP_NUMBER}
        totalSteps={TOTAL_STEPS}
      >
        Distribute devices to members
      </StepSubheader>
      <p>How would you like to distribute WHOOP devices to new members?</p>
      <EmailsInput
        label="Emails"
        emails={emails}
        disabled
      />
      {loading ? <LoadingPage overlay /> : (
        <>
          <div className={styles.shippingTypeHeader}>choose an option</div>
          <OptionSelect
            options={options}
            value={state.shippingMethod}
            setValue={(value: ShippingMethodType) => handleShippingOptionSelect(value)}
          />
          {state.shippingMethod === ShippingMethodType.BULK && (
            <Alert className={styles.bulkAlert} type={Alerts.INFO}>
              <span className={styles.bulkAlertText}>
                Reminder: Make sure you have enough devices to provide to your members.
                Contact your CSM if you need additional devices to distribute.
              </span>
            </Alert>
          )}
          {state.shippingMethod === ShippingMethodType.DIRECT && (
            <Alert className={styles.bulkAlert} type={Alerts.INFO}>
              <span className={styles.bulkAlertText}>
                Ship to Members Directly should only be selected
                after confirming with WHOOP that it is the best option for you.
                Please contact support@whoopunite.com if you have any questions.
              </span>
            </Alert>
          )}
        </>
      )}
    </div>
  );
}

export default SelectShippingMethod;
