import { dashboardRoleDisplay } from 'accountDetails/collaboratorsPage/roleDropdown/util';
import { N7 } from 'components/text';
import { ReactElement } from 'react';
import { CellProps } from 'react-table';
import { DropdownSelectCell, normalCell } from 'tableUtils/tableCells';
import { Profile } from 'types/auth';
import { Collaborator, DescriptionDropdownOption } from 'types/collaborator';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import { defaultRoleOptions, PRIVACY_LEVEL_NAME_MAP } from 'accountDetails/types';
import styles from './reviewCollaboratorDetails.module.scss';

export const COLLABORATOR_EMAIL_COLUMN = {
  Header: 'COLLABORATOR EMAIL',
  accessor: 'email',
  width: '34%',
  sortType: 'alphanumeric',
  Cell: normalCell,
};

export const CSM_ROLE_OPTION = {
  name: 'WHOOP CSM',
  active: true,
  description: 'WHOOP Customer Success Manager to help you unlock the potential of WHOOP Unite.',
  value: DashboardRole.WHOOP_CSM,
};

export const ACCOUNT_ADMIN_ROLE_OPTION = {
  name: dashboardRoleDisplay.account_admin,
  active: false,
  description: 'The Account Admin oversees the account and can view account details, purchase add-ons, create and access all groups, and invite/edit Account Managers and Group Managers.',
  value: DashboardRole.ACCOUNT_ADMIN,
};

export const ACCOUNT_MANAGER_ROLE_OPTION = {
  name: dashboardRoleDisplay.account_manager,
  active: true,
  description: 'Account Managers can create and access all groups in the account and invite/edit Group Managers.',
  value: DashboardRole.ACCOUNT_MANAGER,
};

export const getRoleOptions = (
  checkAccess: (role: DashboardRole, greaterThanAccessOnly?: boolean) => boolean,
  isCSMRow: boolean = false,
) => {
  if (isCSMRow) {
    return [CSM_ROLE_OPTION];
  }
  if (checkAccess(DashboardRole.WHOOP_CSM)) {
    return [ACCOUNT_ADMIN_ROLE_OPTION, ACCOUNT_MANAGER_ROLE_OPTION, ...defaultRoleOptions];
  }

  if (checkAccess(DashboardRole.ACCOUNT_ADMIN)) {
    return [ACCOUNT_MANAGER_ROLE_OPTION, ...defaultRoleOptions];
  }

  if (checkAccess(DashboardRole.ACCOUNT_MANAGER)) {
    return defaultRoleOptions;
  }

  return [];
};

const allRoleOptions = [ACCOUNT_ADMIN_ROLE_OPTION,
  ACCOUNT_MANAGER_ROLE_OPTION, ...defaultRoleOptions];

const checkAccessForEditing = (
  role: DashboardRole,
  checkAccess: (role: DashboardRole, greaterThanAccessOnly: boolean) => boolean,
  disableEdit: boolean = false,
) => disableEdit || !checkAccess(role, true)
      || role === DashboardRole.WHOOP_CSM;

export const ROLE_TEXT_COLUMN = {
  Header: 'ROLE',
  accessor: 'role',
  width: '21%',
  sortType: 'alphanumeric',
  Cell: ({ cell: { value } }: CellProps<any>): ReactElement => {
    const roleName = allRoleOptions.find((o) => o.value === value)?.name;
    return (
      <N7>
        {roleName}
      </N7>
    );
  },
};

// Used in two places. Collaborators table and in the 'add collaborators' flow.
// Collaborators table passes in the optional user variable and the add collaborators
// use case does not. So going to conditionally return DropdownSelectCell.
export const getRoleColumn = (
  disableEdit: boolean,
  checkAccess: (role: DashboardRole, greaterThanAccessOnly?: boolean) => boolean,
  user?: Profile,
) => ({
  Header: () => <span className={styles.columnHeader}>ROLE</span>,
  accessor: 'role',
  width: '18%',
  sortType: 'alphanumeric',
  align: 'left',
  Cell: (cellProps: CellProps<Collaborator>): ReactElement => {
    const { row } = cellProps;
    const isCSM = row.original.role === DashboardRole.WHOOP_CSM;
    const roleOptions = getRoleOptions(checkAccess, isCSM);
    const isUser = user && user.id === row?.original?.user_id;
    return DropdownSelectCell(cellProps, 'role', roleOptions, checkAccessForEditing(row.original.role, checkAccess, disableEdit), isUser);
  },
});

export const PRIVACY_LEVEL_TEXT_COLUMN = {
  Header: 'PRIVACY LEVEL',
  accessor: 'privacy_level',
  width: '21%',
  sortType: 'alphanumeric',
  Cell: ({ cell: { value } }: CellProps<String>): ReactElement => (
    <N7>
      {PRIVACY_LEVEL_NAME_MAP[value as PrivacyLevel]}
    </N7>
  ),
};

export const getPrivacyLevelColumn = (
  privacyLevelDropdownOptions: DescriptionDropdownOption[],
  disableEdit: boolean,
  checkAccess: (role: DashboardRole, greaterThanAccessOnly: boolean) => boolean,
) => ({
  Header: () => <span className={styles.columnHeader}>PRIVACY LEVEL</span>,
  accessor: 'privacy_level',
  width: '18%',
  align: 'left',
  sortType: 'alphanumeric',
  Cell: (cellProps: CellProps<Collaborator>): ReactElement => {
    const { row } = cellProps;
    return DropdownSelectCell(cellProps, 'privacy_level', privacyLevelDropdownOptions, disableEdit || !checkAccess(row.original.role, true));
  },
});

export const getJobTitleTextColumn = (jobTitleOptions: DescriptionDropdownOption[]) => ({
  Header: 'JOB TITLE',
  accessor: 'job_title_id',
  width: '21%',
  sortType: 'alphanumeric',
  Cell: ({ cell: { value } }: CellProps<any>): ReactElement => {
    const jobTitle = jobTitleOptions.find((o) => o.value === value)?.name;
    return (
      <N7>
        {jobTitle}
      </N7>
    );
  },
});

export const getOptionTextColumn = (
  options: DescriptionDropdownOption[],
  field: string,
  fieldHeader: string,
  privacyCeiling: PrivacyLevel,
) => ({
  Header: fieldHeader,
  accessor: field,
  width: '21%',
  align: 'left',
  sortType: 'alphanumeric',
  Cell: (cellProps: CellProps<Collaborator>): ReactElement => {
    const { row, cell } = cellProps;
    const option = options.find((o) => o.value === cell.value);
    return (
      <>
        {row.original.role === DashboardRole.WHOOP_CSM
          ? PRIVACY_LEVEL_NAME_MAP[privacyCeiling] : option?.name}
      </>
    );
  },
});

export const getJobTitleOptionsColumn = (
  jobTitleOptions: DescriptionDropdownOption[],
  checkAccess?: (role: DashboardRole, greaterThanAccessOnly: boolean) => boolean,
  disableEdit?: boolean,
) => ({
  Header: () => <span className={styles.columnHeader}>JOB TITLE</span>,
  accessor: 'job_title',
  width: '18%',
  align: 'left',
  sortType: 'alphanumeric',
  Cell: (cellProps: CellProps<Collaborator>): ReactElement => {
    const { row } = cellProps;
    return DropdownSelectCell(cellProps, 'job_title_id', jobTitleOptions, checkAccessForEditing(row.original.role, checkAccess, disableEdit));
  },
});
