import { Colors } from '@whoop/web-components';
import { WidgetType } from 'insights/components/widget/widget';
import { BarDatum, BarItemProps, ResponsiveBar } from '@nivo/bar';
import { DayMonthXAxisTick, DayYTick } from 'dataVisualizations/axis/axis';
import { AxisTickProps } from '@nivo/axes';
import { timeAxisValues } from 'dataVisualizations/axis/utils';
import { formatDate } from 'progress/profile/profileUtils';
import dayjs from 'dayjs';
import {
  BAR_KEYS_BY_WIDGET,
  BAR_COLORS_BY_WIDGET,
} from 'insights/components/widget/widgetFormatter';
import {
  RecoveryBucket, SleepDebtBucket,
  SleepPerformanceBucket, StrainBucket, TrainingZoneBucket,
} from 'api/widgetApi';
import { useMemo } from 'react';
import { RangeBarComponent } from 'dataVisualizations/bars/bars';
import { useDateRange } from 'insights/hooks/useDateRange';
import styles from './rangeBarGraph.module.scss';
import { getGridYVals, getMaxBucketSum } from './rangeBarGraphUtils';

export type WidgetProps = {
  widgetType: WidgetType;
  buckets: StrainBucket[] |
  SleepDebtBucket[] | SleepPerformanceBucket[] | RecoveryBucket[] | TrainingZoneBucket[];
  hoverStateFFEnabled: boolean;
  highlightedIndex: string;
  setHighlightedIndex: (val: string) => void;
};

export const findTopBarComponentKey = (dayData: BarDatum, widgetKeys: string[]) => {
  for (let i = widgetKeys.length - 1; i >= 0; i -= 1) {
    // if the data object contains a value for the key
    // it should be the top of the bar graph since we are traversing from the top down
    if (dayData[widgetKeys[i]]) {
      return `${widgetKeys[i]}.${dayData.date}`;
    }
  }
  return null;
};

function RangeBarGraph({
  widgetType, buckets, hoverStateFFEnabled, highlightedIndex, setHighlightedIndex,
}: WidgetProps) {
  const { dateRange, numOfSelectedDays } = useDateRange();

  const maxSum = getMaxBucketSum(buckets, widgetType);
  const gridYValues = getGridYVals(maxSum);
  const numDays = dayjs(dateRange.to).diff(dayjs(dateRange.from), 'days');
  const dateAxisValues = timeAxisValues(
    formatDate(dateRange.from),
    formatDate(dateRange.to),
    0,
  );

  const graphData = useMemo(() => {
    // BarDatum uses [key: string]: string | number.
    // Which is basically what the Buckets  types look like
    let parsedData = buckets as unknown as BarDatum[];
    // If there is no data, we need to create a graphData with 0 values
    if (buckets.length === 0) {
      parsedData = dateAxisValues.map((date: string) => ({
        date,
        [BAR_KEYS_BY_WIDGET[widgetType][0]]: 0,
      }));
    }
    // Add topBarComponentKey property to each day of data
    parsedData = parsedData.map((dayData) => {
      const key = findTopBarComponentKey(dayData, BAR_KEYS_BY_WIDGET[widgetType]);
      return {
        ...dayData,
        topBarComponentKey: key,
      };
    });
    return parsedData;
  }, [buckets]);

  return (
    <div
      className={styles.rangeGraph}
      onMouseLeave={() => setHighlightedIndex(null)}
    >
      <ResponsiveBar
        data={graphData}
        keys={BAR_KEYS_BY_WIDGET[widgetType]}
        indexBy="date"
        margin={{
          top: 10, right: 0, bottom: 50, left: 30,
        }}
        minValue={0}
        maxValue={maxSum}
        // Sets the padding vertically between each bar piece
        innerPadding={1.5}
        enableLabel={false}
        enableGridX={false}
        enableGridY
        isInteractive
        // Sets the padding horizontally between the days
        padding={numDays < 8 ? 0.8 : 0.5}
        gridYValues={gridYValues}
        barComponent={
          (barComponent: BarItemProps<BarDatum>) => RangeBarComponent(
            barComponent.bar,
            hoverStateFFEnabled ? highlightedIndex : null,
            hoverStateFFEnabled ? setHighlightedIndex : () => {},
          )
        }
        axisLeft={{
          tickValues: gridYValues,
          tickSize: 0,
          renderTick: (
            tick: AxisTickProps<number>,
          ) => DayYTick(tick.x, tick.y, tick.value, null, styles.rangeGraphAxisText),
        }}
        colors={BAR_COLORS_BY_WIDGET[widgetType]}
        axisBottom={{
          tickSize: 0,
          renderTick: ({
            x, y, tickIndex, value,
          }: AxisTickProps<string>) => DayMonthXAxisTick({
            tick: {
              x, y, tickIndex, value,
            },
            numOfSelectedDays,
            className: styles.rangeGraphAxisText,
          }),
        }}
        theme={{
          grid: {
            line: {
              stroke: Colors.whiteAlpha200,
              strokeWidth: 1,
            },
          },
        }}
      />
    </div>
  );
}

export default RangeBarGraph;
