import { BreakdownDTO } from 'api/breakdownApi';
import { baseSeats } from '../store/seats';
import {
  calories,
  hrv,
  recoveryScore,
  rhr,
  sleepConsistency,
  sleepDuration,
  sleepEfficiency,
  sleepPerformance,
  strainScore,
  workoutCounts,
} from '../store/breakdown';

const average = (array: number[]) => array.reduce((a, b) => a + b) / array.length;

export const strainBreakdown: BreakdownDTO<'dayStrain'> = {
  average: average(strainScore),
  hide_average: false,
  user_data: baseSeats.map((s, i) => ({
    user_id: s.user_id,
    metric: 'dayStrain',
    data_value: strainScore[i],
    opted_in: s.opted_in,
  })),
};

export const caloriesBreakdown: BreakdownDTO<'calories'> = {
  average: average(calories),
  hide_average: false,
  user_data: baseSeats.map((s, i) => ({
    user_id: s.user_id,
    metric: 'calories',
    data_value: calories[i],
    opted_in: s.opted_in,
  })),
};

export const workoutCountBreakdown: BreakdownDTO<'workoutCount'> = {
  average: average(workoutCounts),
  hide_average: false,
  user_data: baseSeats.map((s, i) => ({
    user_id: s.user_id,
    metric: 'workoutCount',
    data_value: workoutCounts[i],
    opted_in: s.opted_in,
  })),
};

export const recoveryBreakdown: BreakdownDTO<'recoveryScore'> = {
  average: average(recoveryScore),
  hide_average: false,
  user_data: baseSeats.map((s, i) => ({
    user_id: s.user_id,
    metric: 'recoveryScore',
    data_value: recoveryScore[i],
    opted_in: s.opted_in,
  })),
};

export const rhrBreakdown: BreakdownDTO<'rhr'> = {
  average: average(rhr),
  hide_average: false,
  user_data: baseSeats.map((s, i) => ({
    user_id: s.user_id,
    metric: 'rhr',
    data_value: rhr[i],
    opted_in: s.opted_in,
  })),
};

export const hrvBreakdown: BreakdownDTO<'hrv'> = {
  average: average(hrv),
  hide_average: false,
  user_data: baseSeats.map((s, i) => ({
    user_id: s.user_id,
    metric: 'hrv',
    data_value: hrv[i],
    opted_in: s.opted_in,
  })),
};

export const sleepConsistencyBreakdown: BreakdownDTO<'sleepConsistency'> = {
  average: average(sleepConsistency),
  hide_average: false,
  user_data: baseSeats.map((s, i) => ({
    user_id: s.user_id,
    metric: 'sleepConsistency',
    data_value: sleepConsistency[i],
    opted_in: s.opted_in,
  })),
};

export const sleepEfficiencyBreakdown: BreakdownDTO<'sleepEfficiency'> = {
  average: average(sleepEfficiency),
  hide_average: false,
  user_data: baseSeats.map((s, i) => ({
    user_id: s.user_id,
    metric: 'sleepEfficiency',
    data_value: sleepEfficiency[i],
    opted_in: s.opted_in,
  })),
};

export const sleepPerformanceBreakdown: BreakdownDTO<'sleepPerformance'> = {
  average: average(sleepPerformance),
  hide_average: false,
  user_data: baseSeats.map((s, i) => ({
    user_id: s.user_id,
    metric: 'sleepPerformance',
    data_value: sleepPerformance[i],
    opted_in: s.opted_in,
  })),
};

export const sleepDurationBreakdown: BreakdownDTO<'sleepDuration'> = {
  average: average(sleepDuration),
  hide_average: false,
  user_data: baseSeats.map((s, i) => ({
    user_id: s.user_id,
    metric: 'sleepDuration',
    data_value: sleepDuration[i],
    opted_in: s.opted_in,
  })),
};
