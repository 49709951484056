import { classes, Icon } from '@whoop/web-components';
import styles from 'insights/components/widget/widget.module.scss';
import { Widget, WidgetType } from 'insights/components/widget/widget';
import Header from 'insights/components/widget/header/header';
import LoadingSkeleton from 'insights/components/loadingSkeleton/loadingSkeleton';
import { useWidgetQuery } from 'api/hooks/useWidgetQuery';
import LeaderboardTable from 'insights/components/leaderboardTable/leaderboardTable';
import { MemoizedPieChartAndLabel } from 'insights/components/pieChartAndLabel/pieChartAndLabels';
import { usePrivacy } from 'context/privacy-context';
import { PrivacyLevel } from 'types/dashboardUser';
import RangeBarGraph from 'insights/components/rangeBarGraph/rangeBarGraph';
import DailyBarGraph from 'insights/components/dailyBarGraph/dailyBarGraph';
import {
  BaseLeaderboard, RecoveryLeaderboard, SleepLeaderboard,
  StrainLeaderboard, TrainingZoneLeaderboard,
} from 'api/widgetApi';
import { Column } from 'react-table';
import { useEffect, useMemo, useState } from 'react';
import DailyHoverDataComponent from 'insights/components/hoverDataComponent/dailyHoverDataComponent';
import RangeHoverDataComponent from 'insights/components/hoverDataComponent/rangeHoverDataComponent';
import TrainingGraph from 'insights/components/trainingGraph/trainingGraph';
import { NodeData } from 'dataVisualizations/points/points';
import DailyTrainingZoneHover from 'insights/components/hoverDataComponent/dailyTrainingZoneHover';
import { useDateRange } from 'insights/hooks/useDateRange';
import {
  BREAKDOWN_LABEL_BY_WIDGET,
  DEFAULT_SORT_BY_WIDGET,
  ICON_BY_WIDGET,
  COLLAPSED_LEADERBOARD_COLUMNS_BY_WIDGET,
  WIDGET_TYPE_TO_NAME,
  WIDGET_TO_AVG_KEY,
  EXPANDED_LEADERBOARD_COLUMNS_BY_WIDGET,
  PRIMARY_METRICS_COLUMNS_BY_WIDGET,
  WIDGET_STATIC_VOW,
} from '../widgetFormatter';
import { insufficientDataMessage, privacyLevelMessage } from './keyStatisticWidgetConsts';

export type WidgetProps = {
  widgetType: WidgetType;
  toggleExpanded: (type: WidgetType) => void;
  isExpanded: boolean;
  showHoverState: boolean;
};

export interface ReducedLeaderboardData extends BaseLeaderboard {
  metricValue: number;
}

export const getMetricValueFromLeaderboardData = (
  user: RecoveryLeaderboard | SleepLeaderboard | StrainLeaderboard,
  widgetType: WidgetType,
  index?: number,
) => {
  let metricValue;
  if ('recovery' in user) {
    metricValue = user.recovery;
  } else if (widgetType === WidgetType.SLEEP_PERFORMANCE && 'sleep_performance' in user) {
    metricValue = user.sleep_performance;
  } else if (widgetType === WidgetType.SLEEP_DEBT && 'sleep_debt_minutes' in user) {
    metricValue = user.sleep_debt_minutes;
  } else if ('strain' in user) {
    metricValue = user.strain;
  }

  return {
    metricValue,
    user_id: user.user_id ?? index,
    avatar_url: user.avatar_url,
    name: user.name,
    opted_in: user.opted_in,
    base_line: user.base_line,
    base_line_sentiment: user.base_line_sentiment,
    day_over_day: user.day_over_day,
    day_over_day_sentiment: user.day_over_day_sentiment,
  };
};

export const reduceLeaderboardData = (
  leaderboardArray: RecoveryLeaderboard[] | SleepLeaderboard[] | StrainLeaderboard[],
  widgetType: WidgetType,
) => {
  if (leaderboardArray) {
    return leaderboardArray.map(
      (
        row: RecoveryLeaderboard | SleepLeaderboard | StrainLeaderboard,
        index: number,
      ) => getMetricValueFromLeaderboardData(row, widgetType, index),
    );
  }
  return null;
};

export const formatDataForDailyGraph = (
  leaderboardArray: ReducedLeaderboardData[],
) => {
  if (leaderboardArray) {
    return leaderboardArray.map((row: ReducedLeaderboardData, index: number) => ({
      metricValue: row.metricValue,
      user_id: row.user_id ?? index,
      name: row.name,
      avatar_url: row.avatar_url,
    }));
  }
  return null;
};

function KeyStatisticWidget(
  {
    widgetType, isExpanded, toggleExpanded, showHoverState,
  }: WidgetProps,
) {
  const {
    data, isLoading, isFetching,
  } = useWidgetQuery(widgetType);
  const { isInPrivacyLevel } = usePrivacy();
  const { dateRange } = useDateRange();
  const dailyView = !dateRange.to;
  const noDataState = data?.leaderboard.length === 0;
  // This has type NodeData when used for the training zone widget
  const [highlightedIndex, setHighlightedIndex] = useState<string | number | NodeData>(null);

  // This makes sure the highlighted index doesn't get stuck on old data
  useEffect(() => {
    setHighlightedIndex(null);
  }, [data]);

  // Leaderboard data type is RecoveryLeaderboard[] | SleepLeaderboard[] | StrainLeaderboard[],
  // Need object with the same type regardless of widget type. This object has field 'metricValue',
  // That is either Recovery Score, Strain, Sleep Performance, or Sleep Debt
  // depending on widget type.
  const reducedLeaderboardData: ReducedLeaderboardData[] = useMemo(() => {
    if (widgetType !== WidgetType.TRAINING_ZONE) {
      return reduceLeaderboardData(
        data?.leaderboard as RecoveryLeaderboard[] | SleepLeaderboard[] | StrainLeaderboard[],
        widgetType,
      );
    }
    return null;
  }, [data]);

  const dailyBarGraphData = useMemo(
    () => formatDataForDailyGraph(reducedLeaderboardData),
    [reducedLeaderboardData],
  );

  const dailyHoverData = reducedLeaderboardData?.find(
    (row) => row.user_id === highlightedIndex,
  );

  const showLeaderboard = isInPrivacyLevel(PrivacyLevel.primary_metrics) && !noDataState;

  const atLeastPerformanceMetrics = isInPrivacyLevel(PrivacyLevel.performance_metrics);

  const message = noDataState ? insufficientDataMessage : privacyLevelMessage;

  let leaderboardColumnsToUse: Column[] = COLLAPSED_LEADERBOARD_COLUMNS_BY_WIDGET[widgetType];
  if (isExpanded) {
    leaderboardColumnsToUse = atLeastPerformanceMetrics
      ? EXPANDED_LEADERBOARD_COLUMNS_BY_WIDGET[widgetType]
      : PRIMARY_METRICS_COLUMNS_BY_WIDGET[widgetType];
  }

  const avgVal = data?.[WIDGET_TO_AVG_KEY[widgetType] as keyof typeof data] as number;

  const highlightedRangeBucketIndex = data?.buckets
    .findIndex((bucket) => bucket.date === highlightedIndex);

  const rangeHoverBucket = data?.buckets[highlightedRangeBucketIndex];

  return (
    <Widget id={widgetType}>
      <Header
        title={WIDGET_TYPE_TO_NAME[widgetType]}
        onClick={() => toggleExpanded(widgetType)}
        isExpanded={isExpanded}
      />
      <div className={styles.expandableCardContent}>
        {isExpanded
          && (
            <div className={classes(styles.expandedContent)}>
              <LoadingSkeleton isLoading={isLoading}>
                <div
                  className={classes(
                    styles.graphDescription,
                    highlightedIndex && styles.addBackground,
                  )}
                >
                  {!dailyView && rangeHoverBucket && (
                    <RangeHoverDataComponent
                      bucket={rangeHoverBucket}
                      widgetType={widgetType}
                    />
                  )}
                  {dailyView && (highlightedIndex as NodeData)?.trainingZone && (
                    <DailyTrainingZoneHover node={highlightedIndex as NodeData} />
                  )}
                  {dailyView && dailyHoverData && (
                    <DailyHoverDataComponent
                      dailyHoverData={dailyHoverData}
                      widgetType={widgetType}
                    />
                  )}
                  {!highlightedIndex && (
                    <>
                      <Icon name="logo_whoop_circle" className={styles.logoIcon} />
                      {WIDGET_STATIC_VOW[widgetType]}
                    </>
                  )}
                </div>
                <div className={styles.graphTitleLine}>
                  <span className={styles.title}>
                    {dailyView
                    && (widgetType === WidgetType.TRAINING_ZONE ? 'Day Strain'
                      : WIDGET_TYPE_TO_NAME[widgetType])}
                    {!dailyView && 'Group Breakdown'}
                  </span>
                  {dailyView && avgVal && (
                    <span>
                      <span className={styles.averageLine}>---</span>
                      Group Average
                    </span>
                  )}
                </div>
                {data && !isFetching && !dailyView && (
                  <RangeBarGraph
                    widgetType={widgetType}
                    buckets={data.buckets}
                    hoverStateFFEnabled={showHoverState}
                    highlightedIndex={highlightedIndex as string}
                    setHighlightedIndex={setHighlightedIndex}
                  />
                )}
                {data && dailyView && (
                  <>
                    {widgetType === WidgetType.TRAINING_ZONE ? (
                      <>
                        {!isFetching && (
                        <TrainingGraph
                          data={data.leaderboard as TrainingZoneLeaderboard[]}
                          highlightedNode={highlightedIndex as NodeData}
                          setHighlightedNode={setHighlightedIndex}
                        />
                        )}
                      </>
                    ) : (
                      <DailyBarGraph
                        widgetType={widgetType}
                        data={dailyBarGraphData}
                        avgVal={avgVal}
                        hoverStateFFEnabled={showHoverState}
                        highlightedIndex={highlightedIndex as string}
                        setHighlightedIndex={setHighlightedIndex}
                      />
                    )}
                    {(widgetType !== WidgetType.TRAINING_ZONE
                        || (widgetType === WidgetType.TRAINING_ZONE && !isFetching)) && (
                        <div className={classes(
                          styles.groupMembers,
                          widgetType === WidgetType.TRAINING_ZONE && styles.trainingZoneLabel,
                        )}
                        >
                          {widgetType === WidgetType.TRAINING_ZONE ? 'Recovery' : 'Group Members'}
                        </div>
                    )}
                  </>
                )}
              </LoadingSkeleton>
            </div>
          )}
        <div className={
          classes(
            styles.condensedContent,
            !isLoading && showLeaderboard && styles.gradient,
            showLeaderboard
              ? styles.contentHeight
              : styles.messageHeight,
          )
        }
        >
          <div className={classes(
            isExpanded && styles.expandedSpacing,
            isLoading && styles.loading,
          )}
          >
            <LoadingSkeleton isLoading={isLoading}>
              <div className={classes(
                styles.breakdownChart,
                isExpanded && styles.expandedBreakdownChart,
              )}
              >
                <MemoizedPieChartAndLabel
                  data={data?.breakdown}
                  labels={BREAKDOWN_LABEL_BY_WIDGET[widgetType]}
                  icon={ICON_BY_WIDGET[widgetType]}
                  noDataState={noDataState}
                />
              </div>
            </LoadingSkeleton>
          </div>
          <div className={classes(
            isLoading && showLeaderboard && styles.loadingLeaderboard,
            showLeaderboard ? styles.leaderboard : styles.message,
          )}
          >
            <LoadingSkeleton isLoading={isLoading}>
              {
                showLeaderboard
                  ? (
                    <LeaderboardTable
                      columns={leaderboardColumnsToUse}
                      leaderboardType={widgetType}
                      data={data?.leaderboard}
                      defaultSortById={DEFAULT_SORT_BY_WIDGET[widgetType]}
                    />
                  )
                  : (
                    <div className={styles.messageContainer}>
                      <Icon
                        name={message.icon}
                        className={styles.icon}
                        data-testid={message.icon}
                      />
                      <p className={styles.messageText}>{message.text}</p>
                    </div>
                  )
              }
            </LoadingSkeleton>
          </div>
        </div>
      </div>
    </Widget>
  );
}

export default KeyStatisticWidget;
