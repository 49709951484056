import { NamedUser } from './base';

export interface SeatDetail extends NamedUser {
  id: number;
  contract_number: number;
  contract_start_date: Date;
  contract_end_date: Date;
  joined_on: Date;
  opted_in: boolean;
  activated_on: Date;
  last_activity_at: Date;
  strap_serial: string;
  status: SeatStatus;
  user_id: number;
  email: string;
  membership_status: string;
  shipping_type?: ShippingType;
  hub_member: boolean;
}

export interface Seat {
  id: number;
  created_at: Date;
  updated_at: Date;
  last_activity_at: Date;
  userId: number;
  strap_serial: string;
  accept_token: string;
  email: string;
  contract_number: number;
  seat_status: SeatStatus;
  joined_on: Date;
  activated_on: Date;
  invited_email: string;
}

export enum SeatStatus {
  Active = 'active',
  Deactivated = 'deactivated',
  Invited = 'invited',
  Pending = 'pending',
  Unknown = 'unknown',
}

export enum ActivatedSeatStatus {
  Activated = 'activated',
}

export enum HubMemberStatus {
  ALL = 'all',
  HUB_MEMBER = 'hub member',
  MEMBER = 'member',
}

export enum ShippingType {
  ALL = 'all',
  BULK = 'bulk',
  DIRECT = 'direct',
  EXISTING_MEMBER = 'existing_member',
}

export interface EmailAndSeatStatus {
  email: string;
  status: SeatStatus;
}
