import { Colors } from '@whoop/web-components';
import { GroupsLandingStatNames } from 'api/groupLandingApi';
import { getColorFromVal, STRAIN_MAX } from 'progress/progressPageUtils';

export const getPieValAndColor = (val: number, id: GroupsLandingStatNames) => {
  // If value is null or undefined, show default gray color for entire pie chart
  if (!val) {
    return {
      displayVal: `--${id !== GroupsLandingStatNames.SCALED_DAY_STRAIN ? '%' : ''}`,
      pieVal: 100,
      color: Colors.gray600,
    };
  }
  if (id === GroupsLandingStatNames.SCALED_DAY_STRAIN) {
    return {
      displayVal: val.toFixed(1),
      pieVal: (val / STRAIN_MAX) * 100,
      color: Colors.strainBlue,
    };
  }
  if (id === GroupsLandingStatNames.RECOVERY_SCORE) {
    return {
      displayVal: `${Math.round(val)}%`,
      pieVal: val,
      color: getColorFromVal(val),
    };
  }
  if (id === GroupsLandingStatNames.SLEEP_PERFORMANCE) {
    return {
      displayVal: `${Math.round(val)}%`,
      pieVal: val,
      color: Colors.sleepBlue,
    };
  }
  // Default case
  throw new Error('Invalid id passed in from GroupsLandingStatNames');
};

// Return the direction its going to be sorted in (the opposite of wasSortedDesc)
export const getTrackedSortDirection = (wasSortedDesc: boolean) => {
  let sortDirection = 'descending';
  if (wasSortedDesc === undefined || wasSortedDesc) {
    sortDirection = 'ascending';
  }
  return sortDirection;
};
