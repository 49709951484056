import {
  useEffect, useRef, useState,
} from 'react';
import { Icon, Button, classes } from '@whoop/web-components';
import {
  DateRange, DayPicker, ClassNames, DateFormatter,
} from 'react-day-picker';
import { UseStatePropType } from 'types/useStatePropType';
import dayPickerStyles from 'react-day-picker/dist/style.module.css';
import {
  formatWeekdayDayMaybeYear, getNumDays, getNumDaysText,
} from 'progress/utils/date';
import { Period } from 'types/analytics';
import dayjs from 'dayjs';
import { getFormattedRangeDay } from 'progress/profile/profileUtils';
import CustomDay from 'components/customDatePicker/customDay/customDay';
import styles from './datePicker.module.scss';

type DatePickerProps = {
  currentRange: DateRange;
  applyRange?: UseStatePropType<DateRange>;
  selectSingleDay?: boolean;
  minNumDays?: number;
  disabled?: boolean;
  disableBefore?: Date;
  styleDatePickerBox?: string;
  datePickerButtonStyles?: string;
  autoSelectRange?: Period;
  required?: boolean;
};

function DatePicker({
  currentRange,
  applyRange,
  selectSingleDay,
  minNumDays = 0,
  disabled,
  disableBefore,
  styleDatePickerBox,
  datePickerButtonStyles,
  autoSelectRange,
  required,
}: DatePickerProps) {
  const [range, setRange] = useState<DateRange>(currentRange);
  const [showDayPicker, setShowDayPicker] = useState<boolean>(false);
  const classNames: ClassNames = {
    ...dayPickerStyles,
    day_range_start: styles.dayRangeStart,
    day_range_end: styles.dayRangeEnd,
    day_range_middle: styles.dayRangeMiddle,
    day_selected: styles.daySelected,
    // @ts-ignore Ignoring dayPickerStyles.day
    day: `${dayPickerStyles.day} ${styles.dayButton}`,
    row: styles.rowBody,
    head_row: styles.rowHead,
    caption_label: styles.monthCaption,
    nav_button_previous: styles.monthMover,
    nav_button_next: styles.monthMover,
  };
  const today = new Date();

  const datePickerRef = useRef(null);
  const datePickerButtonRef = useRef(null);

  const formatCaption: DateFormatter = (date) => (
    <>
      {dayjs(date).format('MMM YYYY')}
    </>
  );

  const applyDay = (day: Date) => {
    setRange({ from: day });
    applyRange({ from: day });
    setShowDayPicker(false);
  };

  const handleCancel = () => {
    setRange(currentRange);
    setShowDayPicker(false);
  };

  const handleApply = () => {
    applyRange(range);
    setShowDayPicker(false);
  };

  const handleClickOutside = (event: any) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)
      && datePickerButtonRef.current && !datePickerButtonRef.current.contains(event.target)) {
      handleCancel();
    }
  };

  useEffect(() => {
    setRange(currentRange);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [currentRange]);

  const autoSelectDateRange = (selectedRange: DateRange) => {
    let dateSelected;
    // If the range or range.to is undefined, return
    if (!selectedRange || !selectedRange?.to) {
      return;
    }

    if (dayjs(range.from).isSame(dayjs(selectedRange.from))) {
      dateSelected = selectedRange.to;
    } else if (dayjs(range.to).isSame(dayjs(selectedRange.to))) {
      dateSelected = selectedRange.from;
    }

    setRange({
      to: dayjs(dateSelected).toDate(),
      from: dayjs(dateSelected).subtract(autoSelectRange === Period.SEVEN_DAYS ? 6 : 29, 'days').toDate(),
    });
  };

  const setDateRange = (selectedRange: DateRange) => {
    // If range is undefined (due to deselect)
    if (!selectedRange) {
      return;
    }

    // If from and to are the same date
    if (dayjs(selectedRange.to).isSame(dayjs(selectedRange.from))) {
      setRange({ from: selectedRange.from });
      return;
    }

    setRange(selectedRange);
  };

  return (
    <>
      <button
        onClick={() => setShowDayPicker((prevState) => !prevState)}
        className={classes(styles.datePickerButton, datePickerButtonStyles)}
        ref={datePickerButtonRef}
        data-testid="dayPickerButton"
        type="button"
        disabled={disabled}
      >
        <span>
          {currentRange?.to
            ? getFormattedRangeDay(currentRange)
            : formatWeekdayDayMaybeYear(currentRange?.from)}
        </span>
        <Icon name="annual_pa" className={styles.calendarIcon} />
      </button>
      {showDayPicker && (
        <div className={classes(styles.datePickerBox, styleDatePickerBox)} ref={datePickerRef}>
          {selectSingleDay ? (
            <DayPicker
              mode="single"
              selected={range?.from}
              required={required}
              onSelect={(day: Date) => {
                applyDay(day);
              }}
              components={{
                Day: CustomDay,
              }}
              defaultMonth={dayjs(range?.from).toDate() ?? dayjs(today).toDate()}
              toMonth={today}
              fromMonth={disableBefore}
              disabled={[{ after: today }, { before: disableBefore ?? null }]}
              numberOfMonths={1}
              formatters={{ formatCaption }}
              classNames={classNames}
            />
          ) : (
            <>
              <DayPicker
                mode="range"
                selected={range}
                onSelect={(r: DateRange) => (autoSelectRange
                  ? autoSelectDateRange(r) : setDateRange(r)
                )}
                defaultMonth={dayjs(range?.from).toDate() ?? dayjs(today).toDate()}
                toMonth={today}
                fromMonth={disableBefore}
                disabled={[
                  { after: today },
                  { before: disableBefore ?? null },
                ]}
                components={{
                  Day: CustomDay,
                }}
                numberOfMonths={2}
                formatters={{ formatCaption }}
                classNames={{
                  ...classNames,
                  nav: styles.nav, // nav arrow styling for range view
                  caption_start: styles.captionStart,
                  caption_end: styles.captionEnd,
                }}
                min={minNumDays}
              />
              <div className={styles.footer}>
                <div className={styles.dateInfo}>
                  <span className={styles.rangeText}>
                    {range?.to ? getFormattedRangeDay(range)
                      : formatWeekdayDayMaybeYear(range?.from)}
                  </span>
                  <span className={styles.numDays}>
                    {getNumDaysText(range, minNumDays)}
                  </span>
                </div>
                <div className={styles.actions}>
                  <Button
                    size="small"
                    onClick={handleCancel}
                    theme="enterprise"
                    variant="link"
                    className={`${styles.button} ${styles.cancel}`}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    onClick={handleApply}
                    theme="enterprise"
                    variant="normal"
                    className={`${styles.button} ${styles.apply}`}
                    disabled={getNumDays(range) < minNumDays}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default DatePicker;
