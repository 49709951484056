import { GroupLanding, GroupsLandingStatNames, SENTIMENT } from 'api/groupLandingApi';
import { baseGroups } from '../groups';

export const mockGroupLandingData: GroupLanding = {
  end_date: '2023-06-14',
  groups: [
    {
      id: 1,
      member_count: 2,
      name: baseGroups[0].name,
      average_hidden: false,
      average_stats: [
        {
          name: GroupsLandingStatNames.SCALED_DAY_STRAIN,
          val: 9.4039,
          diff: 4.126480015273046,
          sentiment: SENTIMENT.NEUTRAL,
        },
        {
          name: GroupsLandingStatNames.SLEEP_PERFORMANCE,
          val: 84,
          diff: -5.7063492063492,
          sentiment: SENTIMENT.POSITIVE,
        },
        {
          name: GroupsLandingStatNames.RECOVERY_SCORE,
          val: 62.333333333333336,
          diff: 25.935828877005356,
          sentiment: SENTIMENT.POSITIVE,
        },
      ],
    },
    {
      id: 2,
      member_count: 1,
      name: baseGroups[1].name,
      average_hidden: false,
      average_stats: [
        {
          name: GroupsLandingStatNames.SCALED_DAY_STRAIN,
          val: 15.4039,
          diff: 3.026480015273046,
          sentiment: SENTIMENT.NEUTRAL,
        },
        {
          name: GroupsLandingStatNames.SLEEP_PERFORMANCE,
          val: 74,
          diff: 2.7063492063492,
          sentiment: SENTIMENT.POSITIVE,
        },
        {
          name: GroupsLandingStatNames.RECOVERY_SCORE,
          val: 65.33333333333336,
          diff: -4.935828877005356,
          sentiment: SENTIMENT.POSITIVE,
        },
      ],
    },
    {
      id: 3,
      member_count: 3,
      name: 'Hidden Average',
      average_hidden: true,
      average_stats: [],
    },
    {
      id: 4,
      member_count: 5,
      name: 'No Data Group',
      average_hidden: false,
      average_stats: [
        {
          name: GroupsLandingStatNames.SCALED_DAY_STRAIN,
          val: null,
          diff: null,
          sentiment: null,
        },
        {
          name: GroupsLandingStatNames.SLEEP_PERFORMANCE,
          val: null,
          diff: null,
          sentiment: null,
        },
        {
          name: GroupsLandingStatNames.RECOVERY_SCORE,
          val: null,
          diff: null,
          sentiment: null,
        },
      ],
    },
    {
      id: 5,
      member_count: 7,
      name: baseGroups[3].name,
      average_hidden: false,
      average_stats: [
        {
          name: GroupsLandingStatNames.SCALED_DAY_STRAIN,
          val: 13.4039,
          diff: 4.026480015273046,
          sentiment: SENTIMENT.NEUTRAL,
        },
        {
          name: GroupsLandingStatNames.SLEEP_PERFORMANCE,
          val: 80,
          diff: 3.7063492063492,
          sentiment: SENTIMENT.POSITIVE,
        },
        {
          name: GroupsLandingStatNames.RECOVERY_SCORE,
          val: 70.33333333333336,
          diff: -2.935828877005356,
          sentiment: SENTIMENT.POSITIVE,
        },
      ],
    },
  ],
  salesforce_account_id: 'foster-and-jefferson',
  salesforce_account_name: 'Foster & Jefferson',
  start_date: '2023-06-08',
};
