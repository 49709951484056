import { Colors, Icon } from '@whoop/web-components';
import { PieChartLabel } from './iconPieChartConsts';
import styles from './iconPieChart.module.scss';

export type IconPieData = {
  id: string;
  label: string;
  value: number;
  color: string;
};

export function CenteredIcon(icon: string, centerX: number, centerY: number, iconSize: number) {
  return (
    <g transform={`translate(${centerX - (iconSize / 2)}, ${centerY - (iconSize / 2)})`} className={styles.pieChartIcon}>
      <Icon name={icon} width={iconSize} height={iconSize} />
    </g>
  );
}

export const convertDataToPieData = (data: { [key: string]: number }, labels: PieChartLabel[]) => {
  const parsedPieData: IconPieData[] = [];
  labels.forEach((label: PieChartLabel) => {
    if (data?.[label.key]) {
      parsedPieData.push({
        id: label.key,
        label: label.key,
        value: data?.[label.key],
        color: label.color,
      });
    }
  });

  const pieDataToUse = parsedPieData.length > 0 ? parsedPieData : [
    {
      color: Colors.gray600,
      id: 'emptyState',
      label: 'empty',
      value: 1,
    },
  ];
  return pieDataToUse;
};
