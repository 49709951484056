import { baseGroups } from 'mocks/store/groups';
import { STORIED_SUMMARY_DATA } from 'mocks/store/progress/summaryDataStories';
import {
  breakdownSleepRecords, breakdownRecoveryRecords,
  breakdownStrainRecords, progressBaseSeats, progressMemberProfile,
} from 'mocks/store/progressData';
import { getCurrentDate } from 'mocks/store/data';
import { getFormattedDate } from 'progress/utils/date';
import { Pillars } from 'types/analytics';
import dayOverviewData from 'mocks/store/progress/overview_hr_data.json';
import dayjs from 'dayjs';
import {
  mockAverageHRMetric, mockCaloriesMetric, mockRecoveryScore,
  mockSleepScores, mockStrainMetric, mockMemberTrendsData,
} from 'mocks/store/progress/pillarOverviewData';

export const DAYS_IN_MONTH = 30;

export const getNumDays = (startDate, endDate) => {
  if (!endDate) {
    return 1;
  }
  const numDays = dayjs(endDate).diff(dayjs(startDate), 'days') + 1;
  return numDays;
};

export const getMonthlyWindow = (data) => {
  const currentDate = getCurrentDate();
  const dayOfWeek = currentDate.getDay();
  return data.slice(dayOfWeek, DAYS_IN_MONTH + dayOfWeek);
};

const getSleepSummaryValue = () => Math.floor(Math.random() * 50) + 50;

const getRecoverySummaryValue = () => Math.floor(Math.random() * 100);

const getStrainSummaryValue = () => (Math.random() * 17) + 4;

const getBatteryLevelValue = () => (Math.random() * 100);

// May need this in the future so keeping for now
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createRandomSummaryData = (pillar, metricName) => {
  const data = [];
  const today = getCurrentDate();
  const startDay = new Date(today.setDate(today.getDate() - 30));
  for (let i = 0; i < 30; i += 1) {
    let value;
    if (pillar === Pillars.SLEEP) {
      value = getSleepSummaryValue();
    } else if (pillar === Pillars.RECOVERY) {
      value = getRecoverySummaryValue();
    } else {
      value = getStrainSummaryValue();
    }
    data.push({
      average: value,
      date: getFormattedDate(new Date(startDay.setDate(startDay.getDate() + 1))),
      metric_name: metricName,
    });
  }
  return {
    cohort_averages: data,
  };
};

export const createStoriedSummaryData = (pillar, metricName, story = STORIED_SUMMARY_DATA) => {
  const today = getCurrentDate();
  const startDay = new Date(today.setDate(today.getDate() - 30));
  const data = getMonthlyWindow(story[pillar]);
  return {
    cohort_averages: data.map((d) => ({
      average: d,
      date: getFormattedDate(new Date(startDay.setDate(startDay.getDate() + 1))),
      metric_name: metricName,
    })),
  };
};

const shuffle = (array) => array.sort(() => Math.random() - 0.5);

const getSeatsToUse = (cohortId) => {
  let seatsToUse = progressBaseSeats;
  if (cohortId === '1') {
    seatsToUse = progressBaseSeats.slice(0, 5);
  } else if (cohortId === '2') {
    seatsToUse = progressBaseSeats.slice(6, 11);
  }
  return seatsToUse;
};

const getAvgVal = (records, property) => {
  const numRecords = records.length;
  return records.reduce((total, next) => total + next[property], 0) / numRecords;
};

export const getBreakdownSleepData = (cohortId) => {
  const shuffledMockValues = shuffle(breakdownSleepRecords);
  const seatsToUse = getSeatsToUse(cohortId);
  const records = seatsToUse.map((seat, index) => ({
    ...seat,
    duration: shuffledMockValues[index].duration,
    performance: shuffledMockValues[index].performance,
    sleep_consistency: shuffledMockValues[index].sleep_consistency,
    sleep_efficiency: shuffledMockValues[index].sleep_efficiency,
    name: `${seat.first_name} ${seat.last_name}`,
    avatar_url: '',
    last_activity_at: '',
  }));

  return {
    average_duration: getAvgVal(records, 'duration'),
    average_performance: getAvgVal(records, 'performance'),
    average_sleep_consistency: getAvgVal(records, 'sleep_consistency'),
    average_sleep_efficiency: getAvgVal(records, 'sleep_efficiency'),
    sleep_averages: records,
  };
};

export const getBreakdownRecoveryData = (cohortId) => {
  const shuffledMockValues = shuffle(breakdownRecoveryRecords);
  const seatsToUse = getSeatsToUse(cohortId);
  const records = seatsToUse.map((seat, index) => ({
    ...seat,
    rhr: shuffledMockValues[index].rhr,
    hrv: shuffledMockValues[index].hrv,
    score: shuffledMockValues[index].score,
    name: `${seat.first_name} ${seat.last_name}`,
    avatar_url: '',
    last_activity_at: '',
  }));

  return {
    average_score: getAvgVal(records, 'score'),
    average_hrv: getAvgVal(records, 'hrv'),
    average_rhr: getAvgVal(records, 'rhr'),
    recovery_averages: records,
  };
};

export const getBreakdownStrainData = (cohortId) => {
  const shuffledMockValues = shuffle(breakdownStrainRecords);
  const seatsToUse = getSeatsToUse(cohortId);
  const records = seatsToUse.map((seat, index) => ({
    ...seat,
    day_strain: shuffledMockValues[index].day_strain,
    calories: shuffledMockValues[index].calories,
    workout_count: shuffledMockValues[index].workout_count,
    name: `${seat.first_name} ${seat.last_name}`,
    avatar_url: '',
    last_activity_at: '',
  }));

  return {
    average_day_strain: getAvgVal(records, 'day_strain'),
    average_calories: getAvgVal(records, 'calories'),
    average_workout_count: getAvgVal(records, 'workout_count'),
    strain_averages: records,
  };
};

export const getMemberOverviewData = (userId) => {
  const memberProfile = progressBaseSeats.filter(
    (seat) => seat.user_id === parseInt(userId, 10),
  )[0];
  // Ignoring so that property can be added to the memberProfile object
  // eslint-disable-next-line prefer-destructuring
  memberProfile.username = memberProfile.email.split('@')[0];

  const group = baseGroups[0];
  group.joined_date = getCurrentDate();

  return {
    cohort_member_details_list: [group],
    member_profile: memberProfile,
    pillar_overview: progressMemberProfile.pillar_overview,
  };
};
export const getMemberOverviewV2Data = (userId) => {
  const memberProfile = progressBaseSeats.filter(
    (seat) => seat.user_id === parseInt(userId, 10),
  )[0];
  // Ignoring so that property can be added to the memberProfile object
  // eslint-disable-next-line prefer-destructuring
  memberProfile.username = memberProfile.email.split('@')[0];

  const group = baseGroups[0];
  group.joined_date = getCurrentDate();

  return {
    cohort_member_details_list: [group],
    member_profile: memberProfile,
  };
};

const dateMinusHours = (numHours) => {
  const date = getCurrentDate();
  date.setHours(date.getHours() - numHours);
  return date;
};

export const getComplianceData = (groupId) => {
  const seatsToUse = getSeatsToUse(groupId);
  const complianceData = seatsToUse.map((seat, index) => {
    const latest_uploaded_metric = dateMinusHours(index * 6);
    return {
      ...seat,
      sensor_battery_level: getBatteryLevelValue(),
      last_activity_at: latest_uploaded_metric,
      latest_uploaded_metric,
      time_off_wrist_seconds: Math.floor(Math.random() * 86400),
      avatar_url: '',
    };
  });

  return complianceData;
};

export const getMemberDayOverview = () => dayOverviewData;

export const getMemberTrendsData = (memberId, startDate, endDate) => {
  // See how many days data is needed for
  const numDays = getNumDays(startDate, endDate);
  const mockDataTrend = mockMemberTrendsData.data_trend.slice(0, numDays);
  // Put the data together and return w/ dates
  for (let i = 0; i < numDays; i += 1) {
    const cycleDay = dayjs(startDate).add(i, 'day');
    const cycleStartDay = [cycleDay.get('month') + 1, cycleDay.get('date'), cycleDay.get('year')];
    mockDataTrend[i].cycle_start_day = cycleStartDay;
  }

  return {
    data_trend: mockDataTrend,
    memberId,
  };
};

const createMetricData = (startTime, endTime, mockValues) => {
  const numDays = getNumDays(startTime, endTime);
  // deep copy mockValues
  const clonedMockValues = { ...mockValues }.metrics.slice(0, numDays);
  for (let i = 0; i < numDays; i += 1) {
    // Update the cycle_start_day property to be within the date range passed in from the request
    const cycleDay = dayjs(startTime).add(i, 'day');
    const cycleStartDay = [cycleDay.get('month') + 1, cycleDay.get('date'), cycleDay.get('year')];
    clonedMockValues[i].cycle_start_day = cycleStartDay;
  }

  const sum = clonedMockValues.reduce((acc, val) => acc + val.metric_value, 0);
  return {
    average_value: sum / numDays,
    metrics: clonedMockValues,
  };
};

export const getStrainMetric = (startTime, endTime) => createMetricData(
  startTime,
  endTime,
  mockStrainMetric,
);

export const getCaloriesMetric = (startTime, endTime) => createMetricData(
  startTime,
  endTime,
  mockCaloriesMetric,
);

export const getAverageHRMetric = (startTime, endTime) => createMetricData(
  startTime,
  endTime,
  mockAverageHRMetric,
);

export const getRecoveryScore = (startTime, endTime) => createMetricData(
  startTime,
  endTime,
  mockRecoveryScore,
);

export const getSleepPerformance = (startTime, endTime) => createMetricData(
  startTime,
  endTime,
  mockSleepScores,
);
