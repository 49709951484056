import LandingPageWrapper from 'landingPageWrapper/landingPageWrapper';
import { Button, classes, Icon } from '@whoop/web-components';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from 'assets/icons/xRed.svg';
import { ReactNode } from 'react';
import styles from './messagePage.module.scss';

type MessagePageProps = {
  icon: string;
  title: string;
  subtitle?: ReactNode;
  showRedirectButton?: boolean;
  buttonLabel?: string;
  redirectLink?: string;
  includeFooter?: boolean;
};

function MessagePage({
  icon,
  title,
  subtitle,
  showRedirectButton = true,
  buttonLabel = 'Home Page',
  redirectLink = '/accounts',
  includeFooter = true,
}: MessagePageProps) {
  const navigate = useNavigate();

  const renderIcon = () => {
    switch (icon) {
      case 'error': {
        return <img className={styles.errorIcon} src={ErrorIcon} alt="Error" />;
      }
      default: {
        return <Icon className={styles.icon} name={icon} alt={icon} data-testid={icon} />;
      }
    }
  };

  return (
    <LandingPageWrapper includeFooter={includeFooter}>
      <div className={classes(styles.messagePage, 'whoop-typography')}>
        {renderIcon()}
        <h2>{title}</h2>
        <p className={classes(styles.subtitle, 'p3')}>{subtitle}</p>
        {showRedirectButton && (
          <Button
            label={buttonLabel}
            variant="secondary"
            theme="dark"
            onClick={() => {
              navigate(redirectLink);
            }}
          />
        )}
      </div>
    </LandingPageWrapper>
  );
}

export default MessagePage;
