import {
  Pillars, SleepDetailsMetric,
} from 'types/analytics';
import { ActivityOverview } from 'api/analyticsApi';
import useActivityById from 'api/hooks/useActivityById';
import styles from './statDetailsTiles.module.scss';
import ActivityStatTile from '../ActivityStatTile/activityStatTile';
import {
  RECOVERY_METRIC_MAP, SLEEP_METRIC_MAP, StatTileDetails, STRAIN_METRIC_MAP,
} from './statDetailsTilesConsts';

type StatDetailsTilesProps = {
  pillar: Pillars;
  activitySelected: ActivityOverview;
};

const PILLAR_TO_METRIC_MAP: { [key in Pillars]: { [x: string]: StatTileDetails } } = {
  [Pillars.STRAIN]: STRAIN_METRIC_MAP,
  [Pillars.RECOVERY]: RECOVERY_METRIC_MAP,
  [Pillars.SLEEP]: SLEEP_METRIC_MAP,
};

function StatDetailsTiles({ pillar, activitySelected }: StatDetailsTilesProps) {
  // Due to issue with Nivo rendering, we need to set the data to null before we load a new activity
  // So, if the activitySelected is null, we return empty data
  const { data: activity } = activitySelected
    ? useActivityById(pillar, activitySelected.id)
    : { data: { metrics: [] as SleepDetailsMetric[] } };

  if (activity) {
    return (
      <div className={styles.tilesColumn}>
        {activity.metrics.map((metric: SleepDetailsMetric) => {
          const { stat, icon, formatter } = PILLAR_TO_METRIC_MAP[pillar][metric.metric_name];
          return (
            <ActivityStatTile
              key={stat}
              icon={icon}
              stat={stat}
              fill={stat === STRAIN_METRIC_MAP.activity_duration.stat}
              value={
                formatter
                  ? formatter(metric.metric_value)
                  : metric.metric_value
              }
            />
          );
        })}
      </div>
    );
  }
  return null;
}

export default StatDetailsTiles;
