import { WhoopRole } from './whoopRole';

export enum DashboardRole {
  WHOOP_CSM = 'whoop_csm',
  ACCOUNT_ADMIN = 'account_admin',
  ACCOUNT_MANAGER = 'account_manager',
  GROUP_MANAGER = 'group_manager',
}

export enum PrivacyLevel {
  no_metrics = 'no_metrics',
  aggregate_metrics = 'aggregate_metrics',
  primary_metrics = 'primary_metrics',
  performance_metrics = 'performance_metrics',
  all_metrics = 'all_metrics',
}

export type DashboardUser = {
  salesforce_account_id: string;
  user_id: number;
  dashboard_role: WhoopRole;
  is_primary_owner: boolean;
  privacy_level: PrivacyLevel;
  role: DashboardRole;
  job_title_id?: string;
};
