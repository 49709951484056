import { CohortCollaborator, GroupMember } from 'types/cohort';
import { SeatDetail, SeatStatus } from 'types/seat';
import { Collaborator } from 'types/collaborator';

export enum AddGroupSteps {
  set_name_description = 'set_name_description',
  add_collaborators = 'add_collaborators',
  add_members = 'add_members',
  loading = 'loading',
  error = 'error',
}

export type AddGroupReducerState = {
  groupName: string;
  groupDescription: string;
  isGroupConcealed: boolean;
  error: Error;
  step: AddGroupSteps;
  cohortCollaborators: CohortCollaborator[];
  members: GroupMember[];
};

export type AddGroupReducerAction =
  | { type: 'next' }
  | { type: 'back' }
  | { type: 'handleInputText'; field: string; value: string }
  | { type: 'handleGroupConcealedChange'; value: boolean }
  | { type: 'handleCollaboratorsChange'; value: CohortCollaborator[] }
  | { type: 'handleMembersChange'; value: GroupMember[] }
  | { type: 'error'; payload: Error };

export const addGroupReducer = (
  state: AddGroupReducerState,
  action: AddGroupReducerAction,
): AddGroupReducerState => {
  switch (action.type) {
    case 'handleInputText':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'handleCollaboratorsChange':
      return {
        ...state,
        cohortCollaborators: action.value,
      };
    case 'handleMembersChange':
      return {
        ...state,
        members: action.value,
      };
    case 'handleGroupConcealedChange':
      return {
        ...state,
        isGroupConcealed: action.value,
      };
    case 'next':
      if (state.step === AddGroupSteps.set_name_description) {
        return {
          ...state,
          step: AddGroupSteps.add_collaborators,
        };
      }
      if (state.step === AddGroupSteps.add_collaborators) {
        return {
          ...state,
          step: AddGroupSteps.add_members,
        };
      }
      if (state.step === AddGroupSteps.add_members) {
        return {
          ...state,
          step: AddGroupSteps.loading,
        };
      }
      break;

    case 'back':
      if (state.step === AddGroupSteps.add_collaborators) {
        return {
          ...state,
          step: AddGroupSteps.set_name_description,
        };
      }
      if (state.step === AddGroupSteps.add_members) {
        return {
          ...state,
          step: AddGroupSteps.add_collaborators,
        };
      }
      break;

    case 'error':
      return {
        ...state,
        error: action.payload,
        step: AddGroupSteps.error,
      };
    default:
      return state;
  }

  return state;
};

export const collaboratorToCohortCollaborators = (
  collaborator: Collaborator[],
): CohortCollaborator[] => collaborator.map((user) => {
  const firstAndLast = user.name.split(' ');
  return {
    user_id: user.user_id,
    first_name: firstAndLast[0],
    last_name: firstAndLast[1],
    avatar_url: user.avatar_url,
    privacy_level: user.privacy_level,
    email: user.email,
  };
});

export const seatDetailsToGroupMembers = (
  details: SeatDetail[],
): GroupMember[] => details.filter((detail) => detail.status === SeatStatus.Active)
  .map((detail) => ({
    user_id: detail.user_id,
    seat_id: detail.id,
    first_name: detail.first_name,
    last_name: detail.last_name,
    strap_serial: detail.strap_serial,
    last_activity_at: detail.last_activity_at,
    email: detail.email,
    seat_status: detail.status,
    activated_on: detail.activated_on,
    opted_in: detail.opted_in,
  }));
