import { classes, Icon } from '@whoop/web-components';
import DefaultAvatar from 'assets/hub-default-user-profile.svg';
import FeatureFlag from 'components/featureFlag';
import StatusBadge from 'components/statusBadge';
import { N7, P1, SUBTITLE_2 } from 'components/text';
import { shortDateWithTimeFormat, utcTableFormat } from 'helpers/dateUtils';
import { ReactElement, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { CohortCollaborator, isCohort } from 'types/cohort';
import { Collaborator, DescriptionDropdownOption, isCollaborator } from 'types/collaborator';
import { Hub, HubUser, isHubListPayload } from 'types/hub';
import { SeatDetail } from 'types/seat';
import IconTooltip from 'components/IconTooltip/iconTooltip';
import { dashboardRoleDisplay } from 'accountDetails/collaboratorsPage/roleDropdown/util';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import { PRIVACY_LEVEL_NAME_MAP } from 'accountDetails/types';
import DescriptionDropdown from './descriptionDropdown/descriptionDropdown';
import styles from './table.module.scss';
import { getIdentifier } from './utils';

export const dateCell = (
  cellProps: CellProps<any>,
  className: string = '',
): ReactElement => {
  const { cell, row } = cellProps;
  let date: string = null;
  let isValid: boolean = false;

  if (cell.value) {
    date = utcTableFormat(cell.value);
    isValid = date !== utcTableFormat(new Date(0));
    if (row.original.status === 'invited') {
      isValid = false;
    }
  }
  return <N7 className={className}>{isValid ? date : '--'}</N7>;
};

export const statusBadgeCell = (cellProps: CellProps<SeatDetail | HubUser | Hub>) => (
  <StatusBadge status={cellProps.row.original.status} />
);

export const dateWithTimeCell = ({
  value,
}: CellProps<any>): ReactElement => <N7>{value ? shortDateWithTimeFormat(new Date(value)) : '--'}</N7>;

export const normalCell = (cellProps: CellProps<any>): ReactElement => {
  const { value } = cellProps.cell;
  return <N7>{value}</N7>;
};

export const optOutIconCell = () => (
  <IconTooltip
    icon="community"
    moreInfoText={{
      verticalOffset: 55,
      header: '',
      details: 'This user is not sharing their individual data.',
    }}
  />
);

export const hideAverageCell = () => (
  <IconTooltip
    icon="hide_privacy"
    moreInfoText={{
      verticalOffset: 125,
      header: '',
      details: (
        <div>
          To protect the member privacy, team averages are hidden when
          <ul className={styles.hideAverageCell}>
            <li>
              A group has less than 4 members
            </li>
            <li>
              A group has only one opt-out member
            </li>
          </ul>
        </div>
      ),
    }}
  />
);

export const concealedIconNormalCell = (
  cellProps: CellProps<any>,
  autoConceal: boolean,
): ReactElement => {
  const { value, row } = cellProps.cell;
  return (
    <div className={styles.concealedIconRow}>
      <N7>{value}</N7>
      <FeatureFlag flagName="enterprise-fe-concealed-mode-toggle">
        {(row.original.concealed || autoConceal) && <Icon name="score_hidden" className={styles.concealedIcon} />}
      </FeatureFlag>
    </div>
  );
};

export const accountCell = (cellProps: CellProps<Collaborator>) => (
  <p>{`All ${cellProps.row.original.privacy_level}`}</p>
);

const getSubtitle = (row: any) => {
  if (isCohort(row.original)) {
    return row.original.about;
  } if (isHubListPayload(row.original)) {
    return row.original.description;
  }

  return row.original.email;
};

export const nameSubtitleCell = (
  cellProps: CellProps<any>,
  titleVal?: string,
  showSubtitle?: boolean,
  subtitleVal?: string,
): ReactElement => {
  const { row, loading } = cellProps;
  const title: string = titleVal || cellProps.cell.value;
  const subtitle = subtitleVal || getSubtitle(row);

  return (
    <div>
      <P1>{title || '--'}</P1>
      {!showSubtitle && (
        <SUBTITLE_2
          className={`${styles.memberEmail} ${loading ? styles.loading : ''}`}
        >
          {subtitle || '--'}
        </SUBTITLE_2>
      )}
    </div>
  );
};

export const nameSubtitleWithPictureCell = (
  cellProps: CellProps<any>,
  titleVal?: string,
): ReactElement => {
  const { row, loading } = cellProps;
  const title: string = titleVal || cellProps.cell.value;
  let subtitle;
  if (isCohort(row.original)) {
    subtitle = row.original.about;
  } else if (isHubListPayload(row.original)) {
    subtitle = row.original.description;
  } else {
    subtitle = row.original.email;
  }
  return (
    <div className={styles.pictureContainer}>
      <img src={row.original?.avatar_url ? row.original?.avatar_url : DefaultAvatar} alt="my_account" className={styles.avatar} />
      <div className={styles.textContainer}>
        <P1>{title || '--'}</P1>
        <SUBTITLE_2
          className={`${styles.memberEmail} ${loading ? styles.loading : ''}`}
        >
          {subtitle || '--'}
        </SUBTITLE_2>
      </div>
    </div>
  );
};

export const nameWithAvatarCell = (cellProps: CellProps<Collaborator | CohortCollaborator>) => {
  let name = '';
  if (isCollaborator(cellProps.row.original)) {
    name = cellProps.row.original.name;
  } else {
    name = `${cellProps.row.original.first_name} ${cellProps.row.original.last_name}`;
  }
  return (
    <div className={styles.avatarCell}>
      <div className={styles.avatar}>
        {cellProps.row.original.avatar_url ? <img alt="Avatar" src={cellProps.row.original.avatar_url} className={styles.avatarSize} /> : <Icon name="my_account" />}
      </div>
      {name}
    </div>
  );
};

export const breakdownTableNameWithAvatarCell = (cellProps: CellProps<any>) => (
  <div className={styles.avatarCell}>
    <div className={styles.avatar}>
      {cellProps.row.original.avatar_url ? <img alt="Avatar" src={cellProps.row.original.avatar_url} className={styles.largerAvatarSize} /> : <img src={DefaultAvatar} alt="my_account" className={styles.largerAvatarSize} />}
    </div>
    <N7>
      {getIdentifier(cellProps.row.original)}
    </N7>
  </div>
);

type EditCellProps<S> = {
  row: CellProps<any>;
  update: (row: S) => void;
  updateField: keyof S;
};

export function EditCell<S extends unknown>({ row, update, updateField }: EditCellProps<S>) {
  const [editMode, setEditMode] = useState(false);
  const [editDisplay, setEditDisplay] = useState('');

  useEffect(() => {
    setEditDisplay(row.value);
  }, [row]);

  const handleSubmit = async () => {
    setEditMode(false);
    const data = row.row.original;
    data[updateField] = editDisplay;
    try {
      update(data);
    } catch (e) {
      setEditDisplay(row.value);
    }
  };

  const cancel = () => {
    setEditDisplay(row.value);
  };

  return (
    <div
      className={styles.editCellContainer}
      onClick={() => {
        if (!editMode) setEditMode(true);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && editMode) handleSubmit();
      }}
      onBlur={() => {
        handleSubmit();
      }}
      role="presentation"
    >
      <div className={styles.editCell}>
        {editMode ? (
          <input
            value={editDisplay}
            onChange={(e) => setEditDisplay(e.target.value)}
            placeholder=""
            className={styles.editCellInput}
            size={editDisplay ? editDisplay.length : 0}
          />
        ) : (
          <div className={`${!editMode && styles.editCellContainerHover}`}>
            <p className={styles.editCellText}>{editDisplay}</p>
            <Icon
              onClick={cancel}
              variant="normal"
              name="edit"
              className={styles.editIcon}
            />
          </div>
        )}
      </div>

    </div>
  );
}

export function DropdownSelectCell(
  cellProps: CellProps<Collaborator>,
  field: 'role' | 'privacy_level' | 'job_title_id',
  options: DescriptionDropdownOption[],
  disableEdit: boolean,
  isUser?: boolean,
) {
  const { row } = cellProps;

  // CSMs do not have a job title so we hardcode it for them
  if (row.original.role === DashboardRole.WHOOP_CSM) {
    return <div className={styles.dropdownStatic} data-testid={`$non-editable-${field}`}>WHOOP CSM</div>;
  }

  // @ts-ignore
  const value = row.original[field];
  if (disableEdit || isUser) {
    let optionName;
    if (field === 'job_title_id') {
      optionName = options.find((o) => o.value === value)?.name;
    } else if (field === 'role') {
      optionName = dashboardRoleDisplay[value as DashboardRole];
    } else {
      optionName = PRIVACY_LEVEL_NAME_MAP[value as PrivacyLevel];
    }
    return <div className={styles.dropdownStatic} data-testid={`$non-editable-${field}`}>{optionName ?? 'Unassigned'}</div>;
  }
  return (
    <DescriptionDropdown
      options={options}
      field={field}
      cellProps={cellProps}
    />
  );
}

type Cell = {
  value: string;
};

export function IconCell({ value }: Cell) {
  return (
    <div className={styles.iconContainer}>
      <Icon data-testid={value} className={styles.icon} name={value} />
    </div>
  );
}

export function TextCell({ value }: Cell) {
  return <div className={classes('whoop-typography', 'c2', styles.textCell)}>{value}</div>;
}

export const widgetNameAvatarCell = (
  cellProps: CellProps<{ avatar_url: string; name: string }>,
) => (
  <div className={styles.widgetAvatar}>
    {cellProps.row.original.avatar_url ? <img alt="Avatar" src={cellProps.row.original.avatar_url} className={styles.avatar} /> : <img src={DefaultAvatar} alt="my_account" className={styles.avatar} />}
    <span className={styles.widgetLeaderboardName}>
      {cellProps.row.original.name}
    </span>
  </div>
);

export const groupLandingMembersCell = (
  cellProps: CellProps<{ name: string; id: number; memberCount: number }>,
) => (
  <div className={classes(styles.groupLandingCell, 'whoop-typography')}>
    {cellProps.row.original.name}
    <div className={styles.numMembersRow}>
      <Icon name="community" className={styles.communityIcon} />
      {`${cellProps.row.original.memberCount} members`}
    </div>
  </div>
);
