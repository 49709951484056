import {
  BarDatum, BarItemProps, ResponsiveBar,
} from '@nivo/bar';
import { AxisTickProps } from '@nivo/axes';
import { Colors } from '@whoop/web-components';
import { InsightsYAxisTick, ProfileAxisTick } from 'dataVisualizations/axis/axis';
import DefaultAvatar from 'assets/hub-default-user-profile.svg';
import { InsightsLabeledBarComponent } from 'dataVisualizations/bars/bars';
import { DAILY_GRAPHS_Y_AXIS_VALUES } from 'insights/components/widget/widgetFormatter';
import { CustomAvgMarker } from 'dataVisualizations/layers/layers';
import {
  useEffect, useRef, useState,
} from 'react';
import { WidgetType } from '../widget/widget';
import styles from './dailyBarGraph.module.scss';

export interface BarData {
  metricValue: number;
  user_id: number;
  name: string;
  avatar_url: string;
}

export type DailyBarGraphProps = {
  widgetType: WidgetType;
  data: BarData[];
  avgVal: number;
  hoverStateFFEnabled: boolean;
  highlightedIndex: string;
  setHighlightedIndex: (highlightedIndex: string) => void;
};

function DailyBarGraph({
  widgetType, data, avgVal, hoverStateFFEnabled, highlightedIndex, setHighlightedIndex,
}: DailyBarGraphProps) {
  const graphRef = useRef(null);
  const [width, setWidth] = useState(0);

  const graphWidthObserver = new ResizeObserver((entries) => {
    setWidth(entries[0].contentRect?.width);
  });

  useEffect(() => {
    graphWidthObserver.observe(graphRef?.current);

    return () => {
      graphWidthObserver.disconnect();
    };
  }, []);

  const numberOfRenderedUsers = data.filter((users) => users.metricValue).length;
  const profilePictureHeight = 24;
  const minPadding = 8;
  const shouldSimplifyGraph = (numberOfRenderedUsers * (profilePictureHeight + minPadding)) > width;

  const profileMap: Map<number, string> = new Map();
  const CustomMarkerLayer = CustomAvgMarker(avgVal, widgetType);

  // Filter out null values
  const graphData = data.filter((row: BarData) => row.metricValue !== null);

  // For each row, add user_id and avatar_url to the map
  graphData.forEach((row: BarData) => {
    profileMap.set(row.user_id, row.avatar_url ? row.avatar_url : DefaultAvatar);
  });

  // 60 is the minimum value the max can be
  const maxSleepDebt = widgetType === WidgetType.SLEEP_DEBT
    ? Math.max(60, ...graphData.map((row: BarData) => row.metricValue)) : 0;

  const yAxisValues = DAILY_GRAPHS_Y_AXIS_VALUES[widgetType](maxSleepDebt);

  // Disable hover when there are more than 100 data points.
  const showHover = hoverStateFFEnabled && graphData.length <= 100;

  return (
    <div ref={graphRef} className={styles.barGraph} onMouseLeave={() => setHighlightedIndex(null)}>
      <ResponsiveBar
        data={graphData as unknown as BarDatum[]}
        keys={['metricValue']}
        indexBy="user_id"
        margin={{
          top: 20, right: 0, bottom: shouldSimplifyGraph ? 12 : 40, left: 40,
        }}
        minValue={0}
        maxValue={yAxisValues[yAxisValues.length - 1]}
        enableLabel={false}
        enableGridX={false}
        enableGridY
        // Sets the padding horizontally between individual bars
        padding={graphData.length < 200 ? 0.7 : 0}
        gridYValues={yAxisValues}
        axisLeft={{
          tickValues: yAxisValues,
          tickSize: 0,
          renderTick: (tick: AxisTickProps<string>) => InsightsYAxisTick(
            tick.x,
            tick.y,
            tick.value,
            widgetType,
          ),
        }}
        axisBottom={shouldSimplifyGraph ? null : {
          tickValues: graphData.map(
            (barData: BarData) => barData.user_id,
          ),
          tickSize: 0,
          renderTick: (tick: AxisTickProps<string>) => ProfileAxisTick(
            tick.x,
            tick.y,
            tick.value,
            profileMap,
            showHover ? highlightedIndex : null,
            showHover ? setHighlightedIndex : () => {},
          ),
        }}
        theme={{
          grid: {
            line: {
              stroke: Colors.whiteAlpha200,
              strokeWidth: 1,
            },
          },
        }}
        barComponent={
          (barComponent: BarItemProps<BarDatum>) => InsightsLabeledBarComponent(
            barComponent.bar,
            widgetType,
            showHover ? highlightedIndex : null,
            showHover ? setHighlightedIndex : () => {},
            shouldSimplifyGraph,
          )
        }
        markers={!avgVal ? [] : [
          {
            axis: 'y',
            value: avgVal,
            lineStyle: { stroke: Colors.white, strokeWidth: 1, strokeDasharray: '4 4' },
            legend: '',
          },
        ]}
        layers={['grid', 'axes', CustomMarkerLayer, 'markers', 'bars', 'legends', 'annotations']}
      />
    </div>
  );
}

export default DailyBarGraph;
