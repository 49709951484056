import { jobTitleClient } from 'api';
import { JobTitle } from 'api/jobTitleApi';
import { useAccounts } from 'context/account-context';
import {
  useEffect, useState,
} from 'react';

export function useJobTitles() {
  const { account } = useAccounts();
  const [jobTitles, setJobTitles] = useState<JobTitle[]>([]);

  const sortJobTitles = (titleA: JobTitle) => (!titleA.privacy_level ? 1 : 0);

  useEffect(() => {
    const init = async () => {
      let fetchedJobTitles: JobTitle[] = [];
      if (account?.id) {
        fetchedJobTitles = await jobTitleClient.getAccountJobTitles();
      }
      setJobTitles(fetchedJobTitles.sort(sortJobTitles));
    };

    init();
  }, [account?.id]);

  return jobTitles;
}
