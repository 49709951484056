import { Cohort, CohortCollaborator } from 'types/cohort';
import { baseAccount } from './data';
import { baseSeats } from './seats';

export const PRIMARY_GROUP_NAME = 'Primary Group';
export const SALES_GROUP_NAME = 'Sales';
export const FINANCE_GROUP_NAME = 'Finance';
export const FIELD_HOCKEY_GROUP_NAME = "Women's Field Hockey";
export const SWIM_TEAM_NAME = "Men's Swim Team";
export const SURGEONS_GROUP_NAME = 'Surgeons';

export const seatsInGroup = [
  { groupId: 1, seatIds: baseSeats.filter((seat) => seat.status === 'active').map((seat) => seat.id) },
  { groupId: 2, seatIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
  { groupId: 3, seatIds: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20] },
  { groupId: 4, seatIds: [1, 2, 4, 6, 8, 12, 15, 18, 19, 20] },
  { groupId: 5, seatIds: [3, 5, 7, 9, 10, 11, 13, 14, 16, 17] },
  { groupId: 6, seatIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
];

const groupTemplates = [
  {
    name: PRIMARY_GROUP_NAME,
    collaborators: [] as CohortCollaborator[],
    about: 'This contains all members.',
    is_primary: true,
    created_at: '10/08/2021',
  },
  {
    name: FINANCE_GROUP_NAME,
    collaborators: [] as CohortCollaborator[],
    about: 'Finance team managed by Ben Carson',
    created_at: '05/24/2022',
  },
  {
    name: SALES_GROUP_NAME,
    collaborators: [{
      user_id: 1001,
      first_name: 'Cassie',
      last_name: 'Bell',
      avatar_url: '',
      email: 'cassie.bell@fosterjefferson.com',
      privacy_level: 'no_metrics',
    }] as CohortCollaborator[],
    about: 'Sales team managed by Brad Fitzgerald',
    created_at: '07/01/2022',
  },
  {
    name: SWIM_TEAM_NAME,
    collaborators: [] as CohortCollaborator[],
    about: "Group for men's swimming athletes",
    created_at: '07/31/2022',
  },
  {
    name: FIELD_HOCKEY_GROUP_NAME,
    collaborators: [] as CohortCollaborator[],
    about: "Group for women's field hockey team",
    created_at: '10/08/2021',
  },
  {
    name: SURGEONS_GROUP_NAME,
    collaborators: [] as CohortCollaborator[],
    about: 'Surgeon rotation members managed by Lindsay Fox',
    created_at: '01/05/2022',
  },
];

export const baseGroups: Cohort[] = [...groupTemplates.map((group, i) => ({
  ...group,
  id: i + 1,
  cohort_id: i + 1,
  community_id: i + 1,
  salesforce_account_id: baseAccount.id,
  legacy_team_id: i + 200,
  member_count: seatsInGroup.find((g) => g.groupId === i + 1).seatIds.length,
  concealed: false,
}))];

export default baseGroups;
