import TrendIndicatorBox from 'insights/components/trendIndicatorBox/trendIndicatorBox';
import { SENTIMENT } from 'api/groupLandingApi';
import styles from './groupsTableCells.module.scss';
import IconTooltip from '../../../components/IconTooltip/iconTooltip';

export type Diff = {
  diff: number;
  sentiment: SENTIMENT;
};

type TrendCellProps = {
  roundedString: string;
  diffString: string;
  diff: Diff;
  noDataText: string;
};

function TrendCell({
  roundedString, diffString, diff, noDataText,
}: TrendCellProps) {
  if (roundedString) {
    return (
      <div className={styles.percentWithTrend}>
        {roundedString}
        <TrendIndicatorBox diff={diff.diff} parsedDiff={diffString} trend={diff.sentiment} />
      </div>
    );
  }
  return <IconTooltip hoverableText={noDataText} moreInfoText={{ header: '', details: 'There is no data for this range', verticalOffset: 55 }} />;
}

export function PercentTrendCell(value: number, diffObj: Diff) {
  const { diff } = diffObj;
  const roundedString = value ? `${Math.round(value)}%` : null;
  const diffString = (diff || diff === 0) ? `${Math.round(Math.abs(diff))}%` : '--%';
  return <TrendCell roundedString={roundedString} diffString={diffString} diff={diffObj} noDataText="--%" />;
}

export function StrainTrendCell(value: number, diffObj: Diff) {
  const { diff } = diffObj;
  // eslint-disable-next-line react/destructuring-assignment
  const roundedString = value ? value.toFixed(1) : null;
  const diffString = (diff || diff === 0) ? (Math.abs(diff)).toFixed(1) : '--';
  return <TrendCell roundedString={roundedString} diffString={diffString} diff={diffObj} noDataText="--" />;
}
