import { Icon, classes } from '@whoop/web-components';
import { PrivacyLevel } from 'types/dashboardUser';
import styles from './privacyLevelTable.module.scss';
import usePrivacyLevelTable, { PrivacyLevelIndicator } from './usePrivacyLevelTable/usePrivacyLevelTable';

type PrivacyLevelTableProps = {
  privacyLevel: PrivacyLevel;
  changePrivacyLevel: (p: PrivacyLevel) => void;
};
function PrivacyLevelTable({ privacyLevel, changePrivacyLevel }: PrivacyLevelTableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedCol,
  } = usePrivacyLevelTable(privacyLevel);

  const onClick = (p: string) => {
    changePrivacyLevel(p as PrivacyLevel);
  };

  const legend = [
    {
      icon: PrivacyLevelIndicator.CHECK,
      text: 'Share individual data',
    },
    {
      icon: PrivacyLevelIndicator.AGGREGATE,
      text: 'Share group average',
    },
    {
      icon: PrivacyLevelIndicator.CROSS,
      text: 'Not shared',
    },
  ];

  return (
    <div className={styles.wrapper}>
      <table className={classes('whoop-typography', styles.table)} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={classes(column.id === selectedCol && styles.activeCell)}
                  {...column.getHeaderProps()}
                >
                  <label
                    htmlFor={column.id}
                    className={
                      classes(
                        'c2',
                        'font-bold',
                        column.id === 'data_type' ? styles.alignLeft : styles.headerContainer,
                      )
                    }
                  >
                    {column.render('Header')}
                    {
                      column.id !== 'data_type'
                      && (
                        <>
                          <input
                            id={column.id}
                            onChange={() => onClick(column.id)}
                            checked={column.id === selectedCol}
                            className={styles.radioInput}
                            type="radio"
                            name="radio"
                          />
                          <span className={styles.inputIcon} />
                        </>
                      )
                    }
                  </label>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={styles.tableBody} {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (<td className={classes(cell.column.id === selectedCol && styles.activeCell)} {...cell.getCellProps()}>{cell.render('Cell')}</td>))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className={styles.tableFooter}>
        {
          legend.map((key) => (
            <div className={classes('whoop-typography', styles.key)} key={key.icon}>
              <Icon className={styles.icon} name={key.icon} />
              <span className={classes('c2', 'font-semibold')}>{key.text}</span>
            </div>
          ))
        }
      </div>
    </div>

  );
}

export default PrivacyLevelTable;
