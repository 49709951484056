import {
  DefaultBodyType, PathParams, ResponseComposition, rest, RestContext, RestRequest,
} from 'msw';
import {
  getMetricDataV2,
} from 'mocks/actions/memberOverviewActions';
import { getDelayVal, mswBaseUri } from 'mocks/utils/utils';
import { MetricType } from 'types/analytics';

// extracting handle generic handler instead of using param :pillar because
// when pillar is a path parameter like :pillar MSW gets overzealous and
// grabs any request with a path like v2/*/*
const genericMetricHandler = (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
) => {
  const startDate = req.url.searchParams.get('startDate');
  const endDate = req.url.searchParams.get('endDate');

  const { metricType } = req.params;
  return res(
    ctx.delay(getDelayVal(50)),
    ctx.status(200),
    ctx.json(getMetricDataV2(<MetricType>metricType, startDate, endDate)),
  );
};

export const memberOverviewHandlers = [
  rest.get(mswBaseUri('v2/strain-overview/:metricType'), genericMetricHandler),

  rest.get(mswBaseUri('v2/sleep-overview/:metricType'), genericMetricHandler),

  rest.get(mswBaseUri('v2/recovery-overview/:metricType'), genericMetricHandler),
];

export default memberOverviewHandlers;
