import {
  STRAIN_MAX,
} from 'progress/progressPageUtils';
import {
  MemberTrend, StrainRecoveryPointData, StrainRecoveryPoints, TrendKey,
} from 'types/analytics';
import {
  DatumValue, Serie,
} from '@nivo/line';
import { Colors } from '@whoop/web-components';
import { getMonthDayValues } from 'progress/utils/date';
import { convertStrainToString } from 'progress/utils/numberFormatter';
import { formatDate } from 'progress/profile/profileUtils';

// Converts a caled recovery point value too a recovery percentage
export const toRecoveryScorePercentage = (recovery: DatumValue) => Number(
  ((Number(recovery) * 100) / STRAIN_MAX).toFixed(),
);

// Convert date into readable format for display i.e. 8/7/2022 -> Sun, Aug 7
export const getFormattedMonthDayValues = (date: string) => {
  const monthDayValues = getMonthDayValues(date);
  return `${monthDayValues.day}, ${monthDayValues.month} ${monthDayValues.dayNum}`;
};

// Converts points data into sorted buckets of data for the bar chart
export const parseStrainBarData = (data: StrainRecoveryPointData[]) => {
  const strainBucket1: any = {
    x: '<10.0',
    count: 0,
  };
  const strainBucket2: any = {
    x: '10.1-14.0',
    count: 0,
  };
  const strainBucket3: any = {
    x: '14.1-18.0',
    count: 0,
  };
  const strainBucket4: any = {
    x: '18.1-21.0',
    count: 0,
  };

  data.forEach((point: StrainRecoveryPointData) => {
    if (point.value <= 10) {
      strainBucket1[point.date] = 1;
      strainBucket1.count += 1;
    } else if (point.value <= 14) {
      strainBucket2[point.date] = 1;
      strainBucket2.count += 1;
    } else if (point.value <= 18) {
      strainBucket3[point.date] = 1;
      strainBucket3.count += 1;
    } else {
      strainBucket4[point.date] = 1;
      strainBucket4.count += 1;
    }
  });
  return [strainBucket1, strainBucket2, strainBucket3, strainBucket4];
};

// Converts points data into sorted buckets of data for the bar chart
export const parseRecoveryBarData = (data: StrainRecoveryPointData[]) => {
  const recoveryBucket1: any = {
    x: '0-33%',
    count: 0,
  };
  const recoveryBucket2: any = {
    x: '34-66%',
    count: 0,
  };
  const recoveryBucket3: any = {
    x: '67-100%',
    count: 0,
  };

  data.forEach((point: StrainRecoveryPointData) => {
    const recoveryVal = toRecoveryScorePercentage(point.value);
    if (recoveryVal <= 33) {
      recoveryBucket1[point.date] = 1;
      recoveryBucket1.count += 1;
    } else if (recoveryVal <= 66) {
      recoveryBucket2[point.date] = 1;
      recoveryBucket2.count += 1;
    } else {
      recoveryBucket3[point.date] = 1;
      recoveryBucket3.count += 1;
    }
  });
  return [recoveryBucket1, recoveryBucket2, recoveryBucket3];
};

// Creates an array of string days inclusive between two dates
// Used for bar chart keys and data
export const getDaysInRange = (start: Date, end: Date): Date[] => {
  const inBetween = [];
  for (let i = new Date(start); i <= new Date(end); i.setDate(i.getDate() + 1)) {
    inBetween.push(new Date(i));
  }
  return inBetween;
};

// Transforms StrainRecoveryPoints to Serie[] for Nivo to read
export const getTransformedSeries = (points: StrainRecoveryPoints[]): Serie[] => {
  const transformedSeries = points.map((point) => {
    const datum = point.pointData.map((data) => (
      {
        x: data.date,
        y: data.value === null ? null : convertStrainToString(data.value),
        key: point.pointType,
      }
    )).sort((a, b) => (new Date(a.x) < new Date(b.x) ? -1 : 1));
    const serie = { id: point.pointType, data: datum };
    return serie;
  });

  return transformedSeries;
};

// Sets line color to strain color or grey for recovery lines
export const LineColor = (line: Serie) => {
  if (line.id === TrendKey.Strain) {
    return Colors.strainBlue;
  }
  return Colors.strainBlueAlpha400;
};

// Converts api data trend data into data that can be used for bar charts
export const getBarDataFromTrend = (daysInRange: Date[], data_trend: MemberTrend[]) => {
  const formattedDatesInRange = daysInRange.map(formatDate);
  const trendMap = new Map(data_trend.map((t) => [
    formatDate(new Date((t.cycle_start_day as unknown) as Date)), t,
  ]));
  const strainData: StrainRecoveryPointData[] = [];
  const recoveryData: StrainRecoveryPointData[] = [];

  formattedDatesInRange.forEach((day) => {
    const datePoint = day;

    if (trendMap.get(day)?.pillar_data_list.length !== 0) {
      trendMap.get(day).pillar_data_list.forEach((t) => {
        if (t.trend_key === TrendKey.Strain) {
          strainData.push({ date: datePoint, value: t.trend_value });
          // When has strain data but no recovery data for the day, add a null value for recovery
          if (trendMap.get(day).pillar_data_list.length === 1) {
            recoveryData.push({ date: datePoint, value: null });
          }
        } else if (t.trend_key === TrendKey.Recovery) {
          const scaledRecovery = Number(((t.trend_value * 21) / 100));
          recoveryData.push({ date: datePoint, value: scaledRecovery });
          // When has recovery data but no strain data for the day, add a null value for strain
          if (trendMap.get(day).pillar_data_list.length === 1) {
            strainData.push({ date: datePoint, value: null });
          }
        }
      });
    } else {
      const noData: StrainRecoveryPointData = { date: day, value: null };
      strainData.push(noData);
      recoveryData.push(noData);
    }
  });

  return { strainData, recoveryData };
};
