import { PrivacyLevel, DashboardRole } from 'types/dashboardUser';
import { Collaborator, CSMUser, InviteStatus } from 'types/collaborator';
import { invitedAndBouncedCollaborators } from './data';

export const currentDashboardUser: Collaborator = {
  name: 'John Doe',
  email: 'JohnDoe@whoop.com',
  role: DashboardRole.WHOOP_CSM,
  privacy_level: PrivacyLevel.all_metrics,
  avatar_url: '',
  user_id: 1000,
  status: InviteStatus.accepted,
};

export const csmUser: CSMUser = {
  first_name: 'John',
  last_name: 'Doe',
  email: 'JohnDoe@whoop.com',
  avatar_url: '',
  user_id: 1000,
};

export const csmCollaborator: Collaborator = {
  name: 'Jack Clark',
  email: 'jack.clark@whoop.com',
  role: DashboardRole.WHOOP_CSM,
  privacy_level: PrivacyLevel.performance_metrics,
  avatar_url: '',
  user_id: 10000,
  status: InviteStatus.accepted,
};

const collaboratorTemplates = [
  {
    first_name: 'Cassie',
    last_name: 'Bell',
    role: DashboardRole.ACCOUNT_ADMIN,
    privacy_level: PrivacyLevel.no_metrics,
  },
  {
    first_name: 'Brian',
    last_name: 'Carson',
    role: DashboardRole.GROUP_MANAGER,
    privacy_level: PrivacyLevel.no_metrics,
  }, {
    first_name: 'Brad',
    last_name: 'Fitzgerald',
    role: DashboardRole.GROUP_MANAGER,
    privacy_level: PrivacyLevel.aggregate_metrics,
  }, {
    first_name: 'Jeanne',
    last_name: 'Bryan',
    role: DashboardRole.GROUP_MANAGER,
    privacy_level: PrivacyLevel.aggregate_metrics,
  }, {
    first_name: 'Julie',
    last_name: 'Joseph',
    role: DashboardRole.GROUP_MANAGER,
    privacy_level: PrivacyLevel.aggregate_metrics,
  }, {
    first_name: 'Lindsay',
    last_name: 'Fox',
    role: DashboardRole.GROUP_MANAGER,
    privacy_level: PrivacyLevel.aggregate_metrics,
  },
];

export const collaborators: Collaborator[] = [...collaboratorTemplates.map((template, i) => {
  const { first_name, last_name, ...collaborator } = template;
  return {
    ...collaborator,
    name: `${template.first_name} ${template.last_name}`,
    email: `${template.first_name.toLowerCase()}.${template.last_name.toLowerCase()}@fosterjefferson.com`,
    avatar_url: '',
    user_id: 1000 + i + 1,
    status: InviteStatus.accepted,
  };
}), csmCollaborator, currentDashboardUser, ...invitedAndBouncedCollaborators];

export default collaborators;
