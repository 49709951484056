import { Navigate, useParams } from 'react-router-dom';
import { PrivacyLevel } from 'types/dashboardUser';
import { usePrivacy } from 'context/privacy-context';
import GroupsPage from './groupsPage/groupsPage';
import IndividualsPage from './individualsPage/individualsPage';
import GroupDetailsView from './groupDetailsView/groupDetailsView';
import { InsightsParams, GroupsPageTabs } from './components/groupsHeader/groupsHeader';

function InsightsTabSelect() {
  const { tab, groupId, accountId } = useParams<InsightsParams>();
  const { isInPrivacyLevel } = usePrivacy();
  const redirect = `/accounts/${accountId}/insights/${GroupsPageTabs.GROUPS}`;

  if (tab === GroupsPageTabs.INDIVIDUALS && isInPrivacyLevel(PrivacyLevel.primary_metrics)) {
    return <IndividualsPage />;
  }
  if (tab === GroupsPageTabs.GROUPS && groupId) {
    return <GroupDetailsView />;
  }

  if (tab === GroupsPageTabs.GROUPS) {
    return <GroupsPage />;
  }

  return <Navigate to={redirect} replace />;
}

export default InsightsTabSelect;
