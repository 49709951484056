import { classes, Colors } from '@whoop/web-components';
import styles from '../bars.module.scss';

type BarLabelProp = {
  width: number;
  value: number | string;
  topBarComponentKey?: string | number;
  color?: string;
  barKey?: string;
  customFormat: (val: number) => string;
  className?: string;
};

export function BarLabel({
  width,
  color,
  value,
  topBarComponentKey,
  barKey,
  customFormat,
  className,
}: BarLabelProp) {
  const v = value as number;
  // Hides 0 values from the bar chart label
  const getValToDisplay = (val: number) => {
    if (val === 0) {
      return null;
    }
    return val;
  };

  // if stacked bar chart render text only on top bar
  if (topBarComponentKey) {
    if (topBarComponentKey === barKey) {
      return (
        <text
          textAnchor="middle"
          className={classes(styles.barLabel, className)}
          x={width / 2}
          y={-6}
          fill={Colors.blackAlpha900}
        >
          { customFormat
            ? customFormat(v)
            : getValToDisplay(v)}
        </text>

      );
    }
    return null;
  }
  return (
    <text
      textAnchor="middle"
      className={classes(styles.barLabel, className)}
      x={width / 2}
      y={-6}
      fill={color ?? Colors.strainBlue}
    >
      {customFormat ? customFormat(v) : getValToDisplay(v)}
    </text>
  );
}
