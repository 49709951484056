import { useQuery } from '@tanstack/react-query';
import { getDayOverview } from 'api/analyticsApi';
import { formatDate } from 'progress/profile/profileUtils';
import { useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { useParams } from 'react-router-dom';
import { UrlParams } from 'types/profile';

export default function useDayOverview(range: DateRange) {
  const { userId } = useParams<UrlParams>();

  const queryParams = useMemo(() => {
    const param = {
      memberId: parseInt(userId, 10),
      date: formatDate(range.from),
    };
    return param;
  }, [userId, range]);

  return useQuery({
    queryKey: [queryParams],
    queryFn: () => getDayOverview(queryParams),
  });
}
