import Card from 'progress/components/Card/card';
import { getFormattedRangeDay } from 'progress/profile/profileUtils';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import { Pillars } from 'types/analytics';
import { useDateRange } from 'insights/hooks/useDateRange';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ActivityOverview } from 'api/analyticsApi';
import useActivities from 'api/hooks/useActivities';
import ZoneDurationsBarCard from 'progress/components/zoneDurationsBarCard/zoneDurationsBarCard';
import StatDetailsTiles from 'progress/components/statDetailsTiles/statDetailsTiles';
import ActivityHRCard from 'progress/components/activityHRCard/activityHRCard';
import CardPlaceholder from 'progress/components/cardPlaceholder/cardPlaceholder';
import styles from './activityDetailsCard.module.scss';

type ActivitiesDetailsCardProps = {
  pillar: Pillars;
};

const ACTIVITY_PLACEHOLDER_MAP = {
  [Pillars.STRAIN]: {
    icon: 'nap',
    text: 'No Strain Activity detected on this day',
  },
  [Pillars.SLEEP]: {
    icon: 'nap',
    text: 'No Sleep Activity detected on this day',
  },
  [Pillars.RECOVERY]: {
    icon: 'meditation',
    text: 'No Recovery Activity detected on this day',
  },
};

function ActivityDetailsCard({ pillar }: ActivitiesDetailsCardProps) {
  const { dateRange } = useDateRange();
  const { data: activities, isFetching } = useActivities(pillar, dateRange);
  const [activitySelected, setActivitySelected] = useState<ActivityOverview>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const activityStatsDetail = useMemo(() => {
    const numActivities = activities?.length;
    const formattedDate = getFormattedRangeDay(dateRange);
    if (numActivities > 0) {
      return `${numActivities} activit${numActivities === 1 ? 'y' : 'ies'} on ${formattedDate}`;
    }
    return formattedDate;
  }, [activities]);

  const addActivityToUrl = (activity: ActivityOverview) => {
    const params = new URLSearchParams(searchParams);
    params.set('activityId', activity.id);
    setSearchParams(params, { replace: true });

    setActivitySelected(activity);
  };

  useEffect(() => {
    if (!isFetching && activities && activities.length) {
      const urlActivityId = (searchParams.get('activityId'));
      const foundActivity = activities.find((activity) => activity.id === urlActivityId);

      if (foundActivity) {
        addActivityToUrl(foundActivity);
      } else {
        addActivityToUrl(activities[0]);
      }
    }
  }, [activities, isFetching]);

  if (!isFetching && !activities?.length) {
    const activityPlaceholders = ACTIVITY_PLACEHOLDER_MAP[pillar];
    return (
      <CardPlaceholder
        {...activityPlaceholders}
        cardTitle="Activity Statistics"
        cardDetails={activityStatsDetail}
        subtext={['If this appears to be an error, make sure this member wears their WHOOP strap properly.']}
        showBackground
      />
    );
  }

  return (
    <Card
      loading={isFetching}
      id="Activity Statistics"
    >
      <CardHeader
        title="Activity Statistics"
        details={activityStatsDetail}
        activities={activities}
        activitySelected={activitySelected}
        setActivitySelected={(activity: ActivityOverview) => {
          // When nivo line graph tries to switch directly from one data set to another
          // the page becomes completely unresponsive 10+ seconds. We unmount the graph
          // then use the timeout to set the data to avoid this.
          setActivitySelected(null);
          setTimeout(() => {
            addActivityToUrl(activity);
          }, 10);
        }}
      />
      <div className={styles.activityStatsContentContainer}>
        {/* TODO : no data state - show empty boxes */}
        <ZoneDurationsBarCard pillar={pillar} activitySelected={activitySelected} />
        <ActivityHRCard pillar={pillar} activitySelected={activitySelected} />
        <StatDetailsTiles pillar={pillar} activitySelected={activitySelected} />
      </div>
    </Card>
  );
}

export default ActivityDetailsCard;
