import { useEffect, useState } from 'react';
import { adminClient, authClient } from 'api';
import LandingPageWrapper from 'landingPageWrapper/landingPageWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import Login from 'login/login';
import { SignupAccountType, SignupDetails } from 'types/admin';
import { ProfileRegistration } from 'types/base';
import LoadingPage from 'loading';
import { useAuth } from 'context/auth-context';
import {
  CreateAccount, FeedbackMessage, OptionalForms, SetupProfile,
} from './components';
import { PAGE_STATE } from './constants';
import PreRegistration from './components/preRegistration/preRegistration';

function Register() {
  const navigate = useNavigate();
  const { token } = useParams();
  const { login } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userId, setUserId] = useState(0);
  const [signupDetails, setSignupDetails] = useState<SignupDetails>(null);

  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(PAGE_STATE.CREATE_ACCOUNT);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const data: SignupDetails = await adminClient.getSignupDetailsByInviteToken(token);
        setSignupDetails(data);
      } catch (error) {
        if (error.response.status === 404) {
          // Invalid token
          navigate('/access-restricted');
        } else if (error.response.status === 400) {
          // Invite already used
          navigate('/login');
        } else {
          throw new Error('Unhandled error: ', error);
        }
      } finally {
        setLoading(false);
      }
    };
    if (!signupDetails) {
      init();
    } else if (
      signupDetails.account_type === SignupAccountType.USER
      && step !== PAGE_STATE.OPTIONAL_FORMS
    ) {
      setStep(PAGE_STATE.CREATE_EXISTING_ACCOUNT);
    } else if (signupDetails.account_type === SignupAccountType.STAFF) {
      setEmail(signupDetails.email);
      setStep(PAGE_STATE.STAFF_REGISTRATION);
    } else if (signupDetails.account_type === SignupAccountType.NEW_ACCOUNT) {
      setEmail(signupDetails.email);
    }
  }, [token, signupDetails]);

  const handleRegistration = async (profile: ProfileRegistration) => {
    setLoading(true);
    const {
      firstName, lastName, countryKey,
    } = profile;
    const user = {
      email,
      password,
      firstName,
      lastName,
      city: '',
      admin_division: '',
      country: countryKey,
      tos_accepted: true,
      type: 'user',
      can_upload_data: false,
    };

    const createdUserId = await authClient.validateAndCreateUser(user, token);
    setUserId(createdUserId);
    // Logging user in so that they can upload user avatar
    await login(user.email.trim(), user.password, false);
    setStep(PAGE_STATE.OPTIONAL_FORMS);
    setLoading(false);
  };

  if (loading) {
    return (
      <LoadingPage />
    );
  }
  if (step === PAGE_STATE.CREATE_EXISTING_ACCOUNT) {
    return <Login signupDetails={signupDetails} />;
  }

  if (step === PAGE_STATE.STAFF_REGISTRATION) {
    return <PreRegistration email={email} token={token} setStep={setStep} />;
  }

  return (
    <LandingPageWrapper>
      {step === PAGE_STATE.CREATE_ACCOUNT && (
        <CreateAccount
          setStep={setStep}
          email={email}
          setPassword={setPassword}
        />
      )}
      {step === PAGE_STATE.SETUP_PROFILE && (
        <SetupProfile
          handleRegistration={handleRegistration}
        />
      )}
      {step === PAGE_STATE.WELCOME && (
        <FeedbackMessage
          success
          header="Welcome to WHOOP!"
          subtext="Your WHOOP account has been created successfully. Use your own new
          credentials to sign into your enterprise account."
          onConfirm={() => navigate('/login')}
        />
      )}
      {step === PAGE_STATE.OPTIONAL_FORMS && (
        <OptionalForms userId={userId} setStep={setStep} />
      )}
    </LandingPageWrapper>
  );
}

export default Register;
