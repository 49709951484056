import { useMemo, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { AxisTickProps } from '@nivo/axes';
import { CustomHorizontalBarTick, CustomZoneTick } from 'dataVisualizations/axis/axis';

type HorizontalDurationsBarGraphProps = {
  values: number[];
  colors: string[];
  yLabels: string[];
  tickFormatter: (val: number) => string;
};

const margins = {
  top: 0, right: 66, bottom: 0, left: 0,
};

function HorizontalDurationsBarGraph(
  {
    values, colors, yLabels, tickFormatter,
  }: HorizontalDurationsBarGraphProps,
) {
  const [data, setData] = useState([]);

  const createBarData = (foundVals: number[]) => {
    const parsedData = foundVals.map((val: number, index: number) => (
      { id: index, value: val }
    ));
    setData(parsedData);
  };

  useMemo(() => {
    createBarData(values);
  }, [values]);

  return (
    <ResponsiveBar
      data={data}
      layout="horizontal"
      minValue={0}
      keys={['value']}
      margin={margins}
      indexBy="id"
      borderRadius={2}
      enableGridX={false}
      enableGridY={false}
      axisBottom={null}
      isInteractive={false}
      padding={0.15}
      animate={false}
      axisRight={{
        renderTick: (tick: AxisTickProps<string>) => CustomZoneTick(
          tick.x,
          tick.y,
          tick.tickIndex,
          yLabels,
        ),
      }}
      axisLeft={{
        renderTick: (tick: AxisTickProps<string>) => CustomHorizontalBarTick(
          tick.x,
          tick.y,
          values[tick.tickIndex],
          tickFormatter,
        ),
      }}
      colors={(d) => colors[d.index]}
      enableLabel={false}
      layers={['grid', 'bars', 'axes', 'markers', 'legends', 'annotations']}
    />
  );
}

export default HorizontalDurationsBarGraph;
