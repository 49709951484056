import { LandingGroup, GroupsLandingStatNames } from 'api/groupLandingApi';
import { usePinnedRow } from 'helpers/usePinnedRow';
import {
  Cell, Column, useSortBy, useTable,
} from 'react-table';
import { useMemo } from 'react';
import { groupLandingMembersCell } from 'tableUtils/tableCells';
import { usePrivacy } from 'context/privacy-context';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import { useAccounts } from 'context/account-context';
import { Diff, PercentTrendCell, StrainTrendCell } from './groupsTableCells';

const nameColumn = [
  { accessor: 'name', disableSortBy: true, Cell: groupLandingMembersCell } as Column,
] as Column<BaseData>[];

const pillarColumns = [
  {
    Header: 'Avg. Strain',
    accessor: GroupsLandingStatNames.SCALED_DAY_STRAIN,
    sortType: 'alphanumeric',

    Cell: ({ value, row }: Cell<BaseData>) => StrainTrendCell(value, row.original[`${GroupsLandingStatNames.SCALED_DAY_STRAIN}_diff`]),
  },
  {
    Header: 'Avg. Recovery',
    accessor: GroupsLandingStatNames.RECOVERY_SCORE,
    sortType: 'alphanumeric',
    Cell: ({ value, row }: Cell<BaseData>) => PercentTrendCell(value, row.original[`${GroupsLandingStatNames.RECOVERY_SCORE}_diff`]),
  },
  {
    Header: 'Avg. Sleep Performance',
    accessor: GroupsLandingStatNames.SLEEP_PERFORMANCE,
    sortType: 'alphanumeric',
    Cell: ({ value, row }: Cell<BaseData>) => PercentTrendCell(value, row.original[`${GroupsLandingStatNames.SLEEP_PERFORMANCE}_diff`]),
  },
];

export interface BaseData {
  id: number;
  name: string;
  memberCount: number;
  isPinnedRow: boolean;
  averageHidden: boolean;
  SLEEP_PERFORMANCE_diff: Diff;
  RECOVERY_SCORE_diff: Diff;
  SCALED_DAY_STRAIN_diff: Diff;
}

function useGroupsTable(
  landingGroups: LandingGroup[],
) {
  const { isInPrivacyLevel } = usePrivacy();
  const { checkAccess } = useAccounts();
  const columnsToUse = useMemo(() => {
    if (isInPrivacyLevel(PrivacyLevel.aggregate_metrics)) {
      return [...nameColumn, ...pillarColumns] as Column<BaseData>[];
    }
    return nameColumn as Column<BaseData>[];
  }, [landingGroups]);

  const data = useMemo(() => {
    // Converting average stats data from an array of objects
    // Into one object with stats data as properties, so table can use this
    const dataFromStats = landingGroups.map(
      (landingGroup: LandingGroup, index: number) => {
        const mappedData: { [key: string] : string | number | boolean | Diff } & BaseData = {
          name: landingGroup.name,
          id: landingGroup.id,
          memberCount: landingGroup.member_count,
          averageHidden: landingGroup.average_hidden,
          isPinnedRow: index === 0 && checkAccess(DashboardRole.ACCOUNT_MANAGER),
          SLEEP_PERFORMANCE_diff: null,
          RECOVERY_SCORE_diff: null,
          SCALED_DAY_STRAIN_diff: null,
        };
        landingGroup.average_stats.forEach((stat) => {
          mappedData[stat.name] = stat.val;
          mappedData[`${stat.name}_diff`] = {
            diff: stat.diff,
            sentiment: stat.sentiment,
          };
        });
        return mappedData;
      },
    );
    return dataFromStats;
  }, [landingGroups]);

  return {
    ...useTable(
      {
        columns: columnsToUse,
        data,
        disableSortRemove: true,
        initialState: {
          sortBy: [
            {
              id: GroupsLandingStatNames.SCALED_DAY_STRAIN,
              desc: true,
            },
          ],
        },
      },
      usePinnedRow,
      useSortBy,
    ),
  };
}

export default useGroupsTable;
