import dayjs from 'dayjs';
import { useRef } from 'react';
import {
  ActiveModifiers,
  Button, DateRange, DayProps, useDayRender,
} from 'react-day-picker';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { DAYS_OF_WEEK } from '../datePickerUtils/datePickerUtils';
import styles from '../customDatePicker.module.scss';

dayjs.extend(advancedFormat);
dayjs.extend(isBetween);

// https://github.com/gpbl/react-day-picker/blob/0e4c95eb4af6e19e838c063fe99631d1f6c7e1df/packages/react-day-picker/src/components/Day/Day.tsx
// in the new library update, the day component can only be selected by the date
// this custom component mimics the one from the library
// with an aria-label to select the component with the date and month
function CustomDay({ date, displayMonth }: DayProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(date, displayMonth, buttonRef);
  const { isHidden, selectedDays, activeModifiers } = dayRender as {
    isHidden: boolean;
    selectedDays: DateRange;
    activeModifiers: ActiveModifiers;
  };
  const isInSelectedRange = activeModifiers.range_middle
  || activeModifiers.range_end
  || activeModifiers.range_start;

  // if the day isHidden return an empty div
  // but if the day has a range selected (from & to) that goes over different months
  // apply a gradient on the right day (start of next month or end of previous month)
  if (isHidden) {
    if (selectedDays?.to
      && dayjs(displayMonth).isBetween(selectedDays.from, selectedDays.to, 'month', '[]')
      && !dayjs(selectedDays.from).isSame(selectedDays.to, 'month')) {
      if (dayjs(date).get('date') === 1 && isInSelectedRange) {
        // start the gradient at the end of the month
        return <div data-testid="startGradient" className={styles.startGradient} />;
      }

      if (dayjs(date).get('date') === dayjs(date).endOf('month').get('date') && isInSelectedRange) {
        // end the gradient at the start of the month
        return <div data-testid="endGradient" className={styles.endGradient} />;
      }
    }

    return (
      <div />
    );
  }

  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />;
  }

  return (
    <Button
      aria-label={`${dayjs(date).format('Do MMMM')} (${DAYS_OF_WEEK[dayjs(date).day()]})`}
      {...dayRender.buttonProps}
      ref={buttonRef}
    />
  );
}

export default CustomDay;
