import Card from 'progress/components/Card/card';
import { getFormattedRangeDay } from 'progress/profile/profileUtils';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import {
  Pillars, MetricType, PRIMARY_METRIC_MAP, METRIC_DISPLAY_NAMES,
} from 'types/analytics';
import useMetric from 'api/hooks/useMetric';
import { useDateRange } from 'insights/hooks/useDateRange';
import PillarBreakdownBar from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBar';
import { groupBreakdownData } from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarUtils';
import {
  PillarBreakdownBarData,
  PillarBreakdownDataWithMetric,
  PillarKeys, pillarKeysToDisplay, PILLAR_KEY_MAP, RecoveryKeys, StrainKeys,
} from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarConsts';
import PillarBreakdownBarLabel from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarLabel';
import { ComputedDatum } from '@nivo/bar';
import { classes } from '@whoop/web-components';
import styles from './pillarBreakdownBarCard.module.scss';

type PillarBreakdownBarCardProps = {
  pillar: Pillars;
  barClickHandler?: (datum: ComputedDatum<PillarBreakdownDataWithMetric>,
    event: React.MouseEvent<Element>) => void;
  noMargin?: boolean;
  selectedKey?: StrainKeys | RecoveryKeys;
};

function PillarBreakdownBarCard({
  pillar, barClickHandler, noMargin = false, selectedKey,
}: PillarBreakdownBarCardProps) {
  const { dateRange } = useDateRange();
  const detailDate = getFormattedRangeDay(dateRange);
  const pillarMetricType: MetricType = PRIMARY_METRIC_MAP[pillar];
  const { data, isLoading } = useMetric(
    pillarMetricType,
    dateRange,
  );
  let breakdownBarData: PillarBreakdownBarData[] = [];
  if (data) {
    const { barData } = groupBreakdownData(pillar, data);
    breakdownBarData = [barData];
  }
  const pillarKeys = PILLAR_KEY_MAP[pillar];

  const colorsToUse: string[] = [];
  pillarKeys.forEach((key) => {
    // If no selectedKey, no values selected, so show all normal colors
    // If key is selectedKey, then this is the selected value so show normal color
    if (!selectedKey || key === selectedKey) {
      colorsToUse.push(pillarKeysToDisplay[key].color);
    } else {
      colorsToUse.push(pillarKeysToDisplay[key].disabledColor);
    }
  });

  return (
    <Card
      id={`${pillarMetricType}-breakdown`}
      loading={isLoading}
      className={styles.pillarBarCard}
    >
      <CardHeader
        title={`${METRIC_DISPLAY_NAMES[pillarMetricType]} Breakdown`}
        details={detailDate}
      />
      <div className={classes(styles.pillarBreakdownBar, noMargin && styles.overviewPageTopMargin)}>
        <PillarBreakdownBar
          data={breakdownBarData}
          colors={colorsToUse}
          keys={pillarKeys}
          barClickHandler={barClickHandler}
        />
      </div>
      {data && (
      <div className={styles.labels}>
        {pillarKeys.map((key: PillarKeys) => (
          <PillarBreakdownBarLabel
            key={key}
            count={breakdownBarData[0][key]}
            displayVals={pillarKeysToDisplay[key]}
          />
        ))}
      </div>
      )}
    </Card>
  );
}

export default PillarBreakdownBarCard;
