import {
  Cohort, CohortCollaborator, CreateCohortParams, GroupMember,
} from 'types/cohort';
import { Collaborator, InviteStatus } from 'types/collaborator';
import { SeatStatus } from 'types/seat';
import {
  baseGroups, seatsInGroup,
} from '../store/groups';
import { getAccount, getCollaborators } from './accountActions';
import { getSeats } from './seatActions';

export const getSeatIds = (groupId: number) => {
  const seatList = seatsInGroup.find(
    (group) => group.groupId === groupId,
  );
  return seatList ? seatList.seatIds : [];
};

const getIndexOfGroup = (groupId: number) => seatsInGroup.findIndex(
  (group) => group.groupId === groupId,
);

// CohortCollaborator will get deprecated in tech-debt WU-351
const convertToCohortCollaborator = (collaborator: Collaborator) => ({
  user_id: collaborator?.user_id,
  first_name: collaborator?.name?.split(' ')[0],
  last_name: collaborator?.name?.split(' ')[1],
  avatar_url: collaborator?.avatar_url,
  email: collaborator?.email,
  privacy_level: collaborator?.privacy_level,
} as CohortCollaborator);

export const getGroups = (includePrimaryGroup: boolean = false) => {
  if (!includePrimaryGroup) {
    return baseGroups.filter((g) => !g.is_primary);
  }
  return baseGroups;
};

export const getGroup = (groupId: number) => baseGroups.find((g) => g.id === groupId);

export const createGroup = (group: CreateCohortParams) => {
  const groupId = baseGroups.length + 1;
  const newGroup: Cohort = {
    created_at: null,
    name: group.name,
    member_count: 0,
    avatar_url: null,
    collaborators: [] as CohortCollaborator[],
    about: group.about,
    id: groupId,
    community_id: groupId,
    salesforce_account_id: getAccount().id,
    legacy_team_id: null,
    concealed: group.concealed,
  };
  baseGroups.push(newGroup);
  seatsInGroup.push({ groupId, seatIds: [] });
  return newGroup;
};

export const deleteGroup = (groupId: number) => {
  const currentGroup: Cohort = baseGroups.find((group) => group.id === groupId);
  const index = baseGroups.indexOf(currentGroup);
  baseGroups.splice(index, 1);
};

export const getGroupMembers = (groupId: number) => {
  const seatIdList: number[] = getSeatIds(groupId);
  const groupSeats = getSeats().filter((seat) => seatIdList.includes(seat.id));
  const groupMembers: GroupMember[] = groupSeats.map((seat) => {
    const {
      // remove generic seat data not associated with a group member
      activated_on,
      contract_number,
      contract_start_date,
      contract_end_date,
      last_activity_at,
      ...groupMember
    } = {
      ...seat,
      seat_id: seat.id,
      cohort_id: groupId,
    };
    return groupMember;
  });
  return {
    total_count: seatIdList.length,
    offset: 0,
    records: groupMembers,
  };
};

export const addGroupMember = (seatId: number, groupId: number) => {
  const group: Cohort = baseGroups.find((g) => g.id === groupId);
  baseGroups[baseGroups.indexOf(group)].member_count = group.member_count + 1;

  const seatIdList: number[] = getSeatIds(groupId);
  if (!seatIdList.includes(seatId)) {
    seatsInGroup[getIndexOfGroup(groupId)].seatIds.push(seatId);
  }
};

export const removeGroupMember = (seatId: number, groupId: number) => {
  const group: Cohort = baseGroups.find((g) => g.id === groupId);
  baseGroups[baseGroups.indexOf(group)].member_count = group.member_count - 1;
  seatsInGroup[getIndexOfGroup(groupId)].seatIds = seatsInGroup[getIndexOfGroup(groupId)]
    .seatIds.filter((id) => id !== seatId);
};

export const getAvailableGroupMembers = (groupId: number) => {
  const availableSeatIds = getSeats()
    .filter((seat) => !getSeatIds(groupId).includes(seat.id) && seat.status === SeatStatus.Active);
  return availableSeatIds.map((seat) => ({
    ...seat,
    seat_id: seat.id,
    cohort_id: groupId,
  }));
};

export const getAvailableCollaborators = (groupId: number) => {
  const group: Cohort = baseGroups.find((g) => g.id === groupId);
  const availableCollaborators: Collaborator[] = getCollaborators().filter(
    (collab: Collaborator) => collab.status === InviteStatus.accepted
      && !group.collaborators.includes(convertToCohortCollaborator(collab)),
  );
  return availableCollaborators.map((collab) => {
    const nameComponents = collab?.name?.split(' ');
    return {
      ...collab,
      first_name: nameComponents[0],
      last_name: nameComponents[1],
    };
  });
};

export const addCollaborator = (userId: number, groupId: number) => {
  const group: Cohort = baseGroups.find((g) => g.id === groupId);
  const collaborator: Collaborator = getCollaborators().find((collab) => collab.user_id === userId);
  if (collaborator && !group.collaborators.includes(convertToCohortCollaborator(collaborator))) {
    group.collaborators.push(convertToCohortCollaborator(collaborator));
  }
};

export const updateGroup = (groupId: number, group: Cohort) => {
  const newGroup: Cohort = baseGroups.find((g) => g.id === groupId);
  newGroup.about = group.about;
  newGroup.concealed = group.concealed;
  return newGroup;
};

export const removeCollaboratorFromGroup = (userId: number, groupId: number) => {
  const group: Cohort = baseGroups.find((g) => g.id === groupId);
  const groupCollaborator: CohortCollaborator = group.collaborators.find(
    (gc) => gc.user_id === userId,
  );
  if (groupCollaborator) {
    const indexOfCollaborator = group.collaborators.indexOf(groupCollaborator);
    group.collaborators.splice(indexOfCollaborator, 1);
  }
};
