import { MemberTrend, MetricOverview, Pillars } from 'types/analytics';
import { PillarBreakdownBarData, PillarBreakdownDayData } from './pillarBreakdownBarConsts';

const addStrainDayToBreakdownObject = (
  value: number,
  breakdown: { [key: string]: string[] },
  date: string,
) => {
  if (value < 10) {
    breakdown.light.push(date);
  } else if (value <= 14) {
    breakdown.moderate.push(date);
  } else if (value <= 18) {
    breakdown.strenuous.push(date);
  } else if (value > 18) {
    breakdown.allOut.push(date);
  }
};

export const groupStrainBreakdownData = (data: MetricOverview) => {
  const breakdownDays = {
    allOut: [] as string[],
    strenuous: [] as string[],
    moderate: [] as string[],
    light: [] as string[],
  };
  data.metrics.forEach((metric) => {
    if (metric.metric_value === null) {
      return;
    }
    const dateString = new Date(metric.cycle_start_day as unknown as Date).toLocaleDateString();
    addStrainDayToBreakdownObject(metric.metric_value, breakdownDays, dateString);
  });

  const barData = {
    metric: 'day_strain',
    allOut: breakdownDays.allOut.length,
    strenuous: breakdownDays.strenuous.length,
    moderate: breakdownDays.moderate.length,
    light: breakdownDays.light.length,
  };

  return { breakdownDays, barData };
};

const addRecoveryToBreakdownObject = (
  value: number,
  breakdown: { [key: string]: string[] },
  date: string,
) => {
  if (value < 34) {
    breakdown.red.push(date);
  } else if (value <= 66) {
    breakdown.yellow.push(date);
  } else if (value > 66) {
    breakdown.green.push(date);
  }
};

export const groupRecoveryBreakdownData = (data: MetricOverview) => {
  const breakdownDays = {
    green: [] as string[],
    yellow: [] as string[],
    red: [] as string[],
  };
  data.metrics.forEach((metric) => {
    if (metric.metric_value === null) {
      return;
    }

    const dateString = new Date(metric.cycle_start_day as unknown as Date).toLocaleDateString();
    addRecoveryToBreakdownObject(metric.metric_value, breakdownDays, dateString);
  });

  const barData = {
    metric: 'recovery',
    green: breakdownDays.green.length,
    yellow: breakdownDays.yellow.length,
    red: breakdownDays.red.length,
  };

  return { breakdownDays, barData };
};

export const getTrendBreakdownDays = (trends: MemberTrend[]) => {
  const foundStrainBreakdownDays = {
    allOut: [] as string[],
    strenuous: [] as string[],
    moderate: [] as string[],
    light: [] as string[],
  };

  const foundRecoveryBreakdownDays = {
    green: [] as string[],
    yellow: [] as string[],
    red: [] as string[],
  };

  trends?.forEach((memberTrend: MemberTrend) => {
    const dateString = new Date(
      memberTrend.cycle_start_day as unknown as Date,
    ).toLocaleDateString();
    const firstTrend = memberTrend.pillar_data_list[0]?.trend_value;
    const secondTrend = memberTrend.pillar_data_list[1]?.trend_value;
    let recoveryVal;
    let strainVal;
    if (memberTrend.pillar_data_list[0]?.trend_key === 'RECOVERY') {
      recoveryVal = firstTrend;
      strainVal = secondTrend;
    } else {
      recoveryVal = secondTrend;
      strainVal = firstTrend;
    }
    addStrainDayToBreakdownObject(strainVal, foundStrainBreakdownDays, dateString);
    addRecoveryToBreakdownObject(recoveryVal, foundRecoveryBreakdownDays, dateString);
  });

  return { foundStrainBreakdownDays, foundRecoveryBreakdownDays };
};

export const groupSleepBreakdownData = (data: MetricOverview) => {
  const breakdownDays = {
    optimal: [] as string[],
    sufficient: [] as string[],
    poor: [] as string[],
  };
  data.metrics.forEach((metric) => {
    if (metric.metric_value === null) {
      return;
    }

    const dateString = new Date(metric.cycle_start_day as unknown as Date).toLocaleDateString();
    if (metric.metric_value < 71) {
      breakdownDays.poor.push(dateString);
    } else if (metric.metric_value <= 85) {
      breakdownDays.sufficient.push(dateString);
    } else if (metric.metric_value > 85) {
      breakdownDays.optimal.push(dateString);
    }
  });

  const barData = {
    metric: 'recovery',
    optimal: breakdownDays.optimal.length,
    sufficient: breakdownDays.sufficient.length,
    poor: breakdownDays.poor.length,
  };

  return { breakdownDays, barData };
};

const PILLAR_TO_GROUP_BREAKDOWN_FUNCTION = {
  [Pillars.STRAIN]: groupStrainBreakdownData,
  [Pillars.RECOVERY]: groupRecoveryBreakdownData,
  [Pillars.SLEEP]: groupSleepBreakdownData,
};

export const groupBreakdownData = (
  pillar: Pillars,
  data: MetricOverview,
): { breakdownDays: PillarBreakdownDayData; barData: PillarBreakdownBarData } => {
  const groupingFunction = PILLAR_TO_GROUP_BREAKDOWN_FUNCTION[pillar];
  return groupingFunction(data);
};
