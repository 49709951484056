import { Colors } from '@whoop/web-components';
import { SleepMetricDetailsType } from 'types/analytics';

export const SLEEP_COLOR_ZONES = {
  [SleepMetricDetailsType.WAKE_DURATION]: Colors.gray500,
  [SleepMetricDetailsType.LIGHT_SLEEP_DURATION]: Colors.sleepZonePurple,
  [SleepMetricDetailsType.REM_DURATION]: Colors.sleepZoneMagenta,
  [SleepMetricDetailsType.SLOW_WAVE_DURATION]: Colors.sleepZonePink,
};

export const SLEEP_STAGE_TITLE = {
  [SleepMetricDetailsType.WAKE_DURATION]: 'Awake',
  [SleepMetricDetailsType.LIGHT_SLEEP_DURATION]: 'Light',
  [SleepMetricDetailsType.REM_DURATION]: 'REM',
  [SleepMetricDetailsType.SLOW_WAVE_DURATION]: 'SWS',
};
