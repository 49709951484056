import { accountClient, adminClient } from 'api';
import { client } from 'api/apiClient';
import { getAccessForUser } from 'api/dashboardApi';
import { useAuth } from 'context/auth-context';
import { PrivacyProvider } from 'context/privacy-context';
import LoadingPage from 'loading';
import {
  ReactChild,
  createContext,
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountDetail } from 'types/account';
import { AccountConfiguration, reidentificationTokenKey } from 'types/admin';
import { DashboardRoleHierarchy } from 'types/collaborator';
import { DashboardRole, DashboardUser } from 'types/dashboardUser';

export interface AccountInterface {
  accounts: AccountDetail[];
  account: AccountDetail;
  loading: boolean;
  accountConfig: AccountConfiguration;
  checkAccess: (role: DashboardRole, greaterThanAccessOnly?: boolean) => boolean;
  setAccountConfig: (config: AccountConfiguration) => void;
  updateAccount: () => void;
  isReidTokenValid: () => Promise<boolean>;
}

export const AccountContext = createContext<AccountInterface>({
  accounts: [],
  account: null,
  loading: false,
  isReidTokenValid: null,
} as AccountInterface);

type AccountProviderPropTypes = {
  children?: ReactChild[] | ReactChild;
};

export type AccountParams = {
  accountId: string;
};

export function AccountProvider({ children }: AccountProviderPropTypes) {
  const { user } = useAuth();
  const { accountId } = useParams<AccountParams>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState<AccountDetail[]>([]);
  const [accountConfig, setAccountConfig] = useState<AccountConfiguration>(null);
  const [accountAccess, setAccountAccess] = useState<DashboardUser>(null);

  const checkAccess = (role: DashboardRole, greaterThanAccessOnly: boolean = false) => {
    if (user.admin) return true;

    const minimumRoleLevel = DashboardRoleHierarchy.get(role);
    const currentRoleLevel = DashboardRoleHierarchy.get(accountAccess.role);

    if (greaterThanAccessOnly) {
      return currentRoleLevel > minimumRoleLevel;
    }

    return currentRoleLevel >= minimumRoleLevel;
  };

  const isReidTokenValid = async () => {
    const currentToken = localStorage.getItem(reidentificationTokenKey);
    if (currentToken) {
      // Make api call to see if token matches the one returned from the backend
      const isValidToken: boolean = await adminClient.isTokenValid();
      return isValidToken;
    }
    return false;
  };

  const updateAccount = async () => {
    setAccounts(await accountClient.getAccounts());
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);

      const accountData = await accountClient.getAccounts();
      if (accountData.length === 0) {
        navigate('/error', { replace: true });
      }
      setAccounts(accountData);
      // if accountId === load-accounts there is no salesforceAccountId for api calls
      if (accountId !== 'load-accounts') {
        let access;
        if (!user.admin) {
          access = await getAccessForUser(user.id);
          setAccountAccess(access);
        }
        setAccountConfig(await adminClient.getAccountConfigurations());

        analytics.identify(user.id, {
          salesforceAccountId: accountId,
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
          role: user.admin ? 'admin' : access?.role,
        });
      } else {
        // if on load-accounts nav to the account home
        navigate(`/accounts/${accountData[0].id}/people`, { replace: true });
      }

      setLoading(false);
    };

    if (user) {
      init();
    }

    const requestInterceptor = client.interceptors.request.use((config) => {
      const newConfig = { ...config };
      if (accountId !== 'load-accounts') {
        newConfig.params = {
          ...config.params,
          salesforceAccountId: accountId,
        };
      }
      const reidentificationToken = localStorage.getItem(reidentificationTokenKey);
      if (reidentificationToken && config.url.indexOf('enterprise-service/') > -1) {
        newConfig.headers = {
          ...config.headers,
          'X-WHOOP-REID-TOKEN': reidentificationToken,
        };
      }
      return newConfig;
    });

    return () => client.interceptors.request.eject(requestInterceptor);
  }, [accountId]);

  const value = useMemo(
    () => (
      {
        loading,
        account: accounts.find((a) => a.id === accountId),
        accountConfig,
        accounts,
        setAccountConfig,
        checkAccess: (
          user.admin || accountAccess) ? checkAccess : null,
        isReidTokenValid,
        updateAccount,
      }),
    [loading,
      accountId,
      accounts,
      accountAccess,
      accountConfig,
      setAccountConfig,
      isReidTokenValid,
      updateAccount],
  );

  return (
    <AccountContext.Provider
      value={value}
    >
      <PrivacyProvider account={value.account}>
        {loading ? <LoadingPage /> : children}
      </PrivacyProvider>
    </AccountContext.Provider>
  );
}

export const useAccounts = () => useContext(AccountContext);
