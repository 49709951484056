import { UpdateJobTitle } from 'api/jobTitleApi';
import {
  createJobTitle, deleteJobTitle, getAllJobTitles, updateJobTitle,
} from 'mocks/actions/jobTitleActions';
import { getDelayVal, mswBaseUri } from 'mocks/utils/utils';
import { rest } from 'msw';

export const jobTitleHandlers = [
  rest.get(mswBaseUri('v1/account-job-title'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(getAllJobTitles()),
  )),
  rest.post(mswBaseUri('v1/account-job-title'), async (req, res, ctx) => {
    const jobTitle = await req.json();
    const newJobTitle = createJobTitle(jobTitle.job_title_id);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
      ctx.json(newJobTitle),
    );
  }),
  rest.put(mswBaseUri('v1/account-job-title'), async (req, res, ctx) => {
    const updatedJobTitle: UpdateJobTitle = await req.json();
    updateJobTitle(updatedJobTitle);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
    );
  }),
  rest.delete(mswBaseUri('v1/account-job-title'), async (req, res, ctx) => {
    const { job_title_id } = await req.json();
    deleteJobTitle(job_title_id);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(204),
    );
  }),
];

export default jobTitleHandlers;
