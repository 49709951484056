import { Icon } from '@whoop/web-components';
import styles from './iconPieChart.module.scss';
import { PieChartLabel } from './iconPieChartConsts';

type IconPieChartLegendProps = {
  labels: PieChartLabel[];
  data: { [key: string]: number };
  noDataState?: boolean;
};

function IconPieChartLegend({ labels, data, noDataState = false }: IconPieChartLegendProps) {
  return (
    <div className={styles.pieChartLabels}>
      <p className={styles.breakdownTitle}>Breakdown</p>
      {labels.map((label: PieChartLabel) => {
        if (!noDataState && !data) {
          return null;
        }
        return (
          <div data-testid="piechart-label" className={styles.label} key={label.key}>
            <div className={styles.labelSquare} style={{ backgroundColor: label.color }} />
            <div className={styles.labelCount}>{noDataState ? '-' : data[label.key]}</div>
            <Icon className={styles.icon} name="profile" />
            <div data-testid={`${label.label} - pieChartLabel`} className={styles.labelName}>{label.label}</div>
          </div>
        );
      })}
    </div>
  );
}

export default IconPieChartLegend;
