import { analyticsClient } from 'api';
import { usePrivacy } from 'context/privacy-context';
import { useEffect, useState } from 'react';
import {
  Column, TableInstance, TableState,
  useFilters, usePagination, useSortBy, useTable,
} from 'react-table';
import { DeviceHealth, PillarOptions, Pillars } from 'types/analytics';
import { usePinnedRow, UsePinnedRowInstanceProps } from 'helpers/usePinnedRow';
import { deviceHealthColumns, initialDeviceHealthTableState } from './deviceHealth';
import {
  getRecoveryData, getRecoveryTableColumns, initialRecoveryTableState, RecoveryBreakdownData,
} from './recovery';
import {
  getSleepData, getSleepTableColumns, initialSleepTableState, SleepBreakdownData,
} from './sleep';
import {
  getStrainData, getStrainTableColumns, initialStrainTableState, StrainBreakdownData,
} from './strain';

export type BreakdownDataRow = {
  user_id?: number;
  opted_in?: boolean;
  hide_average?: boolean;
  isPinnedRow: boolean;
};

export type LoadingTableInstanceProps = {
  loading: boolean;
};

export type BreakdownTableInstance = TableInstance<BreakdownDataRow>
& LoadingTableInstanceProps
& UsePinnedRowInstanceProps<BreakdownDataRow>;

export default function useBreakdownTable(
  groupId: number,
  pillar: PillarOptions,
  start: Date,
  end: Date,
  searchTerm?: string,
) {
  const { privacyLevel } = usePrivacy();
  const [columns, setColumns] = useState<Column<BreakdownDataRow>[]>([]);
  const [data, setData] = useState<BreakdownDataRow[]>([]);
  const [initialState, setInitialState] = useState<Partial<TableState>>();
  const [loading, setLoading] = useState(false);

  const setDataWithPinnedRow = (
    parsedData: SleepBreakdownData[] | RecoveryBreakdownData[] | StrainBreakdownData[],
  ) => {
    const newData = parsedData.map((row) => ({
      ...row,
      isPinnedRow: !row.user_id,
    }));
    setData(newData);
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      switch (pillar) {
        case Pillars.SLEEP: {
          const sleepData = await getSleepData(groupId, start, end, privacyLevel);
          const sleepColumns = getSleepTableColumns(
            privacyLevel,
          ) as Column<BreakdownDataRow>[];
          setColumns(sleepColumns);
          setInitialState(initialSleepTableState);
          setDataWithPinnedRow(sleepData);
          break;
        }
        case Pillars.STRAIN: {
          const strainData = await getStrainData(groupId, start, end, privacyLevel);
          const strainColumns = getStrainTableColumns(
            privacyLevel,
          ) as Column<BreakdownDataRow>[];
          setColumns(strainColumns);
          setInitialState(initialStrainTableState);
          setDataWithPinnedRow(strainData);
          break;
        }
        case Pillars.RECOVERY: {
          const recoveryData = await getRecoveryData(groupId, start, end, privacyLevel);
          const recoveryColumns = getRecoveryTableColumns(
            privacyLevel,
          ) as Column<BreakdownDataRow>[];
          setColumns(recoveryColumns);
          setInitialState(initialRecoveryTableState);
          setDataWithPinnedRow(recoveryData);
          break;
        }
        case DeviceHealth.DEVICE_HEALTH: {
          const deviceHealthData = await analyticsClient.getDeviceHealthData(groupId);
          setColumns(deviceHealthColumns as Column<BreakdownDataRow>[]);
          setInitialState(initialDeviceHealthTableState);
          setDataWithPinnedRow(deviceHealthData);
          break;
        }
        default:
          throw new Error(`unknown pillar type: ${pillar}`);
      }
      setLoading(false);
    }

    if (groupId) {
      fetchData();
    }
  }, [groupId, pillar, start, end, privacyLevel]);

  return {
    ...useTable(
      {
        columns,
        data,
        initialState: {
          ...initialState,
          filters: [{
            id: 'name',
            value: searchTerm ?? '',
          }],
        },
        disableSortRemove: true,
      },
      usePinnedRow,
      useFilters,
      useSortBy,
      usePagination,
    ),
    loading,
  } as BreakdownTableInstance;
}
