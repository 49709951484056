import { classes, Icon } from '@whoop/web-components';
import useMetric from 'api/hooks/useMetric';
import { SUBTITLE_3 } from 'components/text';
import { useDateRange } from 'insights/hooks/useDateRange';
import { getNumDays } from 'progress/utils/date';
import { MetricType, METRIC_DISPLAY_NAMES } from 'types/analytics';
import styles from './pillarStatTile.module.scss';
import { comparisonCorrelation, formatMetric, METRIC_ICONS } from './pillarStatTileUtils';

type PillarStatTilesProps = {
  metric: MetricType;
  isSelected?: boolean;
  setSelectedMetricType?: () => void;
};

function PillarStatTile({
  setSelectedMetricType, isSelected, metric,
}: PillarStatTilesProps) {
  const { dateRange, comparisonDateRange } = useDateRange();
  const isRangeView = getNumDays(dateRange) > 1;
  const { data: metricData, isLoading: isLoadingMetricData } = useMetric(metric, dateRange);
  const { data: comparisonData, isLoading: isLoadingComparisonData } = useMetric(
    metric,
    comparisonDateRange,
  );
  const isLoadingData = isLoadingComparisonData && isLoadingMetricData;

  const formatValue = (metricValue: number, isComparisonValue: boolean = false) => {
    // if undefined or null this condition will be met
    if (metricValue == null) {
      return '--';
    }
    // will format data including
    return formatMetric(metricValue, metric, isComparisonValue);
  };

  const onClick = () => {
    if (isRangeView && setSelectedMetricType) {
      setSelectedMetricType();
    }
  };

  return (
    <div
      className={classes(
        styles.pillarStatTile,
        isSelected && styles.selectedTile,
        isLoadingData && styles.loadingAnimation,
        isRangeView && styles.pointer,
      )}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      {!isLoadingData && (
        <>
          <div className={styles.titleContainer}>
            <Icon name={METRIC_ICONS[metric]} className={classes(styles.icon)} />
            <SUBTITLE_3 className={styles.title}>{METRIC_DISPLAY_NAMES[metric]}</SUBTITLE_3>
          </div>
          <div className={styles.valueContainer}>
            <div className={styles.currentValueRow}>
              <h1 className={styles.currentValue}>{formatValue(metricData?.average_value)}</h1>
              {comparisonCorrelation(
                metric,
                metricData?.average_value,
                comparisonData?.average_value,
              )}
            </div>
            <h2 className={styles.average}>{formatValue(comparisonData?.average_value, true)}</h2>
          </div>
        </>
      )}
      {isSelected && <div className={styles.selected} />}
    </div>
  );
}

export default PillarStatTile;
