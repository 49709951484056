import {
  convertHRVtoString,
  convertStrainToString,
  convertToNumberLocale,
  getSleepDuration,
  roundToOneDecimal,
} from 'progress/utils/numberFormatter';
import {
  MetricType, RecoveryMetricType, SleepMetricType, StrainMetricType,
} from 'types/analytics';
import ComparisonArrow, { ArrowDirection, Comparison, ComparisonType } from '../comparionArrow/comparisonArrow';
import styles from './pillarStatTile.module.scss';

export const METRIC_ICONS = {
  [StrainMetricType.CALORIES]: 'calories',
  [StrainMetricType.STRAIN]: 'strain',
  [StrainMetricType.AVG_HR]: 'heart_rate',
  [RecoveryMetricType.SCORE]: 'recovery_score',
  [RecoveryMetricType.HRV]: 'hrv_outline',
  [RecoveryMetricType.RHR]: 'rhr_outline',
  [RecoveryMetricType.RESPIRATORY_RATE]: 'respiratory_rate',
  [SleepMetricType.PERFORMANCE]: 'sleep_outline',
  [SleepMetricType.DURATION]: 'hours_of_sleep',
  [SleepMetricType.LATENCY]: 'latency',
  [SleepMetricType.CONSISTENCY]: 'bedtime',
  [SleepMetricType.DISTURBANCES]: 'disturbances',
  [SleepMetricType.EFFICIENCY]: 'efficiency',
};

export const enum ValueType {
  NUMBER = 'number',
  TIME = 'time',
  DECIMAL = 'decimal',
}
export const compareTrend = (
  averageValue: number,
  comparisonValue: number,
  directTrend: boolean,
  valueType: ValueType,
) => {
  const comparison: Comparison = {
    arrowDirection: ArrowDirection.EQUAL,
  };

  let roundComparison: number;
  let roundAverage: number;

  switch (valueType) {
    case ValueType.DECIMAL: {
      roundAverage = roundToOneDecimal(averageValue);
      roundComparison = roundToOneDecimal(comparisonValue);
      break;
    }
    case ValueType.TIME: {
      // compare the seconds
      roundAverage = Math.round(averageValue / 1000);
      roundComparison = Math.round(comparisonValue / 1000);
      break;
    }
    case ValueType.NUMBER: {
      roundAverage = Math.round(averageValue);
      roundComparison = Math.round(comparisonValue);
      break;
    }
    default: {
      roundAverage = averageValue;
      roundComparison = comparisonValue;
    }
  }

  if (comparisonValue == null || averageValue == null) {
    return comparison;
  }

  if (roundAverage > roundComparison) {
    comparison.arrowDirection = ArrowDirection.UP;
    comparison.comparisonType = directTrend ? ComparisonType.POSITIVE : ComparisonType.NEGATIVE;
  }
  if (roundAverage < roundComparison) {
    comparison.arrowDirection = ArrowDirection.DOWN;
    comparison.comparisonType = directTrend ? ComparisonType.NEGATIVE : ComparisonType.POSITIVE;
  }

  return comparison;
};

export const formatMetric = (
  metricValue: number,
  metricType: MetricType,
  isComparisonValue: boolean = false,
) => {
  switch (metricType) {
    // percent
    case SleepMetricType.EFFICIENCY:
    case SleepMetricType.PERFORMANCE:
    case RecoveryMetricType.SCORE: {
      return isComparisonValue ? (
        `${Math.round(metricValue)}%`
      ) : (
        <>
          {`${Math.round(metricValue)}`}
          <div className={styles.percent}>%</div>
        </>
      );
    }
    // decimal
    case RecoveryMetricType.RESPIRATORY_RATE:
    case StrainMetricType.STRAIN: {
      return convertStrainToString(metricValue);
    }
    // locale
    case StrainMetricType.CALORIES: {
      return convertToNumberLocale(metricValue);
    }
    // milliseconds
    case RecoveryMetricType.HRV: {
      return convertHRVtoString(metricValue);
    }
    // time (sleep)
    case SleepMetricType.LATENCY:
    case SleepMetricType.DURATION: {
      return getSleepDuration(metricValue);
    }
    // number
    default: {
      return Math.round(metricValue).toString();
    }
  }
};

export const comparisonCorrelation = (
  metricType: MetricType,
  averageValue: number,
  comparisonValue: number,
) => {
  let comparisonType: Comparison;
  switch (metricType) {
    // inverse
    // time
    case SleepMetricType.LATENCY: {
      comparisonType = compareTrend(averageValue, comparisonValue, false, ValueType.TIME);
      break;
    }
    case RecoveryMetricType.RESPIRATORY_RATE: {
      comparisonType = compareTrend(averageValue, comparisonValue, false, ValueType.DECIMAL);
      break;
    }
    case SleepMetricType.DISTURBANCES:
    case RecoveryMetricType.RHR: {
      comparisonType = compareTrend(averageValue, comparisonValue, false, ValueType.NUMBER);
      break;
    }
    // direct
    // Time
    case SleepMetricType.DURATION: {
      comparisonType = compareTrend(averageValue, comparisonValue, true, ValueType.TIME);
      break;
    }
    // Nearest Tenth
    case StrainMetricType.STRAIN: {
      comparisonType = compareTrend(averageValue, comparisonValue, true, ValueType.DECIMAL);
      break;
    }
    case RecoveryMetricType.HRV: {
      comparisonType = compareTrend(
        averageValue * 1000,
        comparisonValue * 1000,
        true,
        ValueType.NUMBER,
      );
      break;
    }
    // Number
    case SleepMetricType.EFFICIENCY:
    case SleepMetricType.CONSISTENCY:
    case SleepMetricType.PERFORMANCE:
    case RecoveryMetricType.SCORE:
    case StrainMetricType.AVG_HR:
    case StrainMetricType.CALORIES: {
      comparisonType = compareTrend(averageValue, comparisonValue, true, ValueType.NUMBER);
      break;
    }
    // no correlation
    default: {
      comparisonType = {
        arrowDirection: ArrowDirection.EQUAL,
      };
    }
  }

  return <ComparisonArrow compare={comparisonType} />;
};
