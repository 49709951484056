import { WidgetType } from 'insights/components/widget/widget';
import { BAR_KEYS_BY_WIDGET } from 'insights/components/widget/widgetFormatter';
import {
  RecoveryBucket,
  SleepDebtBreakdown,
  SleepPerformanceBreakdown,
  StrainBreakdown,
  TrainingZoneBucket,
} from 'api/widgetApi';

type AllBreakdowns = StrainBreakdown | SleepDebtBreakdown
| SleepPerformanceBreakdown | RecoveryBucket | TrainingZoneBucket;

// Get the max value from the data.buckets object
// Make it divisible by 5
export const getMaxBucketSum = (buckets: AllBreakdowns[], widgetType: WidgetType) => {
  let maxSum = 0;
  buckets.forEach((bucket) => {
    let bucketSum: number = 0;
    // Go through each of the bucket keys and add them to the sum
    BAR_KEYS_BY_WIDGET[widgetType].forEach((key: keyof AllBreakdowns) => {
      bucketSum += bucket[key];
    });
    if (bucketSum > maxSum) {
      maxSum = bucketSum;
    }
  });

  // get the next value divisible by 5 from maxSum
  if (maxSum % 5 !== 0) {
    maxSum = Math.ceil(maxSum / 5) * 5;
  }

  // If no data, set maxSum to 10
  if (maxSum === 0) {
    maxSum = 10;
  }

  return maxSum;
};

// Create an array of values from 0 to the next highest value divisible by 5
// This is used to create the 6 grid lines on the graph (from 0 to maxVal)
export const getGridYVals = (maxSum: number) => {
  const highestVal = Math.ceil(maxSum / 5) * 5;
  const multiplier = highestVal / 5;
  const gridYVals = [];
  for (let i = 0; i <= 5; i += 1) {
    gridYVals.push(i * multiplier);
  }
  return gridYVals;
};
