import { Pillars, ActivityTypes } from 'types/analytics';
import dayjs from 'dayjs';
import {
  mockSleepActivities, mockSleepActivityDetail, mockStrainActivities, mockStrainActivityDetail,
} from 'mocks/store/progress/mockActivityData';
import { getNumDays } from 'progress/utils/date';
import { DateRange } from 'react-day-picker';

const PILLAR_TO_ACTIVITY_DETAIL = {
  [Pillars.SLEEP]: mockSleepActivityDetail,
  [Pillars.STRAIN]: mockStrainActivityDetail,
  [Pillars.RECOVERY]: null as any,
};

const createSleepActivityData = (dateRange: DateRange) => {
  const numDays = getNumDays(dateRange);

  // deep copy mockValues
  const clonedMockActivities = [];
  let index = 0;
  let dateOffset = 0;
  while (dateOffset < numDays) {
    // Update the cycle_start_day property to be within the date range passed in from the request
    const nextActivity = {
      ...mockSleepActivities[index],
      cycle_start_date: dayjs(dateRange.from).add(dateOffset, 'day').format('YYYY-MM-DD'),
    };
    if (mockSleepActivities[index].type !== ActivityTypes.NAP) {
      dateOffset += 1;
    }
    index += 1;
    clonedMockActivities.push(nextActivity);
  }

  return clonedMockActivities;
};

const createStrainActivityData = (dateRange: DateRange) => {
  const numDays = getNumDays(dateRange);
  // Show certain number of activities based on the date range length
  let numActivities = 1;
  if (numDays > 1 && numDays <= 7) {
    numActivities = 4;
  } else if (numDays > 7) {
    numActivities = 10;
  }

  const clonedMockValues = [...mockStrainActivities].slice(0, numActivities);
  for (let i = 0; i < numActivities; i += 1) {
    clonedMockValues[i].cycle_start_date = dayjs(dateRange.from).add(i, 'day').format('YYYY-MM-DD');
  }
  return clonedMockValues;
};

export const createActivityDetailData = (pillar: Pillars) => (
  PILLAR_TO_ACTIVITY_DETAIL[pillar]
);

const PILLAR_TO_ACTIVITY_DATA_FUNC = {
  [Pillars.SLEEP]: createSleepActivityData,
  [Pillars.STRAIN]: createStrainActivityData,
  [Pillars.RECOVERY]: null as any,
};

export const createActivityData = (
  dateRange: DateRange,
  pillar: Pillars,
) => PILLAR_TO_ACTIVITY_DATA_FUNC[pillar](dateRange);
