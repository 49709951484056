import { client } from 'api/apiClient';

export enum GroupsLandingStatNames {
  SCALED_DAY_STRAIN = 'SCALED_DAY_STRAIN',
  SLEEP_PERFORMANCE = 'SLEEP_PERFORMANCE',
  RECOVERY_SCORE = 'RECOVERY_SCORE',
}

export enum SENTIMENT {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
}

export type AverageStat = {
  name: GroupsLandingStatNames;
  val: number;
  diff: number;
  sentiment: SENTIMENT;
};

export type LandingGroup = {
  id: number;
  name: string;
  member_count: number;
  average_hidden: boolean;
  average_stats: AverageStat[];
};

export type GroupLanding = {
  salesforce_account_id: string;
  salesforce_account_name: string;
  start_date: string;
  end_date: string;
  groups: LandingGroup[];
};

type GroupLandingParams = {
  salesforceAccountId: string;
  startDate: string;
  endDate: string;
};

export const getGroupLanding = async (params: GroupLandingParams) => {
  const response = await client.get<GroupLanding>('enterprise-service/v1/groups/landing', { params });
  return response.data;
};

export type OptimalStrainMapping = {
  recovery: number;
  lower_optimal_strain: number;
  optimal_strain: number;
  upper_optimal_strain: number;
};

// Temp mock API till BE writes one
export const getTrainingAdaptation: OptimalStrainMapping[] = [
  {
    recovery: 0,
    lower_optimal_strain: 2.5,
    optimal_strain: 5.0,
    upper_optimal_strain: 7.5,
  },
  {
    recovery: 1,
    lower_optimal_strain: 2.7189533015990865,
    optimal_strain: 5.196400773480657,
    upper_optimal_strain: 7.673868869481044,
  },
  {
    recovery: 2,
    lower_optimal_strain: 2.936148901759088,
    optimal_strain: 5.391187751274845,
    upper_optimal_strain: 7.846306519251022,
  },
  {
    recovery: 3,
    lower_optimal_strain: 3.1514406508112103,
    optimal_strain: 5.584245621541945,
    upper_optimal_strain: 8.017224608275207,
  },
  {
    recovery: 4,
    lower_optimal_strain: 3.364682399086662,
    optimal_strain: 5.775459072441342,
    upper_optimal_strain: 8.186534795518885,
  },
  {
    recovery: 5,
    lower_optimal_strain: 3.5757279969166516,
    optimal_strain: 5.9647127921324214,
    upper_optimal_strain: 8.354148739947336,
  },
  {
    recovery: 6,
    lower_optimal_strain: 3.784431294632384,
    optimal_strain: 6.151891468774562,
    upper_optimal_strain: 8.519978100525838,
  },
  {
    recovery: 7,
    lower_optimal_strain: 3.990646142565069,
    optimal_strain: 6.336879790527151,
    upper_optimal_strain: 8.683934536219674,
  },
  {
    recovery: 8,
    lower_optimal_strain: 4.194226391045912,
    optimal_strain: 6.519562445549567,
    upper_optimal_strain: 8.845929705994124,
  },
  {
    recovery: 9,
    lower_optimal_strain: 4.395025890406121,
    optimal_strain: 6.699824122001196,
    upper_optimal_strain: 9.005875268814467,
  },
  {
    recovery: 10,
    lower_optimal_strain: 4.5928984909769035,
    optimal_strain: 6.877549508041422,
    upper_optimal_strain: 9.163682883645986,
  },
  {
    recovery: 11,
    lower_optimal_strain: 4.787698043089467,
    optimal_strain: 7.052623291829626,
    upper_optimal_strain: 9.319264209453959,
  },
  {
    recovery: 12,
    lower_optimal_strain: 4.979278397075017,
    optimal_strain: 7.224930161525188,
    upper_optimal_strain: 9.472530905203664,
  },
  {
    recovery: 13,
    lower_optimal_strain: 5.167493403264763,
    optimal_strain: 7.394354805287499,
    upper_optimal_strain: 9.623394629860385,
  },
  {
    recovery: 14,
    lower_optimal_strain: 5.352196911989911,
    optimal_strain: 7.560781911275934,
    upper_optimal_strain: 9.771767042389405,
  },
  {
    recovery: 15,
    lower_optimal_strain: 5.53324277358167,
    optimal_strain: 7.724096167649883,
    upper_optimal_strain: 9.917559801756,
  },
  {
    recovery: 16,
    lower_optimal_strain: 5.710484838371247,
    optimal_strain: 7.884182262568725,
    upper_optimal_strain: 10.060684566925453,
  },
  {
    recovery: 17,
    lower_optimal_strain: 5.883776956689849,
    optimal_strain: 8.040924884191845,
    upper_optimal_strain: 10.201052996863044,
  },
  {
    recovery: 18,
    lower_optimal_strain: 6.052972978868681,
    optimal_strain: 8.194208720678622,
    upper_optimal_strain: 10.33857675053405,
  },
  {
    recovery: 19,
    lower_optimal_strain: 6.217926755238954,
    optimal_strain: 8.343918460188446,
    upper_optimal_strain: 10.473167486903758,
  },
  {
    recovery: 20,
    lower_optimal_strain: 6.3784921361318725,
    optimal_strain: 8.489938790880693,
    upper_optimal_strain: 10.604736864937442,
  },
  {
    recovery: 21,
    lower_optimal_strain: 6.534522971878645,
    optimal_strain: 8.63215440091475,
    upper_optimal_strain: 10.733196543600386,
  },
  {
    recovery: 22,
    lower_optimal_strain: 6.68587311281048,
    optimal_strain: 8.770449978450001,
    upper_optimal_strain: 10.858458181857868,
  },
  {
    recovery: 23,
    lower_optimal_strain: 6.832396409258584,
    optimal_strain: 8.904710211645828,
    upper_optimal_strain: 10.980433438675172,
  },
  {
    recovery: 24,
    lower_optimal_strain: 6.973946711554161,
    optimal_strain: 9.03481978866161,
    upper_optimal_strain: 11.099033973017576,
  },
  {
    recovery: 25,
    lower_optimal_strain: 7.110377870028423,
    optimal_strain: 9.160663397656734,
    upper_optimal_strain: 11.214171443850358,
  },
  {
    recovery: 26,
    lower_optimal_strain: 7.241543735012577,
    optimal_strain: 9.282125726790586,
    upper_optimal_strain: 11.325757510138807,
  },
  {
    recovery: 27,
    lower_optimal_strain: 7.367298156837827,
    optimal_strain: 9.399091464222542,
    upper_optimal_strain: 11.433703830848193,
  },
  {
    recovery: 28,
    lower_optimal_strain: 7.487494985835384,
    optimal_strain: 9.51144529811199,
    upper_optimal_strain: 11.537922064943803,
  },
  {
    recovery: 29,
    lower_optimal_strain: 7.601988072336452,
    optimal_strain: 9.619071916618312,
    upper_optimal_strain: 11.638323871390917,
  },
  {
    recovery: 30,
    lower_optimal_strain: 7.71063126667224,
    optimal_strain: 9.72185600790089,
    upper_optimal_strain: 11.73482090915481,
  },
  {
    recovery: 31,
    lower_optimal_strain: 7.813278419173957,
    optimal_strain: 9.81968226011911,
    upper_optimal_strain: 11.82732483720077,
  },
  {
    recovery: 32,
    lower_optimal_strain: 7.909783380172807,
    optimal_strain: 9.912435361432351,
    upper_optimal_strain: 11.915747314494073,
  },
  {
    recovery: 33,
    lower_optimal_strain: 8.0,
    optimal_strain: 10.0,
    upper_optimal_strain: 12.0,
  },
  {
    recovery: 34,
    lower_optimal_strain: 8.08464398168518,
    optimal_strain: 10.08267345430021,
    upper_optimal_strain: 12.080041741372074,
  },
  {
    recovery: 35,
    lower_optimal_strain: 8.16471638055313,
    optimal_strain: 10.161010558620701,
    upper_optimal_strain: 12.156061295005623,
  },
  {
    recovery: 36,
    lower_optimal_strain: 8.240499075377786,
    optimal_strain: 10.235282334835208,
    upper_optimal_strain: 12.228315182978632,
  },
  {
    recovery: 37,
    lower_optimal_strain: 8.312273944933064,
    optimal_strain: 10.305759804817464,
    upper_optimal_strain: 12.297059927369085,
  },
  {
    recovery: 38,
    lower_optimal_strain: 8.380322867992891,
    optimal_strain: 10.372713990441197,
    upper_optimal_strain: 12.362552050254969,
  },
  {
    recovery: 39,
    lower_optimal_strain: 8.444927723331194,
    optimal_strain: 10.436415913580143,
    upper_optimal_strain: 12.425048073714263,
  },
  {
    recovery: 40,
    lower_optimal_strain: 8.506370389721898,
    optimal_strain: 10.497136596108035,
    upper_optimal_strain: 12.484804519824962,
  },
  {
    recovery: 41,
    lower_optimal_strain: 8.564932745938926,
    optimal_strain: 10.555147059898601,
    upper_optimal_strain: 12.542077910665038,
  },
  {
    recovery: 42,
    lower_optimal_strain: 8.620896670756206,
    optimal_strain: 10.610718326825582,
    upper_optimal_strain: 12.597124768312492,
  },
  {
    recovery: 43,
    lower_optimal_strain: 8.674544042947657,
    optimal_strain: 10.664121418762697,
    upper_optimal_strain: 12.65020161484529,
  },
  {
    recovery: 44,
    lower_optimal_strain: 8.726156741287213,
    optimal_strain: 10.715627357583692,
    upper_optimal_strain: 12.701564972341439,
  },
  {
    recovery: 45,
    lower_optimal_strain: 8.776016644548788,
    optimal_strain: 10.765507165162287,
    upper_optimal_strain: 12.751471362878902,
  },
  {
    recovery: 46,
    lower_optimal_strain: 8.824405631506318,
    optimal_strain: 10.814031863372225,
    upper_optimal_strain: 12.800177308535684,
  },
  {
    recovery: 47,
    lower_optimal_strain: 8.87160558093372,
    optimal_strain: 10.86147247408723,
    upper_optimal_strain: 12.847939331389755,
  },
  {
    recovery: 48,
    lower_optimal_strain: 8.917898371604924,
    optimal_strain: 10.908100019181042,
    upper_optimal_strain: 12.895013953519106,
  },
  {
    recovery: 49,
    lower_optimal_strain: 8.963565882293851,
    optimal_strain: 10.954185520527387,
    upper_optimal_strain: 12.941657697001723,
  },
  {
    recovery: 50,
    lower_optimal_strain: 9.008889991774428,
    optimal_strain: 11.0,
    upper_optimal_strain: 12.988127083915588,
  },
  {
    recovery: 51,
    lower_optimal_strain: 9.054152578820581,
    optimal_strain: 11.045814479472613,
    upper_optimal_strain: 13.03467863633869,
  },
  {
    recovery: 52,
    lower_optimal_strain: 9.099635522206233,
    optimal_strain: 11.091899980818958,
    upper_optimal_strain: 13.08156887634901,
  },
  {
    recovery: 53,
    lower_optimal_strain: 9.14562070070531,
    optimal_strain: 11.138527525912767,
    upper_optimal_strain: 13.129054326024534,
  },
  {
    recovery: 54,
    lower_optimal_strain: 9.192389993091737,
    optimal_strain: 11.185968136627775,
    upper_optimal_strain: 13.177391507443248,
  },
  {
    recovery: 55,
    lower_optimal_strain: 9.240225278139441,
    optimal_strain: 11.234492834837711,
    upper_optimal_strain: 13.22683694268314,
  },
  {
    recovery: 56,
    lower_optimal_strain: 9.289408434622342,
    optimal_strain: 11.28437264241631,
    upper_optimal_strain: 13.277647153822187,
  },
  {
    recovery: 57,
    lower_optimal_strain: 9.340221341314368,
    optimal_strain: 11.3358785812373,
    upper_optimal_strain: 13.330078662938382,
  },
  {
    recovery: 58,
    lower_optimal_strain: 9.392945876989444,
    optimal_strain: 11.38928167317442,
    upper_optimal_strain: 13.384387992109707,
  },
  {
    recovery: 59,
    lower_optimal_strain: 9.447863920421497,
    optimal_strain: 11.444852940101397,
    upper_optimal_strain: 13.440831663414148,
  },
  {
    recovery: 60,
    lower_optimal_strain: 9.505257350384447,
    optimal_strain: 11.502863403891965,
    upper_optimal_strain: 13.499666198929686,
  },
  {
    recovery: 61,
    lower_optimal_strain: 9.565408045652223,
    optimal_strain: 11.563584086419855,
    upper_optimal_strain: 13.561148120734309,
  },
  {
    recovery: 62,
    lower_optimal_strain: 9.628597884998749,
    optimal_strain: 11.627286009558802,
    upper_optimal_strain: 13.625533950906004,
  },
  {
    recovery: 63,
    lower_optimal_strain: 9.69510874719795,
    optimal_strain: 11.694240195182537,
    upper_optimal_strain: 13.693080211522753,
  },
  {
    recovery: 64,
    lower_optimal_strain: 9.765222511023751,
    optimal_strain: 11.764717665164792,
    upper_optimal_strain: 13.764043424662542,
  },
  {
    recovery: 65,
    lower_optimal_strain: 9.839221055250075,
    optimal_strain: 11.838989441379299,
    upper_optimal_strain: 13.838680112403356,
  },
  {
    recovery: 66,
    lower_optimal_strain: 9.91738625865085,
    optimal_strain: 11.91732654569979,
    upper_optimal_strain: 13.917246796823179,
  },
  {
    recovery: 67,
    lower_optimal_strain: 10.0,
    optimal_strain: 12.0,
    upper_optimal_strain: 14.0,
  },
  {
    recovery: 68,
    lower_optimal_strain: 10.087564638567649,
    optimal_strain: 12.087564638567649,
    upper_optimal_strain: 14.087564638567649,
  },
  {
    recovery: 69,
    lower_optimal_strain: 10.180317739880891,
    optimal_strain: 12.180317739880891,
    upper_optimal_strain: 14.180317739880891,
  },
  {
    recovery: 70,
    lower_optimal_strain: 10.278143992099109,
    optimal_strain: 12.278143992099109,
    upper_optimal_strain: 14.278143992099107,
  },
  {
    recovery: 71,
    lower_optimal_strain: 10.380928083381686,
    optimal_strain: 12.380928083381688,
    upper_optimal_strain: 14.380928083381686,
  },
  {
    recovery: 72,
    lower_optimal_strain: 10.48855470188801,
    optimal_strain: 12.48855470188801,
    upper_optimal_strain: 14.488554701888011,
  },
  {
    recovery: 73,
    lower_optimal_strain: 10.600908535777457,
    optimal_strain: 12.600908535777455,
    upper_optimal_strain: 14.600908535777457,
  },
  {
    recovery: 74,
    lower_optimal_strain: 10.717874273209416,
    optimal_strain: 12.717874273209416,
    upper_optimal_strain: 14.717874273209414,
  },
  {
    recovery: 75,
    lower_optimal_strain: 10.839336602343266,
    optimal_strain: 12.839336602343266,
    upper_optimal_strain: 14.839336602343266,
  },
  {
    recovery: 76,
    lower_optimal_strain: 10.96518021133839,
    optimal_strain: 12.96518021133839,
    upper_optimal_strain: 14.965180211338389,
  },
  {
    recovery: 77,
    lower_optimal_strain: 11.095289788354176,
    optimal_strain: 13.095289788354176,
    upper_optimal_strain: 15.095289788354176,
  },
  {
    recovery: 78,
    lower_optimal_strain: 11.22955002155,
    optimal_strain: 13.229550021550002,
    upper_optimal_strain: 15.229550021550004,
  },
  {
    recovery: 79,
    lower_optimal_strain: 11.36784559908525,
    optimal_strain: 13.36784559908525,
    upper_optimal_strain: 15.36784559908525,
  },
  {
    recovery: 80,
    lower_optimal_strain: 11.510061209119307,
    optimal_strain: 13.510061209119307,
    upper_optimal_strain: 15.510061209119307,
  },
  {
    recovery: 81,
    lower_optimal_strain: 11.656081539811552,
    optimal_strain: 13.656081539811552,
    upper_optimal_strain: 15.656081539811554,
  },
  {
    recovery: 82,
    lower_optimal_strain: 11.805791279321376,
    optimal_strain: 13.805791279321376,
    upper_optimal_strain: 15.805791279321376,
  },
  {
    recovery: 83,
    lower_optimal_strain: 11.959075115808158,
    optimal_strain: 13.959075115808158,
    upper_optimal_strain: 15.959075115808156,
  },
  {
    recovery: 84,
    lower_optimal_strain: 12.115817737431275,
    optimal_strain: 14.115817737431275,
    upper_optimal_strain: 16.115817737431275,
  },
  {
    recovery: 85,
    lower_optimal_strain: 12.275903832350117,
    optimal_strain: 14.275903832350117,
    upper_optimal_strain: 16.275903832350117,
  },
  {
    recovery: 86,
    lower_optimal_strain: 12.439218088724065,
    optimal_strain: 14.439218088724067,
    upper_optimal_strain: 16.439218088724065,
  },
  {
    recovery: 87,
    lower_optimal_strain: 12.605645194712501,
    optimal_strain: 14.605645194712503,
    upper_optimal_strain: 16.6056451947125,
  },
  {
    recovery: 88,
    lower_optimal_strain: 12.775069838474813,
    optimal_strain: 14.775069838474813,
    upper_optimal_strain: 16.775069838474813,
  },
  {
    recovery: 89,
    lower_optimal_strain: 12.947376708170378,
    optimal_strain: 14.947376708170376,
    upper_optimal_strain: 16.947376708170378,
  },
  {
    recovery: 90,
    lower_optimal_strain: 13.12245049195858,
    optimal_strain: 15.122450491958581,
    upper_optimal_strain: 17.12245049195858,
  },
  {
    recovery: 91,
    lower_optimal_strain: 13.300175877998804,
    optimal_strain: 15.300175877998804,
    upper_optimal_strain: 17.300175877998804,
  },
  {
    recovery: 92,
    lower_optimal_strain: 13.480437554450434,
    optimal_strain: 15.480437554450436,
    upper_optimal_strain: 17.480437554450432,
  },
  {
    recovery: 93,
    lower_optimal_strain: 13.663120209472849,
    optimal_strain: 15.663120209472849,
    upper_optimal_strain: 17.66312020947285,
  },
  {
    recovery: 94,
    lower_optimal_strain: 13.848108531225439,
    optimal_strain: 15.848108531225439,
    upper_optimal_strain: 17.84810853122544,
  },
  {
    recovery: 95,
    lower_optimal_strain: 14.03528720786758,
    optimal_strain: 16.03528720786758,
    upper_optimal_strain: 18.035287207867583,
  },
  {
    recovery: 96,
    lower_optimal_strain: 14.224540927558657,
    optimal_strain: 16.224540927558657,
    upper_optimal_strain: 18.224540927558657,
  },
  {
    recovery: 97,
    lower_optimal_strain: 14.415754378458056,
    optimal_strain: 16.415754378458054,
    upper_optimal_strain: 18.415754378458054,
  },
  {
    recovery: 98,
    lower_optimal_strain: 14.608812248725158,
    optimal_strain: 16.608812248725158,
    upper_optimal_strain: 18.608812248725158,
  },
  {
    recovery: 99,
    lower_optimal_strain: 14.803599226519342,
    optimal_strain: 16.803599226519342,
    upper_optimal_strain: 18.803599226519342,
  },
  {
    recovery: 100,
    lower_optimal_strain: 15.0,
    optimal_strain: 17.0,
    upper_optimal_strain: 19.0,
  },
];
