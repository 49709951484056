import { classes } from '@whoop/web-components';
import {
  ReactNode,
} from 'react';
import styles from './widget.module.scss';

export enum WidgetType {
  RECOVERY = 'recovery',
  SLEEP_PERFORMANCE = 'sleep',
  SLEEP_DEBT = 'sleep-debt',
  DAY_STRAIN = 'strain',
  TRAINING_ZONE = 'training-zone',
}

type WidgetWrapperProps = {
  children: ReactNode;
  id: string;
};

export function Widget({ children, id }: WidgetWrapperProps) {
  return (
    <div data-testid={id} className={classes(styles.widgetContainer)}>
      {children}
    </div>
  );
}
