import { HEADING_4 } from 'components/text';
import { Icon } from '@whoop/web-components';
import styles from './pagination.module.scss';

type PaginationProps = {
  previousPage : () => void;
  canPreviousPage: boolean;
  nextPage: () => void;
  canNextPage: boolean;
  rangeStart: number;
  rangeEnd: number;
  total: number;
};

function Pagination({
  previousPage, canPreviousPage, nextPage, canNextPage, rangeStart, rangeEnd, total,
} : PaginationProps) {
  return (
    <div className={styles.pagination}>
      {total > 0 && (
        <>
          <button
            data-testid="pagination-prev-btn"
            className={styles.button}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            type="button"
          >
            <div className={`${canPreviousPage ? styles.circleBackground : ''}`}>
              <Icon name="caret_left" className={styles.paginationArrows} />
            </div>
          </button>
          <span className={styles.paginationValues}>
            <HEADING_4>{`${rangeStart}-${rangeEnd} of ${total}`}</HEADING_4>
          </span>
          <button
            data-testid="pagination-next-btn"
            className={styles.button}
            onClick={() => nextPage()}
            disabled={!canNextPage}
            type="button"
          >
            <div className={`${canNextPage ? styles.circleBackground : ''}`}>
              <Icon name="caret_right" className={styles.paginationArrows} />
            </div>
          </button>
        </>
      )}
    </div>
  );
}

export default Pagination;
