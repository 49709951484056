import StrapManagementHeader from 'components/strapManagementHeader';
import { BODY_SHORT_1 } from 'components/text';
import Toast from 'components/toast';
import {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import RemovalModal from 'removal/removal';
import ResendInviteModal from 'resendInvite/resendInvite';
import WithdrawInviteModal from 'withdrawInvite/withdrawInvite';
import InviteDeactivatedModal from 'inviteDeactivated/inviteDeactivated';
import Loading from 'loading';
import { PAGE_STATE } from 'constants/pageState';
import { DEFAULT_STATUS_FILTERS } from 'constants/accountTable';
import AddMembersToGroup from 'groups/addMembersToGroup';
import { useAccounts } from 'context/account-context';
import { SeatStatus } from 'types/seat';
import InviteModal from 'wizards/invite/invite';
import { getAllSeats } from 'api/seatApi';
import StrapsTable from './strapsTable/strapsTable';
import styles from './account.module.scss';

const createSeatsByStatus = (seats) => {
  const seatsMap = new Map([
    [SeatStatus.Active, []],
    [SeatStatus.Invited, []],
    [SeatStatus.Pending, []],
    [SeatStatus.Deactivated, []],
    [SeatStatus.Unknown, []],
  ]);

  return seats.reduce(
    (acc, seat) => {
      // Filtering out unexpected statuses
      // should chat with Beth about how we want to handle these status edge cases.
      if (acc.has(seat.status)) {
        acc.get(seat.status).push(seat);
      }

      return acc;
    },
    seatsMap,
  );
};

function AccountPage() {
  const { account, loading: loadingAccounts } = useAccounts();

  const [loading, setLoading] = useState(true);
  const [allSeats, setAllSeats] = useState([]);
  const [statusFilters, setStatusFilters] = useState(DEFAULT_STATUS_FILTERS);
  const [pageState, setPageState] = useState(PAGE_STATE.DASHBOARD);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const seatsByStatus = useMemo(() => createSeatsByStatus(allSeats), [allSeats]);

  // This is so accountSettings integration tests will run without throwing the
  // Can't perform a React state update on an unmounted component error
  useEffect(() => () => {}, []);

  const fetchSeatData = useCallback(async () => {
    if (!loadingAccounts) {
      setLoading(true);
      try {
        const { data } = await getAllSeats();
        setAllSeats(data);
      } catch (err) {
        setAllSeats([]);
      }
      setLoading(false);
    }
  }, [loadingAccounts, account]);

  const triggerToast = (message) => {
    setToastMessage(message);
    setToastOpen(true);
  };

  if (loadingAccounts) {
    return <Loading />;
  }

  if (pageState === PAGE_STATE.INVITE_MEMBERS) {
    return (
      <InviteModal
        refetchData={fetchSeatData}
        pageState={pageState}
        setPageState={setPageState}
        existingMemberSeats={
          seatsByStatus.get(SeatStatus.Pending).concat(seatsByStatus.get(SeatStatus.Active))
        }
      />
    );
  }

  if (pageState === PAGE_STATE.RESEND_INVITES) {
    const resendRows = selectedRows.filter(
      (row) => row.original.status === SeatStatus.Invited,
    );
    return (
      <ResendInviteModal
        selectedRows={resendRows}
        setSelectedRows={setSelectedRows}
        refetchData={fetchSeatData}
        pageState={pageState}
        setPageState={setPageState}
        triggerToast={triggerToast}
        invitedSeatStatus={SeatStatus.Invited}
      />
    );
  }

  if (pageState === PAGE_STATE.REINIVTE_DEACTIVATED) {
    const resendRows = selectedRows.filter(
      (row) => row.original.status === SeatStatus.Deactivated,
    );
    return (
      <InviteDeactivatedModal
        selectedRows={resendRows}
        setSelectedRows={setSelectedRows}
        refetchData={fetchSeatData}
        pageState={pageState}
        setPageState={setPageState}
        triggerToast={triggerToast}
        invitedSeatStatus={SeatStatus.Deactivated}
      />
    );
  }

  if (pageState === PAGE_STATE.REMOVE_MEMBERS) {
    return (
      <RemovalModal
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        refetchData={fetchSeatData}
        pageState={pageState}
        setPageState={setPageState}
        triggerToast={triggerToast}
      />
    );
  }

  if (pageState === PAGE_STATE.WITHDRAW_INVITES) {
    const withdrawRows = selectedRows.filter(
      (row) => row.original.status === 'invited',
    );
    return (
      <WithdrawInviteModal
        selectedRows={withdrawRows}
        setSelectedRows={setSelectedRows}
        refetchData={fetchSeatData}
        pageState={pageState}
        setPageState={setPageState}
        triggerToast={triggerToast}
      />
    );
  }

  if (pageState === PAGE_STATE.ADD_TO_GROUP) {
    return (
      <AddMembersToGroup
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        pageState={pageState}
        setPageState={setPageState}
        triggerToast={triggerToast}
      />
    );
  }

  return (
    <>
      <div className={styles.accountContainer}>
        <StrapManagementHeader account={account} />
        <div className={styles.tableContainer}>
          <div className={styles.table}>
            <StrapsTable
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setPageState={setPageState}
              seatsByStatus={seatsByStatus}
              data={allSeats}
              fetchData={fetchSeatData}
              loading={loading}
              statusFilters={statusFilters}
              setStatusFilters={setStatusFilters}
            />
          </div>
        </div>
      </div>
      <Toast open={toastOpen} setOpen={setToastOpen}>
        <BODY_SHORT_1>{toastMessage}</BODY_SHORT_1>
      </Toast>
    </>
  );
}

export default AccountPage;
