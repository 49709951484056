export const formatValue = (pieVal: number, displayVal: string) => {
  const formattedDisplayVal = displayVal === null ? -1 : displayVal;
  const emptyValue = 100 - pieVal;
  return [
    {
      id: 'value',
      label: 'value',
      value: pieVal,
      // need to check that if the formattedValue is -1 the value is null or undefined
      displayVal: formattedDisplayVal === -1 ? '--' : formattedDisplayVal,
    },
    {
      id: 'emptyCircle',
      label: 'emptyCircle',
      value: emptyValue,
      displayVal: emptyValue,
    },
  ];
};
