import { useState } from 'react';
import { Button } from '@whoop/web-components';
import { removeMember } from 'api/seatApi';
import { PAGE_STATE } from 'constants/pageState';
import Loading from 'loading';
import { trackEvent } from 'helpers/analyticsTracking';
import { useAccounts } from 'context/account-context';
import ActionModal from 'components/actionModal/actionModal';
import { MEMBERS_COLUMN, SELECTION_COLUMN } from 'tableUtils/tableColumns';
import { SeatDetail } from 'types/seat';
import createHeading from 'helpers/modalUtils';

type RemovalModalProps = {
  selectedRows: any[];
  setSelectedRows: (selectedRows: any[]) => void;
  refetchData: () => void;
  setPageState: (pageState: string) => void;
  triggerToast: (message: string) => void;
};

function RemovalModal(props: RemovalModalProps) {
  const {
    selectedRows,
    setSelectedRows,
    refetchData,
    setPageState,
    triggerToast,
  } = props;
  const { account } = useAccounts();
  const [loading, setLoading] = useState(false);
  const { updateAccount } = useAccounts();

  const toastMessage = (fulfilled: number, failed: number) => {
    let msg = '';
    if (fulfilled) {
      msg += `${fulfilled} member${
        fulfilled === 1 ? ' has' : 's have'
      } been removed and will no longer upload data to your enterprise account.`;
    }
    if (failed) {
      msg += ` ${failed} member failed to be removed.`;
    }
    return msg;
  };

  const handleRemoveMembers = async () => {
    trackEvent('Remove Member - Confirm Page Button Click', {
      salesforceAccountId: account.id,
    });
    setLoading(true);
    const promises = selectedRows.map((member) => removeMember(member.original.id));

    const results = await Promise.allSettled(promises);
    const numFulfilled = results.filter(
      (res) => res.status === 'fulfilled',
    ).length;
    const numFailed = promises.length - numFulfilled;
    setLoading(false);
    setSelectedRows([]);
    setPageState(PAGE_STATE.DASHBOARD);
    await refetchData();
    updateAccount();
    triggerToast(toastMessage(numFulfilled, numFailed));
  };

  const ActionButton = (
    <Button
      label={`Remove ${selectedRows.length} ${
        selectedRows.length === 1 ? 'member' : 'members'
      }`}
      onClick={handleRemoveMembers}
      disabled={selectedRows.length < 1}
      variant="primary"
    />
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <ActionModal
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      setPageState={setPageState}
      ActionButton={ActionButton}
      title="Are you sure you want to remove these team members?"
      body="The following team members will no longer be associated with this enterprise account."
      heading={createHeading(selectedRows, 'member', 'members')}
      columnList={[SELECTION_COLUMN, MEMBERS_COLUMN]}
      rowAccessor={(row: SeatDetail) => row.id}
    />
  );
}

export default RemovalModal;
