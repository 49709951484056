import { useState } from 'react';
import {
  DEFAULT_PILLAR_METRIC_TYPE, MetricType, Pillars,
} from 'types/analytics';
import ActivitiesTable from 'progress/components/ActivitiesTable/activitiesTable';
import PillarBreakdownBarCard from 'progress/components/pillarBreakdownBarCard/pillarBreakdownBarCard';
import StatisticTrendChart from 'progress/components/statisticTrendChart/statisticTrendChart';
import StatisticsCard from 'progress/components/statisticsCard/statisticsCard';
import { Row, Col } from '@whoop/web-components';
import layoutStyles from '../layout.module.scss';

function RangeView() {
  const [selectedMetricType, setSelectedMetricType] = useState<MetricType>(
    DEFAULT_PILLAR_METRIC_TYPE[Pillars.STRAIN],
  );

  return (
    <>
      <Row center="xs" className={layoutStyles.topCardsContainer}>
        <Col xs={4}>
          <PillarBreakdownBarCard pillar={Pillars.STRAIN} />
        </Col>
        <Col xs={8}>
          <StatisticsCard
            pillar={Pillars.STRAIN}
            selectedMetricType={selectedMetricType}
            setSelectedMetricType={setSelectedMetricType}
          />
        </Col>
      </Row>
      <Row center="xs" className={layoutStyles.bottomCardsContainer}>
        <Col xs={4}>
          <ActivitiesTable pillar={Pillars.STRAIN} />
        </Col>
        <Col xs={8}>
          <StatisticTrendChart selectedMetricType={selectedMetricType} />
        </Col>
      </Row>
    </>
  );
}

export default RangeView;
