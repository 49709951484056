import { classes } from '@whoop/web-components';
import { ReactNode } from 'react';
import styles from './loadingSkeleton.module.scss';

type LoadingSkeletonProps = {
  isLoading: boolean;
  children?: ReactNode;
};

function LoadingSkeleton({ isLoading, children }: LoadingSkeletonProps) {
  if (isLoading) {
    return <div className={classes(styles.loadingAnimation)} />;
  }

  return <>{children}</>;
}

export default LoadingSkeleton;
