import { Serie } from '@nivo/line';

// Details needed for annotations
export type OverviewGraphAnnotation = {
  title: string;
  link: string;
  activityName: string;
  duration: string;
};

// This adds custom properties needed for annotations and tooltips
// Series is one piece that makes up a line graph.
// There are series for each sleep/activity/overview chunk of data
export interface Series extends Serie {
  color: string;
  annotationData?: OverviewGraphAnnotation;
  navigate?: (link: string) => void;
}

export enum SeriesType {
  OVERVIEW = 'overview',
  ACTIVITY = 'activity',
  SLEEP = 'sleep',
}

// This is specific to our code that is used in custom layers
export type SeriesDetails = {
  type: SeriesType;
  details?: any;
  color: string;
};

export type DayHR = {
  activity_id: string;
  metrics: HRMetric[];
};

export type HRMetric = {
  hr: number;
  timestamp: number;
  user_id: number;
};

export type ActivityDetails = {
  id: string;
  cycle_id: number;
  cycle_day_start: number[];
  user_id: number;
  created_at: string;
  updated_at: string;
  version: number;
  during: string;
  timezone: string;
  timezone_offset: string;
  source: string;
  score_state: string;
  score_type: string;
  type: string;
  translated_type: string;
  source_id: string;
  activity_v1_id: number;
  scaled_intensity_score: number;
  raw_intensity_score: number;
  percent_recorded: number;
  kilojoules: number;
  max_heart_rate: number;
  average_heart_rate: number;
  // zone_durations: ZoneDuration;
  gps_data: [];
};

type SleepDetails = {
  created_at: string;
  cycle_id: number;
  during: string;
  id: string;
  nap: boolean;
  sleep_score: number;
  updated_at: string;
  user_id: number;
  version: number;
};

export type HROverview = {
  day_hr: DayHR[];
  activities: Record<string, ActivityDetails>;
  sleeps: Record<string, SleepDetails>;
};
