import MessagePage from 'messagePage/messagePage';

function NoAccessPage() {
  return (
    <MessagePage
      icon="error"
      title="Hmm, we couldn’t show this page"
      subtitle=" This page may require permissions. Try reaching out to your admin if you think there is an error."
      showRedirectButton
    />
  );
}

export default NoAccessPage;
