import { Icon } from '@whoop/web-components';
import { useEffect, useState } from 'react';
import fuzzysort from 'fuzzysort';
import { UseStatePropType } from 'types/useStatePropType';
import styles from './fuzzySearch.module.scss';

type FuzzySearchProps<S extends object> = {
  searchables: S[];
  searchKeys: string[];
  limit?: number;
  placeholder?: string;
  setFilteredSearch: UseStatePropType<S[]>;
};

function FuzzySearch<S extends object>({
  searchables, searchKeys, limit = 5, placeholder, setFilteredSearch,
}: FuzzySearchProps<S>) {
  const [searchInput, setSearchInput] = useState<string>('');

  useEffect(() => {
    const res = fuzzysort.go(
      searchInput,
      searchables,
      {
        keys: searchKeys,
        limit,
      },
    ).map((item) => item.obj);

    setFilteredSearch(res);
  }, [searchInput]);

  return (
    <>
      <Icon className={styles.searchIcon} name="search" />
      <input
        type="text"
        placeholder={placeholder}
        onChange={(event) => setSearchInput(event.target.value)}
        value={searchInput}
        className={styles.fuzzySearchInput}
      />
    </>
  );
}

export default FuzzySearch;
