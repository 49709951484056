import Card from 'progress/components/Card/card';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import { Pillars, StageDuration } from 'types/analytics';
import { MoreInfoText } from 'components/IconTooltip/iconTooltipTypes';
import { ActivityOverview } from 'api/analyticsApi';
import { Colors } from '@whoop/web-components';
import { convertMillisecondsToSecond, durationFormatter } from 'progress/utils/numberFormatter';
import HorizontalBarGraph from 'dataVisualizations/horizontalBarGraph/horizontalBarGraph';
import useActivityById from 'api/hooks/useActivityById';
import styles from './zoneDurationsBarCard.module.scss';

const convertDurations = (zoneDurations: StageDuration[]) => zoneDurations.map(
  (zone) => convertMillisecondsToSecond(zone.stage_duration),
);
const ZONES_DISPLAY_NAMES = {
  [Pillars.SLEEP]: 'Sleep Stages',
  [Pillars.STRAIN]: 'Heart Rate Zones',
  [Pillars.RECOVERY]: '',
};

const hrZonesMoreInfo: MoreInfoText = {
  header: 'What are heart rate zones?',
  details: 'Heart rate zones are a percentage of your maximum heart rate (heartbeats per minute).\n\nExercise too close to your maximum HR (Mhr) and your heart and body will struggle to keep up with the demands.',
};

const sleepZonesMoreInfo: MoreInfoText = {
  header: 'What are Sleep Stages??',
  details: 'Sleep is a dynamic process. The average healthy adult will experience 3-5 sleep cycles per night.\n\nWithin those cycles, there are 4 distinct sleep stages: Light Sleep, Deep/Slow Wave Sleep (SWS), Rapid Eye Movement (REM) Sleep and Wake.',
};

const ZONES_MORE_INFO_TEXT = {
  [Pillars.SLEEP]: sleepZonesMoreInfo,
  [Pillars.STRAIN]: hrZonesMoreInfo,
  [Pillars.RECOVERY]: { header: '', details: '' },
};

export const COLORS_MAP = {
  [Pillars.STRAIN]: [
    Colors.blackAlpha300, Colors.hrZoneGrey, Colors.hrZoneBlue,
    Colors.hrZoneTeal, Colors.hrZoneOrange, Colors.hrZoneRed,
  ],
  [Pillars.SLEEP]: [Colors.sleepZoneMagenta, Colors.sleepZonePink,
    Colors.sleepZonePurple, Colors.gray500,
  ],
  [Pillars.RECOVERY]: [] as string[],
};

export const ZONE_LABELS_MAP = {
  [Pillars.STRAIN]: ['0-49%', '50-59%', '60-69%', '70-79%', '80-89%', '90-100%'],
  [Pillars.SLEEP]: ['REM', 'SWS Deep', 'Light', 'Awake'],
  [Pillars.RECOVERY]: [] as string[],
};

type ZoneDurationsBarCardProps = {
  pillar: Pillars;
  activitySelected: ActivityOverview;
};

function ZoneDurationsBarCard({ pillar, activitySelected }: ZoneDurationsBarCardProps) {
  // Due to issue with Nivo rendering, we need to set the data to null before we load a new activity
  // So, if the activitySelected is null, we return empty data
  const { data: activity, isLoading } = activitySelected
    ? useActivityById(pillar, activitySelected.id)
    : { data: { stage_durations: [] as StageDuration[] }, isLoading: true };

  return (
    <Card
      id={ZONES_DISPLAY_NAMES[pillar]}
      showBackground={false}
      loading={isLoading}
      className={styles.zoneDurationsBox}
    >
      <CardHeader
        title={ZONES_DISPLAY_NAMES[pillar]}
        moreInfoText={ZONES_MORE_INFO_TEXT[pillar]}
      />
      {activity && (
        <div className={styles[`${pillar}ZoneDurationsChart`]}>
          <HorizontalBarGraph
            values={convertDurations(activity.stage_durations)}
            colors={COLORS_MAP[pillar]}
            yLabels={ZONE_LABELS_MAP[pillar]}
            tickFormatter={durationFormatter}
          />
        </div>
      )}
    </Card>
  );
}

export default ZoneDurationsBarCard;
