import dayjs from 'dayjs';
import duration, { DurationUnitType } from 'dayjs/plugin/duration';
import {
  MetricOverview,
  MetricType, RecoveryMetricType, SleepMetricType, StrainMetricType,
} from 'types/analytics';

dayjs.extend(duration);

export const convertStrainToString = (num: number) => {
  if (num === null) {
    return null;
  }
  return num.toFixed(1);
};

export const convertToNumberLocale = (num: number) => {
  if (num) {
    return Math.round(num).toLocaleString('en-US');
  }

  if (num === 0) {
    return '0';
  }
  return null;
};

export const convertHRVtoString = (num: number) => Math.round(num * 1000).toString();

export const roundToOneDecimal = (val: number): number => Math.round(val * 10) / 10;

export const roundToOneDecimalString = (val: number): string => val.toFixed(1);

export const convertSecondToMillisecond = (val: number): number => Math.round(val * 1000);

export const convertMillisecondsToSecond = (val: number): number => val / 1000;

export const formatNumberToPercent = (val: number): string => (val ? `${Math.round(val)}%` : '');

export const getSleepDuration = (milliseconds: number, unit: DurationUnitType = 'minute'): string => (milliseconds ? dayjs.duration((dayjs.duration(milliseconds).as(unit)), unit).format('H:mm') : '0:00');

export const durationFormatter = (val: number) => dayjs.duration(val, 'seconds').format('H:mm:ss');

export const METRIC_LABEL_FORMATTER: { [key in MetricType]: (val: number) => string } = {
  [StrainMetricType.STRAIN]: convertStrainToString,
  [StrainMetricType.AVG_HR]: null,
  [StrainMetricType.CALORIES]: convertToNumberLocale,
  [RecoveryMetricType.SCORE]: formatNumberToPercent,
  [RecoveryMetricType.HRV]: null,
  [RecoveryMetricType.RHR]: null,
  [RecoveryMetricType.RESPIRATORY_RATE]: roundToOneDecimalString,
  [SleepMetricType.PERFORMANCE]: formatNumberToPercent,
  [SleepMetricType.LATENCY]: getSleepDuration,
  [SleepMetricType.DISTURBANCES]: null,
  [SleepMetricType.DURATION]: getSleepDuration,
  [SleepMetricType.EFFICIENCY]: formatNumberToPercent,
  [SleepMetricType.CONSISTENCY]: null,
};

export const METRIC_Y_AXIS_FORMATTER: { [key in MetricType]: (val: number) => string } = {
  [StrainMetricType.STRAIN]: null,
  [StrainMetricType.AVG_HR]: null,
  [StrainMetricType.CALORIES]: null,
  [RecoveryMetricType.SCORE]: null,
  [RecoveryMetricType.HRV]: null,
  [RecoveryMetricType.RHR]: null,
  [RecoveryMetricType.RESPIRATORY_RATE]: null,
  [SleepMetricType.PERFORMANCE]: null,
  [SleepMetricType.LATENCY]: getSleepDuration,
  [SleepMetricType.DISTURBANCES]: null,
  [SleepMetricType.DURATION]: getSleepDuration,
  [SleepMetricType.EFFICIENCY]: null,
  [SleepMetricType.CONSISTENCY]: null,
};

export const METRIC_NUMBER_FORMATTER: { [key in MetricType]: (val: number) => number } = {
  [StrainMetricType.STRAIN]: roundToOneDecimal,
  [StrainMetricType.AVG_HR]: Math.round,
  [StrainMetricType.CALORIES]: Math.round,
  [RecoveryMetricType.SCORE]: Math.round,
  [RecoveryMetricType.HRV]: convertSecondToMillisecond,
  [RecoveryMetricType.RHR]: Math.round,
  [RecoveryMetricType.RESPIRATORY_RATE]: roundToOneDecimal,
  [SleepMetricType.PERFORMANCE]: Math.round,
  [SleepMetricType.LATENCY]: Math.round,
  [SleepMetricType.DISTURBANCES]: Math.round,
  [SleepMetricType.DURATION]: Math.round,
  [SleepMetricType.EFFICIENCY]: Math.round,
  [SleepMetricType.CONSISTENCY]: Math.round,
};

export const getBarAxisVals = (
  data: MetricOverview,
  padding: number,
  metricName: MetricType,
) => {
  const formatter = METRIC_NUMBER_FORMATTER[metricName];
  const numGridLines = 4;
  // Uses the formatter to handle values such as hrv and time
  const maxVal = Math.max(...data.metrics.map((metric) => formatter(metric.metric_value)));
  // Making top value a value divisible by the padding val
  const paddedMaxVal = Math.ceil(maxVal / padding) * padding;
  const axisVals: number[] = [];
  const step = paddedMaxVal / numGridLines;
  for (let i = 0; i <= numGridLines; i += 1) {
    axisVals.push(i * step);
  }
  return axisVals;
};

const strainAxisVals = [0, 5, 10, 15, 21];
const percentAxisVals = [0, 25, 50, 75, 100];

export const METRIC_TO_BAR_Y_AXIS_VALS : {
  [key in MetricType] : (data: MetricOverview) => number[]
} = {
  [StrainMetricType.CALORIES]: (data) => getBarAxisVals(data, 100, StrainMetricType.CALORIES),
  [StrainMetricType.STRAIN]: () => strainAxisVals,
  [StrainMetricType.AVG_HR]: (data) => getBarAxisVals(data, 10, StrainMetricType.AVG_HR),
  [RecoveryMetricType.SCORE]: () => percentAxisVals,
  [RecoveryMetricType.HRV]: (data) => getBarAxisVals(data, 10, RecoveryMetricType.HRV),
  [RecoveryMetricType.RHR]: (data) => getBarAxisVals(data, 10, RecoveryMetricType.RHR),
  [RecoveryMetricType.RESPIRATORY_RATE]: (
    data,
  ) => getBarAxisVals(data, 5, RecoveryMetricType.RESPIRATORY_RATE),
  [SleepMetricType.PERFORMANCE]: () => percentAxisVals,
  [SleepMetricType.DURATION]: (data) => getBarAxisVals(data, 1000, SleepMetricType.DURATION),
  [SleepMetricType.LATENCY]: (data) => getBarAxisVals(data, 1000, SleepMetricType.LATENCY),
  [SleepMetricType.CONSISTENCY]: () => percentAxisVals,
  [SleepMetricType.DISTURBANCES]: (data) => getBarAxisVals(data, 5, SleepMetricType.DISTURBANCES),
  [SleepMetricType.EFFICIENCY]: () => percentAxisVals,
};

// Creates axis vals by adding 1x the range (difference between the min and max values from data)
// to both the min and max values and creating grid values from there.
// These do not have to start at zero
// Ex. minVal = 24, maxVal = 40. Create grid values from 8 to 56
export const getLineAxisVals = (
  data: MetricOverview,
  metricName: MetricType,
) => {
  const formatter = METRIC_NUMBER_FORMATTER[metricName];
  // We actually want 5 gridlines,
  // but we have a starting value, so only want to get 4 more lines after that
  const numGridLines = 4;
  // Uses the formatter to handle values such as hrv and time
  const allMetricValues: number[] = data.metrics.map((metric) => formatter(metric.metric_value));
  const maxVal = Math.max(...allMetricValues);
  const minVal = Math.min(...allMetricValues);

  // Set range to difference between max and min value
  // Set range to minVal if range would create negative axes values
  let range = maxVal - minVal;
  if (minVal - range < 0) {
    range = minVal;
  }
  // Max and min grid vals. Making sure they're divisible by the number of grid lines we will have
  const paddedMinVal = Math.floor((minVal - range) / numGridLines) * numGridLines;
  const paddedMaxVal = Math.ceil((maxVal + range) / numGridLines) * numGridLines;

  // Creating the axis valuesfrom paddedMinVal to paddedMaxVal
  const axisVals: number[] = [];
  const step = (paddedMaxVal - paddedMinVal) / numGridLines;
  for (let i = 0; i <= numGridLines; i += 1) {
    axisVals.push(paddedMinVal + (i * step));
  }
  return axisVals;
};

export const METRIC_TO_LINE_Y_AXIS_VALS : {
  [key in MetricType] : (data: MetricOverview) => number[]
} = {
  [StrainMetricType.CALORIES]: (data) => getLineAxisVals(data, StrainMetricType.CALORIES),
  [StrainMetricType.STRAIN]: () => strainAxisVals,
  [StrainMetricType.AVG_HR]: (data) => getLineAxisVals(data, StrainMetricType.AVG_HR),
  [RecoveryMetricType.SCORE]: () => percentAxisVals,
  [RecoveryMetricType.HRV]: (data) => getLineAxisVals(data, RecoveryMetricType.HRV),
  [RecoveryMetricType.RHR]: (data) => getLineAxisVals(data, RecoveryMetricType.RHR),
  [RecoveryMetricType.RESPIRATORY_RATE]: (
    data,
  ) => getLineAxisVals(data, RecoveryMetricType.RESPIRATORY_RATE),
  [SleepMetricType.PERFORMANCE]: () => percentAxisVals,
  [SleepMetricType.DURATION]: (data) => getLineAxisVals(data, SleepMetricType.DURATION),
  [SleepMetricType.LATENCY]: (data) => getLineAxisVals(data, SleepMetricType.LATENCY),
  [SleepMetricType.CONSISTENCY]: () => percentAxisVals,
  [SleepMetricType.DISTURBANCES]: (data) => getLineAxisVals(data, SleepMetricType.DISTURBANCES),
  [SleepMetricType.EFFICIENCY]: () => percentAxisVals,
};
