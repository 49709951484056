import { useState } from 'react';
import {
  AccordionV2, Button, classes, Icon,
} from '@whoop/web-components';
import { BODY_SHORT_1 } from 'components/text';
import { SignupAccountType, SignupDetails } from 'types/admin';
import { adminClient } from 'api';
import { UseStatePropType } from 'types/useStatePropType';
import { PAGE_STATE } from 'register/constants';
import { useNavigate } from 'react-router-dom';
import LoadingPage from 'loading';
import styles from '../preRegistration.module.scss';
import { createAccordionSection } from '../preRegistration.util';

type ChangeEmailStepProps = {
  image: string;
  email: string;
  token: string;
  backStep: () => void;
  setStep: UseStatePropType<string>;
};

function ChangeEmailStep({
  image, email, token, backStep, setStep,
}: ChangeEmailStepProps) {
  const navigate = useNavigate();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const splitEmail = email.split('@');

  splitEmail.splice(1, 0, '+staff@');
  const suggestedEmail = splitEmail.join('');

  const errorMessage = (
    <>
      <div className={styles.pageWrapper} />
      <div className={styles.infoTooltip}>
        <div className={styles.tooltipContent}>
          <button
            type="button"
            aria-label="close error message"
            className={styles.closeButton}
            onClick={() => setShowErrorMessage(false)}
          >
            <Icon className={styles.closeIcon} name="cross" />
          </button>
          <BODY_SHORT_1 className={classes(styles.headerText, styles.bold)}>
            Your email is still registered on app.whoop.com
          </BODY_SHORT_1>
          <div className={styles.detailsText}>
            <BODY_SHORT_1>
              Our system indicates
              {' '}
              {email}
              {' '}
              is still being used on app.whoop.com.
            </BODY_SHORT_1>
            <BODY_SHORT_1>
              Make sure you use a different email and save the change on app.whoop.com,
              and try it again.
            </BODY_SHORT_1>
            <BODY_SHORT_1>
              If this issue persists, please reach out
              to your customer success manager.
            </BODY_SHORT_1>
          </div>
        </div>
      </div>
    </>
  );

  const handleCheckUpdatedEmail = async () => {
    setLoading(true);
    try {
      const data: SignupDetails = await adminClient.getSignupDetailsByInviteToken(token);
      if (data.account_type !== SignupAccountType.STAFF) {
        setStep(PAGE_STATE.CREATE_ACCOUNT);
      } else {
        setShowErrorMessage(true);
      }
    } catch (error) {
      if (error.response.status === 404) {
        // Invalid token
        navigate('/access-restricted');
      } else if (error.response.status === 400) {
        // Invite already used
        navigate('/login');
      } else {
        throw new Error('Unhandled error: ', error);
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <LoadingPage />
    );
  }

  return (
    <div className={styles.preRegistrationContainer}>
      <div className={classes(styles.instructions, styles.bringToTop)}>
        <p className={styles.stepText}>STEP 2/2</p>
        <h2>Change email address</h2>
        <ul className={styles.list}>
          <li>
            On app.whoop.com, under Profile Information,
            <span className={styles.bold}>
              {' '}
              change your email to
              {' '}
              {suggestedEmail}
              {' '}
              and Press Save.
            </span>
          </li>
          <li>
            After you save your changes, continue to the next step on this page.
          </li>
        </ul>
        <div className={styles.accordionContainer}>
          <BODY_SHORT_1 className={classes(styles.bold, styles.faq)}>FAQ&apos;s</BODY_SHORT_1>
          <AccordionV2
            className={styles.accordion}
            sections={createAccordionSection(email)}
            theme="dark"
          />
        </div>
        <div className={styles.flowButtons}>
          <Button
            label="Back"
            onClick={backStep}
            theme="dark"
            variant="normal"
          />
          <Button
            label="Continue"
            onClick={() => handleCheckUpdatedEmail()}
            theme="enterprise"
            variant="primary"
          />
          {showErrorMessage && errorMessage}
        </div>
      </div>
      <img className={styles.image} src={image} alt="change email step" />
    </div>
  );
}

export default ChangeEmailStep;
