import { client } from 'api/apiClient';
import { PrivacyLevel } from 'types/dashboardUser';

const accountUrl = 'enterprise-service/v1';

export type JobTitle = {
  job_title_id: string;
  job_title_name: string;
  privacy_level?: PrivacyLevel; // empty for job titles that are unassigned
  salesforce_account_id: string;
};

export type UpdateJobTitle = {
  job_title_id: string;
  privacy_level: PrivacyLevel;
  previous_job_title_id: string;
  salesforce_account_id: string;
};

export const getAccountJobTitles = async () => {
  const response = await client.get<JobTitle[]>(`${accountUrl}/account-job-title`);
  return response.data;
};

export const createAccountJobTitle = async (jobTitleId: string, accountId: string) => {
  const response = await client.post(`${accountUrl}/account-job-title`, {
    job_title_id: jobTitleId,
    salesforce_account_id: accountId,
  });
  return response.data;
};

export const updateAccountJobTitle = async (
  newJobTitleId: string,
  previousJobTitleId: string,
  privacyLevel: PrivacyLevel,
  accountId: string,
) => {
  const updatedJobTitle = {
    job_title_id: newJobTitleId,
    previous_job_title_id: previousJobTitleId,
    privacy_level: privacyLevel,
    salesforce_account_id: accountId,
  };
  const response = await client.put<UpdateJobTitle>(`${accountUrl}/account-job-title`, updatedJobTitle);
  return response.data;
};

export const deleteAccountJobTitle = async (jobTitleId: string, accountId: string) => {
  const response = await client.delete(
    `${accountUrl}/account-job-title`,
    { data: { job_title_id: jobTitleId, salesforce_account_id: accountId } },
  );
  return response.data;
};
