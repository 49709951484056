import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { trackEvent } from 'helpers/analyticsTracking';
import { useEffect } from 'react';
import MessagePage from '../messagePage';
import styles from './maintenancePage.module.scss';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

type MaintenancePageProps = {
  dateTime: string;
};

function MaintenancePage({ dateTime }: MaintenancePageProps) {
  const whoopMaintenanceDate = dayjs(dateTime);
  // get timezone from user's browser
  const browserTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // maintenance window is supposed to be 3 hours
  const endTime = whoopMaintenanceDate.tz(browserTZ).add(3, 'hours').format('h A (z)');

  const handleStatusClick = () => {
    trackEvent('Maintenance Page - Status Page Click');
  };

  useEffect(() => {
    trackEvent('Maintenance Page - Visit');
  }, []);

  return (
    <MessagePage
      icon="no_internet"
      title="The service is interrupted due to ongoing maintenance"
      subtitle={(
        <>
          {`WHOOP Unite is temporarily unavailable until ${endTime}. Please come back later or `}
          <a className={styles.link} href="https://status.whoop.com/" onClick={handleStatusClick}>
            check our system status.
          </a>
        </>
      )}
      showRedirectButton={false}
    />
  );
}

export default MaintenancePage;
