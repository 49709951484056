import { GroupsLandingStatNames, LandingGroup } from 'api/groupLandingApi';
import { useAccounts } from 'context/account-context';
import PieChart from 'dataVisualizations/pieChart/pieChart';
import { Icon, classes } from '@whoop/web-components';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { hideAverageCell } from 'tableUtils/tableCells';
import { usePrivacy } from 'context/privacy-context';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import { Row } from 'react-table';
import { trackEvent } from 'helpers/analyticsTracking';
import { useMemo } from 'react';
import styles from './groupsTable.module.scss';
import { getPieValAndColor, getTrackedSortDirection } from './groupsTableUtils';
import useGroupsTable, { BaseData } from './useGroupsTable';
import { moveNullRowsToBottom } from '../leaderboardTable/bucketData';

type GroupsTableProps = {
  data: LandingGroup[];
};

function GroupsTable({ data }: GroupsTableProps) {
  const navigate = useNavigate();
  const { account } = useAccounts();
  const [searchParams] = useSearchParams();
  const { isInPrivacyLevel } = usePrivacy();
  const { checkAccess } = useAccounts();
  const canClickRow = isInPrivacyLevel(PrivacyLevel.aggregate_metrics);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    pinnedRow,
    prepareRow,
    state,
  } = useGroupsTable(data);

  const sortedRows = useMemo(() => {
    const sortedColumnKey = state.sortBy[0]?.id;
    return moveNullRowsToBottom(rows, sortedColumnKey);
  }, [rows, state]);

  const handleRowClick = (groupId: number) => {
    if (canClickRow) {
      trackEvent('Insights Page - Group Row Click', { groupId });
      const params = new URLSearchParams(searchParams);
      params.set('groupId', groupId.toString());
      const queryParams = createSearchParams(params).toString();
      navigate(`${groupId}${queryParams ? `?${queryParams}` : ''}`);
    }
  };

  const renderRow = (row: Row<BaseData>) => {
    prepareRow(row);
    return (
      <tr
        {...row.getRowProps()}
        className={classes(styles.tableRow, canClickRow && styles.clickableRow)}
        onClick={() => handleRowClick(row.original.id)}
      >
        {row.cells.map((cell) => (
          <td className={styles.cell} {...cell.getCellProps()}>
            {cell.row.original.averageHidden && cell.column.id !== 'name' ? hideAverageCell() : cell.render('Cell')}
          </td>
        ))}
      </tr>
    );
  };

  const renderPinnedRow = () => {
    if (!pinnedRow) {
      return null;
    }

    return (
      <>
        {renderRow(pinnedRow)}
        <tr>
          <td className={styles.allMemberBorderBottom} colSpan={pinnedRow.cells.length} />
        </tr>
      </>
    );
  };

  return (
    <table className={styles.groupsTable} {...getTableProps()} cellSpacing="0">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr className={styles.tableHeaderRow} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              const headerVal = data[0].average_stats.find((stat) => stat.name === column.id)?.val;

              const { pieVal, color, displayVal } = getPieValAndColor(
                headerVal,
                column.id as GroupsLandingStatNames,
              );

              return (
                <th
                  className={styles.tableHeader}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  onClick={(e) => {
                    if (checkAccess(DashboardRole.ACCOUNT_MANAGER)
                    && isInPrivacyLevel(PrivacyLevel.aggregate_metrics)) {
                      const sortDirection = getTrackedSortDirection(column.isSortedDesc);
                      trackEvent('Insights Page - Groups Page Sort', { column: column.Header, sortDirection });
                      column.getSortByToggleProps().onClick(e);
                    }
                  }}
                >
                  {column.canSort ? (
                    <div className={styles.headerSection}>
                      {checkAccess(DashboardRole.ACCOUNT_MANAGER) && (
                      <div className={styles.tableHeaderChart}>
                        <PieChart
                          pieValue={pieVal}
                          displayVal={displayVal}
                          color={color}
                          groupsPage
                        />
                      </div>
                      )}
                      <div className={styles.tableHeaderText}>
                        {column.render('Header')}
                        {column.isSorted && <Icon name={column.isSortedDesc ? 'tiny_caret_down' : 'tiny_caret_up'} />}
                      </div>
                    </div>
                  ) : (
                    <span className={styles.allGroupsText}>
                      {account?.account_name}
                    </span>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody className={classes(pinnedRow && styles.tableBody)} {...getTableBodyProps()}>
        {renderPinnedRow()}
        {sortedRows.map((row) => renderRow(row))}
      </tbody>
    </table>
  );
}

export default GroupsTable;
