import { Icon } from '@whoop/web-components';
import { SENTIMENT } from 'api/groupLandingApi';
import styles from './trendIndicatorBox.module.scss';
import { getIcon, getTrendColor } from './trendIndicator.utils';

type TrendCompProps = {
  diff: number;
  parsedDiff: string;
  trend: SENTIMENT;
};

function TrendIndicatorBox({ diff, parsedDiff, trend }: TrendCompProps) {
  const { background, color } = getTrendColor(trend);
  return (
    <div className={styles.trendBox} style={{ backgroundColor: background, color }}>
      {diff !== null && <Icon name={getIcon(diff)} className={styles.trendIcon} />}
      <span>
        {parsedDiff}
      </span>
    </div>
  );
}

export default TrendIndicatorBox;
